import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import Tooltip from 'components/application/Tooltip';
import GreyCrossIcon from '-!svg-react-loader?name=GreyTickIcon!icons/status-ic-grey-cross.svg';
import PersonnelTrainingDownload from 'components/training/PersonnelTrainingDownload';
import GreyTickIcon from '-!svg-react-loader?name=GreyTickIcon!icons/status-ic-grey-tick.svg';
import RedCrossIcon from '-!svg-react-loader?name=RedCrossIcon!icons/status-ic-red.svg';
import GreenTick from '-!svg-react-loader?name=GreenTick!icons/status-ic-green.svg';
import AmberIcon from '-!svg-react-loader?name=AmberIcon!icons/status-ic-amber.svg'

export default function RoleTrainingBar(props) {

  const shouldTruncateRoleName = props.role && props.role.attributes.position.length > 150;
  const roleContext = props.role && (
    <Fragment>
      &nbsp;for the&nbsp;
      <span className='tw-font-medium'>
        {props.role.attributes.position}
      </span>
      &nbsp;role
    </Fragment>
  )

  const activeContent = (() => {
    switch (props.courseTrainingStatus) {
      case 'noRequirements': return (
        <div className='flex flex--vertically-centered m-l--4'>
          <GreyTickIcon className='[&_rect]:tw-fill-grey-300 tw-h-7' />
          <span className='m-l-12'>{props.name} doesn't have any required training</span>
        </div>
      )

      case 'expiring': return (
        <div className='flex flex--vertically-centered m-l--4'>
          <AmberIcon className='[&_rect]:tw-fill-amber-400 tw-h-7' />
          <span
            className={`m-l-12 mw-545 truncated-text-container__two-lines${shouldTruncateRoleName ? ' truncated-text-container__two-lines-overflow' : ''}`}
          >
            {props.name} has the required training
            {roleContext} but it expires soon
          </span>
        </div>
      )

      case 'invalid': return (
        <div className='flex flex--vertically-centered m-l--4'>
          <RedCrossIcon className='[&_rect]:tw-fill-red-500 tw-h-7' />
          <span
            className={`m-l-12 mw-545 truncated-text-container__two-lines${shouldTruncateRoleName ? ' truncated-text-container__two-lines-overflow' : ''}`}
          >
            {props.name} doesn't have the required training
            {roleContext}
          </span>
        </div>
      )

      case 'valid': return (
        <div className='flex flex--vertically-centered m-l--4'>
          <GreenTick className='[&_rect]:tw-fill-green-400 tw-h-7' />
          <span
            className={`m-l-12 mw-545 truncated-text-container__two-lines ${shouldTruncateRoleName ? 'truncated-text-container__two-lines-overflow' : ''}`}
          >
            {props.name} has the required training
            {roleContext}
          </span>
        </div>
      )
    }
  })()


  const archivedContent = (() => {
    return (
      <div className='flex flex--vertically-centered m-l--4'>
        <GreyCrossIcon className='[&_rect]:tw-fill-grey-300 tw-h-7' />
        <span className='m-l-12'>{props.name} is archived</span>
      </div>
    )
  })()

  let disabledReason;
  if (props.isArchived) {
    disabledReason = 'isArchived';
  } else if (props.courseTrainingStatus === 'noRequirements' && props.training.length == 0) {
    disabledReason = 'noRecords';
  } else if (!props.trainingEvidenceProcessed) {
    disabledReason = 'processingAttachments';
  }

  const disabledTooltip = {
    isArchived: 'Report can\'t be downloaded because the personnel is archived',
    noRecords: 'No records to download',
    processingAttachments: 'Currently uploading evidence'
  }[disabledReason];

  const content = (
    props.isArchived ? archivedContent : activeContent
  )

  return (
    <React.Fragment>
      <RowBar
        content={!props.isLoading && content}
        modifiers={!props.isLoading && props.roleHasRequiredCourses ? ['large', 'border-bottom-none'] : ['large']}
        actions={
          <div className='tooltip-parent'>
            <PersonnelTrainingDownload
              trainingEvidenceProcessed={props.trainingEvidenceProcessed}
              onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
              personnelId={props.personnelId}
              roleId={props.role ? props.role.id : null}
              disabled={!!disabledReason}
            />
            {disabledReason && (<Tooltip placement='top' trigger='hover' tooltip={disabledTooltip} />)}
          </div>
        }
      />
    </React.Fragment>
  )
}

RoleTrainingBar.propTypes = {
  role: PropTypes.object,
  name: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
  courseTrainingStatus: PropTypes.string.isRequired,
  loaded: PropTypes.bool,
  onAddRole: PropTypes.func,
  roleHasRequiredCourses: PropTypes.bool
}

RoleTrainingBar.defaultProps = {
  isLoading: false,
  roleHasRequiredCourses: true
}
