import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { isHrefOrOnClickRequired } from "./propTypes";

export default function FilledButton({ children, className, color, disabled = false, onDark = false, type = 'button', ...restProps }) {
  const attributes = {
    className: classNames(
      'tw-apply-filled-button tw-apply-filled-button--md',
      onDark ? 'tw-apply-filled-button--on-dark' : 'tw-apply-filled-button--on-light',
      {
        [`tw-apply-filled-button--${color}`]: !!color
      },
      className
    ),
    ...restProps
  };

  return restProps.href ? (
    <a {...attributes} data-disabled={disabled}>
      {children}
    </a>
  ) : (
    <button {...attributes} disabled={disabled} type={type}>
      {children}
    </button>
  )
}

FilledButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['mint', 'blue', 'red']),
  disabled: PropTypes.bool,
  href: isHrefOrOnClickRequired,
  onClick: isHrefOrOnClickRequired,
  onDark: PropTypes.bool,
  type: PropTypes.string
}
