import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function DestroyModal(props) {
  const {
    isOpen,
    displayText,
    confirmationText,
    additionalContent,
    onClose,
    onDestroy
  } = props;

  const onDestroyClick = () => {
    onClose()
    onDestroy()
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={onDestroyClick}>
        {confirmationText}
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-grey-900 tw-text-l tw-font-semibold m-0 p-0'>{displayText}</h2>
        {additionalContent}
      </div>
    </ModalBox>
  )
}

DestroyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  displayText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  additionalContent: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired
};

DestroyModal.defaultProps = {
  additionalContent: null
};
