import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';
import moment from 'moment';

import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import ActionsPill from 'components/application/ActionsPill';
import CalendarIcon from '-!svg-react-loader?name=CalendarIcon!icons/calendar.svg';
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/double-arrow-rotate.svg';
import { autoEnrolmentStatus, autoEnrolmentStatusText } from '../helpers/resources/autoEnrolments';

const bookingTooltipOptions = {
  expiring: 'Training is expiring soon and requires booking',
  expired: 'Training is expired and requires booking',
  missing: 'Training is missing and requires booking',
  personnelEmailUnavailable: 'Personnel must have an email address to book eLearning',
  creditInfoUnavailable: 'There was a problem displaying your remaining credits'
};

const enrolmentTooltipText = ({ booking, booker, registration }) => {
  let tooltipText = ''

  if (booking.attributes.autoEnrolled) {
    tooltipText = `Course was auto-enrolled on ${moment(booking.attributes.createdAt).format('D MMM YY')}`
  } else if (booker) {
    tooltipText = `Course was enrolled by ${booker.attributes.firstName} ${booker.attributes.lastName} on ${moment(booking.attributes.createdAt).format('D MMM YY')}`
  } else {
    tooltipText = `Course was enrolled on ${moment(booking.attributes.createdAt).format('D MMM YY')}`
  }

  return registration ? `${tooltipText} and was last accessed on ${moment(registration.attributes.updatedAt).format('D MMM YY')}` : tooltipText
}

export default function ELearningBookings({
  booking,
  booker,
  course,
  eLearningCourse,
  eLearningAllowance,
  registration,
  latestTraining,
  currentTrainingStatus,
  currentPersonnel,
  isCreditInfoUnavailable,
  isEligibleForManualBooking,
  isEligibleForAutoEnrolment,
  autoEnrolmentExclusion,
  onAddELearningBooking
}) {

  const { isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();
  const currentActor = useCurrentActor();
  const isPersonnelEmailUnavailable = !currentPersonnel.email;
  const isELearningNonCustom = eLearningCourse && !eLearningCourse.attributes.custom;
  let bookingTooltipKey;

  if (isELearningNonCustom && isCreditInfoUnavailable) {
    bookingTooltipKey = 'creditInfoUnavailable'
  } else if (isPersonnelEmailUnavailable) {
    bookingTooltipKey = 'personnelEmailUnavailable'
  } else {
    bookingTooltipKey = currentTrainingStatus
  };

  if (registration) {
    return (
      <ActionsPill
        color='green'
        displayText='eLearning in progress'
        icon={<CalendarIcon height={16} width={16} />}
        tooltipText={enrolmentTooltipText({ booking, booker, registration })}
      />
    )
  }

  if (booking) {
    return (
      <ActionsPill
        color='green'
        displayText='eLearning enrolled'
        icon={<CalendarIcon height={16} width={16} />}
        tooltipText={enrolmentTooltipText({ booking, booker, registration })}
      />
    )
  }

  if (currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register']) && isEligibleForAutoEnrolment && !autoEnrolmentExclusion) {
    const status = autoEnrolmentStatus({
      isELearningNonCustom,
      currentELearningCredits: eLearningAllowance.loaded && eLearningAllowance.currentCredits,
      isCreditInfoUnavailable,
      isPersonnelEmailUnavailable,
      isAutoEnrolmentIncludingMissingTraining,
      latestTraining: latestTraining,
      courseExpiringDuration: course.attributes?.expiringDuration
    });

    const statusText = autoEnrolmentStatusText({
      status,
      latestTrainingExpiryDate: latestTraining?.attributes?.expiryDate,
      courseExpiringDuration: course.attributes?.expiringDuration
    })

    const isAutoEnrolActionBlocked = isPersonnelEmailUnavailable || (isELearningNonCustom && (isCreditInfoUnavailable || status.includes('insufficientCredits')));

    return (
      <ActionsPill
        color={`${isAutoEnrolActionBlocked ? 'amber' : 'green'}`}
        displayText='Auto-enrols'
        icon={<AutoEnrolIcon height={16} width={16} />}
        tooltipText={statusText}
      />
    )
  }

  if (isEligibleForManualBooking) return (
    <ActionsPill
      color='cyan'
      isDisabled={isPersonnelEmailUnavailable || isCreditInfoUnavailable}
      displayText='Enrol eLearning'
      icon={<CalendarIcon height={16} width={16} />}
      tooltipText={bookingTooltipOptions[bookingTooltipKey]}
      onClick={() => onAddELearningBooking({ course, eLearningCourse })}
    />
  )

  return null
}

ELearningBookings.propTypes = {
  booking: PropTypes.object,
  course: PropTypes.object,
  eLearningCourse: resourceShape('eLearningCourse'),
  eLearningAllowance: PropTypes.object,
  registration: PropTypes.object,
  latestTraining: PropTypes.object,
  autoEnrolmentExclusion: resourceShape('autoEnrolmentExclusion'),
  currentTrainingStatus: PropTypes.string,
  currentPersonnel: PropTypes.object,
  isCreditInfoUnavailable: PropTypes.bool,
  isEligibleForManualBooking: PropTypes.bool,
  onAddELearningBooking: PropTypes.func
}

ELearningBookings.defaultProps = {
  hasPersonnelEditableAccess: true
}
