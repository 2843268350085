import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import { currencyCodes } from 'components/helpers/currency';

export default function CurrencyField(props) {
  const {
    label,
    name,
    value,
    currencyCode = 'GBP',
    isRequired = false,
    removeErrorStyling,
    fieldError,
    onChange
  } = props;

  const separatorRegex = new RegExp(`^\\d+(\\${currencyCodes[currencyCode].separator}\\d{0,2})?`);

  const handleInputChange = (event) => {
    onChange(event, (value) => {
      const matchedValue = value.match(separatorRegex);

      return matchedValue ? matchedValue[0] : ''
    })
  }

  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputClassName = isInvalid ? (
    'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600'
  ) : (
    'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300'
  );

  return (
    <div className='text-field'>
      <div className='form-group'>
        <label className={`field__label tw-font-medium${isRequired ? " after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal" : ''}${isInvalid ? " after:tw-text-red-600" : ''}`} htmlFor={name}>
          {label}
        </label>
        <div className='flex flex--vertically-centered field__input-container tw-relative'>
          <div className='tw-absolute tw-left-3 tw-top-[10px] tw-ml-[1px] tw-mt-[1px]'>
            {currencyCodes[currencyCode].unit}
          </div>
          <input
            id={voca.snakeCase(name)}
            type='text'
            name={name}
            value={value}
            className={`field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto tw-pl-7 ${inputClassName}`}
            onChange={handleInputChange}
            onFocus={removeErrorStyling}
          />
        </div>
      </div>
    </div>
  )
}

CurrencyField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  isRequired: PropTypes.bool,
  removeErrorStyling: PropTypes.func.isRequired,
  fieldError: PropTypes.object,
  onChange: PropTypes.func.isRequired
};
