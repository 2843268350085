import { connect } from 'react-redux';

import { addError } from 'action-creators/ErrorActionCreators';

import StepRow from 'components/sequences/StepRow';

const mapStateToProps = (state, ownProps) => {
  const step = state.domain.methodStepCollection.data[ownProps.stepId];
  const stepsListIsOrdering = state.ui.ordering[`methodSequenceSteps--${step.relationships.methodSequence.data.id}`].inProgress;
  const stepNameKey = `stepName--${step.id}`;
  const stepFieldText = state.application.editingContent.hasOwnProperty(stepNameKey) ? state.application.editingContent[stepNameKey] : step.attributes.text;
  const isEditingToggled = !!(!stepsListIsOrdering && state.ui.toggling[stepNameKey]);
  const areAttachmentsAllowed = state.application.areAttachmentsAllowed;

  return {
    areAttachmentsAllowed,
    isEditingToggled,
    step,
    stepFieldText,
    stepNameKey,
    stepsListIsOrdering
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload))
  }
}

const AvailableStep = connect(mapStateToProps, mapDispatchToProps)(StepRow)

export default AvailableStep
