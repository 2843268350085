import React from "react";
import PropTypes from "prop-types";
import voca from "voca";
import _ from "lodash";

import Tooltip from "components/application/Tooltip";

const staticLifecycleStatus = {
  live: { indicatorClassName: "tw-bg-green-400" },
  future: { indicatorClassName: "tw-bg-amber-400" },
  ended: { indicatorClassName: "tw-bg-grey-300" },
  notSet: { indicatorClassName: "tw-bg-white tw-border-2 tw-border-solid tw-border-grey-300" }
}

export default function LifecycleStatusIndicator({ status, ramsSingularName }) {
  const capitalizedRamsSingularName = voca.capitalize(ramsSingularName)

  const dynamicLifecycleStatus = {
    live: { tooltipText: `${capitalizedRamsSingularName} is live` },
    future: { tooltipText: `${capitalizedRamsSingularName} has future start date` },
    ended: { tooltipText: `${capitalizedRamsSingularName} ended` },
    notSet: { tooltipText: `${capitalizedRamsSingularName} date not set` }
  };

  const lifecycleStatus = _.merge(staticLifecycleStatus, dynamicLifecycleStatus);

  const displayableStatus = lifecycleStatus[status];

  return displayableStatus ? (
    <div className='tooltip-parent flex'>
      <div className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${displayableStatus.indicatorClassName}`} />
      <Tooltip placement='top' trigger='hover' tooltip={displayableStatus.tooltipText} />
    </div>
  ) : null
}

LifecycleStatusIndicator.propTypes = {
  status: PropTypes.oneOf(Object.keys(staticLifecycleStatus)),
  ramsSingularName: PropTypes.string.isRequired
};
