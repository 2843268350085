import { setupCustomFieldRichTextEditor } from '../../../shared/rich-text-editor';

$(function() {
  if ($('.project-form .rich-text-editor').length > 0) {
    const editors = $('.project-form .rich-text-editor');

    for (let i = 0; i < editors.length; i++) {
      setupCustomFieldRichTextEditor({ selector: `#${editors[i].id}` })
    };
  }
})
