import React from "react";
import PropTypes from "prop-types";

const riskAssessmentsStatus = {
  notApplicable: { indicatorClassName: "tw-bg-grey-300", textClassName: "tw-text-grey-300", text: "Not applicable" },
  outOfDate: { indicatorClassName: "tw-bg-red-500", textClassName: "tw-text-red-500", text: "Out of date" },
  upToDate: { indicatorClassName: "tw-bg-green-400", textClassName: "tw-text-green-400", text: "Up to date" }
};

export default function RiskAssessmentsStatusIndicator({ status }) {
  const displayableStatus = riskAssessmentsStatus[status];

  return displayableStatus ? (
    <div className="tw-flex tw-items-center">
      <div className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${displayableStatus.indicatorClassName}`} />
      <div className={`tw-text-s tw-font-medium tw-tracking-wide tw-ml-1 ${displayableStatus.textClassName}`}>
        {displayableStatus.text}
      </div>
    </div>
  ) : null
}

RiskAssessmentsStatusIndicator.propTypes = {
  status: PropTypes.oneOf([...Object.keys(riskAssessmentsStatus), "noStatus"])
};
