import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import { reducer, initialState } from 'reducers/collectionReducer';
import { initialMember, getMember, getCollection } from 'components/helpers/resources/attachments';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import Attachments from 'components/attachments/Attachments';

export default function AttachmentsField(props) {
  const {
    label,
    tooltip,
    name,
    value,
    isVisible,
    seedAttachments,
    onChange,
    onError,
    onUploadSuccess,
    maxUploads
  } = props;

  const [attachments, dispatch] = useReducer(reducer, initialState);

  const createAttachment = ({ attachment, fileType, uploadKey }) => {
    axios
      .post('/attachments', {
        additional_processing: fileType === 'pdf',
        attachment: { attached_key: uploadKey },
        type: fileType
      })
      .then(response => {
        const member = getMember({
          attachment: response.data.data,
          coverImages: response.data.included.filter(obj => obj.type === 'coverImage')
        });

        dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
      })
      .catch(_error => onError())
  };

  const updateAttachment = ({ attachment, params }) => {
    axios
      .patch(`/attachments/${attachment.hashid}`, { type: attachment.recordType, attachment: params })
      .then(response => {
        const member = getMember({
          attachment: response.data.data,
          coverImages: response.data.included.filter(obj => obj.type === 'coverImage')
        });

        dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
      })
      .catch(_error => onError())
  };

  const deleteAttachment = ({ attachment }) => {
    dispatch({ type: 'REMOVE', key: attachment.key })
  };

  const handleUpload = file => dispatch({ type: 'ADD', payload: {...initialMember, file: file } });

  const handleAttachmentsChange = (event, { attachment }) => dispatch({ type: 'CHANGE', event: event, key: attachment.key });

  const handlePollingSuccess = ({ response, attachment }) => {
    const member = getMember({
      attachment: response.data,
      coverImages: response.included.filter(obj => obj.type === 'coverImage')
    });

    if (onUploadSuccess) {
      onUploadSuccess()
    }
    dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
  };

  useEffect(() => {
    if (isVisible) {
      const payload = seedAttachments.data.length === 0 ? [] : getCollection(seedAttachments);

      dispatch({ type: 'LOAD', payload: payload })
    }
  }, [isVisible])

  useEffect(() => {
    const newValue = attachments.collection.map(a => Number(a.id));
    const attachmentsHaveBeenModified = JSON.stringify(value) !== JSON.stringify(newValue);
    const attachmentsHaveBeenPersisted = attachments.collection.every(a => a.id);

    if (attachments.collection.length === 0 || attachmentsHaveBeenPersisted) {
      attachmentsHaveBeenModified && onChange({ target: { name: name, value: newValue } })
    }
  }, [JSON.stringify(attachments.collection)])

  return (
    <div className='attachments-field'>
      <div className='form-group'>
        <label className='field__label tw-font-medium' htmlFor={name}>
          {label}
          {tooltip &&
            <Tooltip placement='top' trigger='hover' tooltip={tooltip}>
              <CircleQuestion />
            </Tooltip>
          }
        </label>
        {attachments.loaded && (
          <div className='attachments__wrapper tw-border-grey-300'>
            <Attachments
              mode='compact'
              attachments={attachments.collection}
              createAttachment={createAttachment}
              updateAttachment={updateAttachment}
              deleteAttachment={deleteAttachment}
              onError={onError}
              onUpload={handleUpload}
              onChange={handleAttachmentsChange}
              onPollingSuccess={handlePollingSuccess}
              maxUploads={maxUploads}
            />
          </div>
        )}
      </div>
    </div>
  )
}

AttachmentsField.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  isVisible: PropTypes.bool,
  seedAttachments: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

AttachmentsField.defaultProps = {
  isVisible: true,
  seedAttachments: { data: [], included: [] }
}
