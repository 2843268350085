import React from "react";
import qs from "qs";

import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useRamsAnalyticsDashboardContext } from "components/contexts/RamsAnalyticsDashboardContext";
import { useBreadBoard } from "components/contexts/Toaster";
import useFilters from "components/ramsAnalytics/hooks/useFilters";
import useProjectsModal from "components/ramsAnalytics/hooks/useProjectsModal";

import { filtersListFormatter, filtersFormatter } from "components/ramsAnalytics/helpers/reportHeader";
import { formatPaginatedProjects } from "components/ramsAnalytics/helpers/projectsModal";
import { getNextPageParam } from "components/ramsAnalytics/helpers/reactQuery";

import Card from "components/ramsAnalytics/components/Card";
import ReportHeader from "components/ramsAnalytics/components/ReportHeader";
import StatusReportBody from "components/ramsAnalytics/components/StatusReportBody";
import ProjectsModal from "components/ramsAnalytics/components/ProjectsModal";
import NoDataMessage from "components/ramsAnalytics/components/NoDataMessage";

const projectsModalAppliedFilters = {
  "Approved": { approved_state: true, approved_with_conditions_state: true },
  "Pending approval": { pending_state: true },
  "Rejected": { rejected_state: true },
  "Not submitted": { not_submitted_state: true }
};

const staticChartData = [
  { countKey: "approvedCount", cellClassName: "tw-fill-green-400", dotClassName: "tw-bg-green-400", name: "Approved" },
  { countKey: "pendingCount", cellClassName: "tw-fill-amber-300", dotClassName: "tw-bg-amber-300", name: "Pending approval" },
  { countKey: "rejectedCount", cellClassName: "tw-fill-red-400", dotClassName: "tw-bg-red-400", name: "Rejected" },
  { countKey: "notSubmittedCount", cellClassName: "tw-fill-mutedBlue-300", dotClassName: "tw-bg-mutedBlue-300", name: "Not submitted" }
];

export default function ApprovalStatusCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({ live: true, future: true });

  const fetchApprovalStatusReport = () => axios.post("/rams_analytics/approval_status_report", { approval_status_report: filters });

  const {
    data: approvalStatusReport
  } = useQuery({
    queryKey: ["approvalStatusReport", filters],
    queryFn: fetchApprovalStatusReport,
    keepPreviousData: true,
    onError: breadBoard.addInedibleToast
  });

  const attributes = approvalStatusReport ? approvalStatusReport.data.data.attributes : {};

  const {
    approvedCount,
    approvedWithConditionsCount,
    pendingCount,
    rejectedCount,
    notSubmittedCount,
    liveFilterCount,
    futureFilterCount
  } = attributes ? attributes : {};

  const totalCount = Object.values({ approvedCount, approvedWithConditionsCount, pendingCount, rejectedCount, notSubmittedCount }).reduce((a, b) => a + b, 0);
  const filterCounts = { live: liveFilterCount, future: futureFilterCount };
  const filterDots = { live: "green", future: "amber" };

  const dynamicChartData = [(approvedCount + approvedWithConditionsCount), pendingCount, rejectedCount, notSubmittedCount];

  const chartData = staticChartData.map((data, index) => {
    return { ...data, count: dynamicChartData[index] }
  });

  const { projectsModal, openProjectsModal, closeProjectsModal } = useProjectsModal();

  const fetchProjects = (page) => {
    const queryString = qs.stringify({ filter: { ...filters, ...projectsModal.filters }, page });

    return axios.get(`/rams_analytics/approval_status_report/projects?${queryString}`)
  }

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects
  } = useInfiniteQuery({
    queryKey: ["projects", "approvalStatusReport", { ...filters, ...projectsModal.filters }],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast
  });

  const handleSegmentClick = ({ entry }) => {
    openProjectsModal({
      title: `Approval status: ${entry.name}`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: entry.count }),
      appliedFilters: projectsModalAppliedFilters[entry.name]
    })

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Approval status",
      segment: entry.name,
      filters: filters
    })
  }

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: project click", {
      chart: "Approval status",
      project: project.name,
      filters: filters
    })
  }

  return (
    <Card>
      <ReportHeader
        title="Approval status"
        subTitle={filtersListFormatter({ filters, ramsPluralName: ramsAnalyticsDashboardContext.ramsPluralName })}
        filters={filtersFormatter({ filters, filterCounts, filterDots })}
        minChecked={1}
        onFilterChange={handleFilterChange}
      />
      {approvalStatusReport && (
        totalCount > 0 ? (
          <StatusReportBody chartData={chartData} totalCount={totalCount} onSegmentClick={handleSegmentClick} />
        ) : (
          <NoDataMessage message="Please check the applied filters" />
        )
      )}
      <ProjectsModal
        isOpen={projectsModal.isOpen}
        title={projectsModal.title}
        subTitle={projectsModal.subTitle}
        projects={projects}
        hasNextPage={hasNextPageOfProjects}
        closeModal={closeProjectsModal}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
      />
    </Card>
  )
}
