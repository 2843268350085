import HandshqApp from '../handshq-app';

HandshqApp.EnableFormSubmit = function() {
  $('.disable-form-submit :input').change(function() {
    $(':input[type="submit"]').prop('disabled', false);
  });
}

$(function() {
  HandshqApp.EnableFormSubmit()
})
