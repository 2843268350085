$(document).ready( function() {
	if ( $('#warn-on-back-button').length > 0 ) {
		enableNavigationConfirmation();
		browserBackButtonHandler();
	}
});

// Allows for elements that arrive after doc ready
$(document).ajaxComplete( function() {
	if ( $('#warn-on-back-button').length > 0 ) {
		browserBackButtonHandler();
	}
});


function enableNavigationConfirmation () {
	window.onbeforeunload = function() {
		return "You are about to leave this page. Any changes you have made to the content will be lost. Are you sure you want to do this?";
	};
}

function browserBackButtonHandler () {
	// disable onbeforeunload for legal navigation links
	const legalNavigationCss = [ 'a', '.no-back-warning' ];

	legalNavigationCss.forEach( function( css )  {
		$( css ).unbind( 'mousedown' );
		$( css ).unbind( 'mouseup' );
		$( css ).on( 'mousedown', function() {
			window.onbeforeunload = null;
		});
	});
}

export { browserBackButtonHandler };
