import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query'

import SelectedMembersOptions from './SelectedMembersOptions';

export default function SelectedMembersList({
  team,
  domainTeamMembers,
  onChange,
  onTeamMemberDelete
}) {
  const queryClient = useQueryClient()
  const collectedAssignableMembers = queryClient.getQueriesData(['assignableMembers']).map((queryResult) => {
    const [_queryKey, queryData] = queryResult;
    if (!queryData) return null
    return queryData.pages.map((pageData) => pageData.data).flat()
  }).flat().filter(Boolean)

  const currentTeamMembers = team.members.map(member => {
    const selectedMember = (member.persisted ? domainTeamMembers.collection : collectedAssignableMembers).find(assignableMember => assignableMember.id === member.id)
    if(!selectedMember) return null;
    const isStatusChangeEnabled = !!(member.userId && member.personnelId);

    let tooltip;
    if (!isStatusChangeEnabled) {
      tooltip = `
        ${selectedMember.attributes.firstName}
        ${selectedMember.relationships.user.data === null
          ? "is not a user so can't be a manager"
          : "can't be made a member as they do not have a personnel profile in this division"
        }
      `;
    }

    return {
      ...member,
      isStatusChangeEnabled,
      tooltip,
      name: `${selectedMember.attributes.firstName} ${selectedMember.attributes.lastName}`
    }
  }).filter(Boolean);

  return (
    <div className='form-container'>
      {currentTeamMembers.map((member) => {
        return (
          <div
            key={member.id}
            className='tw-group/pill tw-rounded-md tw-bg-grey-050 tw-h-10 chip-item-pill w-100 py-6 px-12 m-b-8 tw-flex tw-relative tw-justify-between tw-items-center'
          >
            <div className='tw-text-s tw-font-medium tw-tracking-wide tw-w-52'>
              {member.name}
            </div>
            <div className='tw-flex tw-items-center'>
              <SelectedMembersOptions member={member} onChange={onChange} />
              <span
                onClick={() => onTeamMemberDelete(member.id)}
                className="collection-select__multivalue-remove m-l-8 circle--remove tw-bg-transparent hover:tw-bg-red-600 before:tw-content-[''] before:tw-bg-grey-700 hover:before:tw-bg-white after:tw-content-[''] after:tw-bg-grey-700 hover:after:tw-bg-white"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

SelectedMembersList.propTypes = {
  domainTeamMembers: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onTeamMemberDelete: PropTypes.func.isRequired
};
