import React from 'react';
import Toast from 'components/application/Toast';
import { personDisplayName } from 'components/helpers/users';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import { useBreadBoard } from 'components/contexts/Toaster';


export default function ProjectPersonnelAdditionUndoneToast(props) {
  const breadBoard = useBreadBoard();

  return(
    <Toast
      toastKey={props.toastKey}
      burn={breadBoard.handleBurnToast}
      modifiers='no-undo'
      contents={
        <>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>
            Adding&nbsp;
            <span className='tw-font-medium'>
              {personDisplayName(props.personnel.attributes)}
            </span>
            &nbsp;was undone
          </div>
        </>
      }

    />
  )
}
