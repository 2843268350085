import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import Tooltip from 'components/application/Tooltip';

export default function Tab(props) {
  const isActive = props.activeTab === props.label;
  const onClick = () => {
    if (!props.disabled) { props.onTabClick(props.label) }
  }

  const navContent = (
    <React.Fragment>
      {props.icon}
      <span className='tab__text tw-font-medium'>
        {voca.capitalize(props.label)}
      </span>
    </React.Fragment>
  )

  const tabClassName = (() => {
    switch (true) {
      case (props.disabled):
        return "tab--disabled tw-text-grey-300 [&_svg_path]:tw-fill-grey-300";
      case (isActive):
        return "tab--active tw-text-blue-400 after:tw-content-[''] after:tw-border-blue-400 [&_svg_path]:tw-fill-blue-400"
      default:
        return "tw-text-grey-500 [&_svg_path]:tw-fill-grey-500 hover:after:tw-content-[''] hover:after:tw-border-blue-400"
    }
  })()

  return (
    <li
      className={`tab ${tabClassName}`}
      onClick={onClick}
    >
      <div className='tab__content-wrapper'>
        {
          props.disabled && props.tooltipText ? (
          <Tooltip placement='top' trigger='hover' tooltip={props.tooltipText}>
            {navContent}
          </Tooltip>
          ) : (navContent)
        }
      </div>

    </li>
  );
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired
};

Tab.defaultProps = {
  icon: null
}
