import React from 'react';
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "react-query-settings/queryClient";
import AppWrapper from 'components/apps/AppWrapper';
import ProjectActivityTab from 'components/projectActivity/ProjectActivityTab';

export default function ProjectActivityApp({ wrappedProps, contextProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrapper {...contextProps}>
        <ProjectActivityTab {...wrappedProps} />
      </AppWrapper>
    </QueryClientProvider>
  )
}
