import React  from 'react';
import PropTypes from 'prop-types';

import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function ToastUpdateMessage({ message, type }) {
  return(
    <>
      <div className={`toast__icon ${type === 'success' ? 'tw-bg-green-500' : 'tw-bg-red-600'}`}>
        {type === 'success' ? (
          <TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} />
        ) : (
          <CrossIcon className='[&_polygon]:tw-fill-white' width={20} height={20} />
        )}
      </div>
      <div className='toast__text'>
        { message }
      </div>
    </>
  )
}

ToastUpdateMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
