import { enableAllPlugins } from "immer";
enableAllPlugins()
import { produce } from "immer";
import voca from "voca";
import moment from "moment";
import momentTimezone from "moment-timezone";
import qs from "qs";
import _ from "lodash";

import { filterQueryStringSchema } from "components/trainingRegisterReports/schemas/filterQueryStringSchema";
import { estimatesSchema } from "components/trainingRegisterReports/schemas/estimatesSchema";
import { filtersSchema } from "components/trainingRegisterReports/schemas/filtersSchema";

const defaultValidators = [
  { schema: estimatesSchema, key: "estimates" },
  { schema: filtersSchema, key: "filters" },
];

const requiredDatePeriodProps = {
  withinTheNext: ["withinTheNextFrequency", "withinTheNextInterval"],
  withinTheLast: ["withinTheLastFrequency", "withinTheLastInterval"],
  before: ["before"],
  after: ["after"],
  onExactDate: ["onExactDate"],
  customDateRange: ["customDateRangeFrom", "customDateRangeTo"]
};

const trainingReportTypes = ["expiringTraining", "completedTraining", "currentTrainingStatus"];

function formatOption(option) {
  return { value: option, label: voca.capitalize(voca.kebabCase(option).replace(/-/g, " ")) }
}

function validateForm({ formValues, additionalValidators, safe = false }) {
  const validators = [...additionalValidators, ...defaultValidators];

  if (safe) {
    return validators.reduce((acc, validator) => {
      const safeParsedSchema = validator.schema.safeParse(formValues[validator.key]);

      if (safeParsedSchema.error) {
        safeParsedSchema.error.issues.forEach((issue) => {
          const validationErrorKey = issue.path[0];

          if (acc[validationErrorKey]) {
            acc[validationErrorKey].push(issue.message)
          } else {
            acc[validationErrorKey] = [issue.message];
          }
        })
      }

      return acc
    }, {})
  } else {
    validators.forEach((validator) => {
      validator.schema.parse(formValues[validator.key])
    })
  }
}

function applyFormFilter({ formValues, additionalValidators, filter }) {
  filterQueryStringSchema.parse(filter)

  const newFormValues = produce(formValues, draftState => {
    Object.entries(filter).forEach(([filterKey, filterValue]) => {
      if (["divisionId", "roleId", "courseId", "lineManagerId", "teamId"].includes(filterKey)) {
        const filterName = `${filterKey.replace("Id", "")}Filter`;
        const filterIds = filterValue.eq ? [filterValue.eq] : filterValue.in;

        draftState.filters.selectedFilters.push(filterName)
        draftState.filters[filterName].selectedIds = filterIds;
      }
    })
  });

  validateForm({ formValues: newFormValues, additionalValidators })

  return newFormValues
}

function mergeStoredForm({ formValues, additionalValidators, storedFormValues }) {
  const newFormValues = produce(formValues, draftState => { _.merge(draftState, storedFormValues) });

  validateForm({ formValues: newFormValues, additionalValidators })

  return newFormValues
}

function buildFormValues({ type, formValues, additionalValidators, getLocalStorageItem, removeLocalStorageItem }) {
  const searchParams = new URLSearchParams(window.location.search);
  const queryString = searchParams.toString();

  const filter = qs.parse(queryString)["filter"];
  const searchParamsType = trainingReportTypes.includes(searchParams.get("type")) ? searchParams.get("type") : null;

  if (filter && searchParamsType === type) {
    try { return applyFormFilter({ formValues, additionalValidators, filter }) } catch { }
  }

  const storedFormValues = getLocalStorageItem();

  if (storedFormValues) {
    try { return mergeStoredForm({ formValues, additionalValidators, storedFormValues }) } catch { removeLocalStorageItem() }
  }

  return formValues
}

function assignDateParams({ params, key, date }) {
  const dateFormat = "YYYY-MM-DD";
  const london = momentTimezone.tz("Europe/London");

  let eq, gt, gte, lt, lte;

  switch (true) {
    case date.period === "withinTheNext":
      gte = london.clone().format(dateFormat);
      lte = london.clone().add(date.withinTheNextFrequency, date.withinTheNextInterval).format(dateFormat);
      break
    case date.period === "withinTheLast":
      gte = london.clone().subtract(date.withinTheLastFrequency, date.withinTheLastInterval).format(dateFormat);
      lte = london.clone().format(dateFormat);
      break
    case ["thisWeek", "thisMonth", "thisQuarter", "thisYear"].includes(date.period):
      const thisUnitOfTime = date.period.replace("this", "").toLowerCase();

      gte = london.clone().startOf(thisUnitOfTime === "week" ? "isoWeek" : thisUnitOfTime).format(dateFormat);
      lte = london.clone().endOf(thisUnitOfTime === "week" ? "isoWeek" : thisUnitOfTime).format(dateFormat);
      break
    case ["nextWeek", "nextMonth", "nextQuarter", "nextYear"].includes(date.period):
      const nextUnitOfTime = date.period.replace("next", "").toLowerCase();

      gte = london.clone().add(1, nextUnitOfTime).startOf(nextUnitOfTime === "week" ? "isoWeek" : nextUnitOfTime).format(dateFormat);
      lte = london.clone().add(1, nextUnitOfTime).endOf(nextUnitOfTime === "week" ? "isoWeek" : nextUnitOfTime).format(dateFormat);
      break
    case ["lastWeek", "lastMonth", "lastQuarter", "lastYear"].includes(date.period):
      const lastUnitOfTime = date.period.replace("last", "").toLowerCase();

      gte = london.clone().subtract(1, lastUnitOfTime).startOf(lastUnitOfTime === "week" ? "isoWeek" : lastUnitOfTime).format(dateFormat);
      lte = london.clone().subtract(1, lastUnitOfTime).endOf(lastUnitOfTime === "week" ? "isoWeek" : lastUnitOfTime).format(dateFormat);
      break
    case date.period === "today":
      eq = london.clone().format(dateFormat);
      break
    case date.period === "tomorrow":
      eq = london.clone().add(1, "day").format(dateFormat);
      break
    case date.period === "yesterday":
      eq = london.clone().subtract(1, "day").format(dateFormat);
      break
    case date.period === "before":
      lt = moment.parseZone(date.before).format(dateFormat);
      break
    case date.period === "after":
      gt = moment.parseZone(date.after).format(dateFormat);
      break
    case date.period === "onExactDate":
      eq = moment.parseZone(date.onExactDate).format(dateFormat);
      break
    case date.period === "customDateRange":
      gte = moment.parseZone(date.customDateRangeFrom).format(dateFormat);
      lte = moment.parseZone(date.customDateRangeTo).format(dateFormat);
      break
  }

  params[key] = { eq, gt, gte, lt, lte };
}

function assignTrainingStatusFilterParams({ params, trainingStatusFilter }) {
  const availableFilters = Object.keys(trainingStatusFilter);
  const selectedFilters = availableFilters.filter(k => trainingStatusFilter[k]);

  if (availableFilters.length !== selectedFilters.length) {
    params["training_status"] = { in: selectedFilters }
  }
}

function assignEstimatesParams({ params, estimates }) {
  params["cost_and_time_estimate"] = estimates.costAndTime;
}

function filterKey({ selectedFilter }) {
  return voca.snakeCase(selectedFilter.replace('Filter', ''))
}

function assignMultiSelectFilterParams({ params, filters, selectedFilter }) {
  const { selectedIds, ...rest } = filters[selectedFilter];

  params[`${filterKey({ selectedFilter })}_id`] = (selectedIds.length > 1 ? { in: selectedIds } : { eq: selectedIds[0] });

  Object.keys(rest).forEach((key) => {
    params[voca.snakeCase(key)] = { eq: filters[selectedFilter][key] };
  })
}

function assignCheckboxFilterParams({ params, filters, selectedFilter, value }) {
  const checkedCheckboxes = Object.keys(filters[selectedFilter]).filter(key => filters[selectedFilter][key]);

  if (checkedCheckboxes.length === 1) {
    params[filterKey({ selectedFilter })] = value(checkedCheckboxes[0]);
  }
}

function assignFiltersParams({ params, filters, currentDivision }) {
  filters.selectedFilters.forEach((selectedFilter) => {
    switch (selectedFilter) {
      case "divisionFilter":
        assignMultiSelectFilterParams({ params, filters, selectedFilter })
        break
      case "personnelTypeFilter":
        assignCheckboxFilterParams({ params, filters, selectedFilter, value: (checkedCheckbox) => ({ eq: checkedCheckbox }) })
        break
      case "roleFilter":
        assignMultiSelectFilterParams({ params, filters, selectedFilter })
        break
      case "courseFilter":
        assignMultiSelectFilterParams({ params, filters, selectedFilter })
        break
      case "courseRequirementFilter":
        assignCheckboxFilterParams({ params, filters, selectedFilter, value: (checkedCheckbox) => ({ eq: checkedCheckbox === "required" }) })
        break
      case "lineManagerFilter":
        assignMultiSelectFilterParams({ params, filters, selectedFilter })
        break
      case "teamFilter":
        assignMultiSelectFilterParams({ params, filters, selectedFilter })
        break
    }
  })

  if (!currentDivision.attributes.primary) { params["division_id"] = { eq: currentDivision.id }; }
}

function buildParams({ type, formValues, currentDivision }) {
  const params = {};

  switch (type) {
    case "expiringTraining":
      assignDateParams({ params, key: "expiry_date", date: formValues.expiryDate })
      break
    case "completedTraining":
      assignDateParams({ params, key: "start_date", date: formValues.validFromDate })
      break
    case "currentTrainingStatus":
      assignTrainingStatusFilterParams({ params, trainingStatusFilter: formValues.trainingStatusFilter })
      break
  }

  assignEstimatesParams({ params, estimates: formValues.estimates })
  assignFiltersParams({ params, filters: formValues.filters, currentDivision })

  return { training_register_report: { ...params, type: voca.snakeCase(type) } }
}

function removeNotRequiredDateProps({ localStorageItem, key }) {
  const dateProps = localStorageItem[key];
  const datePeriodProps = requiredDatePeriodProps[dateProps.period];

  Object.keys(dateProps).filter((propsKey) => propsKey !== "period").forEach((propsKey) => {
    if (datePeriodProps && datePeriodProps.includes(propsKey)) return

    delete dateProps[propsKey]
  })
}

function removeNotRequiredFiltersProps({ localStorageItem }) {
  const filtersProps = localStorageItem.filters;

  Object.keys(filtersProps).filter((propsKey) => propsKey !== "selectedFilters").forEach((propsKey) => {
    if (filtersProps.selectedFilters.includes(propsKey)) return

    delete filtersProps[propsKey]
  })
}

function buildLocalStorageItem({ type, formValues }) {
  const localStorageItem = _.cloneDeep(formValues);

  if (["expiringTraining", "completedTraining"].includes(type)) {
    const key = type === "expiringTraining" ? "expiryDate" : "validFromDate";

    removeNotRequiredDateProps({ localStorageItem, key })
  }

  removeNotRequiredFiltersProps({ localStorageItem })

  return localStorageItem
}

export {
  trainingReportTypes,
  formatOption,
  validateForm,
  applyFormFilter,
  mergeStoredForm,
  buildFormValues,
  assignDateParams,
  assignTrainingStatusFilterParams,
  assignEstimatesParams,
  assignFiltersParams,
  buildParams,
  removeNotRequiredDateProps,
  removeNotRequiredFiltersProps,
  buildLocalStorageItem
}
