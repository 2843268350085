import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { userParamsFromState } from 'components/helpers/users';
import { titleize } from 'components/helpers/strings';
import SearchImportablePersonnelField from 'components/users/SearchImportablePersonnelField';
import UserDetailsEditor from 'components/users/UserDetailsEditor';
import AccessManagerSection from 'components/users/AccessManagerSection';
import Tooltip from 'components/application/Tooltip';
import ToastRack from 'components/application/ToastRack';
import useToasts from 'components/hooks/useToasts';
import ErrorMessage from 'components/application/ErrorMessage';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import { scrollToTop } from 'components/helpers/scrolling';
import useUserForm, { defaultAccess } from 'components/users/hooks/useUserForm';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import _ from 'lodash';

export const isRemovingUserAccessToLastDivision = (divisionId, accessControls) => {
  const divisionIdsWithNonNullAccessControls = Object.entries(accessControls).reduce((accumulator, [divisionId, accessControls]) => {
    if (accessControls) { accumulator.push(divisionId) }

    return accumulator
  }, [])

  return divisionIdsWithNonNullAccessControls.length === 1 && divisionIdsWithNonNullAccessControls == divisionId
}

export const hasNoUserDivisionAccess = accessControls => !(Object.values(accessControls).some(Boolean))

export default function FormPage({ context, domainUser, divisions, currentUserId, personnelText, projectDisplayNameSingular, projectDisplayNamePlural, isSamlSsoAllowed, identityProvider, isTeamsAllowed }) {
  // state
  const { formUser, setFormUser, requestError, handleRequestError, resetRequestError, removeErrorStyling } = useUserForm({ seededUser: domainUser, seedType: 'domain' });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toasts, , , ,] = useToasts();
  const ref = useRef()

  //computed
  const isNew = !(domainUser);
  const isUserEmail = (inputValue) => {
    return domainUser && inputValue === domainUser.data.attributes.email
  }
  const handlePersonnelSelection = (option) => {
    const selectedAttributes = option.__isNew__ || option.isNotDerived ? { isDerived: false, email: option.value } : { isDerived: true, ...option.value.attributes };
    resetRequestError()
    setFormUser(prevState => ({ ...prevState, isSelected: true, ...selectedAttributes }))
  }
  const handleSelectionReset = (options = { emailOnly: false }) => {
    const resettingAttributes = options.emailOnly ? { email: '' } : { email: '', firstName: '', lastName: '' };

    setFormUser(prevState => ({ ...prevState, isDerived: false, isSelected: true, ...resettingAttributes }))
  }
  const handleSelectionEdit = () => setFormUser(prevState => ({ ...prevState, isDerived: false }))
  const handleDetailsChange = (changeOptions) => {
    if (requestError.validationErrors[changeOptions.attribute]) {
      removeErrorStyling({ target: { name: changeOptions.attribute } })
    }
    setFormUser(prevState => ({ ...prevState, [changeOptions.attribute]: changeOptions.value }))
  }
  const handleAccessTypeSelection = (accessSelection) => setFormUser(prevState => ({ ...prevState, accessType: accessSelection }));

  const handleDivisionSelection = ({ id, selected }) => {
    let isRolesAndCoursesEditor = formUser.rolesAndCoursesEditor

    if (!selected && isRolesAndCoursesEditor && isRemovingUserAccessToLastDivision(id, formUser.accessControls)) {
      isRolesAndCoursesEditor = false
    }

    setFormUser(prevState => {
      const accessControlsCopy = _.cloneDeep(prevState.accessControls);
      accessControlsCopy[id] = (selected ? defaultAccess : null)

      return {
        ...prevState,
        accessControls: accessControlsCopy,
        rolesAndCoursesEditor: isRolesAndCoursesEditor
      }
    })
  }

  const handleAccessChange = ({ id, accessSelection }) => {
    const relevantAccessControl = formUser.accessControls[id] || defaultAccess;
    setFormUser(prevState => ({ ...prevState, accessControls: { ...prevState.accessControls, [id]: { ...relevantAccessControl, ...accessSelection } } }))
  }

  const handleAccountRoleChange = (accountRole, isSelected, divisionId) => {
    setFormUser(prevState => {
      const accessControlsCopy = _.cloneDeep(prevState.accessControls);

      if (isSelected && hasNoUserDivisionAccess(prevState.accessControls)) {
        accessControlsCopy[divisionId] = defaultAccess;
      }

      return { ...prevState, accessControls: accessControlsCopy, [accountRole]: isSelected }
    })
  }

  const handleSubmission = () => {
    setIsSubmitting(true)
    // todo find snakecase utility
    isNew ? submitUserCreation() : submitUserUpdate()
  }

  const handleSubmissionError = (e) => {
    setIsSubmitting(false)
    handleRequestError(e)
    scrollToTop(ref)
  }

  const submitUserCreation = () => {
    axios
      .post('/users/creations', userParamsFromState({ domainUser, formUser }))
      .then((response) => {
        window.location.assign(`/settings/users?invited_user_id=${response.data.data.id}&personnel_generated=${!!(response.data.meta.personnelGenerated)}`)
      })
      .catch(handleSubmissionError)
  }

  const submitUserUpdate = () => {
    axios
      .patch(`/users/${domainUser.data.id}`, userParamsFromState({ domainUser, formUser }))
      .then((_response) => {
        window.location.assign(`/settings/users?updated_user_id=${domainUser.data.id}`)
      })
      .catch(handleSubmissionError)
  }

  const hasAllRequiredAttributes = ['email', 'firstName', 'lastName'].every((attr) => !!(formUser[attr]))
  const submitContent = isNew ? 'Add user' : 'Save changes';

  return (
    <div ref={ref}>
      <h1 className='tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight m-t-0 m-b-24'>{context === 'new' ? 'Add user' : 'Edit user'}</h1>
      {Object.keys(requestError.validationErrors).length > 0 && (
        <div className='m-b-12'>
          <ErrorMessage
            validationErrors={requestError.validationErrors}
            isFallback={requestError.isFallback}
          />
        </div>
      )}
      <div className={'p-40 content-box--cornered tw-border-grey-100 tw-bg-white'}>
        {isSamlSsoAllowed && identityProvider && context === 'new' && (
          <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-b-32'>
            <div className='m-r-12 fh-20'>
              <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
            </div>
            <p className='m-0'>
              <span>The user must also be added in your identity provider </span>
              <span className='tw-font-semibold'>({titleize(identityProvider.attributes.name)})</span>
              <span> for them to access HandsHQ.</span>
            </p>
          </div>
        )}
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto m-t-0 m-b-32'>User details</h2>
        <div className='fw-360 m-b-40'>
          {!formUser.email && (
            <div className={`form-group${requestError.validationErrors.email && requestError.validationErrors.email.fieldHighlighted ? ' field__invalid' : ''}`}>
              <SearchImportablePersonnelField onPersonnelSelection={handlePersonnelSelection} isUserEmail={domainUser && isUserEmail} removeErrorStyling={removeErrorStyling} />
            </div>
          )}
          {formUser.isSelected && (
            <UserDetailsEditor
              formUser={formUser}
              requestError={requestError}
              removeErrorStyling={removeErrorStyling}
              onSelectionEdit={handleSelectionEdit}
              onSelectionReset={handleSelectionReset}
              onDetailsChange={handleDetailsChange}
            />
          )}
        </div>
        {formUser.isSelected && (
          <>
            <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0' />
            <AccessManagerSection isEditingSelf={currentUserId === (domainUser && domainUser.data.id)} onAccessChange={handleAccessChange} onAccountRoleChange={handleAccountRoleChange} formUser={formUser} divisions={divisions} onAccessTypeSelection={handleAccessTypeSelection} onDivisionSelection={handleDivisionSelection} personnelText={personnelText} projectDisplayNameSingular={projectDisplayNameSingular} projectDisplayNamePlural={projectDisplayNamePlural} isTeamsAllowed={isTeamsAllowed} />
          </>
        )}
      </div>
      <div className='flex flex--vertically-centered flex--justify-content__flex-end content-box--cornered tw-bg-white tw-border-grey-100 fh-72 p-t-16 p-r-40 p-b-16 p-l-40 b-t-none'>
        <TextButton size='md' color='blue' className='m-r-12' href='/settings/users'>
          Cancel
        </TextButton>
        {!hasAllRequiredAttributes ? (
          <Tooltip placement='top' trigger='hover' tooltip='Missing required fields'>
            <FilledButton color='mint' disabled={true} onClick={() => { }}>
              {submitContent}
            </FilledButton>
          </Tooltip>
        ) : (
          <FilledButton color='mint' disabled={isSubmitting} onClick={handleSubmission}>
            {submitContent}
          </FilledButton>
        )}
      </div>
      <ToastRack toasts={toasts} />
    </div>
  )
}

FormPage.propTypes = {
  context: PropTypes.string.isRequired,
  domainUser: PropTypes.object,
  divisions: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  personnelText: PropTypes.string.isRequired,
  isSamlSsoAllowed: PropTypes.bool.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired,
  identityProvider: PropTypes.object
};
