import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sendAnalytics } from 'components/helpers/analytics';

import DocumentGeneratorService from 'components/helpers/DocumentGeneratorService';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import CheckboxField from 'components/application/CheckboxField';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';

export default function ProjectCoshhDownloadModal(props) {
  const { isOpen, setIsOpen, currentCoshhDocument, projectId, currentUser } = props;

  let generator;
  const generateUrl = `/projects/${projectId}/coshh_document_downloads`;
  const defaultDocumentOptions = { sds: false };
  const [coshhDocumentIsGenerating, setCoshhDocumentIsGenerating] = useState(false);
  const [coshhDocumentHasErrored, setCoshhDocumentHasErrored] = useState(false)
  const [documentOptions, setDocumentOptions] = useState(defaultDocumentOptions);

  const closeModal = () => {
    setIsOpen(false)
    if (!coshhDocumentIsGenerating || coshhDocumentHasErrored) {
      reset()
    }
  };

  const reset = () => {
    setCoshhDocumentIsGenerating(false)
    setCoshhDocumentHasErrored(false)
    setDocumentOptions(defaultDocumentOptions)
  };

  const handleDownloadClick = () => {
    generateCoshhDocumentPdf()
    setCoshhDocumentIsGenerating(true)
  };

  const successCallback = (docUrl) => {
    closeModal()
    sendAnalytics('Individual COSHH downloaded', { currentUser: currentUser, coshhDocumentTitle: currentCoshhDocument.title, coshhDocumentId: currentCoshhDocument.id })
    window.location.href = docUrl
  };

  const failureCallback = () => {
    setCoshhDocumentHasErrored(true)
  };

  const generateCoshhDocumentPdf = () => {
    const generateParams = { ...documentOptions, coshh_document_id: currentCoshhDocument.id }

    generator = new DocumentGeneratorService({ generateUrl, generateParams, successCallback, failureCallback })
    generator.start()
  };

  const handleChange = (event) => {
    const option = event.target.value
    setDocumentOptions({...documentOptions, [option]: event.target.checked})
  };

  const formContent = (
    <div className='action-modal__box tw-bg-white action-modal__box--small'>
      <section className='action-modal__form-body p-32 tw-border-grey-100'>
        <h2 className='modalbox-header__sub-title p-b-16 tw-font-inter tw-text-grey-900 tw-text-l tw-font-semibold tw-tracking-auto'>
          Download COSHH for { currentCoshhDocument && currentCoshhDocument.title }
        </h2>
        <div>
          Do you want to include the manufacturer safety data sheet?
        </div>
        <CheckboxField
          height='short'
          onChange={handleChange}
          className='action-modal__form-checkbox'
          label='Include SDS'
          name='sds'
          value='sds'
          checked={documentOptions.sds}
          customPadding='p-t-16'
          labelTextProps={{ className: 'tw-font-medium' }}
        />
      </section>
      <section className='action-modal__form-footer'>
        <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
          Cancel
        </TextButton>
        <FilledButton color='mint' onClick={handleDownloadClick}>
          Download
        </FilledButton>
      </section>
    </div>
  );

  return (
    <>
      {
        isOpen &&
          <ActionModal isOpen={isOpen} closeModal={closeModal} closeable={true}>
            {coshhDocumentIsGenerating ? (
              <ActionModalContent
                closeModal={closeModal}
                closeable={true}
                withHeader={false}
                closeButtonText={'Cancel'}
                modifiers={['small']}
                bodyIcon={coshhDocumentHasErrored ? <ErrorIcon width={48} height={48} className='[&_path]:tw-fill-red-600' /> : <LoadingIcon width={48} height={48} />}
                bodyTitle={coshhDocumentHasErrored ? 'COSHH assessment not generated' : 'Generating COSHH assessment'}
                bodyText={coshhDocumentHasErrored ? 'Please try again or contact support for help' : 'This may take a few moments. When it is finished, your COSHH assessment will be downloaded.'}
                iconStyling='tw-apply-loading-spinner--blue-light'
              />
            ) : formContent}
          </ActionModal>
      }
    </>
  )
}

ProjectCoshhDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  currentCoshhDocument: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired
};
