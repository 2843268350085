import React from 'react';
import PropTypes from 'prop-types';
import IconGroup from 'components/IconGroup';

export default function IconGroupsContainer({ iconGroups, projectId }) {
  const parsedIconGroups = JSON.parse(iconGroups);

  return (
    <div>
      {parsedIconGroups.data.map(iconGroup =>
        <IconGroup
          key={iconGroup.id}
          iconGroupId={parseInt(iconGroup.id)}
          iconGroupName={iconGroup.attributes.name}
          icons={parsedIconGroups.included.filter(icon => icon['attributes']['iconGroupId'] === parseInt(iconGroup.id))}
          projectId={projectId}
        />
      )}
    </div>
  )

}

IconGroupsContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  iconGroups: PropTypes.string.isRequired
};
