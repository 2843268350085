import React from 'react';
import { useSpring, animated } from 'react-spring'

export default function Glimmer() {
  const springSliding = useSpring({
    loop: true,
    delay: 300,
    config: { duration: 800 },
    from: {
      right: '100%',
      transform: 'skew(-40deg) translateX(-110%)'
    },
    to: {
      right: '0%',
      transform: 'skew(-40deg) translateX(110%)'
    },
  })

  return (
    <div className='fh-14 br-24 overflow-hidden pos-rel'>
      <div className='h-full w-100 tw-bg-grey-025'></div>
      <animated.div style={{
        top: 0,
        position: 'absolute',
        background: 'rgba(0,0,0,0.2)',
        ...springSliding
      }} className='fw-50 h-full'></animated.div>
    </div>
  )
}
