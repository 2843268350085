import { useState, createRef } from 'react';
import PropTypes from 'prop-types';

const initialOtp = ['', '', '', '', '', ''];

export default function useOneTimePassword() {
  const [inputRefs] = useState(() => Array.from({ length: initialOtp.length }, () => createRef()));
  const [otpCode, setOtpCode] = useState(initialOtp);
  const [currentInput, setCurrentInput] = useState(0);

  const handleCleanupField = () => {
    inputRefs.map((ref) => { ref.current.value = ''; })
    setOtpCode(initialOtp)
    setCurrentInput(0)
    inputRefs[0].current.focus()
  }

  const moveFocus = (delta) => {
    const i = currentInput + delta;
    inputRefs[i].current.focus()
    setCurrentInput(i)
  }

  const removeLastInput = () => {
    const newOptCode = [...otpCode];
    newOptCode[currentInput] = ''
    setOtpCode(newOptCode)
    if (currentInput > 0) { moveFocus(-1) } // move focus to previous input
  }

  const addInput = (value) => {
    const newOptCode = [...otpCode]
    newOptCode[currentInput] = value
    setOtpCode(newOptCode)
    if (currentInput < 5) { moveFocus(1) } // move focus to next input
  }

  const handleInputKeyUp = (event) => {
    const value = event.key;
    const reg = new RegExp(/^\d+$/);
    if (value == 'Backspace' && currentInput >= 0) {
      removeLastInput()
    }
    else if (reg.test(value) && currentInput <= 5) {
      addInput(value)
    }
  }

  const handleFormPasteCapture = (event) => {
    const pastedData = event.clipboardData.getData('text/plain');
    const newOptCode = [...otpCode]
    pastedData.split('').map((data, i) => { newOptCode[i] = data; })
    setOtpCode(newOptCode)
    inputRefs.map((ref, i) => { ref.current.value = pastedData[i]; })
  }

  const handleInputClick = (event, index) => {
    event.preventDefault()
    inputRefs[index].current.focus()
    setCurrentInput(index)
  }

  return {
    inputRefs,
    otpCode,
    handleInputKeyUp,
    handleFormPasteCapture,
    handleInputClick,
    handleCleanupField
  }
}

export const oneTimePasswordShape = PropTypes.shape({
  inputRefs: PropTypes.array.isRequired,
  otpCode: PropTypes.array.isRequired,
  handleInputKeyUp: PropTypes.func.isRequired,
  handleFormPasteCapture: PropTypes.func.isRequired,
  handleInputClick: PropTypes.func.isRequired,
  handleCleanupField: PropTypes.func.isRequired
});
