var React = require('react');

function CheckmarkIcon (props) {
    return React.createElement("svg",props,React.createElement("g",{"fill":"none","fillRule":"evenodd"},[React.createElement("circle",{"cx":"8","cy":"8","r":"8","fill":"#16AED1","key":0}),React.createElement("polygon",{"fill":"#FFF","points":"4.776 7.405 3.833 8.347 7.034 11.548 12.164 4.952 11.112 4.133 6.909 9.537","key":1})]));
}

CheckmarkIcon.defaultProps = {"width":"16","height":"16","viewBox":"0 0 16 16"};

module.exports = CheckmarkIcon;

CheckmarkIcon.default = CheckmarkIcon;
