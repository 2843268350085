import React from 'react';
import Toast from 'components/application/Toast';
import { personDisplayName } from 'components/helpers/users';
import ProjectPersonnelAdditionUndoneToast from 'components/projectPersonnel/ProjectPersonnelAdditionUndoneToast';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function ProjectPersonnelAddedToast(props) {
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();
  const toastMessage = ` was successfully added to the ${currentActor.division.attributes.singularRamsName || 'RAMS'}.`;

  const onUndo = () => {
    // promise based chain ideally
    props.destroyProjectPersonnel(props.projectPersonnelId, props.displayName, true)
    breadBoard.addToast(
      <ProjectPersonnelAdditionUndoneToast
        onBurnToast={breadBoard.handleBurnToast}
        personnel={props.personnel}
      />
    )
    breadBoard.handleBurnToast(props.toastKey)
  }

  return(
    <Toast
      toastKey={props.toastKey}
      burn={breadBoard.handleBurnToast}
      modifiers='with-undo'
      burnTimer={8000}
      contents={
        <React.Fragment>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>
            <span className='tw-font-medium'>
              {personDisplayName(props.personnel.attributes)}
            </span>
            {toastMessage}
          </div>
          <div className='p-l-32 p-t-12'>
            <OutlinedButton size='sm' color='grey' onClick={onUndo}>Undo</OutlinedButton>
          </div>
        </React.Fragment>
      }
    />
  )
}
