import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function DestroyModal(props) {
  const { isOpen, setClose, fieldValueCount, titleText, bodyText, onDestroy } = props;

  const handleDeleteClick = () => {
    setClose()
    onDestroy()
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={setClose}>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDeleteClick}>
        {`Delete${fieldValueCount ? ` ${fieldValueCount} record${fieldValueCount > 1 ? 's' : ''}` : ''}`}
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={setClose}
      customFooter={customFooter}
    >
      <React.Fragment>
        <div className='modalbox-body'>
          <div className='tw-text-grey-900 tw-text-l tw-font-semibold m-b-16'>{titleText}</div>
          <div>{bodyText}</div>
        </div>
      </React.Fragment>
    </ModalBox>
  )
}

DestroyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  fieldValueCount: PropTypes.number.isRequired,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onDestroy: PropTypes.func.isRequired
};
