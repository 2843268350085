import React from 'react';
import PropTypes from 'prop-types';

import { SortableHandle } from 'react-sortable-hoc';

import Tooltip from 'components/application/Tooltip';

import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';

const DragHandle = SortableHandle(({ isSorting }) => <DragIcon className={`drag-icon [&_path]:tw-fill-grey-300${isSorting ? ' visibility-hidden' : ''}`} width={8} height={18} />);

export default function FieldOption({ option, isSorting, isInvalid, removeErrorStyling, isAutoFocused, isDeleteAllowed, onOptionValueChange, onOptionDelete }) {
  const inputClassName = (() => {
    switch (true) {
      case (isInvalid):
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <li className='sortable-collection__member-wrapper'>
      <div className='sortable-collection__member'>
        <DragHandle isSorting={isSorting} />
        <div className={'sortable-collection__input-container'}>
          <input
            id={`sortable-collection__input-${option.id}`}
            className={`sortable-collection__input field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}`}
            type='text'
            value={option.value}
            onFocus={removeErrorStyling}
            autoFocus={isAutoFocused}
            onChange={(event) => { onOptionValueChange({ id: option.id, value: event.target.value }) }}
          />
        </div>
        <div className='m-t-8 m-r-20 m-b-8 m-l-12'>
          <div className={`tooltip-parent tw-cursor-pointer tw-inline-flex tw-items-center tw-justify-center tw-h-6 tw-w-6 tw-p-0 tw-border-0 tw-rounded-lg ${isDeleteAllowed ? 'tw-bg-transparent [&_svg_path]:tw-fill-grey-500 hover:tw-bg-red-025 [&_svg_path]:hover:tw-fill-red-600 active:tw-bg-red-050 [&_svg_path]:active:tw-fill-red-600' : 'tw-pointer-events-none tw-bg-grey-050 [&_svg_path]:tw-fill-grey-300'}`}>
            <TrashIcon
              width={24}
              height={24}
              className='sortable-collection__delete-button'
              onClick={isDeleteAllowed ? () => { onOptionDelete({ id: option.id }) } : null}
            />
            {!isDeleteAllowed && <Tooltip placement='left' trigger='hover' tooltip='List must have two items' className='tooltip-dark--max-w-xxs' />}
          </div>
        </div>
      </div>
    </li>
  )
}

FieldOption.propTypes = {
  option: PropTypes.exact({
    id: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  isSorting: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  isAutoFocused: PropTypes.bool.isRequired,
  isDeleteAllowed: PropTypes.bool.isRequired,
  onOptionValueChange: PropTypes.func.isRequired,
  onOptionDelete: PropTypes.func.isRequired
};
