import React, { useReducer } from "react";
import { enableAllPlugins } from "immer";
enableAllPlugins()
import { produce } from "immer";
import moment from "moment";

import validationErrorsReducer, { initialValidationErrorsState, mapValidationErrorsDispatch } from "components/validationErrors/reducers/validationErrorsReducer";
import { validFromDateSchema } from "components/trainingRegisterReports/schemas/dateSchema";
import { validateForm, buildFormValues } from "components/trainingRegisterReports/helpers";

import { useCurrentActor } from "components/contexts/CurrentActor";
import useReportForm, { initialDateState, initialEstimatesState, initialFiltersState } from "components/trainingRegisterReports/hooks/useReportForm";
import useWindowStorage from "components/hooks/useWindowStorage";

import Report from "components/trainingRegisterReports/reports/Report";
import DatePeriod from "components/trainingRegisterReports/components/DatePeriod";

const initialFormValues = { validFromDate: initialDateState, estimates: initialEstimatesState, filters: initialFiltersState };

const additionalValidators = [{ schema: validFromDateSchema, key: "validFromDate" }];

const periodOptions = [
  "withinTheLast",
  "thisWeek",
  "lastWeek",
  "thisMonth",
  "lastMonth",
  "thisQuarter",
  "lastQuarter",
  "thisYear",
  "lastYear",
  "today",
  "yesterday",
  "before",
  "after",
  "onExactDate",
  "customDateRange"
];

export default function CompletedTrainingReport() {
  const currentActor = useCurrentActor();

  const [getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem] = useWindowStorage(`completedTrainingReport|${currentActor.user.id}|${currentActor.division.id}`);
  const {
    formValues,
    handleDateChange,
    handleDateRangeChange,
    handleEstimatesChange,
    handleFilterAddition,
    handleFilterRemoval,
    handleFilterSelection,
    handleFilterDeselection,
    handleFilterChange
  } = useReportForm("completedTraining", initialFormValues, (initialState) => {
    const computedFormValues = produce(initialState, draftState => {
      draftState.validFromDate.period = "withinTheLast";
      draftState.validFromDate.withinTheLastFrequency = "12";
      draftState.validFromDate.withinTheLastInterval = "weeks";
      draftState.validFromDate.before = moment().toDate();
      draftState.validFromDate.after = moment().toDate();
      draftState.validFromDate.onExactDate = moment().toDate();
      draftState.validFromDate.customDateRangeFrom = moment().subtract(3, "months").toDate();
      draftState.validFromDate.customDateRangeTo = moment().toDate();
    });

    return buildFormValues({
      type: "completedTraining",
      formValues: computedFormValues,
      additionalValidators,
      getLocalStorageItem,
      removeLocalStorageItem
    })
  });

  const [validationErrors, validationErrorsDispatch] = useReducer(validationErrorsReducer, initialValidationErrorsState);
  const validationErrorsHandlers = mapValidationErrorsDispatch(validationErrorsDispatch);

  const handleValidate = () => {
    return validateForm({ formValues, additionalValidators, safe: true })
  }

  return (
    <Report
      type="completedTraining"
      title="Completed training report"
      tooltip="The completed training report includes the current and historical recorded training for each course"
      setLocalStorageItem={setLocalStorageItem}
      formValues={formValues}
      validationErrors={validationErrors}
      onValidationErrorsReset={validationErrorsHandlers.handleValidationErrorsReset}
      onValidationErrorsAssignment={validationErrorsHandlers.handleValidationErrorsAssignment}
      onValidationErrorsUnhighlight={validationErrorsHandlers.handleValidationErrorsUnhighlight}
      onEstimatesChange={handleEstimatesChange}
      onFilterAddition={handleFilterAddition}
      onFilterRemoval={handleFilterRemoval}
      onFilterSelection={handleFilterSelection}
      onFilterDeselection={handleFilterDeselection}
      onFilterChange={handleFilterChange}
      onValidate={handleValidate}
    >
      <label className="tw-font-medium tw-mb-1">Valid from date</label>
      <div className="tw-mb-6">
        <DatePeriod
          name="valid_from_date"
          datePeriod={formValues.validFromDate}
          periodOptions={periodOptions}
          fieldError={validationErrors.validFromDate}
          beforeProps={{ latestDate: moment().toDate() }}
          afterProps={{ latestDate: moment().toDate() }}
          onExactDateProps={{ latestDate: moment().toDate() }}
          customDateRangeFromProps={{ latestDate: moment().toDate() }}
          customDateRangeToProps={{ latestDate: moment().toDate() }}
          onChange={handleDateChange}
          onDateRangeChange={handleDateRangeChange}
          onFocus={() => { if (validationErrors.validFromDate) validationErrorsHandlers.handleValidationErrorsUnhighlight("validFromDate") }}
        />
      </div>
    </Report>
  )
}
