import React from "react";
import PropTypes from "prop-types";
import { sortRoleIdsByPosition } from "../helpers/personnel";

export default function CompanyRolesList({
  currentPersonnel,
  assignableRolesCollection,
  onSelectPrimary,
  onRemoveRole,
}) {
  const roleIds = sortRoleIdsByPosition(assignableRolesCollection, currentPersonnel.companyRoleIds);
  return (
    <>
      {roleIds.map((id) => {
        const role = assignableRolesCollection.find((ar) => ar.id === id);
        const isPrimaryCompanyRoleId = currentPersonnel.primaryCompanyRoleId === id;
        return (
          <div
            key={id}
            className="tw-group/pill tw-inline-flex tw-justify-between tw-items-center tw-overflow-hidden tw-rounded-md tw-bg-grey-050 chip-item-pill w-100 py-10 px-12 m-b-8"
          >
            <div className="tw-flex-grow tw-relative tw-inline-flex tw-justify-between tw-items-center m-r-8">
              <span className="tw-text-s tw-font-medium tw-tracking-wide">
                {role ? role.attributes.position : ''}
              </span>
              {isPrimaryCompanyRoleId ? (
                <span className="tw-flex-shrink-0 tw-text-s tw-tracking-wide tw-text-grey-500">
                  {roleIds.length > 1 && 'Primary role'}
                </span>
              ) : (
                <div
                  className="tw-flex-shrink-0 tw-flex-col tw-justify-center tw-cursor-pointer tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-text-s tw-tracking-wide tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-bg-gradient-to-r tw-from-transparent tw-from-0% tw-to-grey-050 tw-to-[26px] tw-hidden group-hover/pill:tw-flex chip-item-pill__action p-l-24"
                  onClick={() => onSelectPrimary(id)}
                >
                  Set as primary
                </div>
              )}
            </div>
            <span
              className="collection-select__multivalue-remove m-l-8 circle--remove tw-bg-transparent hover:tw-bg-red-600 before:tw-content-[''] before:tw-bg-grey-700 hover:before:tw-bg-white after:tw-content-[''] after:tw-bg-grey-700 hover:after:tw-bg-white"
              onClick={() => onRemoveRole(id)}
            />
          </div>
        );
      })}
    </>
  );
}

CompanyRolesList.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  assignableRolesCollection: PropTypes.array.isRequired,
  onSelectPrimary: PropTypes.func.isRequired,
  onRemoveRole: PropTypes.func.isRequired,
};
