import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import voca from "voca";

import { useInfiniteQuery } from "@tanstack/react-query"
import Select, { components } from "react-select";

import { getNextPageParam } from "components/helpers/reactQuery";

import { useBreadBoard } from 'components/contexts/Toaster';

import { Option } from "components/application/CollectionSelect";

import CloseIcon from "-!svg-react-loader?name=CloseIcon!icons/ic-close.svg";
import ChevronUpIcon from "-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg";
import ChevronDownIcon from "-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg";

import CheckboxFields from "components/application/CheckboxFields";

const DropdownIndicator = (props) => {
  const chevronClassName = props.isDisabled ? "[&_polygon]:tw-fill-grey-300" : "[&_polygon]:tw-fill-grey-700"

  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <ChevronUpIcon className={chevronClassName} width={24} height={24} />
      ) : (
        <ChevronDownIcon className={chevronClassName} width={24} height={24} onMouseDown={props.selectProps.onDropdownIndicatorOpen} onTouchEnd={props.selectProps.onDropdownIndicatorOpen} />
      )}
    </components.DropdownIndicator>
  )
}

// custom component required in order to stop the input from being hidden when selection is made
const Input = (props) => <components.Input {...props} isHidden={false} />

const moreSelectedResourcesBreakpoint = 7;

export default function MultiSelectFilter(props) {
  const {
    id,
    name,
    label,
    placeholder,
    autoFocus = false,
    url,
    message,
    selectedIds,
    checkboxes = [],
    resourceLabel,
    resourceSort,
    fieldError,
    onSelect,
    onDeselect,
    onRemove,
    onFocus
  } = props;

  const [selectableResourcesEnabled, setSelectableResourcesEnabled] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [showMoreSelectedResources, setShowMoreSelectedResources] = useState(false);
  const [searchText, setSearchText] = useState("");
  const setSearchTextDebounced = useRef(_.debounce(searchText => setSearchText(searchText), 500)).current;

  const breadBoard = useBreadBoard();

  const isInvalid = !!fieldError?.fieldHighlighted;

  const resourceOption = (resource) => { return { value: resource.id, label: resourceLabel(resource), record: resource } }

  let selectableResourcesFilter;
  if (selectedIds.length > 0) selectableResourcesFilter = { ids: { nin: selectedIds } };
  const selectableResourcesParams = { search: searchText, filter: selectableResourcesFilter };
  const fetchSelectableResources = (page) => axios.get(url, { params: { ...selectableResourcesParams, page: page, per: 25 } })

  const {
    data: selectableResources,
    isFetching: selectableResourcesIsFetching,
    fetchNextPage: selectableResourcesFetchNextPage,
    hasNextPage: selectableResourcesHasNextPage
  } = useInfiniteQuery({
    queryKey: [`selectable_${voca.snakeCase(label)}`, selectableResourcesParams],
    queryFn: async ({ pageParam = 1 }) => {
      const selectableResourcesResponse = await fetchSelectableResources(pageParam);

      return selectableResourcesResponse.data
    },
    getNextPageParam: getNextPageParam,
    enabled: selectableResourcesEnabled || !!searchText,
    onError: breadBoard.addInedibleToast
  })

  const selectableResourceOptions = (resourceData) => {
    return resourceData.pages.map((page) => {
      return page.data.map((resource) => resourceOption(resource))
    }).flat()
  }

  const handleFilterSelection = ({ record }) => {
    onSelect(record.id)

    const newSelectedResources = [...selectedResources, resourceOption(record)].sort(resourceSort);
    setSelectedResources(newSelectedResources)
  }

  const handleFilterDeselection = ({ record }) => {
    onDeselect(record.id)

    const newSelectedResources = selectedResources.filter(selectedResource => selectedResource.record.id !== record.id);
    setSelectedResources(newSelectedResources)
  }

  const visibleSelectedResources = selectedResources.length < moreSelectedResourcesBreakpoint || showMoreSelectedResources ? selectedResources : selectedResources.slice(0, moreSelectedResourcesBreakpoint);

  const fetchSelectedResources = () => {
    axios
      .get(url, { params: { filter: { ids: { in: selectedIds } } } })
      .then((response) => {
        const initialSelectedResources = response.data.data.map((resource) => resourceOption(resource));

        setSelectedResources(initialSelectedResources)
      })
      .catch(breadBoard.addInedibleToast)
  }

  useEffect(() => {
    if (selectedIds.length > 0) fetchSelectedResources()
  }, [])

  return (
    <div className="tw-border-1 tw-border-solid tw-rounded-sm tw-border-grey-100 tw-bg-white tw-p-5" onFocus={onFocus}>
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <label className="tw-font-medium tw-m-0">{label}</label>
        <div className="tw-flex tw-items-center tw-border-0 tw-rounded-lg tw-cursor-pointer tw-h-5 tw-px-2 tw-text-grey-500 tw-bg-white hover:tw-text-red-500 hover:tw-bg-red-025" onClick={onRemove}>
          <span className="tw-text-s tw-font-medium tw-tracking-wide">Remove filter</span>
        </div>
      </div>
      {message && (
        <p className="tw-text-s tw-font-normal tw-tracking-wide tw-m-0 tw-mb-4">{message}</p>
      )}
      <Select
        id={id}
        name={name}
        className={`collection-select__select-container${isInvalid ? " collection-select--invalid" : ""}`}
        classNamePrefix="collection-select"
        placeholder={placeholder}
        autoFocus={autoFocus}
        controlShouldRenderValue={false}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        components={{ Option, DropdownIndicator, Input }}
        filterOption={() => true}
        options={selectableResources ? selectableResourceOptions(selectableResources) : []}
        isLoading={selectableResourcesIsFetching}
        loadingMessage={() => null}
        noOptionsMessage={() => !!searchText ? "No matching results" : null}
        onInputChange={setSearchTextDebounced}
        onChange={handleFilterSelection}
        onDropdownIndicatorOpen={() => setSelectableResourcesEnabled(true)}
        onMenuClose={() => setSelectableResourcesEnabled(false)}
        onMenuScrollToBottom={() => { if (selectableResourcesHasNextPage) selectableResourcesFetchNextPage() }}
      />
      <ul className={`tw-flex tw-flex-col tw-p-0 tw-m-0 ${selectedResources.length > 0 ? " tw-mt-2" : ""}`}>
        {visibleSelectedResources.length > 0 && visibleSelectedResources.map((selected) => {
          return (
            <li
              key={selected.value}
              className="tw-relative tw-flex tw-items-center tw-justify-between tw-border-0 tw-rounded-lg tw-min-h-[36px] tw-pl-3 tw-pr-9 tw-py-2 tw-mt-2 tw-text-grey-700 tw-bg-grey-050"
            >
              <div className="tw-text-s tw-font-medium tw-tracking-wide">{selected.label}</div>
              <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-1 tw-m-[auto_0] tw-group tw-flex tw-items-center tw-justify-center tw-h-9 tw-w-9 tw-cursor-pointer" onClick={() => handleFilterDeselection(selected)}>
                <div className="tw-flex tw-items-center tw-justify-center tw-h-5 tw-w-5 tw-border-0 tw-rounded-lg tw-bg-grey-050 group-hover:tw-bg-grey-200">
                  <CloseIcon className="[&_polygon]:tw-fill-grey-500" width={24} height={24} />
                </div>
              </div>
            </li>
          )
        })}
        {selectedResources.length > moreSelectedResourcesBreakpoint && !showMoreSelectedResources && (
          <div className="tw-mt-4">
            <button className="app-link tw-align-baseline tw-bg-transparent tw-text-s tw-font-medium tw-tracking-wide tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300" onClick={() => setShowMoreSelectedResources(true)}>
              Show {selectedResources.length - moreSelectedResourcesBreakpoint} more
            </button>
          </div>
        )}
      </ul>
      {checkboxes.length !== 0 && (
        <div className="tw-mt-4">
          <CheckboxFields checkboxes={checkboxes} inline={true} minChecked={0} />
        </div>
      )}
    </div>
  )
}

MultiSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  url: PropTypes.string.isRequired,
  message: PropTypes.string,
  selectedIds: PropTypes.array.isRequired,
  checkboxes: PropTypes.array,
  resourceLabel: PropTypes.func.isRequired,
  resourceSort: PropTypes.func.isRequired,
  fieldError: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired
};
