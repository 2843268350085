import React from 'react';
import PropTypes from 'prop-types';

import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';

export default function BlankPersonnelSearchResults(props) {
  return(
    <div className='section section--notice br-tl-0 br-tr-0 tw-border-grey-100 tw-bg-white'>
      <NoResultsIcon width={64} height={64} className='[&_path]:tw-fill-grey-300' />
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>
        No results found
        <span> for '{props.search}'</span>
      </h2>
      <p className='section__content m-t-8 m-b-8'>
        {
          props.allowAdditionalSearching && (
            <span>
              Try searching again or&nbsp;
              {props.allowFilterChange && (
                <><a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='#0' onClick={props.onFilterInclusionClick}>include all filters</a> or&nbsp;</>
              )}
              <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='#0' onClick={props.onSetToggle}>
                {`search ${props.alternatePersonnelSetName ? `${props.alternatePersonnelSetName} ` : ''}personnel`}
              </a>
            </span>
          )
        }
      </p>
    </div>
  )
}


BlankPersonnelSearchResults.propTypes = {
  search: PropTypes.string.isRequired,
  allowAdditionalSearching: PropTypes.bool.isRequired,
  allowFilterChange: PropTypes.bool.isRequired,
  onFilterInclusionClick: PropTypes.func,
  onSetToggle: PropTypes.func,
  alternatePersonnelSetName: PropTypes.string.isRequired
}
