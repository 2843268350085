import React, { Fragment, useState } from 'react';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';
import PersonnelShow from 'components/personnel/PersonnelShow';
import ShowFooter from 'components/sidepanels/ShowFooter';
import ArchiveButton from 'components/archive/ArchiveButton';
import PersonnelDetails from 'components/personnel/PersonnelDetails';
import PersonnelTeamDetails from 'components/personnel/PersonnelTeamDetails.jsx'
import PersonnelManagementDetails from 'components/personnel/PersonnelManagementDetails.jsx'
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';

const numberOfOngoingProjects = 6;

export default function PersonnelPanel(props) {
  const breadBoard = useBreadBoard();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const isArchived = !!props.currentPersonnel.archivedAt;
  const displayPersonnelDetailsPanel = props.currentPersonnel.lineManagerId || props.currentPersonnel.email || props.currentPersonnel.externalId || props.currentPersonnel.teamsIds.length > 0 || Object.keys(props.currentPersonnel.fieldValues).length > 0
  const header = (
    <div className='popup__porthole popup__porthole--secondary tw-border-white tw-bg-mint-500 [&_svg_path]:tw-fill-white'>
      <PersonIcon width={32} height={32} />
    </div>
  );

  const [ongoingProjects, setOngoingProjects] = useState({ loaded: false, collection: [], metaData: { totalCount: 0 } })
  const [wereOngoingProjectsPreviouslyFetched, setWereOngoingProjectsPreviouslyFetched] = useState(false);

  const handleArchiveButtonMouseEnter = () => {
    if (!isArchived && props.currentPersonnel.isParticipatingInOngoingProjects && !wereOngoingProjectsPreviouslyFetched) {
      setWereOngoingProjectsPreviouslyFetched(true);
      fetchOngoingProjects();
    }
  };

  const fetchOngoingProjects = () => {
    axios
      .get(`/personnel/${props.currentPersonnel.id}/ongoing_projects`, { params: { per: numberOfOngoingProjects } })
      .then((response) => {
        setOngoingProjects(prevState => ({ loaded: true, collection: prevState.collection.concat(response.data.data), metaData: response.data.meta }))
      })
      .catch(breadBoard.addInedibleToast)
  }

  const body = (
    props.personnel && <PersonnelShow
      currentPersonnel={props.currentPersonnel}
      division={props.division}
      subcontractor={props.subcontractor}
      personnel={props.personnel}
      roles={props.roles}
      isCompact={true}
      withPersonnelDetails={false}
      availableFields={props.availableFields}
      availableFieldOptions={props.availableFieldOptions}
    />
  );

  const footer = trainingRegisterResourceManagementContext.hasPersonnelEditableAccess && (
    <ShowFooter
      onEditClick={props.openSidePanel}
      onDeleteClick={props.onDeleteClick}
      isCompact={true}
      isEditButtonHidden={isArchived}
    >
      <ArchiveButton
        disabled={!isArchived && props.currentPersonnel.isParticipatingInOngoingProjects}
        disabledTooltip={<DisabledArchivingPersonnelToolTip currentPersonnel={props.currentPersonnel} ongoingProjects={ongoingProjects} />}
        isCompact={true}
        isArchived={isArchived}
        onClick={props.onArchiveButtonClick}
        onMouseEnter={handleArchiveButtonMouseEnter}
      />
    </ShowFooter>
  )

  return (
    <Fragment>
      <div className='overflow-hidden border-radius'>
        <div className='popup__header popup__header--secondary popup__header--sm tw-bg-mint-500'>
          {header}
        </div>
        <div className='popup__body popup__body--floating tw-bg-white tw-border-grey-100'>
          {body}
        </div>
        <div className='popup__footer popup__footer--floating transition-enabled tw-bg-white tw-border-grey-100'>
          {footer}
        </div>
      </div>
      {displayPersonnelDetailsPanel && (
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100'>
          <div className='p-20'>
            <h2 className='tw-text-l tw-font-semibold m-0 m-b-16'>Personnel details</h2>
            <PersonnelDetails
              isCompact={true}
              defaultDetails={[{ label: 'Personnel ID', text: props.currentPersonnel.externalId }, { label: 'Email', text: props.currentPersonnel.email }]}
              currentPersonnel={props.currentPersonnel}
              availableFields={props.availableFields}
              availableFieldOptions={props.availableFieldOptions}
            />
          </div>
        </div>
      )}
      {trainingRegisterResourceManagementContext.hasTeamViewableAccess && (
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100 tw-max-h-[640px] overflow-auto'>
          <div className='tw-px-5 tw-pt-6 tw-mb-6'>
            <h2 className={`tw-text-l tw-font-semibold m-0 ${Object.keys(props.teams).length === 0 ? 'tw-mb-4' : 'tw-mb-6'}`}>Teams</h2>
            <PersonnelTeamDetails teams={props.teams} />
          </div>
        </div>
      )}
      {
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100 tw-max-h-[640px] overflow-auto'>
          <div className='tw-px-5 tw-pt-6'>
            <h2 className='tw-text-l tw-font-semibold m-0 tw-mb-4'>Management</h2>
            <PersonnelManagementDetails
              currentPersonnel={props.currentPersonnel}
              lineManager={props.lineManager}
            />
          </div>
        </div>
      }
    </Fragment>
  )
}

export function DisabledArchivingPersonnelToolTip({ currentPersonnel, ongoingProjects }) {
  const projectNames = ongoingProjects.collection.map(item => item.attributes.name);
  const otherCount = ongoingProjects.metaData.totalCount - numberOfOngoingProjects;

  return (
    <div className='tw-w-[320px] tw-text-left'>
      <p className='tw-w-[300px]'>{currentPersonnel.firstName} must be removed from the following RAMS to be archived:</p>
      <ul className='tw-m-0 tw-px-3'>
        {projectNames.map((project, index) => {
          return (<li key={index}>{project}</li>)
        })}
      </ul>
      {otherCount > 0 && (<p className='tw-mt-3 tw-mb-0'>+{otherCount} {otherCount > 1 ? 'others' : 'other'}</p>)}
    </div>
  )
}
