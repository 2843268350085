import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from 'components/application/indicators/StatusIndicator';
import Tooltip from 'components/application/Tooltip';

export default function CourseTrainingStatusIndicator(props) {
  const trainingStatusOptions = {
    0: {
      status: 'high',
      tooltipText: 'Required training is missing'
    },
    1: {
      status: 'high',
      tooltipText: 'Required training is missing expiry date'
    },
    2: {
      status: 'high',
      tooltipText: 'Required training has expired'
    },
    3: {
      status: 'medium',
      tooltipText: 'Required training is expiring soon'
    },
    4: {
      status: 'low',
      tooltipText: 'Training is up to date'
    },
    5: {
      status: 'grey',
      tooltipText: 'No required training'
    }
  }[props.status];

  const status = (trainingStatusOptions && trainingStatusOptions['status']);

  return(
    <div className='tooltip-parent flex'>
      <StatusIndicator status={status} />
      <Tooltip placement='top' trigger='hover' tooltip={trainingStatusOptions && trainingStatusOptions['tooltipText']} />
    </div>
  )
}

CourseTrainingStatusIndicator.propTypes = {
  status: PropTypes.number.isRequired
}
