import moment from 'moment';

export const autoEnrolmentConfigurationParamsForCreate = ({ currentCourse, currentContext }) => {
  const {
    hasAutoEnrolBeenActivelySelected,
    autoEnrolEnrolleeScope,
    autoEnrolCourseRequirementScope,
    isELearningProviderSelected,
    expires: isCourseExpirySelected
  } = currentCourse

  const isEligibleCourseExpirySelection = currentContext.isAutoEnrolmentIncludingMissingTraining || isCourseExpirySelected;

  const params = hasAutoEnrolBeenActivelySelected && isELearningProviderSelected && isEligibleCourseExpirySelection && {
    enrollee_scope: autoEnrolEnrolleeScope,
    course_requirement_scope: autoEnrolCourseRequirementScope
  };

  // Explicitly returning null to prevent backend errors when submitting params
  return params || null
}

export const autoEnrolmentConfigurationParamsForUpdate = ({ currentCourse, domainAutoEnrolmentConfiguration, currentContext }) => {
  const {
    hasAutoEnrolBeenActivelySelected,
    autoEnrolEnrolleeScope,
    autoEnrolCourseRequirementScope,
    isELearningProviderSelected,
    expires: isCourseExpirySelected
  } = currentCourse;

  const domainAutoEnrolmentConfigurationId = domainAutoEnrolmentConfiguration?.id;
  const isAutoEnrolmentIncludingMissingTraining = currentContext.isAutoEnrolmentIncludingMissingTraining

  const actionRequired = (function determineActionRequired() {
    if ((function shouldDelete() {
      return domainAutoEnrolmentConfigurationId && (
        !hasAutoEnrolBeenActivelySelected ||
        (!isAutoEnrolmentIncludingMissingTraining && !isCourseExpirySelected) ||
        !isELearningProviderSelected
      )
    })()) {
      return 'delete';
    } else if ((function shouldCreateOrUpdate() {
      return hasAutoEnrolBeenActivelySelected &&
        (isAutoEnrolmentIncludingMissingTraining || isCourseExpirySelected) &&
        isELearningProviderSelected
    })()) {
      return !!domainAutoEnrolmentConfigurationId ? 'update' : 'create';
    }
  })();

  const params = (function determineParams() {
    const attributes = { enrollee_scope: autoEnrolEnrolleeScope, course_requirement_scope: autoEnrolCourseRequirementScope };

    switch (actionRequired) {
      case 'delete':
        return { id: domainAutoEnrolmentConfigurationId, _destroy: 1 };
      case 'create':
        return attributes;
      case 'update':
        return { ...attributes, id: domainAutoEnrolmentConfigurationId };
      default:
        return null;
    }
  })()

  return params
}

export const isEligibleTrainingInclusion = ({ isAutoEnrolmentIncludingMissingTraining, isExpiringCourse, training }) => {
  return (training?.attributes?.expiryDate && !!isExpiringCourse) || (isAutoEnrolmentIncludingMissingTraining && !training)
};

export const isEligibleEmployeeScope = ({ autoEnrolmentConfiguration, isSubcontractor }) => {
  return !!(autoEnrolmentConfiguration?.attributes.enrolleeScope === 'all' || (autoEnrolmentConfiguration?.attributes.enrolleeScope === 'employees' && !isSubcontractor))
};

export const isEligibleCourseRequirementScope = ({ autoEnrolmentConfiguration, isRequired }) => {
  return !!(autoEnrolmentConfiguration?.attributes.courseRequirementScope === 'all' || (autoEnrolmentConfiguration?.attributes.courseRequirementScope === 'required' && isRequired))
};

export const autoEnrolmentBlockers = ({
  autoEnrolmentConfiguration,
  isExpiringCourse,
  courseHasELearningCourse,
  training,
  isSubcontractor,
  isRequired,
  isAutoEnrolmentIncludingMissingTraining,
  isBookingAllowedWithinTrackedPersonnelLimits,
}) => {
  const blockers = [];

  if (!courseHasELearningCourse) blockers.push('noELearningCourse');
  if (!autoEnrolmentConfiguration) blockers.push('noAutoEnrolmentConfiguration');
  if (!isEligibleTrainingInclusion({ isAutoEnrolmentIncludingMissingTraining, isExpiringCourse, training })) blockers.push('trainingInclusion');
  if (autoEnrolmentConfiguration && !isEligibleEmployeeScope({ autoEnrolmentConfiguration, isSubcontractor })) blockers.push('enrolleeScope');
  if (autoEnrolmentConfiguration && !isEligibleCourseRequirementScope({ autoEnrolmentConfiguration, isRequired })) blockers.push('courseRequirementScope');
  if (!isBookingAllowedWithinTrackedPersonnelLimits) blockers.push('trackedPersonnelLimit');

  return blockers
}

export const additionalAutoEnrolmentBlockers = ({
  isELearningNonCustom,
  isCreditInfoUnavailable,
  isPersonnelEmailUnavailable,
  currentELearningCredits,
}) => {
  const blockers = [];

  if (isPersonnelEmailUnavailable) blockers.push('personnelEmailUnavailable');
  if (isELearningNonCustom && isCreditInfoUnavailable) blockers.push('creditInfoUnavailable');
  if (isELearningNonCustom && currentELearningCredits <= 0) blockers.push('insufficientCredits');
  return blockers
}

export const mappedAutoEnrolmentExclusionsByConfigurationId = (autoEnrolmentExclusions) => {
  return Object.fromEntries(
    Object.values(autoEnrolmentExclusions).map(value => [
      value.relationships.autoEnrolmentConfiguration.data.id,
      value
    ])
  );
}

export const proposedAutoEnrolmentDate = ({ latestTrainingExpiryDate, courseExpiringDuration }) => (
  moment(latestTrainingExpiryDate).clone().subtract(courseExpiringDuration)
)

export const autoEnrolmentStatus = ({
  isELearningNonCustom,
  isCreditInfoUnavailable,
  isPersonnelEmailUnavailable,
  currentELearningCredits,
  isAutoEnrolmentIncludingMissingTraining,
  latestTraining,
  courseExpiringDuration
}) => {
  let status = '';

  if (isELearningNonCustom && isCreditInfoUnavailable) {
    status = 'creditInfoUnavailable';
    return status;
  }

  if (isPersonnelEmailUnavailable) {
    status = 'personnelEmailUnavailable';
    return status;
  }

  if (isAutoEnrolmentIncludingMissingTraining && !latestTraining) {
    status = 'autoEnrolsTomorrow';
  }

  const currentDate = moment();
  const trainingExpiryDate = latestTraining?.attributes?.expiryDate ? moment(latestTraining?.attributes?.expiryDate) : null;

  const isTrainingExpiringSoonOrExpired = trainingExpiryDate && (
    trainingExpiryDate.subtract(courseExpiringDuration).isSameOrBefore(currentDate)
  );

  if (isTrainingExpiringSoonOrExpired) {
    status = 'autoEnrolsTomorrow';
  } else if (latestTraining) {
    status = 'autoEnrolsInTheFuture';
  }

  const hasInsufficientCredits = isELearningNonCustom && currentELearningCredits <= 0;

  if (hasInsufficientCredits) {
    status = `${status}|insufficientCredits`;
  }

  return status;
};

export const autoEnrolmentStatusText = ({
  status,
  latestTrainingExpiryDate,
  courseExpiringDuration
}) => {
  switch (status) {
    case 'autoEnrolsTomorrow':
      return 'Course will auto-enrol tomorrow';
    case 'autoEnrolsTomorrow|insufficientCredits':
      return 'Enrolment will be attempted tomorrow but there is currently not enough credits';
    case 'autoEnrolsInTheFuture':
      return `Course will auto-enrol on ${proposedAutoEnrolmentDate({ latestTrainingExpiryDate, courseExpiringDuration }).format('D MMM YY')}`;
    case 'autoEnrolsInTheFuture|insufficientCredits':
      return `Enrolment will be attempted on ${proposedAutoEnrolmentDate({ latestTrainingExpiryDate, courseExpiringDuration }).format('D MMM YY')} but there is currently not enough credits`;
    case 'personnelEmailUnavailable':
      return 'Personnel must have an email address to be auto-enrolled on eLearning';
    case 'creditInfoUnavailable':
      return 'There was a problem calculating the available credits so the course will not be auto-enrolled';
    default:
      return '';
  }
};
