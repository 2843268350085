import HandshqApp from '../handshq-app';

HandshqApp.SnapshotRequest = function(options) {
  const _this = this;
  if (this instanceof HandshqApp.SnapshotRequest) {
    this.pdfReady = options['pdfReady'];
    this.downloadUrl = options['downloadUrl'];
    this.modal = $(options['modal']);
    this.modalInfo = ['ready', 'preparing', 'error'];

    this.snapshotReady = function() {
      _this.showModalInfo('ready');
    };

    this.snapshotNotReady = function() {
      _this.showModalInfo('preparing');

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: _this.downloadUrl,

        error: function(_response) {
          _this.downloadFailure();
        },

        success: function(response) {
          _this.startPolling(response);
        }
      });
    };

    this.showModal = function() {
      _this.modal.modal('show');
    };

    this.showModalInfo = function(show) {
      const hiddenInfo = Object.create(_this.modalInfo);
      hiddenInfo.splice($.inArray(show, _this.modalInfo), 1);
      const $show = _this.modal.find(".snapshot-" + show);

      $.each(hiddenInfo, function(key, value) {
        const $hide = _this.modal.find(".snapshot-" + value);

        _this.hideInfo($hide);
      });

      _this.showInfo($show);
    };

    this.showInfo = function($show) {
      if ($show.hasClass('tw-hidden')) {
        $show.removeClass('tw-hidden');
      }
    };

    this.hideInfo = function($hide) {
      if (!$hide.hasClass('tw-hidden')) {
        $hide.addClass('tw-hidden');
      }
    };

    this.startPolling = function(response) {
      new HandshqApp.Polling({
        id: response['id'],
        url: response['url'],
        maxTime: response['maxTime'],
        successCallback: _this.downloadSuccess.bind(this),
        failureCallback: _this.downloadFailure.bind(this),
        responseCheck: function(response){ return response.url }
      }).start();
    };

    this.downloadSuccess = function() {
      _this.showModalInfo('ready');
    };

    this.downloadFailure = function() {
      _this.showModalInfo('error');

      _this.modal.find('a.snapshot-error').on('click', function() {
        _this.snapshotNotReady();
      });
    };
  } else {
    throw new Error('HandshqApp.SnapshotRequest invoked without new');
  }

  if (_this.modal.data('state')) {
    _this.showModal();
  } else {
    _this.showModal();

    if (_this.pdfReady) {
      _this.snapshotReady();
    } else {
      _this.snapshotNotReady();
    }
  }
};

$(function() {
  if ($('.snapshot-requests').length) {
    const $links = $('.snapshot-requests');

    $links.each(function() {
      $(this).on('click', function(_event) {
        const modal = $(this).data('modal');

        if ($(modal).data('state')) {
          $(modal).modal('show');
        } else {
          new HandshqApp.SnapshotRequest({
            pdfReady: $(this).data('pdfReady'),
            modal: modal,
            downloadUrl: $(this).data('downloadUrl')
          });

          $(modal).data('state', 'clicked');
        }
      });
    });
  }
});
