$(function() {
  $('.risk-matrix-container').each(function() {
    new RiskSelector(this).setup();
  });
});

function RiskSelector (el) {
  this.el = $(el);
  this.formGroup = this.el.closest('.form-group');
  this.riskReference = this.formGroup.find('.risk-reference');
  this.visualRisk = this.formGroup.find('.risk-input');
  this.selectedRisk = this.el.find('td.selected');
}

RiskSelector.prototype.setup = function() {
  this.registerTableToggleEvents();
  this.registerRiskEvents();
};

RiskSelector.prototype.registerTableToggleEvents = function() {
  const _this = this;
  $(document).on('click', function(e) {
    const parentVisualRisk = $(e.target).closest('.risk-selector')[0] == _this.visualRisk.parent()[0];
    if ((e.target == _this.visualRisk[0] || parentVisualRisk) && !_this.el.hasClass('focused')) {
      _this.showTable();
    } else {
      _this.hideTable();
    }
  });
};

RiskSelector.prototype.registerRiskEvents = function() {
  const _this = this;
  this.el.find('.risk-matrix-entry').on('click', function() {
    _this.hideTable();
    _this.assignSelectedRisk($(this));
    _this.riskReference.val($(this).data('riskId'));
    _this.visualRisk.removeClass('negligible low medium-low medium medium-high high');
    const severity = $(this).data('severity');
    const value = $(this).data('value');
    _this.visualRisk.addClass(severity);
    if (_this.visualRisk.hasClass("circle")) {
      _this.visualRisk[0].innerText = value;
    } else {
      _this.visualRisk.val(value);
    }
  });
};

RiskSelector.prototype.showTable = function() {
  this.el.addClass('focused');
};

RiskSelector.prototype.hideTable = function() {
  this.el.removeClass('focused');
};

RiskSelector.prototype.assignSelectedRisk = function(entry) {
  this.selectedRisk.closest('td').removeClass('selected');
  this.selectedRisk = entry;
  this.selectedRisk.closest('td').addClass('selected');
};

export default RiskSelector;
