import React from 'react';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';
import Circle from 'components/application/Circle';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import { personDisplayName } from 'components/helpers/users';
import PropTypes from 'prop-types';

SelectedPersonnelCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default function SelectedPersonnelCard ({firstName, lastName, email, onClose, onEdit}) {
  const initials = `${firstName[0]} ${lastName && lastName[0]}`;

  return(
    <>
      <span className='l-if p-16 tw-bg-white tw-border-grey-100 b-standard br-3 w-100 m-b-32'>
        <Circle className='fw-40 fh-40 tw-bg-blue-400'>{initials}</Circle>
        <span className='flex-auto m-l-12'>
          <span className='tw-font-medium tw-text-grey-900'>{personDisplayName({firstName, lastName})}</span>
          <CloseIcon onClick={onClose} className='handshq--hoverable pull-right [&_polygon]:tw-fill-grey-700' width={24} height={24} />
          <div className='m-b-12'>{email}</div>
          <OutlinedButton size='sm' color='grey' onClick={onEdit}>Edit</OutlinedButton>
        </span>
      </span>
    </>
  )
}
