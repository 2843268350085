import React from 'react';
import PropTypes from 'prop-types';

import LockIcon from '-!svg-react-loader?name=LockIcon!icons/ic-lock-10.svg';
import Tooltip from 'components/application/Tooltip';
import FilledButton from 'components/application/buttons/FilledButton';

export default function EnforceFormCard(props) {
  const {
    formValues,
    isUpgradeRequired,
    isIdentityProviderEnabled,
    onInputChange,
    onSubmit
  } = props;

  return (
    <div className='section p-40 pos-rel tw-bg-white tw-border-grey-100'>
      {isUpgradeRequired && (
        <div className='tooltip-parent tw-absolute -tw-top-3 tw-left-2 tw-flex tw-items-center tw-border-0 tw-rounded-s-sm tw-text-mutedBlue-700 tw-bg-mutedBlue-050 tw-py-[6px] tw-px-2 m-0'>
          <LockIcon className='m-r-8 [&_path]:tw-fill-mutedBlue-700' height={10} width={8} />
          <span className='tw-text-xs tw-font-semibold tw-tracking-wide'>Upgrade required</span>
          <Tooltip placement='top' trigger='hover' tooltip='Speak to your account manager to upgrade your plan' />
        </div>
      )}
      <h3 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto m-t-0 m-b-8'>Enforce single sign-on</h3>
      <p className='m-0 m-b-32'>
        <span>Enabling this will stop users logging in via email and password. </span>
        <span>
          {isUpgradeRequired ?
          "Speak to your account manager about upgrading." :
          "We don't recommend turning this on until you have tested SSO is working correctly to avoid users losing access."}
        </span>
      </p>
      <div className='m-0 form-group'>
        <label className='hhq-checkbox__label tooltip-parent' htmlFor='enforced'>
          <input
            className='hhq-checkbox__input'
            id='enforced'
            name='enforced'
            type='checkbox'
            value='enforced'
            checked={formValues.enforced}
            disabled={isUpgradeRequired || !isIdentityProviderEnabled}
            onChange={onInputChange}
          />
          <span className={`hhq-checkbox__toggle tw-cursor-pointer tw-text-white tw-font-bold tw-border-2 ${formValues.enforced ? 'tw-bg-blue-400 tw-border-blue-400 hover:tw-bg-blue-500 hover:tw-border-blue-500' : 'tw-bg-white tw-border-grey-300 hover:tw-bg-grey-025 hover:tw-border-blue-300'}`}></span>
          {!isIdentityProviderEnabled && (
            <div className='pos-abs pos-t-0 pos-l-0 fh-22 fw-22'>
              <Tooltip placement='top' trigger='hover' tooltip='Single sign-on must be enabled first' />
            </div>
          )}
          <span className='tw-font-normal tw-tracking-auto m-l-12'>
            Enforce log in via single sign-on
          </span>
        </label>
      </div>
      <FilledButton color='blue' className='m-t-40' disabled={isUpgradeRequired || !isIdentityProviderEnabled} onClick={onSubmit}>
        Save changes
      </FilledButton>
    </div>
  )
}

EnforceFormCard.propTypes = {
  formValues: PropTypes.object.isRequired,
  isUpgradeRequired: PropTypes.bool.isRequired,
  isIdentityProviderEnabled: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
