import React, { useRef, useEffect, Fragment } from 'react';
import useForm from 'components/hooks/useForm';
import { personDisplayName } from 'components/helpers/users';
import CollectionSelect from 'components/application/CollectionSelect';
import ReviewStatus from 'components/reviews/ReviewStatus';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonDetailsIcon from '-!svg-react-loader?name=PersonDetailsIcon!icons/person-details.svg';
import ProjectPersonnelRowOptions from 'components/projectPersonnel/ProjectPersonnelRowOptions';
import ProjectPersonnelTrainingStatusDetails from 'components/projectPersonnel/ProjectPersonnelTrainingStatusDetails';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function ProjectPersonnelRow(props) {
  const projectPersonnelId = props.formattedProjectPersonnel.id;
  const personnel = props.formattedProjectPersonnel.personnel;
  const companyRole = props.formattedProjectPersonnel.companyRole;
  const review = props.formattedProjectPersonnel.review;
  const reviewedVersion = props.formattedProjectPersonnel.reviewedVersion;
  const assignableCompanyRoles = props.formattedProjectPersonnel.assignableCompanyRoles;
  const handleShowPersonnel = props.formattedProjectPersonnel.handleShowPersonnel;
  const handleShowAcceptedReview = props.formattedProjectPersonnel.handleShowAcceptedReview;
  const handleShowRejectedReview = props.formattedProjectPersonnel.handleShowRejectedReview;

  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const isInitialMount = useRef(true);
  const displayName = personDisplayName(personnel.attributes);
  const [projectPersonnel, _setProjectPersonnel, , handleOptionChange] = useForm({
    companyRoleId: (companyRole ? companyRole.id : null)
  });

  const updateProjectPersonnel = () => {
    axios
      .patch(`/project_personnel/${projectPersonnelId}`, {
        project_personnel: {
          company_role_id: projectPersonnel.companyRoleId
        }
      })
      .then(response => props.replaceProjectPersonnel(response.data.data))
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: <Fragment><span className='tw-font-semibold'>{displayName}</span> was not updated.<br/>Please try again.</Fragment>
        })
      })
  };

  const companyRoleValue = () => {
    const assignedCompanyRole = assignableCompanyRoles.find(assignableCompanyRole => projectPersonnel.companyRoleId === assignableCompanyRole.id);

    return assignedCompanyRole ? { label: assignedCompanyRole.attributes.position, value: assignedCompanyRole.id } : null
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateProjectPersonnel()
    }
  }, [projectPersonnel.companyRoleId])

  return (
    <tr className='options-toggle-container fh-49 tw-group/row hover:tw-bg-grey-025'>
      <td className='tw-border-grey-100'>
        <div className='fh-32 flex flex--vertically-centered'>
          <span className='tw-font-medium tw-text-grey-900'>{displayName}</span>
          <OutlinedButton className='m-l-8' size='xs' color='grey' onClick={() => { handleShowPersonnel(personnel.id) }}>
            <PersonDetailsIcon className='m-l--4 m-r--4' />
          </OutlinedButton>
        </div>
      </td>
      <td className='tw-border-grey-100'>
        {assignableCompanyRoles.length > 1 ? (
          <div className='mw-260'>
            <CollectionSelect
              name='companyRoleId'
              modifiers={['inline-form']}
              value={companyRoleValue()}
              isRequired={true}
              isDisabled={false}
              isSearchable={false}
              placeholder='Select role...'
              options={
                assignableCompanyRoles.map((assignableCompanyRole) => {
                  return { label: assignableCompanyRole.attributes.position, value: assignableCompanyRole.id }
                })
              }
              onChange={handleOptionChange}
            />
          </div>
        ) : (
          <div className='fh-32 flex flex--vertically-centered'>
            <span className='m-l-1 p-l-10'>{companyRole ? companyRole.attributes.position : null}</span>
          </div>
        )}
      </td>
      {currentActor.isAllowedFeature('training_register')  && (
        <td className='tw-border-grey-100'>
          <ProjectPersonnelTrainingStatusDetails
            status={props.formattedProjectPersonnel.trainingStatus}
          />
        </td>
      )}
      <td className='tw-border-grey-100'>
        {currentActor.isAllowedFeature('digital_signatures') && (
          <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
            <ReviewStatus
              review={review}
              personnel={personnel}
              reviewedVersion={reviewedVersion}
              latestVersion={props.latestVersion}
              onShowAcceptedReview={handleShowAcceptedReview}
              onShowRejectedReview={handleShowRejectedReview}
            />
          </div>
        )}
      </td>
      <td className='tw-border-grey-100 p-r-24'>
        <ProjectPersonnelRowOptions
          review={review}
          personnel={personnel}
          projectPersonnelId={projectPersonnelId}
          fetchProjectPersonnel={props.fetchProjectPersonnel}
          destroyProjectPersonnel={props.destroyProjectPersonnel}
        />
      </td>
    </tr>
  )
}
