import _ from 'lodash';
import PropTypes from 'prop-types';
import setupPolling from 'utilities/setupPolling';

const mapConfigurationShape = {
  centerLatitude: PropTypes.number,
  centerLongitude: PropTypes.number,
  zoomLevel: PropTypes.number,
}

// Regex for UK postcode format. Source: https://stackoverflow.com/a/51885364
const postcodeFormatRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const shortenWeekday = (day) => day.slice(0, 3);

function formatOpeningHours({ hours }) {
  if (_.isEmpty(hours)) return null

  let currentRange = {};

  const ranges = weekdays.reduce((rangeAcc, weekday) => {
    if (!hours[weekday] || !hours[weekday].length) {
      if (!_.isEmpty(currentRange)) rangeAcc.push({ ...currentRange });
      currentRange = {};
    } else if (currentRange.start === undefined && hours[weekday].length > 0) {
      currentRange.start = weekday;
      currentRange.end = weekday;
      currentRange.hours = hours[weekday];
    } else if (_.isEqual(currentRange.hours, hours[weekday])) {
      currentRange.end = weekday;
    } else if (hours[weekday].length > 0) {
      rangeAcc.push({ ...currentRange });
      currentRange.start = weekday;
      currentRange.end = weekday;
      currentRange.hours = hours[weekday];
    }

    return rangeAcc;
  }, []);

  if (!_.isEmpty(currentRange)) ranges.push({ ...currentRange });

  if (_.isEqual(ranges, [{ start: 'Monday', end: 'Sunday', hours: [{ open: '00:00', close: '23:59' }] }])) {
    return 'Open 24 hours';
  }

  return ranges
    .map(
      (range) =>
        `${
          range.start === range.end
            ? shortenWeekday(range.start)
            : `${shortenWeekday(range.start)}-${shortenWeekday(range.end)}`
        }: ${range.hours.map((period) => `${period.open}-${period.close}`).join(', ')}`,
    )
    .join(', ');
}

function formatServiceAge({ maxAge, minAge }) {
  if (!minAge && !maxAge) return 'People of all ages';
  if (maxAge >= 100) return !!minAge ? `Anyone over ${minAge} years` : 'People of all ages';
  if (!!minAge) return `Anyone between ${minAge} and ${maxAge} years`;

  return `Anyone under ${maxAge} years`;
}

function isValidPostcodeFormat({ postcode }) {
  return postcodeFormatRegex.test(postcode);
}

function startPolling({ poller, url }) {
  poller.current = setupPolling({
    requestFn: () => axios.get(url),
    testFn: (response) => ['completed', 'failed'].includes(response.data.meta.request_status),
    intervalTime: 1000,
    maxTime: 60000
  });

  return poller.current.startPolling();
}

export {
  formatOpeningHours,
  formatServiceAge,
  isValidPostcodeFormat,
  mapConfigurationShape,
  startPolling
}
