import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';

import Tooltip from 'components/application/Tooltip';
import SmallStubPill from 'components/application/pills/SmallStubPill';

import { autoEnrolmentStatusText } from 'components/helpers/resources/autoEnrolments';

export default function ELearningCourseAutoEnrolsPill({
  status,
  latestTrainingExpiryDate,
  courseExpiringDuration
 }) {

  const isAutoEnrolActionBlocked = status.includes('insufficientCredits') || status.includes('personnelEmailUnavailable') || status.includes('creditInfoUnavailable');

  return (
    <span className='m-l-8'>
      <Tooltip
        placement='top'
        trigger='hover'
        tooltip={autoEnrolmentStatusText({
          status,
          latestTrainingExpiryDate,
          courseExpiringDuration
        })}
      >
        <SmallStubPill
          className={classNames(
            isAutoEnrolActionBlocked ? 'tw-text-amber-800 tw-bg-amber-025' : 'tw-text-green-800 tw-bg-green-025'
          )}>
          Auto-enrols
        </SmallStubPill>
      </Tooltip>
    </span>
  )
}

ELearningCourseAutoEnrolsPill.propTypes = {
  status: PropTypes.string,
  latestTrainingExpiryDate: PropTypes.string,
  courseExpiringDuration: PropTypes.string
};
