import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Uploader from '../uploader/Uploader';
import Viewer from 'components/attachments/Viewer';

const isSortable = { 'default': true, 'compact': false };

const SortableMember = SortableElement(({ sortIndex, attachment, viewerProps }) => {
  return (
    <Viewer
      index={sortIndex}
      attachment={attachment}
      {...viewerProps}
    />
  )
});

const SortableCollection = SortableContainer(({ mode, attachments, uploaderProps, viewerProps }) => {
  return (
    <div className={`attachments__sortable ${isSortable[mode] ? 'attachments__sortable--enabled' : 'attachments__sortable--disabled'}`}>
      <Uploader {...uploaderProps}/>
      {attachments.map((attachment, index) =>
        <SortableMember
          key={attachment.key || attachment.id}
          disabled={!isSortable[mode] || !attachment.id}
          index={index}
          sortIndex={index}
          attachment={attachment}
          viewerProps={viewerProps}
        />
      )}
    </div>
  )
});

export default function Collection(props) {
  const { mode, attachments, uploaderProps, viewerProps, onSortEnd } = props;

  return (
    <SortableCollection
      helperClass='attachments__item--active'
      transitionDuration={400}
      distance={1}
      axis='xy'
      mode={mode}
      attachments={attachments}
      uploaderProps={uploaderProps}
      viewerProps={viewerProps}
      onSortEnd={isSortable[mode] ? onSortEnd : () => void(0)}
    />
  )
}

Collection.propTypes = {
  mode: PropTypes.string,
  attachments: PropTypes.array.isRequired,
  uploaderProps: PropTypes.object.isRequired,
  viewerProps: PropTypes.object.isRequired,
  onSortEnd: PropTypes.func
}

Collection.defaultProps = {
  mode: 'default'
}
