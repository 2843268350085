import HandshqApp from '../handshq-app';

// could be converted to more generic stateful modal rather than specific to downloading pdfs.
HandshqApp.PdfRetrievalModal = function(options) {
  if (this instanceof HandshqApp.PdfRetrievalModal) {
    const modal = options['el'];
    const downloadBtn = modal.find('.pdf-retrieval__download');
    const downloadBtnSpinner = modal.find('[data-identity="spinner"]');
    const downloadBtnText = downloadBtn.find('span');
    const statusText = modal.find('.pdf-retrieval__text');
    const retryBtn = modal.find('.pdf-retrieval__retry');

    retryBtn.on('click', options['onRetry']);
    downloadBtn.on('click', function() {
      modal.modal('tw-hidden');
    })

    this.showAsPreparing = function() {
      retryBtn.addClass('tw-hidden');
      statusText.text('Preparing to download PDF');
      downloadBtnSpinner.removeClass('tw-hidden');
      downloadBtn.removeClass('tw-hidden').addClass('tw-opacity-30 tw-pointer-events-none');
      downloadBtnText.text('Please wait...');
    }

    this.showAsError = function() {
      downloadBtn.addClass('tw-hidden');
      retryBtn.removeClass('tw-hidden');
      statusText.text('There was a problem creating the PDF');
    }

    this.showAsCompleted = function(pdfUrl) {
      retryBtn.addClass('tw-hidden');
      downloadBtn.removeClass('tw-opacity-30 tw-pointer-events-none tw-hidden').attr('href', pdfUrl);
      downloadBtnText.text('Download now');
      downloadBtnSpinner.addClass('tw-hidden');
      statusText.text('PDF ready to download');
    }

  } else {
    throw new Error('HandshqApp.PdfRetrievalModal invoked without new');
  }
};
