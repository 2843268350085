import React from 'react';

export default function SegmentedControls({choices, activeChoice, onItemClick}) {
  return(
    <span className='segmented-controls tw-border-grey-200 tw-bg-white hover:tw-border-grey-300'>
      {
        choices.map((choice) => {
          return(
            <span
              key={`segmentedControlItem--${choice}`}
              className={`segmented-controls__item tw-text-s tw-font-medium tw-tracking-wide ${activeChoice === choice ? 'segmented-controls__item--active tw-bg-blue-025 tw-text-blue-900' : 'hover:tw-bg-grey-050'}`}
              onClick={() => onItemClick(choice)}
            >
              {choice}
            </span>
          )
        })
      }
    </span>
  )
}
