import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=AddIcon!icons/ic-download.svg';

export default function Download(props) {
  const { hashid, filename, buttonText, disabled } = props;
  const handleClick = () => saveAs(`/attachments/${hashid}/download`, filename);

  return (
    <OutlinedButton size='sm' color='grey' onClick={handleClick} disabled={disabled}>
      <DownloadIcon className='m-l--8' width={24} height={24}/>
      <span>{buttonText}</span>
    </OutlinedButton>
  )
}

Download.propTypes = {
  hashid: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  buttonText: PropTypes.string
}

Download.defaultProps = {
  buttonText: 'Download original'
}
