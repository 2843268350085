import React  from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/application/Toast';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function ResourceChangedToast(props) {

  const {
    resource,
    resourceTarget,
    onBurnToast,
    toastKey,
    status,
    additionalText,
    plural,
    resourceLinkPath,
    resourceLinkLabel,
    resourceMaxLength
  } = props

  return(
    <Toast
      toastKey={toastKey}
      burn={onBurnToast}
      modifiers='no-undo'
      burnTimer={7000}
      contents={
        <React.Fragment>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          {resourceTarget ? (
            <div className='toast__text'>
              {`${resource} has been ${status} for`}
              <span className='tw-font-medium'>
                {` ${resourceTarget}.`}
              </span>
            </div>
          ) : (
            <div className='toast__text'>
              <span className='tw-font-medium'>
                { resourceMaxLength && resource.length > resourceMaxLength ? `${resource.slice(0,resourceMaxLength)}... ` : `${resource} ` }
              </span>
              { additionalText ? `${additionalText} ` : '' }
              { `${plural ? 'were' : 'was'} ${status}` }
            </div>
          )}
          {(resourceLinkPath && resourceLinkLabel) && (
            <div className='p-l-32 p-t-12'>
              <OutlinedButton size='sm' color='grey' href={resourceLinkPath}>{resourceLinkLabel}</OutlinedButton>
            </div>
          )}
        </React.Fragment>
      }
    />
  )
}

ResourceChangedToast.defaultProps = {
  additionalText: null,
  plural: false
}

ResourceChangedToast.propTypes = {
  status: PropTypes.string.isRequired,
  resourceLinkLabel: PropTypes.string,
  resourceLinkPath: PropTypes.string,
  resourceMaxLength: PropTypes.number
}
