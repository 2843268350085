import HandshqApp from 'handshq-app/handshq-app'

export default function DocumentGeneratorService(options) {
  if (this instanceof DocumentGeneratorService) {
    let documentStatusPolling, jobStatusPolling;
    const _this = this;

    this.generateUrl = options.generateUrl;
    this.generateParams = options.generateParams;
    this.successCallback = options.successCallback;
    this.failureCallback = options.failureCallback;
    this.isInline = options.isInline;

    const pollForDocumentStatus = (exportUrl) => {
      documentStatusPolling = new HandshqApp.Polling({
        url: exportUrl,
        maxTime: 600000,
        responseCheck: (response) => { return response.url },
        successCallback: (response) => { _this.successCallback(response.url) },
        failureCallback: (_error) => { _this.failureCallback() }
      })
      documentStatusPolling.start()
    };

    const pollForJobStatus = (jobUrl) => {
      jobStatusPolling = new HandshqApp.Polling({
        url: jobUrl,
        maxTime: 600000,
        responseCheck: (response) => { return response.export_url },
        successCallback: (response) => {
          if (_this.isInline) {
            _this.successCallback(response.export_url)
          } else {
            pollForDocumentStatus(response.export_url)
          }
        },
        failureCallback: (_response) => { _this.failureCallback() }
      })
      jobStatusPolling.start()
    };

    this.start = () => {
      axios
        .post(`${_this.generateUrl}`, (_this.generateParams || {}))
        .then(response => pollForJobStatus(response.data.job_url))
        .catch(_error => _this.failureCallback())
    };

    this.end = () => {
      if (documentStatusPolling) { documentStatusPolling.end() }
      if (jobStatusPolling) { jobStatusPolling.end() }
    };
  } else {
    throw new Error('DocumentGeneratorService invoked without new')
  }
};
