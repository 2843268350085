import React, { useState, Fragment } from 'react';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { personDisplayName } from 'components/helpers/users';
import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import Tooltip from 'components/application/Tooltip';
import DestroyModal from 'components/application/DestroyModal';

export default function ProjectPersonnelRowOptions(props) {
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const displayName = personDisplayName(props.personnel.attributes);

  const resetStatus = () => {
    axios
      .post(`/reviews/${props.review.id}/reset`)
      .then(_response => props.fetchProjectPersonnel)
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: <Fragment>Signature status was not reset.<br/>Please try again.</Fragment>
        })
      })
  };

  const signedOrRejected = { 'rejected': 'rejected', 'accepted': 'signed' }

  const sendReminder = () => {
    axios
      .post(`/reviews/${props.review.id}/reminders`)
      .catch((_error) => {
        breadBoard.addInedibleToast({
          fullMessage: <Fragment><span className='tw-font-medium'>{displayName}</span> was not sent a reminder.<br/>Please try again.</Fragment>
        })
      })
  };

  const sendReminderTooltipText = () => {
    if (props.review.attributes.state === 'rejected' || props.review.attributes.state === 'accepted') {
      return `Personnel has ${signedOrRejected[props.review.attributes.state]} document`
    } else if (!props.personnel.attributes.email) {
      return 'No email address for personnel'
    }
  };

  const signNowTooltipText = () => {
    return `Personnel has ${signedOrRejected[props.review.attributes.state]} document`
  };

  const signNowOption = currentActor.isAllowedFeature('sign_in_person') && props.review && (
    props.review.attributes.state === 'accepted' || props.review.attributes.state === 'rejected' ? (
      <OptionChoice disabled={true}>
        <Tooltip placement='top' trigger='hover' tooltip={signNowTooltipText()}>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Sign now</a>
        </Tooltip>
      </OptionChoice>
    ) : (
      <OptionChoice>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto' href={`/reviews/${props.review.attributes.accessToken}?in_person=true`}>Sign now</a>
      </OptionChoice>
    )
  );

  const removeFromProjectOption = (
    <OptionChoice>
      <a className='tw-text-m tw-text-red-600 hover:tw-text-red-600 tw-font-medium tw-tracking-auto' onClick={() => setDestroyModalIsOpen(true)}>Remove from {currentActor.division.attributes.singularRamsName || 'RAMS'}</a>
    </OptionChoice>
  );

  const resetSignatureStatusOption = props.review && props.review.attributes.state === 'rejected' && (
    <OptionChoice onClick={(setOptionsOpen) => {
      setOptionsOpen(false)
      resetStatus()
    }}>
      <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Reset signature status</a>
    </OptionChoice>
  );

  const sendReminderOption = props.review && props.personnel && (
    props.review.attributes.state === 'rejected' || props.review.attributes.state === 'accepted' || !props.personnel.attributes.email ? (
      <OptionChoice disabled={true}>
        <Tooltip placement='top' trigger={sendReminderTooltipText() ? 'hover' : 'none'} tooltip={sendReminderTooltipText()}>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Send reminder to sign</a>
        </Tooltip>
      </OptionChoice>
    ) : (
      <OptionChoice onClick={(setOptionsOpen) => {
        setOptionsOpen(false)
        sendReminder()
      }}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Send reminder to sign</a>
      </OptionChoice>
    )
  );

  return (
    <Options
      key={props.projectPersonnelId}
      additional={
        <DestroyModal
          isOpen={destroyModalIsOpen}
          displayText={`Are you sure you want to remove ${displayName}?`}
          confirmationText={`Remove ${displayName}`}
          onClose={() => setDestroyModalIsOpen(false)}
          onDestroy={() => props.destroyProjectPersonnel(props.projectPersonnelId, displayName, false)}
        />
      }
    >
      {signNowOption}
      {sendReminderOption}
      {removeFromProjectOption}
      {resetSignatureStatusOption}
    </Options>
  )
}
