import React from 'react';

export default function ActivityTableHeaders(props) {
  return (
    <tr className='handshq__tr--grey tw-bg-grey-050'>
      {props.showVersionNumberColumn && <th className='w-8 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-center'>Version</th>}
      <th className='w-20 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Date</th>
      <th className='w-62 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Change</th>
      <th className='w-15'></th>
    </tr>
  )
}
