import React from 'react';
import PropTypes from 'prop-types';

export default function RequestErrorMessage({ isFallback, validationErrors, withSeparateNestedErrors = false }) {
  const getErrorDetail = (error) => {
    return (withSeparateNestedErrors && error.error.source.nestedResource) ? error.error.nestedErrorDetail : error.error.detail
  }

  return isFallback ? (
    <p>There appears to be a problem, please contact support if this continues.</p>
  ) : (
    <>
      <p className='tw-mb-2'>Please fix the following errors:</p>
      <ul className='tw-mb-0'>
        {Object.keys(validationErrors).map((key, index) => <li key={index}>{getErrorDetail(validationErrors[key])}</li>)}
      </ul>
    </>
  )
}

RequestErrorMessage.propTypes = {
  isFallback: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  withSeparateNestedErrors: PropTypes.bool
};
