import React from 'react';
import voca from 'voca';
import PersonalInfo from 'components/application/PersonalInfo';
import pluralize from 'pluralize';
import { learningMethodMap } from 'components/helpers/resources/courses';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { displayCurrencyString } from 'components/helpers/currency';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function BookingSidePanelCourseTab({ course }) {
  const currentActor = useCurrentActor();
  const currencyCode = currentActor.division.attributes.currencyCode;
  const renewalFrequency = course.attributes.expires ? voca.isBlank(course.attributes.renewalFrequency) ? "" : `${pluralize(course.attributes.renewalInterval, parseInt(course.attributes.renewalFrequency), true)}` : 'Does not expire';
  const costEstimate = course.attributes.costEstimate ? displayCurrencyString({ string: String(course.attributes.costEstimate), currencyCode }) : "";
  const courseDuration = voca.isBlank(course.attributes.timeAmount) ? "" : `${pluralize(course.attributes.timeInterval, parseInt(course.attributes.timeAmount), true)}`;
  const method = learningMethodMap[course.attributes.learningMethod];

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      <PersonalInfo label='Renewal frequency' text={renewalFrequency} />
      <PersonalInfo label='Method' text={method} />
      <PersonalInfo label='Provider' text={course.attributes.provider ? String(course.attributes.provider) : ""} />
      <PersonalInfo label='Cost estimate' text={costEstimate} />
      <PersonalInfo label='Time estimate' text={courseDuration} />
    </div>
  )
}

BookingSidePanelCourseTab.propTypes = {
  course: resourceShape('course')
}
