import { enableAllPlugins } from 'immer';
enableAllPlugins()
import { produce } from 'immer';

const shareSettingsInitialStore = {
  domain: {
    masterActivity: null,
    masterActivityTrades: { data: {}, allDataIds: [] },
    selectableTrades: { data: {}, allDataIds: [] }
  },
  ui: {
    form: {},
    submitting: false
  }
};

const shareSettingsReducer = (state, action) => {
  return (
    produce(state, draftState => {
      switch (action.type) {
        case 'UPDATE_FORM':
          draftState.ui.form[action.key] = action.value;
          break;
        case 'UPDATE_SUBMITTING':
          draftState.ui.submitting = action.value;
          break;
      }
    })
  )
};

export {
  shareSettingsReducer,
  shareSettingsInitialStore
}
