import React from 'react';
import PropTypes from 'prop-types';

import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import FieldForm from 'components/settings/personnelInformation/FieldForm';

export default function FieldSidePanel(props) {
  const {
    sidePanel,
    form,
    removeErrorStyling,
    onFormValueChange,
    onFormOptionValueChange,
    onFormOptionAppend,
    onFormOptionDelete,
    onFormOptionOrderChange,
    onCreate,
    onUpdate,
    closeCallback
  } = props;

  const onBackdropClick = () => {
    const panelLocked = (['new', 'edit'].includes(sidePanel.context));

    if (!panelLocked) { closeCallback() }
  }

  return (
    <SidePanel
      color='tertiary'
      isOpen={sidePanel.isOpen}
      closeCallback={closeCallback}
      headerContent={
        <div className='popup__title popup__title--tertiary'>
          <h1 className='tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{sidePanel.context === 'new' ? 'Add' : 'Edit'} custom field</h1>
        </div>
      }
      bodyContent={
        <FieldForm
          {...form}
          sidePanel={sidePanel}
          removeErrorStyling={removeErrorStyling}
          onFormValueChange={onFormValueChange}
          onFormOptionValueChange={onFormOptionValueChange}
          onFormOptionAppend={onFormOptionAppend}
          onFormOptionDelete={onFormOptionDelete}
          onFormOptionOrderChange={onFormOptionOrderChange}
        />
      }
      footerContent={
        <FormFooter
          text={sidePanel.context === 'new' ? 'Add custom field' : 'Save changes'}
          submitButtonDisabled={form.isSubmitDisabled}
          onCancel={closeCallback}
          onSubmit={sidePanel.context === 'new' ? onCreate : onUpdate}
        />
      }
      onBackdropClick={onBackdropClick}
    />
  )
}

FieldSidePanel.propTypes = {
  sidePanel: PropTypes.exact({
    context: PropTypes.string,
    isOpen: PropTypes.bool.isRequired
  }).isRequired,
  form: PropTypes.exact({
    fieldSettingId: PropTypes.string,
    fieldAttributeId: PropTypes.string,
    name: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    restricted: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    requestError: PropTypes.object.isRequired,
    isSubmitDisabled: PropTypes.bool.isRequired
  }).isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  onFormOptionValueChange: PropTypes.func.isRequired,
  onFormOptionAppend: PropTypes.func.isRequired,
  onFormOptionDelete: PropTypes.func.isRequired,
  onFormOptionOrderChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired
};
