import { useState, useRef } from "react";
import _ from "lodash";

import useResizeObserver from "@react-hook/resize-observer";

export default function useSize(target) {
  const [isResizing, setIsResizing] = useState(false);
  const [size, setSize] = useState(target.getBoundingClientRect());

  const debounceCallback = (size) => {
    setIsResizing(false)
    setSize(size)
  }
  const setDebouncedSize = useRef(_.debounce(debounceCallback, 200)).current;

  useResizeObserver(target, (entry) => {
    if (!isResizing) setIsResizing(true)
    setDebouncedSize(entry.contentRect)
  })

  return { isResizing, size }
}
