import React from 'react';
import PropTypes from 'prop-types';
import RolesTable from 'components/roles/RolesTable';
import Paginator from 'components/application/Paginator';

export default function RolesMain(props) {
  return (
    <React.Fragment>
      <RolesTable
        roles={props.roles}
        setSidePanelContext={props.setSidePanelContext}
        setCurrentRole={props.setCurrentRole}
        resetRequestError={props.resetRequestError}
        openSidePanel={props.openSidePanel}
      />
      { props.metaData.totalPages > 1 &&
        <div className='m-t-80 text-center'>
          <Paginator
            currentPage={props.metaData.currentPage}
            totalPages={props.metaData.totalPages}
            onClick={props.onPageChange}
          />
        </div>
      }
    </React.Fragment>
  )
}

RolesMain.propTypes = {
  setCurrentRole: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  metaData: PropTypes.object.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  openSidePanel: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
}
