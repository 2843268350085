import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/application/Table';
import CoshhRow from 'components/projectCoshh/CoshhRow';

export default function ProjectCoshhTable({ coshhDocuments, onCoshhDocumentRowClick, onDownloadClick, onDeleteClick }) {
  return (
    <Table
      headers={
        <tr className='handshq__tr--grey tw-bg-grey-050'>
          <th className='w-50 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>COSHH assessment</th>
          <th className='w-30 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Manufacturer</th>
          <th className='w-10 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Reference</th>
          <th className='w-10  tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-right'>Actions</th>
        </tr>
      }
      rows={
        coshhDocuments.map((coshhDocument) =>
          <CoshhRow
            key={coshhDocument.id}
            coshhDocument={coshhDocument}
            onCoshhDocumentRowClick={onCoshhDocumentRowClick}
            onDownloadClick={onDownloadClick}
            onDeleteClick={onDeleteClick}
          />
        )
      }
    />
  )
}

ProjectCoshhTable.propTypes = {
  coshhDocuments: PropTypes.array.isRequired,
  onCoshhDocumentRowClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
}

ProjectCoshhTable.defaultProps = {
  coshhDocuments: []
}
