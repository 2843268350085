import React, { useState, useEffect, Fragment } from 'react';
import useDebounce from 'components/hooks/useDebounce';
import PropTypes from 'prop-types';
import Toggleable from 'components/application/Toggleable';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import SearchIcon from '-!svg-react-loader?name=SearchIcon!icons/search.svg';
import CollectionSelect from 'components/application/CollectionSelect';
import ProjectPersonnelAddedToast from 'components/projectPersonnel/ProjectPersonnelAddedToast';
import ProjectPersonnelSidePanel from 'components/projectPersonnel/ProjectPersonnelSidePanel';
import { ProjectPersonnelContext } from 'components/contexts/ProjectPersonnelContext';
import { useBreadBoard } from 'components/contexts/Toaster';

export default function SelectPersonnel(props) {
  const breadBoard = useBreadBoard();
  const [isToggled, setIsToggled] = useState(false);
  const defaultSelect = { inputValue: null, callback: null };
  const [select, setSelect] = useState(defaultSelect);
  const [debouncedInputValue, resetDebouncedInputValue] = useDebounce(select.inputValue, 250);
  const [_assignablePersonnel, setAssignablePersonnel] = useState([]);
  const [createOptionInputValue, setCreateOptionInputValue] = useState(null);

  const resetCreateOptionInputValue = () => { setCreateOptionInputValue(null) };
  const personnelOption = personnel => {
    return {
      value: personnel.id,
      label: `${personnel.attributes.firstName} ${personnel.attributes.lastName}`,
      disabled: props.personnelIds.includes(personnel.id)
    }
  };
  const personnelOptions = (inputValue, callback) => setSelect({ inputValue: inputValue, callback: callback });

  const fetchPersonnelLoadOptions = () => {
    if (debouncedInputValue && debouncedInputValue.length > 1) {
      axios
        .get('/personnel', { params: { account_personnel: true, search: debouncedInputValue } })
        .then((response) => {
          setAssignablePersonnel(response.data.data)
          select.callback(response.data.data.map(personnel => personnelOption(personnel)))
        })
        .catch(breadBoard.addInedibleToast)
    } else {
      select.callback([])
    }
  };

  const createProjectPersonnel = (selected, _actionMeta = {}) => {
    const displayName = selected.label;
    axios
      .post('/project_personnel', { project_personnel: { personnel_id: selected.value, project_id: props.projectId } })
      .then((response) => {
        const personnelData = response.data.included.find(inclusion => inclusion.type === 'personnel');
        breadBoard.addToast(
          <ProjectPersonnelAddedToast
            destroyProjectPersonnel={props.destroyProjectPersonnel}
            projectPersonnelId={response.data.data.id}
            personnel={personnelData}
            displayName={displayName}
          />
        )
        setIsToggled(false)
        props.fetchProjectPersonnel()
      })
      .catch((_error) => {
        breadBoard.addInedibleToast({ fullMessage: <Fragment>{`${displayName} was not added to the RAMS.`}<br/>Please try again.</Fragment> })
      })
  }

  const handleFocus = () => {
    resetDebouncedInputValue()
    resetCreateOptionInputValue()
  }

  const handleCreateOption = (inputValue) => {
    setCreateOptionInputValue(inputValue)
    setIsToggled(false)
  }

  const handleBlur = () => setIsToggled(false);

  const handleDisabledOption = option => option.disabled;

  useEffect(() => {
    if (select.callback) { fetchPersonnelLoadOptions() }
  }, [debouncedInputValue])

  return (
    <React.Fragment>
      <Toggleable
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        primary={
          <OutlinedButton size='sm' color='grey' href='#0'>
            <PersonIcon className='m-l--8' width={24} height={24} />
            <span className='p-l-4'>Add personnel</span>
          </OutlinedButton>
        }
        secondary={
          <div className='pos-rel'>
            <SearchIcon width={24} height={24} className='[&_path]:tw-fill-grey-700 pos-abs zi-1 m-t-5 m-l-5'/>
            <div className='fw-260'>
              <CollectionSelect
                name='personnel'
                modifiers={['inline-form', 'inline-search']}
                isAsync={true}
                noDropdown={true}
                isCreatable={true}
                autoFocus={true}
                options={personnelOptions}
                placeholder='Search or add personnel'
                onChange={createProjectPersonnel}
                externalProps={{
                  onFocus: handleFocus,
                  onCreateOption: handleCreateOption,
                  onBlur: handleBlur,
                  isOptionDisabled: handleDisabledOption,
                  isValidNewOption: (inputValue, _selectValue, _selectOptions) => {
                    const isNotEmpty = inputValue.length > 0
                    const doesNotContainADigit = !/\d/.test(inputValue)
                    return ( isNotEmpty && doesNotContainADigit )
                  },
                  additionalNoOptionsMessage: 'To create a new personnel, start typing their name.'
                }}
              />
            </div>
          </div>
        }
      />
    <ProjectPersonnelContext.Provider value={
      {
        userHasRoleCreatableAccess: props.userHasRoleCreatableAccess
      }
    }>
      <ProjectPersonnelSidePanel
        createOptionInputValue={createOptionInputValue}
        createProjectPersonnel={createProjectPersonnel}
        resetCreateOptionInputValue={resetCreateOptionInputValue}
        availableFields={props.availableFields}
        availableFieldOptions={props.availableFieldOptions}
      />
    </ProjectPersonnelContext.Provider>
    </React.Fragment>
  )
}

export const selectPersonnelShape = PropTypes.exact({
  availableFields: PropTypes.object,
  availableFieldOptions: PropTypes.object,
  fetchProjectPersonnel: PropTypes.func.isRequired,
  personnelIds: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  destroyProjectPersonnel: PropTypes.func,
})

SelectPersonnel.propTypes = selectPersonnelShape;
