import { useState } from 'react';

export default function useCollection(initialCollection) {
  const defaultCollectionErrors = {errorList: [], isFallback: false}
  const [values, setValues] = useState({loaded: false, collection: initialCollection});
  const [collectionErrors, setCollectionErrors] = useState(defaultCollectionErrors);

  const handleChange = (event, meta={}) => {
    const name = event.target.name;
    const value = event.target.value;
    const array = [...values.collection];

    array[meta.index] = {...array[meta.index], [name]: value};

    setValues({...values, collection: array})
  };

  const handleModify = (actionMeta={}) => {
    const array = [...values.collection];
    const newCollectionErrorList = [...collectionErrors.errorList]

    switch (actionMeta.action) {
      case 'add':
        array.push(actionMeta.newData)
        break;
      case 'remove':
        array.splice(actionMeta.index, 1);
        if (newCollectionErrorList[actionMeta.index]) {
          delete newCollectionErrorList[actionMeta.index]
        }
        break;
      case 'replace':
        array[actionMeta.index] = actionMeta.newData;
        break;
  }
    setValues({...values, collection: array});
    setCollectionErrors({...collectionErrors, errorList: newCollectionErrorList})
  };

  const cancelChanges = () => {
    setValues({...values, loaded: false})
    setCollectionErrors(defaultCollectionErrors)
  };

  const loadCollection = (collection) => {
    setValues({loaded: true, collection: collection})
  };

  const isValidationError = (callbacks) => {
    const newErrorList = values.collection.map((member) => {
      const validationErrors = callbacks.map(cb => cb(member, values.collection)).filter(Boolean);

      return validationErrors.length === 0 ? null : {errorMessage: validationErrors[0], fieldHighlighted: true}
    });

    setCollectionErrors({...collectionErrors, errorList: newErrorList})

    return newErrorList.some(item => item)
  }

  const removeErrorStyling = (index) => {
    const _error = collectionErrors.errorList[index].errorMessage
    const newErrorList = [...collectionErrors.errorList]

    newErrorList[index] = {...newErrorList[index], fieldHighlighted: false}
    setCollectionErrors({...collectionErrors, errorList: newErrorList})
  }

  const resetCollectionErrors = (_index) => {
    setCollectionErrors(defaultCollectionErrors)
  }

  const setIsFallback = () => {
    const newCollectionErrors = {...collectionErrors, isFallback: true}
    setCollectionErrors(newCollectionErrors)
  }

  const replaceMember = (index, newMember) => {
    const array = [...values.collection];
    array[index] = newMember;
    setValues({...values, collection: array});
  }

  const submitDisabled = collectionErrors.errorList.some(value => value) && collectionErrors.errorList.every(field => !field || field.fieldHighlighted)

  return [
    values,
    loadCollection,
    handleChange,
    handleModify,
    cancelChanges,
    setCollectionErrors,
    collectionErrors,
    submitDisabled,
    isValidationError,
    setIsFallback,
    resetCollectionErrors,
    removeErrorStyling,
    replaceMember
  ]
}
