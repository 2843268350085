import React from 'react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';

import { collectionsDiffer } from 'components/helpers/objects';
import { resourceIdsAsPositionParams } from 'components/helpers/ordering';
import { genericErrorMessage } from 'components/helpers/errors';

import AvailableStep from 'containers/AvailableStep';

const SortableItem = SortableElement(({ stepId }) => {
  return (
    <AvailableStep stepId={stepId} />
  )
});

const SortableList = SortableContainer(({ items, isSorting }) => {
  return (
    <ul className='block-list block-list--border-bottom-none pos-rel'>
      {
        items.map((stepId, index) => {
          return (
            <React.Fragment key={`step--${stepId}`}>
              {isSorting && <div className='row-bar--ghost row-bar__ghost--step tw-border-blue-100 tw-bg-blue-025'></div>}
              <SortableItem
                index={index}
                stepId={stepId}
              />
            </React.Fragment>
          )
        })
      }
    </ul>
  )
});

const collapsedItemSpace = 52;

export default function StepList({ dispatch, methodSequenceId, containerRef, addError, stepIds, isStepsOpen, isSorting }) {
  const handleSortStart = ({ node, index }) => {
    dispatch({
      type: 'START_STEPS_ORDERING',
      methodSequenceId: methodSequenceId,
      options: { containerRef, node, index, collapsedItemSpace }
    })
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const orderedStepIds = arrayMove(stepIds, oldIndex, newIndex);
    dispatch({
      type: 'FINISH_STEPS_ORDERING',
      methodSequenceId: methodSequenceId,
      newOrder: orderedStepIds
    })
    collectionsDiffer(oldIndex, newIndex) && requestStepsReorder(orderedStepIds)
  }

  const requestStepsReorder = (stepIds) => {
    axios
      .post(`/method_sequences/${methodSequenceId}/method_steps_bulk_updates`, {
        method_steps: {
          method_steps_attributes: resourceIdsAsPositionParams(stepIds)
        }
      })
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }))
  }

  return (
    <React.Fragment>
      <SortableList
        useDragHandle
        lockAxis='y'
        items={stepIds}
        onSortEnd={handleSortEnd}
        isOpen={isStepsOpen}
        helperClass={'sortable-item--active'}
        updateBeforeSortStart={handleSortStart}
        isSorting={isSorting}
        getContainer={() => document.getElementById('main')}
        lockToContainerEdges={true}
      />
    </React.Fragment>
  )
}
