import { enableAllPlugins } from 'immer';
enableAllPlugins()
import { produce } from 'immer';

export const emailProjectPageReducer = (state, action) => {
  return (
    produce(state, draftState => {
      switch (action.type) {
        case 'UPDATE_FORM_FIELD':
          draftState.application.form[action.payload.name] = action.payload.value;
          break;
      }
    })
  )
};

export const emailProjectPageInitialStore = {
  domain: {
    message: { data: {} },
    userCollection: { data: {}, allDataIds: [] },
    versionCollection: { data: {}, allDataIds: [] }
  },
  application: {
    pending: { message: true },
    form: {
      to: [],
      previouslyUsed: [],
      previouslyUsedEmailAddresses: [],
      sendToSelf: false,
      subject: '',
      ramsIncluded: false,
      trainingReportIncluded: false,
      evidenceIncluded: false,
      message: '',
      passwordRequired: false,
      password: ''
    }
  }
};
