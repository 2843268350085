import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { fieldTypeOption, fieldTypeOptions } from 'components/helpers/resources/fieldAttributes';

import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import CollectionSelect from 'components/application/CollectionSelect';
import Tooltip from 'components/application/Tooltip';
import RadioField from 'components/application/RadioField';
import FieldOptions from 'components/settings/personnelInformation/FieldOptions';
import AddButton from 'components/buttons/AddButton';
import { focusField } from '../../helpers/refs';

export default function FieldForm(props) {
  const {
    name,
    fieldType,
    restricted,
    requestError,
    options,
    sidePanel,
    removeErrorStyling,
    onFormValueChange,
    onFormOptionValueChange,
    onFormOptionAppend,
    onFormOptionDelete,
    onFormOptionOrderChange
  } = props;

  const nameInputRef = useRef();

  const onNameChange = ({ target }) => {
    onFormValueChange({ key: 'name', value: target.value })
  };

  const removeNameErrorStyling = () => {
    removeErrorStyling({ key: 'name' })
  };

  const onFieldTypeChange = ({ value }) => {
    onFormValueChange({ key: 'fieldType', value: value })
  };

  const onVisibilityChange = ({ target }) => {
    onFormValueChange({ key: 'restricted', value: (target.value === 'restricted') })
  };

  const removeFieldOptionsErrorStyling = () => {
    removeErrorStyling({ key: 'fieldOptions' })
  };

  useEffect(() => {
    if (sidePanel.isOpen) {
      focusField(nameInputRef)
    }
  }, [sidePanel.isOpen])

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <ErrorMessage modifiers='p-12' validationErrors={requestError.validationErrors} isFallback={requestError.isFallback} />
      <div className='form-container m-b-0'>
        {sidePanel.context === 'edit' && (
          <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-r-20 m-b-20 m-l-20'>
            <div className='m-r-12 fh-20'>
              <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
            </div>
            <p className='m-0'>
              Changes made here will affect existing records for all personnel in the company
            </p>
          </div>
        )}
        <div className='form-container-section'>
          <TextField
            inputRef={nameInputRef}
            label='Name'
            name='name'
            value={name}
            fieldError={requestError.validationErrors.name}
            removeErrorStyling={removeNameErrorStyling}
            isRequired={true}
            onChange={onNameChange}
          />
          <div className='tooltip-parent'>
            <CollectionSelect
              label='Field type'
              name='fieldType'
              value={fieldType ? fieldTypeOption(fieldType) : null}
              isRequired={true}
              placeholder='Select type'
              options={fieldTypeOptions}
              isDisabled={sidePanel.context === 'edit'}
              onChange={sidePanel.context === 'edit' ? () => void(0) : onFieldTypeChange}
            />
            {sidePanel.context === 'edit' && <Tooltip className='pos-t-14 tooltip-dark--max-w-xxs' placement='left' trigger='hover' tooltip="Field type can't be changed" />}
          </div>
          <label className='field__label tw-font-medium' htmlFor='visibility'>Visibility</label>
          <RadioField
            label='Restricted'
            name='visibility'
            value='restricted'
            checked={restricted}
            onChange={onVisibilityChange}
          />
          <div className='tw-text-s tw-font-normal tw-tracking-wide m-t--16 m-l-28'>Only personnel editors can view and edit</div>
          <RadioField
            label='Visible'
            name='visibility'
            value='visible'
            checked={!restricted}
            onChange={onVisibilityChange}
          />
          <div className='tw-text-s tw-font-normal tw-tracking-wide m-t--16 m-l-28'>All users can view</div>
        </div>
        {fieldType === 'select' && (
          <>
            <hr className='tw-h-px tw-bg-grey-100 tw-border-0 tw-mt-8 tw-mb-8'/>
            <div className='m-r-20 m-b-20 m-l-20'><label className='field__label tw-font-medium m-b-0'>Dropdown list</label></div>
            {sidePanel.context === 'edit' && (
              <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-r-20 m-b-20 m-l-20'>
                <div className='m-r-12 fh-20'>
                  <InfoIcon width={20} height={20} className="[&_path]:tw-fill-cyan-800" />
                </div>
                <p className='m-0'>
                  Deleting items from the dropdown list will delete any existing data
                </p>
              </div>
            )}
            <FieldOptions
              options={options}
              fieldError={requestError.validationErrors.fieldOptions}
              removeErrorStyling={removeFieldOptionsErrorStyling}
              onOptionValueChange={onFormOptionValueChange}
              onOptionDelete={onFormOptionDelete}
              onOptionOrderChange={onFormOptionOrderChange}
            />
            <div className='m-t-20 m-r-20 m-l-20'>
              <AddButton text='Add another' onClick={onFormOptionAppend} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

FieldForm.propTypes = {
  fieldSettingId: PropTypes.string,
  fieldAttributeId: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  restricted: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  requestError: PropTypes.exact({
    isFallback: PropTypes.bool.isRequired,
    validationErrors: PropTypes.object.isRequired
  }).isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  sidePanel: PropTypes.exact({
    context: PropTypes.string,
    isOpen: PropTypes.bool.isRequired
  }).isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  onFormOptionValueChange: PropTypes.func.isRequired,
  onFormOptionAppend: PropTypes.func.isRequired,
  onFormOptionDelete: PropTypes.func.isRequired,
  onFormOptionOrderChange: PropTypes.func.isRequired
};
