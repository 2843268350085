export const fieldTypeMap = {
  'singleLineText': 'Single-line text',
  'multiLineText': 'Multi-line text',
  'select': 'Dropdown select',
  'date': 'Date picker',
  'email': 'Email address'
};

export const fieldTypeOption = (key) => {
  return { label: fieldTypeMap[key], value: key }
};

export const fieldTypeOptions = Object.keys(fieldTypeMap).map(key => fieldTypeOption(key));
