import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/application/Table'
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
export default function PersonnelTable({actionsColumnVisible, isArchived, rows}) {
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
    return (
      <Table
        headers={
          <tr>
            { currentActor.isAllowedFeature('training_register') && <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'>Status</th>}
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Name</th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Role</th>
            { trainingRegisterResourceManagementContext.hasTeamViewableAccess && <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Team</th>}
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-w-auto'>Company</th>
            {actionsColumnVisible && (
              <th className={`tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-right ${isArchived ? 'fw-96' : 'fw-72'}`}>Actions</th>
            )}
          </tr>
        }
        rows={rows}
      />
    )
}

PersonnelTable.propTypes = {
  rows: PropTypes.array.isRequired,
  actionsColumnVisible: PropTypes.bool,
  isArchived: PropTypes.bool
};
