import React from 'react';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

const teamsArticleLink = "https://intercom.help/hands-hq/en/articles/9210497-teams-for-training-register-overview";

export default function TeamsAccessExplanationNotification() {
  return (
    <div className="tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 tw-mt-12 m-b-24">
      <div className='fh-20 m-r-12'>
        <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
      </div>
      <p className='m-0'>
        If you're looking to manage teams, this is done directly in the Personnel / Training section. {' '}
        <a href={teamsArticleLink}
          target="_blank"
          rel="noopener noreferrer"
          className="tw-text-cyan-600 hover:tw-text-cyan-800">
          Learn more about teams.
        </a>
      </p>
    </div>
  );
}
