import React from 'react';
import RowBar from 'components/application/RowBar';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

export default function SubcontractorsBar(props) {
  const {
    subcontractorsCount,
    subcontractorsSearch,
    onSearchInputChange,
    onSearchReset,
    onNewSubcontractor
  } = props

  return (
    <RowBar
      modifiers={['border-top-curved', 'border-bottom-none', 'flex-align-items-ie-fix']}
      content={subcontractorsCount > 0 && (
        <SearchField
          value={subcontractorsSearch}
          onChange={onSearchInputChange}
          onReset={onSearchReset}
          name='subcontractorsSearch'
          placeholder='Search your sub-contractors...'
          additionalClasses='search-field__lg'
        />
      )}
      actions={props.actionButtonsVisible && (
        <OutlinedButton size='sm' color='grey' onClick={onNewSubcontractor}>
          <AddIcon className='m-l--8' width={24} height={24}/>
          <span>Add new sub-contractor</span>
        </OutlinedButton>
      )}
    />
  )
}
