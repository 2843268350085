import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/application/Table';
import TeamsRow from 'components/teams/TeamsRow';

export default function TeamsTable({
  teams,
  onTeamSelection,
}) {

  const mappedCompanies = teams.includedCompanies.reduce((acc, company) => {
    acc[company.id] = company;
    return acc;
  }, {});

  const mappedManagers = teams.includedManagers.reduce((acc, manager) => {
    acc[manager.id] = manager;
    return acc;
  }, {});

  return (
    <Table
      headers={
        <tr>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-36'>Team</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-30'>Team managers</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-19'>Division</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-center w-14'>Members</th>
        </tr>
      }
      rows={teams.collection.map(team => {
        const managers = team.relationships.managers ? team.relationships.managers.data.map((manager) => mappedManagers[manager.id]) : [];
        return (
          <TeamsRow
            key={team.id}
            team={team}
            company={mappedCompanies[team.relationships.company.data.id]}
            onTeamRowClick={onTeamSelection}
            managers={managers}
          />
        )
      }
      )}
    />
  )
}

TeamsTable.propTypes = {
  teams: PropTypes.shape({
    collection: PropTypes.array.isRequired,
  }).isRequired,
  onTeamSelection: PropTypes.func.isRequired,
};
