import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tab from 'components/application/Tab';

export default function Tabs(props) {
  const {
    children,
    initialActiveTab,
    modifiers = [],
    navClassName,
    activeTabIsReset = false,
    onTabClick
  } = props;

  //if you give it just one child, calling .map on children will break the component
  const childrenArray = React.Children.toArray(children)

  const labels = childrenArray.map(child => child.props.label);
  const [activeTab, setActiveTab] = useState(initialActiveTab || labels[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (onTabClick) { onTabClick(tab); }
  };
  const resetActiveTab = () => { setActiveTab(labels[0]) };

  const modifierClassNames = modifiers.map(modifier => ` tabs--${modifier}`).join('');

  useEffect(() => {
    if (!labels.includes(activeTab) || activeTabIsReset) {
      resetActiveTab()
    }
  }, [JSON.stringify(labels), activeTabIsReset])

  const tabs = childrenArray.map((child) => {
    const { label, icon, disabled, tooltipText } = child.props;

    return (
      <Tab
        activeTab={activeTab}
        key={label}
        label={label}
        disabled={disabled}
        tooltipText={tooltipText}
        onTabClick={handleTabClick}
        icon={icon}
      />
    );
  });

  return (
    <div className={`tabs${modifiers.length !== 0 ? `${modifierClassNames}` : ''}`}>
      <ol className={`tabs__nav${navClassName ? ` ${navClassName}` : ''}`}>
        {tabs}
      </ol>
      <div className='tabs__content'>
        {childrenArray.find((child) => { return child.props.label === activeTab })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.any,
  initialActiveTab: PropTypes.string,
  modifiers: PropTypes.array,
  navClassName: PropTypes.string,
  activeTabIsReset: PropTypes.bool
};
