import React from 'react';
import PropTypes from 'prop-types';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';

export default function CoshhOptions({ coshhDocument, onDownloadClick, onDeleteClick }) {
  return (
    <>
      <Options>
        <OptionChoice>
          <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto' onClick={() => onDownloadClick({ coshhDocument: coshhDocument })}>Download COSHH</a>
        </OptionChoice>
        <OptionChoice warning={true}>
          <a className='tw-text-m tw-text-red-600 hover:tw-text-red-600 tw-font-medium tw-tracking-auto' onClick={() => onDeleteClick({ coshhDocument: coshhDocument })}>Delete COSHH</a>
        </OptionChoice>
      </Options>
    </>
  )
}

CoshhOptions.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}
