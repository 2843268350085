import React from 'react';

import Background from '-!svg-react-loader!components/brandings/svgs/email/branding-email-bg.svg';
import Content from '-!svg-react-loader!components/brandings/svgs/email/branding-email-content.svg';
import Button from '-!svg-react-loader!components/brandings/svgs/email/branding-email-buttonbg.svg';
import ButtonText from '-!svg-react-loader!components/brandings/svgs/email/branding-email-buttontext.svg';
import Links from '-!svg-react-loader!components/brandings/svgs/email/branding-email-links.svg';
import Logo from '-!svg-react-loader!components/brandings/svgs/email/branding-email-logo.svg';
import Powered from '-!svg-react-loader!components/brandings/svgs/email/branding-email-powered.svg';

export default function EmailPreview({ primaryBgColour, buttonBgColour, buttonTextColour, textLinkColour, handshqLabel, logoSrc}){
  const imageContainerStyles = {
    left: "116px",
    height: "42px",
    top: "11px",
    bottom: "0px",
    width: "84px",
    position: "relative"
  }

  const hasWhiteBackground = primaryBgColour === '#ffffff'

  return (
    <div className='svg-preview__document tw-bg-white' style={{ width: '315px', height: '408px' }}>
      <Content />
      <Background fill={primaryBgColour} stroke={hasWhiteBackground ? '#E4E5EB' : ''} stroke-dasharray={hasWhiteBackground ? '378 315' : ''}/>
      <Button fill={buttonBgColour} />
      <ButtonText fill={buttonTextColour} />
      <Logo fill={primaryBgColour}/>
      <Links fill={textLinkColour} />
      { handshqLabel && (<Powered />) }
      { logoSrc && (
        <div style={imageContainerStyles}>
          <img className='image--bound-scaling' src={logoSrc} alt="Logo shown when emailing documents" />
        </div>
      )
      }
    </div>
  )
}
