const initialMember = {
  id: null,
  imageUrl: '',
  attachableType: '',
  attachableId: null,
  attachedUrl: '',
  attachedProcessed: false,
  hashid: '',
  displaySizeValue: 1,
  caption: '',
  position: null,
  recordType: '',
  file: ''
};

const getMember = ({ attachment, coverImages, attachments = [] }) => {
  const coverImage = coverImages.find(obj => obj.id === (attachment.relationships.coverImage.data || {}).id);
  const originalAttachment = attachments.find(obj => obj.id === (attachment.relationships.original.data || {}).id);
  let originalFields = {};
  if (originalAttachment) {
    const originalCoverImage = coverImages.find(obj => obj.id === (originalAttachment.relationships.coverImage.data || {}).id);
    originalFields = {
      originalImageUrl: (originalCoverImage || originalAttachment).attributes.attachedUrl,
      originalImageThumbUrl: (originalCoverImage || originalAttachment).attributes.thumbUrl
    }
  }

  return {
    id: attachment.id,
    imageUrl: (coverImage || attachment).attributes.attachedUrl,
    imageThumbUrl: (coverImage || attachment).attributes.thumbUrl,
    ...originalFields,
    ...attachment.attributes
  }
};

const getCollection = (data) => {
  const newAttachments = data.data;
  const coverImages = data.included.filter(obj => obj.type === 'coverImage');
  const attachments = data.included.filter(obj => obj.type === 'attachment');

  return newAttachments.map(attachment => getMember({attachment, coverImages, attachments}))
};

const groupAttachmentsByAttachableId = ({ attachments }) => {
  return attachments.reduce((acc, member) => {
    if (!acc.hasOwnProperty(member.relationships.attachable.data.id)) {
      acc[member.relationships.attachable.data.id] = [];
    }
    acc[member.relationships.attachable.data.id].push(member)
    return acc
  }, {})
};

export {
  initialMember,
  getMember,
  getCollection,
  groupAttachmentsByAttachableId
}
