import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import Options from "components/application/Options";
import OptionChoice from "components/application/OptionChoice";
import CheckboxField from "components/application/CheckboxField";
import { DropdownIndicator, Option } from "components/application/CollectionSelect";

import ChevronRightIcon from "-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right-md.svg";
import FunnelIcon from "-!svg-react-loader?name=FunnelIcon!icons/funnel.svg";

const dotClassNameMap = { green: "tw-bg-green-400", amber: "tw-bg-amber-300", grey: "tw-bg-grey-500" };

export default function ReportHeader(props) {
  const {
    title,
    totalCount = 0,
    subTitle,
    filters = [],
    minChecked = 0,
    dropdownOptions = [],
    dropdownValue,
    onTotalCountClick,
    onSelectionChange,
    onFilterChange
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const isMinChecked = filters.filter((filter) => filter.value).length === minChecked;

  return (
    <div className="tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-flex tw-justify-between tw-pt-6 tw-pr-6 tw-pl-6">
      <div>
        <h2 className="tw-text-m tw-font-medium tw-tracking-auto tw-m-0">{title}</h2>
        {totalCount > 0 && (
          <div className="tw-inline-flex tw-items-center tw-cursor-pointer" onClick={onTotalCountClick}>
            <h3 className="tw-text-3xl tw-tracking-tighter tw-font-semibold tw-m-0">{totalCount.toLocaleString()}</h3>
            <ChevronRightIcon className="[&_path]:tw-stroke-transparent group-hover/card:[&_path]:tw-stroke-grey-700" width={24} height={40} />
          </div>
        )}
        {subTitle && <h3 className={`tw-text-s tw-font-medium tw-tracking-wide tw-text-grey-500 tw-m-0 ${totalCount > 0 ? "" : "tw-mt-1"}`}>{subTitle}</h3>}
      </div>
      <div id="report-header-filters-wrapper" className={`tw-h-8 ${isOpen ? "tw-flex" : "tw-hidden"} group-hover/card:tw-flex`}>
        {dropdownOptions.length > 0 && (
          <div className="tw-w-32 tw-mr-2 collection-select collection-select--inline-form">
            <Select
              className="collection-select__select-container"
              classNamePrefix="collection-select"
              options={dropdownOptions}
              value={dropdownValue}
              components={{ DropdownIndicator, Option }}
              isSearchable={false}
              controlShouldRenderValue={true}
              onChange={onSelectionChange}
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
            />
          </div>
        )}
        {filters.length > 0 && (
          <Options
            listStyle="tw-min-w-[256px] [&_li]:tw-border-none [&_li]:tw-h-11 [&_li]:tw-cursor-default"
            btnToggle={() => (
              <div className="tw-flex tw-justify-center tw-items-center tw-border-1 tw-border-solid tw-rounded-lg tw-border-grey-300 tw-bg-white hover:tw-border-grey-400 hover:tw-bg-grey-050 tw-w-8 tw-h-8">
                <FunnelIcon width={12} height={12} />
              </div>
            )}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
          >
            {filters.map((filter) => {
              const isFilterDisabled = isMinChecked && filter.value;

              return (
                <OptionChoice
                  hoverClassName="hover:tw-bg-transparent"
                  key={filter.name}
                >
                  <div className="tw-flex tw-items-center tw-h-11 tw-pl-3 tw-pr-5">
                    <CheckboxField
                      name={filter.name}
                      value={filter.name}
                      checked={filter.value}
                      disabled={isFilterDisabled}
                      label={
                        <div className="tw-flex tw-justify-between tw-items-center tw-h-[22px] tw-pl-3">
                          <div className="tw-flex tw-items-center tw-text-s tw-font-medium tw-tracking-wide tw-grey-900">
                            {filter.dot && <span className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${dotClassNameMap[filter.dot]} tw-mr-3`} />}
                            {filter.label}
                          </div>
                          <div className="tw-text-s tw-font-normal tw-tracking-wide tw-ml-1">{filter.count}</div>
                        </div>
                      }
                      labelTextProps={{ className: "tw-w-full tw-ml-0" }}
                      onChange={isFilterDisabled ? () => { } : () => onFilterChange(filter.name)}
                    />
                  </div>
                </OptionChoice>
              )
            })}
          </Options>
        )}
      </div>
    </div>
  )
}

ReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
  subTitle: PropTypes.string,
  filters: PropTypes.array,
  minChecked: PropTypes.number,
  dropdownOptions: PropTypes.array,
  dropdownValue: PropTypes.object,
  onTotalCountClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onFilterChange: PropTypes.func
};
