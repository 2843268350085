import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumb, { propsShape as breadCrumbPropsShape } from 'components/application/BreadCrumb';
import ChevronRightIcon from '-!svg-react-loader?height=20px&width=20px&name=ChevronRightIcon!icons/chevron-right.svg';
import Tooltip from 'components/application/Tooltip';

export default function BreadCrumbList({children}) {

  return (
    <div className='flex flex--vertically-centered fh-50'>
      <BreadCrumb key={`breadcrumb--start`} {...children[0]} />
      { children.length > 2 &&
        <>
          <ChevronRightIcon className='[&_polygon]:tw-fill-grey-700' />
            <Tooltip
              className='p-0'
              placement='bottom'
              hideArrow={true}
              trigger='click'
              tooltip={
                children.slice(1, -1).map((childProps) => {
                  const isTooltippable = childProps.length > 19;
                  return (
                    <span onClick={childProps.onClick} className ='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 fs-14 px-12 py-12 trunc-standard mw-185 tw-font-medium'>
                      <span className='tooltip-parent'>
                        {isTooltippable && <Tooltip hideArrow={true} placement='right' delayShow={333} trigger='hover' tooltip={childProps.children} />}
                        {childProps.children}
                      </span>
                    </span>
                  )
                })
              }
            >
            <BreadCrumb
              key={`breadcrumb--middle`}
              onClick={undefined}
              children={'...'}
            />
          </Tooltip>
        </>
      }
      { children.length > 1 &&
        <>
          <ChevronRightIcon className='[&_polygon]:tw-fill-grey-700' />
          <BreadCrumb
            key={`breadcrumb--end`}
            children={children[children.length -1].children}
            onClick={undefined}
          />
        </>
      }
    </div>
  )
}

BreadCrumbList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape(breadCrumbPropsShape))
}
