import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CircleQuestion from 'components/application/CircleQuestion';
import Tooltip from 'components/application/Tooltip';

export default function CheckboxField(props) {
  const {
    className,
    value,
    name,
    checked,
    onChange,
    disabled,
    label,
    labelProps,
    labelTextProps,
    tooltip,
    tooltipType,
    tooltipAlignment,
    tooltipDelay,
    height,
    customPadding,
    customStyling,
    additionalContent,
    renderNestedContent,
    isInputLabelSibling
  } = props;

  const tooltipCheck = (currentPosition) => {
    switch (currentPosition) {
      case 'button':
        return (tooltip && tooltipType === 'button') &&
          <span className={classNames('hhq-checkbox__tooltip', tooltipAlignment && `hhq-checkbox__tooltip--${tooltipAlignment}`)}>
            <Tooltip placement='top' trigger='hover' tooltip={tooltip}>
              <CircleQuestion />
            </Tooltip>
          </span>
      case 'hover':
        return (tooltip && tooltipType === 'hover') &&
          <Tooltip placement='top' trigger='hover' delayShow={tooltipDelay} tooltip={tooltip} />
      default:
        null
    }
  };

  const inputField = (
    <>
      <input
        type='checkbox'
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        className='hhq-checkbox__input tw-peer/input'
        disabled={disabled}
      />
      <span className={classNames(
        'hhq-checkbox__toggle peer-checked/input:peer-disabled/input:tw-opacity-30 tw-text-white tw-font-bold tw-bg-white tw-border-grey-300 tw-border-2 peer-checked/input:tw-bg-blue-400 peer-checked/input:tw-border-blue-400 peer-checked/input:hover:tw-bg-blue-500 peer-checked/input:hover:tw-border-blue-500',
        !disabled && 'tw-cursor-pointer hover:tw-bg-grey-025 hover:tw-border-blue-300',
        height && `hhq-checkbox__toggle--${height}`,
        customStyling,
        tooltipType == 'hover' && tooltip && 'tooltip-parent'
      )}>
        {tooltipCheck('hover')}
      </span>
    </>
  );

  return (
    <>
      <span className={classNames('hhq-checkbox__container', height && `hhq-checkbox__container--${height}`, customPadding, className)}>
        {isInputLabelSibling && inputField}
        <label className={classNames('tw-font-normal hhq-checkbox__label', labelProps.className)}>
          {!isInputLabelSibling && inputField}
          {label && <span className={classNames('hhq-checkbox__label-text', labelTextProps.className)}>{label}</span>}
        </label>
        {tooltipCheck('button')}
        {!!additionalContent && (
          <span className='hhq-checkbox__additional-content'>
            {additionalContent}
          </span>
        )}
      </span>
      {renderNestedContent && (
        <div className='hhq-checkbox__nested-content'>
          {renderNestedContent()}
        </div>
      )}
    </>
  )
}

CheckboxField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  labelTextProps: PropTypes.object,
  tooltip: PropTypes.string,
  tooltipType: PropTypes.string,
  tooltipAlignment: PropTypes.string,
  tooltipDelay: PropTypes.number,
  height: PropTypes.string,
  customPadding: PropTypes.string,
  customStyling: PropTypes.string,
  additionalContent: PropTypes.node,
  renderNestedContent: PropTypes.func,
  isInputLabelSibling: PropTypes.bool
};

CheckboxField.defaultProps = {
  labelProps: { className: '' },
  labelTextProps: { className: '' },
  tooltipType: 'button',
  tooltipDelay: 0,
  isInputLabelSibling: false
}
