import React from 'react';
import PropTypes from 'prop-types';
import RequestErrorMessage from 'components/application/RequestErrorMessage';

export default function ErrorMessage({
  isFallback,
  validationErrors,
  modifiers,
  containerClassnames,
  withSeparateNestedErrors
}) {
  return (isFallback || Object.keys(validationErrors).length > 0) && (
    <div className={containerClassnames}>
      <div className={`tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3${modifiers ? ` ${modifiers}` : ''}`}>
        <RequestErrorMessage
          isFallback={isFallback}
          validationErrors={validationErrors}
          withSeparateNestedErrors={withSeparateNestedErrors}
        />
      </div>
    </div>
  )
}

ErrorMessage.propTypes = {
  isFallback: PropTypes.bool.isRequired
};

ErrorMessage.defaultProps = {
  isFallback: false,
  modifiers: '',
  containerClassnames: 'form-container',
  withSeparateNestedErrors: false
}
