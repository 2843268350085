import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import RowBar from 'components/application/RowBar';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import ToggleableSelect from 'components/selectors/ToggleableSelect';
import GreyPlus from '-!svg-react-loader?name=GreyPlus!icons/add-grey.svg';
import AddButton from 'components/buttons/AddButton';

import { isCourseAssociated } from 'components/helpers/resources/courses';
import { sendAnalytics } from 'components/helpers/analytics';

export default function AdditionalTrainingBar(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const { roles, training, userCourses, actionButtonsVisible, personnelId } = props;

  const handleChange = (selected) => {
    props.onCourseSelectChange({ selected })
  };

  const trainingTitle = props.roleHasRequiredCourses ? 'Additional training' : 'Training'

  const content = props.additionalCoursesCount > 0 ? (
    <React.Fragment>
      <span className='m-r-8 tw-text-l tw-font-semibold'>{trainingTitle}</span>
      <Tooltip placement='top' trigger='hover' tooltip='Training not specific to the role'>
        <CircleQuestion />
      </Tooltip>
    </React.Fragment>
  ) : (
      <div className='flex flex--vertically-centered m-l--4'>
        <GreyPlus className='[&_rect]:tw-fill-grey-300' />
        <span className='m-l-12'>{trainingTitle} can be added here</span>
      </div>
    );

  const disabledBtn = (
    actionButtonsVisible &&
      <AddButton
        text='Add training course'
        disabled={true}
        tooltipText={!props.isLoading && 'Courses need to be added in the course register first'}
      />
  );

  const addCourseToggle = (
    actionButtonsVisible &&
      <ToggleableSelect
        name='course'
        buttonText='Add training course'
        url='/account_courses'
        placeholder='Search courses'
        onChange={handleChange}
        onError={breadBoard.addInedibleToast}
        additionalNoOptionsMessage='New courses must be added from the course register.'
        isOptionDisabled={(course) => isCourseAssociated(course, { training, roles, userCourses })}
        onToggleablePrimaryClick={() => sendAnalytics('Add training course clicked', { currentUser: currentActor.user, personnelId: personnelId })}
      />
  );

  return (
    <RowBar
      modifiers={!props.isLoading && props.additionalCoursesCount > 0 ? ['large', 'border-bottom-none'] : ['large']}
      content={!props.isLoading && content}
      actions={props.coursesMeta.info.totalCount > 0 && !props.isLoading ? addCourseToggle : disabledBtn}
    />
  )
}

AdditionalTrainingBar.propTypes = {
  training: PropTypes.array.isRequired,
  userCourses: PropTypes.array.isRequired,
  courses: PropTypes.object.isRequired,
  onCourseSelectChange: PropTypes.func.isRequired,
  onCourseSelectError: PropTypes.func.isRequired,
  roleHasRequiredCourses: PropTypes.bool.isRequired
}

AdditionalTrainingBar.defaultProps = {
  training: [],
  userCourses: [],
  courses: {},
  roles: [],
  isLoading: false,
  roleHasRequiredCourses: true,
  actionButtonsVisible: true
}
