import React, { useState } from 'react';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import TrainingFormFields from 'components/training/TrainingFormFields';
import FormFooter from 'components/sidepanels/FormFooter';

export default function TrainingSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const courseAttributes = (props.currentTraining.course && props.currentTraining.course.attributes) || {};

  const header = (
    <div className='popup__title popup__title--tertiary-split'>
      <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>{`${props.sidePanelContext === 'new' ? 'Record new' : 'Edit'} training`}</h1>
      <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{courseAttributes.name}</h2>
    </div>
  );

  const body = (
    <div className='popup__body-form'>
      <ErrorMessage validationErrors={props.requestError.validationErrors} isFallback={props.requestError.isFallback} />
      <div className='form-container'>
        <TrainingFormFields
          currentTraining={props.currentTraining}
          requestError={props.requestError}
          removeErrorStyling={props.removeErrorStyling}
          onInputChange={props.onInputChange}
          onError={props.onError}
          includedAttachments={props.includedAttachments}
          includedCoverImages={props.includedCoverImages}
          sidePanelIsOpen={props.sidePanelIsOpen}
          submitDisabled={props.submitDisabled}
          personnelId={props.personnelId}
          onTrainingDateChange={props.onTrainingDateChange}
          onTrainingCalendarClose={props.onTrainingCalendarClose}
        />
      </div>
    </div>
  );

  const footer = (
    <FormFooter
      text={props.sidePanelContext === 'new' ? 'Record training' : 'Save changes'}
      onCancel={props.onCancelTraining}
      onSubmit={props.sidePanelContext === 'new' ? props.createTraining : props.updateTraining}
      submitButtonDisabled={props.submitDisabled}
    />
  );

  const onBackdropClick = () => !(['new', 'edit'].includes(props.sidePanelContext)) && props.closeSidePanel();

  return (
    <SidePanel
      color={props.sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={props.sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={false}
      onBackdropClick={onBackdropClick}
      closeCallback={props.closeSidePanel}
      headerContent={header}
      bodyContent={body}
      footerContent={footer}
      contentContext={props.sidePanelContext}
      submitDisabled={props.submitDisabled}
    />
  )
}

TrainingSidePanel.defaultProps = {
  sidePanelContext: 'new'
}
