import React, { useState } from "react";
import _ from "lodash";

import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "react-query-settings/queryClient";

import { trainingReportTypes } from "components/trainingRegisterReports/helpers";
import { asyncScrollToTop } from "components/helpers/scrolling";

import RadioField from "components/application/RadioField";

import ExpiringTrainingReport from "components/trainingRegisterReports/reports/ExpiringTrainingReport";
import CompletedTrainingReport from "components/trainingRegisterReports/reports/CompletedTrainingReport";
import CurrentTrainingStatusReport from "components/trainingRegisterReports/reports/CurrentTrainingStatusReport";

export const trainingReportFields = [
  { name: "expiring_training", label: "Expiring training" },
  { name: "completed_training", label: "Completed training" },
  { name: "current_training_status", label: "Current training status" }
];

const trainingReportComponents = {
  expiringTraining: <ExpiringTrainingReport />,
  completedTraining: <CompletedTrainingReport />,
  currentTrainingStatus: <CurrentTrainingStatusReport />
};

export default function TrainingRegisterReportsTabWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <TrainingRegisterReportsTab />
    </QueryClientProvider>
  )
}

function TrainingRegisterReportsTab() {
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsType = trainingReportTypes.includes(searchParams.get("type")) ? searchParams.get("type") : null;

  const [selectedReport, setSelectedReport] = useState(searchParamsType || "expiringTraining");

  const handleReportChange = (event) => {
    const report = _.camelCase(event.target.name);

    asyncScrollToTop().then(() => setSelectedReport(report))
  };

  return (
    <>
      <div className="col-sm-3 tw-pl-0">
        <div className="tw-p-6 tw-border-solid tw-border-1 tw-rounded-md tw-border-grey-100 tw-bg-white">
          <h2 className="tw-m-0 tw-mb-6 tw-text-l tw-font-semibold tw-tracking-auto">Reports</h2>
          {trainingReportFields.map((trainingReportField, index) => (
            <RadioField
              key={trainingReportField.name}
              className={`tw-m-0${index === 0 ? "" : " tw-mt-5"}`}
              name={trainingReportField.name}
              label={trainingReportField.label}
              value={true}
              checked={selectedReport === _.camelCase(trainingReportField.name)}
              onChange={handleReportChange}
            />
          ))}
        </div>
      </div>
      <div className="col-sm-9 tw-pr-0 tw-mb-20">
        {trainingReportComponents[selectedReport]}
      </div>
    </>
  )
}
