import React from 'react';
import PropTypes from 'prop-types';
import CheckboxCollection from 'components/application/CheckboxCollection';
import InfoBlock from 'components/application/InfoBlock';

export default function TrainingStatusFilter(props) {
  const { isDisabled, filters, statusCounts, onChange } = props;

  const additionalContent = (isFilterChecked, content) => {
    return isFilterChecked ? content : undefined
  }

  const checkboxes = [
    {
      label: 'Expired / Missing',
      name: 'highTrainingFilter',
      checked: filters.highTrainingFilter,
      disabled: isDisabled,
      additionalContent: additionalContent(filters.highTrainingFilter, <InfoBlock isDisabled={isDisabled} status={'high'} value={statusCounts.statusHighCount}/>)
    },
    {
      label: 'Expiring soon',
      name: 'mediumTrainingFilter',
      checked: filters.mediumTrainingFilter,
      disabled: isDisabled,
      additionalContent: additionalContent(filters.mediumTrainingFilter, <InfoBlock isDisabled={isDisabled} status={'medium'} value={statusCounts.statusMediumCount}/>)
    },
    {
      label: 'Up to date',
      name: 'lowTrainingFilter',
      checked: filters.lowTrainingFilter,
      disabled: isDisabled,
      additionalContent: additionalContent(filters.lowTrainingFilter, <InfoBlock isDisabled={isDisabled} status={'low'} value={statusCounts.statusLowCount}/>)
    },
    {
      label: 'No required training',
      name: 'greyTrainingFilter',
      checked: filters.greyTrainingFilter,
      disabled: isDisabled,
      additionalContent: additionalContent(filters.greyTrainingFilter, <InfoBlock isDisabled={isDisabled} status={'grey'} value={statusCounts.statusGreyCount}/>)
    }
  ]

  return (
    <CheckboxCollection
      checkBoxes={checkboxes}
      labelTextProps={{ className: 'tw-font-medium' }}
      onChange={onChange}
      mustSelect={true}
    />
  )
}

TrainingStatusFilter.propTypes = {
  isDisabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  statusCounts: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

TrainingStatusFilter.defaultProps = {
  isDisabled: false,
  statusCounts: { statusGreyCount: 0, statusLowCount: 0, statusMediumCount: 0, statusHighCount: 0 }
};
