import React from "react";
import PropTypes from "prop-types";

const approvalStatus = {
  approved: { pillClassName: "tw-text-green-800 tw-bg-green-025", text: "Approved" },
  approvedWithConditions: { pillClassName: "tw-text-green-800 tw-bg-green-025", text: "Approved" },
  pending: { pillClassName: "tw-text-amber-800 tw-bg-amber-025", text: "Pending" },
  rejected: { pillClassName: "tw-text-red-800 tw-bg-red-025", text: "Rejected" },
  notSubmitted: { pillClassName: "tw-text-cyan-800 tw-bg-cyan-025", text: "Not submitted" }
};

export default function ApprovalStatusIndicator({ status }) {
  const displayableStatus = approvalStatus[status];

  return displayableStatus ? (
    <div className={`tw-border-0 tw-rounded-lg tw-px-2 tw-py-1 tw-text-s tw-font-medium tw-tracking-wide ${displayableStatus.pillClassName}`}>
      {displayableStatus.text}
    </div>
  ) : null
}

ApprovalStatusIndicator.propTypes = {
  status: PropTypes.oneOf(Object.keys(approvalStatus))
};
