import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/application/CollectionSelect';

export default function SelectCompanyRoles(props) {
  const companyRoleOption = companyRole => ({ value: companyRole.id, label: companyRole.attributes.position });

  const currentPersonnelCompanyRoles = props.assignableRolesCollection.filter(role => props.currentPersonnel.companyRoleIds.includes(role.id));
  const companyRoleValues = currentPersonnelCompanyRoles.map(role => (role ? companyRoleOption(role) : ''));

  const companyRoleOptions = function () {
    return props.assignableRolesCollection ? (
      props.assignableRolesCollection.map(role => companyRoleOption(role)).sort((a, b) => (a.label > b.label ? 1 : -1))
    ) : (
      []
    )
  };

  const generateNewCompanyRole = (inputValue) => {
    const companyRoleUuid = uuidv4();
    props.onNewCompanyRoleAdd({ id: companyRoleUuid, position: inputValue })
    const newRole = { id: companyRoleUuid, type: 'assignableCompanyRole', attributes: { position: inputValue } }
    props.onAssignableRoleAdd(newRole)
    return companyRoleUuid
  }

  const handleCreateOption = (inputValue) => {
    const companyRoleUuid = generateNewCompanyRole(inputValue)
    const newCompanyRoleValues = companyRoleValues.concat({ value: companyRoleUuid, label: inputValue })
    props.onChange(newCompanyRoleValues)
  };

  return (
    <CollectionSelect
      name='companyRoleIds'
      value={companyRoleValues}
      isMulti={true}
      isRequired={false}
      isCreatable={props.isCreatable}
      modifiers={['expandable', props.fieldError ? ' field__invalid' : '']}
      options={companyRoleOptions()}
      placeholder='Search roles...'
      onChange={props.onChange}
      externalProps={props.isCreatable ? {
        onCreateOption: handleCreateOption,
        controlShouldRenderValue: false,
        styles: {
          valueContainer: (provided, _state) => ({
            ...provided,
            // There are paddings added to the usual valueContainer when it is multi select. This stops them taking effect on the placeholder in this valueContainer.
            paddingLeft: "10px !important",
          }),
        }
      } : { additionalNoOptionsMessage: 'New roles can be added by Admins and Roles & courses editors.' }}
    />
  )
}

SelectCompanyRoles.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}
