import React from 'react';
import PropTypes from 'prop-types';
import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';
import AddToolboxIcon from '-!svg-react-loader?name=AddToolboxIcon!icons/addtoolbox-xl.svg';
import FilledButton from 'components/application/buttons/FilledButton';

export default function ResourceBlankNotice(props) {

  const {
    resource,
    onAdd,
    totalCount,
    addMessage,
    addButton,
    icon,
    displayReadOnlyContents
  } = props

  return totalCount > 0 ? (
    <section className='section section--notice br-tl-0 br-tr-0 tw-border-grey-100 tw-bg-white'>
      <NoResultsIcon width={64} height={64} className='[&_path]:tw-fill-grey-300'/>
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>{`No ${resource}s found`}</h2>
    </section>
    ) : (
    <section className='section section--notice tw-border-grey-100 tw-bg-white' >
      {icon ? icon : <AddToolboxIcon width={64} height={64} className='[&_path]:tw-fill-grey-500'/>}
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>{displayReadOnlyContents ? `No ${resource}s yet` : `Add ${resource}s`}</h2>
      <p className='section__content section__content--md'>{addMessage}</p>
      {!displayReadOnlyContents && addButton && <FilledButton color='mint' onClick={onAdd}>{`Add new ${resource}`}</FilledButton>}
    </section>
  )
}

ResourceBlankNotice.propTypes = {
  totalCount: PropTypes.number.isRequired,
  addMessage: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  displayReadOnlyContents: PropTypes.bool
};

ResourceBlankNotice.defaultProps = {
  addButton: true,
  displayReadOnlyContents: false
}
