// reducer start
import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
import { populateResources, assignRelationship } from 'components/helpers/storeHelpers';

enableAllPlugins()

export default function simpleCollectionReducer(state, action) {
  return (
    produce(state, draftState => {
      switch (action.type) {
        case 'RESOURCES_FETCHED':
          if (Array.isArray(action.payload)) {
            action.payload.forEach((payload) => loadFromPayload(payload, draftState))
          } else {
            loadFromPayload(action.payload, draftState)
          }
          break;
      }
    })
  )
}

// note all functions are mutating to be used with immer proxy

export function loadFromPayload(payload, draftState) {
  populateResources(draftState, payload.resources)
  if (payload.relationship) { assignRelationship(draftState, payload.resources, payload.relationship)}
}
