var React = require('react');

function ChevronRightIcon (props) {
    return React.createElement("svg",props,React.createElement("polygon",{"fill":"#2F3641","fillRule":"evenodd","points":"8.293 16 12.293 12 8.293 8 10 6.293 15.707 12 10 17.707"}));
}

ChevronRightIcon.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = ChevronRightIcon;

ChevronRightIcon.default = ChevronRightIcon;
