import React, { useMemo, Fragment } from 'react'
import { useCurrentActor } from 'components/contexts/CurrentActor';
import DatePickerField from 'components/application/DatePickerField';
import AttachmentsField from 'components/attachments/AttachmentsField';
import moment from 'moment';
import TextArea from 'components/application/TextArea';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

import { sendAnalytics } from 'components/helpers/analytics';

const isExpiryDateAfterRenewalDate = ({startDate, expiryDate, renewalFrequency, renewalInterval}) => {
  if (!startDate || !expiryDate) return false;

  startDate = moment(startDate);
  expiryDate = moment(expiryDate);
  const renewalDate = startDate.add(renewalFrequency, renewalInterval);

  return expiryDate.isAfter(renewalDate);
}

export default function TrainingFormFields(props) {
  const {
    currentTraining,
    requestError,
    onInputChange,
    onError,
    includedAttachments,
    includedCoverImages,
    sidePanelIsOpen,
    personnelId
  } = props;

  const currentActor = useCurrentActor();

  const courseAttributes = (currentTraining.course && currentTraining.course.attributes) || {};
  const courseInterval = `${courseAttributes.renewalInterval}`;
  const courseIntervalS = `${courseAttributes.renewalFrequency > 1 ? 's' : ''}`;

  const courseExpires = currentTraining.course && currentTraining.course.attributes.expires;
  const currentTrainingAttachments = useMemo(() => {
    return includedAttachments.filter(a => currentTraining.attachmentIds.includes(Number(a.id)))
  }, [includedAttachments, currentTraining.attachmentIds])

  return (
    <Fragment>
      {courseExpires &&
      <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-b-24'>
        <div className='fh-20 m-r-12'>
          <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
        </div>
        <p className='m-0'>Fill in <span className='tw-font-semibold'>either date</span> and the other will be automatically filled in based off a renewal frequency of <span className='tw-font-semibold'>{`${courseAttributes.renewalFrequency} ${courseInterval}${courseIntervalS}`}.</span></p>
      </div>
      }
      <div className='flex flex--justify-content__space-between'>
        <div className='m-r-12'>
          <DatePickerField
            label='Valid from date'
            name='startDate'
            value={currentTraining.startDate}
            fieldError={props.requestError.validationErrors.startDate}
            isRequired={true}
            removeErrorStyling={props.removeErrorStyling}
            submitDisabled={props.submitDisabled}
            latestDate={moment().toDate()}
            onChange={props.onTrainingDateChange}
            onCalendarClose={props.onTrainingCalendarClose}
          />
        </div>
        <div>
          <DatePickerField
            label='Expiry date'
            name='expiryDate'
            placement='bottom-end'
            value={currentTraining.expiryDate}
            fieldError={props.requestError.validationErrors.expiryDate}
            isRequired={courseExpires}
            removeErrorStyling={props.removeErrorStyling}
            submitDisabled={props.submitDisabled}
            isDisabled={!courseExpires}
            disabledPlaceholderText='Does not expire'
            earliestDate={currentTraining.startDate}
            onChange={props.onTrainingDateChange}
            onCalendarClose={props.onTrainingCalendarClose}
          />
        </div>
      </div>

      {
        isExpiryDateAfterRenewalDate({
          startDate: currentTraining.startDate,
          expiryDate: currentTraining.expiryDate,
          renewalFrequency: courseAttributes.renewalFrequency,
          renewalInterval: courseAttributes.renewalInterval
        }) &&
        <div className='tw-mb-6 tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 tw-flex'>
          <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
            <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
          </div>
          Selected dates exceed the recommended renewal frequency set for this course.
        </div>
      }

      <TextArea label='Notes' name='notes' value={currentTraining.notes} fieldError={requestError.validationErrors.notes} onChange={onInputChange} />
      <AttachmentsField
        label='Upload evidence'
        tooltip='E.g. certificates or cards'
        name='attachmentIds'
        value={currentTraining.attachmentIds}
        isVisible={sidePanelIsOpen}
        seedAttachments={{ data: currentTrainingAttachments, included: includedCoverImages }}
        onChange={onInputChange}
        onError={onError}
        maxUploads={25}
        onUploadSuccess={() => sendAnalytics('Training evidence uploaded', { currentUser: currentActor.user, personnelId: personnelId })}
      />
    </Fragment>
  )
}
