import React from 'react';
import LevelOfRiskIcon from 'components/projectCoshh/LevelOfRiskIcon';

function getCoshhDocumentDetails({ coshhDocument, secondaryFieldLabel = 'Other (specify)', secondaryFieldDefaultValue = 'Other' }) {
  const details = [
    { label: 'Quantity kept on site', text: coshhDocument.quantityOnSite },
    { label: 'Quantity expected to be used', text: coshhDocument.quantityToBeUsed },
    { label: 'Quantity used in one day', text: coshhDocument.substanceQuantityPerDay },
    { label: 'Method of use', text: (coshhDocument.uses  === secondaryFieldLabel) ? (coshhDocument.methodOfUseOther || secondaryFieldDefaultValue) : coshhDocument.uses },
    { label: 'Number of people involved in activity', text: coshhDocument.staffNumber },
    { label: 'Person(s) at risk', text: formatPersonsAtRisk(coshhDocument, secondaryFieldLabel, secondaryFieldDefaultValue) },
    { label: 'Duration of exposure', text: (coshhDocument.workerExposureTime  === secondaryFieldLabel) ? (coshhDocument.workerExposureTimeOther || secondaryFieldDefaultValue) : coshhDocument.workerExposureTime },
    { label: 'Frequency of exposure', text: (coshhDocument.exposureFrequency  === secondaryFieldLabel) ? (coshhDocument.exposureFrequencyOther || secondaryFieldDefaultValue) : coshhDocument.exposureFrequency },
    { label: 'Location of use', text: coshhDocument.location },
    { label: 'Level of risk', text: (coshhDocument.levelOfRisk ? <LevelOfRiskIcon option={coshhDocument.levelOfRisk}/> : '') },
    { label: 'Control measures and additional information', text: coshhDocument.additionalInformation }
  ];

  return details;
}

function formatPersonsAtRisk(coshhDocument, secondaryFieldLabel, secondaryFieldDefaultValue) {
  const personsAtRisk = (coshhDocument.personsAtRisk && coshhDocument.personsAtRisk.map((p) => p.value)) || [];
  const filteredPersonsAtRisk = personsAtRisk.filter((value) => value !== secondaryFieldLabel);
  if (personsAtRisk.includes(secondaryFieldLabel)) { filteredPersonsAtRisk.push(coshhDocument.personsAtRiskOther || secondaryFieldDefaultValue) }
  return filteredPersonsAtRisk.join(', ')
}

export {
  getCoshhDocumentDetails
}
