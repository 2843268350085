import React, { useEffect } from 'react';
import ProjectActivityRow from 'components/projectActivity/ProjectActivityRow';
import ProjectMessageRow from 'components/projectActivity/ProjectMessageRow';
import ActivityTableHeaders from 'components/projectActivity/ActivityTableHeaders';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

export default function ProjectActivityTable(props) {
  const [ref, inView] = useInView();

  const {
    isActive,
    isVersionPdfsEnabled,
    loadMore,
    hasMore,
    downloadDisabled,
    projectHistories,
    messageInfo,
    addInedibleToast,
    projectId,
    currentUser
  } = props

  useEffect(() => {
    isActive && hasMore && inView && loadMore()
  }, [isActive, hasMore, inView])

  return (
    <React.Fragment>
      <table className='table handshq__table handshq__table--spaced handshq__table--middled tw-border-grey-100 tw-bg-white'>
        <thead className='handshq__thead'>
          <ActivityTableHeaders showVersionNumberColumn={isVersionPdfsEnabled} />
        </thead>
        <tbody className={'handshq__tbody'}>
          {projectHistories && (
            projectHistories.map((history) =>
              history.messageId ?
                <ProjectMessageRow
                  history={history}
                  message={messageInfo[history.messageId]}
                  key={history.id}
                  showVersionNumberColumn={isVersionPdfsEnabled}
                  addInedibleToast={addInedibleToast}
                />
              :
                <ProjectActivityRow
                  isVersionPdfsEnabled={isVersionPdfsEnabled}
                  projectId={projectId}
                  currentUser={currentUser}
                  history={history}
                  key={history.id}
                  downloadDisabled={downloadDisabled}
                />
            )
          )}
        </tbody>
      </table>
      {hasMore &&
        <div ref={ref} className={'handshq__tbody-loading-bar tw-apply-loading-spinner--grey-regular'}>
          <LoadingIcon width={18} height={18} />
          <span className='p-l-5'>Loading more...</span>
        </div>
      }
    </React.Fragment>
  )
}
