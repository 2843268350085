import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ImagesIcon from '-!svg-react-loader?name=ImagesIcon!icons/ic-images.svg';
import RotateIcon from '-!svg-react-loader?name=RotateIcon!icons/rotate.svg';
import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';
import Tooltip from 'components/application/Tooltip';
import RangeField from 'components/application/RangeField';

const isDisplaySizeEditable = { 'default': true, 'compact': false };
const isFileNameVisible = { 'default': false, 'compact': true };

export default function Settings(props) {
  const { mode, attachment, updateAttachment, deleteAttachment, onChange } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const isProcessed = attachment.attachedProcessed === true;
  const isImage = attachment.recordType === 'image';
  const isPdf = attachment.recordType === 'pdf';
  const isRotateDisabled = !isProcessed || isPdf;
  const rotateTooltipText = isPdf ? 'PDF files cannot be rotated' : 'Refresh the page in order to rotate the image';

  let timeout;

  const handleRangeChange = (event) => {
    const value = event.target.value;

    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      attachment.id && updateAttachment({ attachment: attachment, params: { display_size_value: value } })
    }, 150);
  };

  const handleRotate = (_event) => {
    if (isProcessed && isImage) {
      onChange({ target: { name: 'attachedProcessed', value: false } }, { attachment: attachment })
      updateAttachment({ attachment: attachment, params: { rotation: true } })
    }
  };

  const handleDelete = (_event) => {
    setIsDeleting(true)
    onChange({ target: { name: 'attachedProcessed', value: false } }, { attachment: attachment })
    deleteAttachment({ attachment: attachment })
  };

  return (
    <div className={`attachments__viewer-settings attachments__viewer-settings--${mode}-mode`}>
      {isDisplaySizeEditable[mode] && (
        <div className='attachments__viewer-slider'>
          <ImagesIcon className="[&_path]:tw-fill-grey-900" height={10} width={12} />
          <div className='tw-w-[74px]'>
            <RangeField
              name='displaySizeValue'
              min={0}
              max={2}
              value={attachment.displaySizeValue}
              onChange={handleRangeChange}
              disabled={!attachment.id}
            />
          </div>
          <ImagesIcon className="[&_path]:tw-fill-grey-900" height={14} width={16} />
        </div>
      )}
      {isFileNameVisible[mode] && (
        <div className='attachments__viewer-filename'>
          <span className='truncated-text-container'>{attachment.filename}</span>
        </div>
      )}
      <div className='attachments__viewer-actions'>
        <Tooltip placement='top' triggerProps={{ className: 'tooltip-trigger tw-flex' }} trigger={isRotateDisabled ? 'hover' : 'none'} tooltip={rotateTooltipText}>
          <button className={`attachments__viewer-rotate tw-mr-1 tw-inline-flex tw-items-center tw-justify-center tw-h-6 tw-w-6 tw-p-0 tw-border-0 tw-rounded-lg ${isRotateDisabled ? 'tw-pointer-events-none tw-bg-grey-050 [&_svg_path]:tw-fill-grey-300' : 'tw-bg-transparent tw-text-grey-500 hover:tw-bg-blue-025 hover:tw-text-grey-600 active:tw-bg-blue-050 active:tw-text-grey-600'}`} onClick={handleRotate} disabled={isRotateDisabled}>
            <RotateIcon width={20} height={20} />
          </button>
        </Tooltip>
        <button className={`attachments__viewer-delete tw-inline-flex tw-items-center tw-justify-center tw-h-6 tw-w-6 tw-p-0 tw-border-0 tw-rounded-lg ${isDeleting ? 'tw-pointer-events-none tw-bg-grey-050 [&_svg_path]:tw-fill-grey-300' : 'tw-bg-transparent [&_svg_path]:tw-fill-grey-500 hover:tw-bg-red-025 [&_svg_path]:hover:tw-fill-red-600 active:tw-bg-red-050 [&_svg_path]:active:tw-fill-red-600'}`} onClick={handleDelete} disabled={isDeleting}>
          <TrashIcon width={20} height={20} />
        </button>
      </div>
    </div>
  )
}

Settings.propTypes = {
  index: PropTypes.number.isRequired,
  mode: PropTypes.string,
  attachment: PropTypes.object.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

Settings.defaultProps = {
  mode: 'default'
}
