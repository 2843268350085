import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import RowBar from 'components/application/RowBar';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import SignatureIcon from '-!svg-react-loader?name=SignatureIcon!icons/ic-signature-24.svg'
import Options from 'components/application/Options';
import Tooltip from 'components/application/Tooltip';
import TrainingDownloadOptions from 'components/training/TrainingDownloadOptions';
import OptionChoice from 'components/application/OptionChoice';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ChevronUpIcon from '-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg';
import ChevronDownIcon from '-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg';
import SelectPersonnel, { selectPersonnelShape } from 'components/projectPersonnel/SelectPersonnel';
import { ProjectPersonnelContext } from 'components/contexts/ProjectPersonnelContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function ProjectPersonnelBar(props) {
  const currentActor = useCurrentActor();
  const [trainingEvidenceProcessed, setTrainingEvidenceProcessed] = useState(false);
  const projectPersonnelContext = React.useContext(ProjectPersonnelContext);
  const isTrainingButtonVisible = projectPersonnelContext.userHasPersonnelAccess && currentActor.isAllowedFeature('training_register')
  const signatureInfo = props.requestedVersion && (props.projectPersonnelReviews.haveSignedSameVersion ? (
    <React.Fragment>
      <span className='tw-font-medium'>{ `Version ${props.projectPersonnelReviews.signedVersion.attributes.displayNumber} ` }</span>
      was signed by everyone
    </React.Fragment>
  ) : (
    <React.Fragment>
      <span className='tw-font-medium'>{ `Version ${props.requestedVersion.attributes.displayNumber} ` }</span>
      was sent to people to sign on {moment.parseZone(props.requestedVersion.attributes.reviewsRequestedAt).format('DD MMM YY [at] HH:mm')}
    </React.Fragment>
  ));

  const signedOrRequestedVersion = props.requestedVersion && (props.projectPersonnelReviews.signedVersion || props.requestedVersion);

  const latestVersionInfo = props.requestedVersion && (props.latestVersion && props.latestVersion.attributes.displayNumber > signedOrRequestedVersion.attributes.displayNumber && (
    <React.Fragment>
      , but&nbsp;<span className='tw-font-medium'>{`version ${props.latestVersion.attributes.displayNumber} `}</span>is the latest.
    </React.Fragment>
  ));

  const displayText = props.projectPersonnelCount === 0 ? (
    `There are no personnel currently added to the ${currentActor.division.attributes.singularRamsName || 'RAMS'}`
  ) : (
    <React.Fragment>
      {
        currentActor.isAllowedFeature('digital_signatures') && (
          props.requestedVersion ? (
            props.personnelWithEmailExists ? (
              <React.Fragment>
                {signatureInfo}
                {latestVersionInfo}
              </React.Fragment>
            ) :
            (
              `Version ${props.requestedVersion.attributes.displayNumber} is ready to be signed`
            )
          ) : (
            `Signatures have not yet been requested for this ${currentActor.division.attributes.singularRamsName || 'RAMS'}`
          )
        )
      }
    </React.Fragment>
  );

  let disabledReason;
  if (props.projectPersonnelCount === 0) {
    disabledReason = 'noPersonnel';
  } else if (!trainingEvidenceProcessed) {
    disabledReason = 'processingAttachments';
  }

  const disabledTooltip = {
    noPersonnel: 'No records to download',
    processingAttachments: 'Currently uploading evidence'
  }[disabledReason];


  const sendReviewReminders = () => {
    axios
      .post(`/projects/${props.projectId}/review_reminders`)
      .catch(_error => breadBoard.addInedibleToast({ fullMessage: 'A reminder was not sent. Please try again.' }))
  };

  const reminderOption = props.projectPersonnelReviews.haveSignedAnyVersion || !props.requestedVersion ? (
    <OptionChoice disabled={true}>
      <Tooltip placement='top' trigger='hover' tooltip='Reviews for the latest version have not been sent'>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Remind people who haven't signed</a>
      </Tooltip>
    </OptionChoice>
  ) : (
    <OptionChoice
      onClick={(setOptionsOpen) => {
        sendReviewReminders()
        setOptionsOpen(false)
      }}
    >
      <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Remind people who haven't signed</a>
    </OptionChoice>
  )

  const reviewsRequestedForLatestVersion = (props.latestVersion && props.latestVersion.attributes.displayNumber) === (props.requestedVersion && props.requestedVersion.attributes.displayNumber);

  const signOption = reviewsRequestedForLatestVersion ? (
    <OptionChoice disabled={true}>
      <Tooltip placement='top' trigger='hover' tooltip='The latest version is currently being reviewed'>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Ask everyone to sign latest version</a>
      </Tooltip>
    </OptionChoice>
  ) : (
    <OptionChoice
      onClick={(setOptionsOpen) => {
        props.requestSignatures()
        setOptionsOpen(false)
      }}
    >
      <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Ask everyone to sign latest version</a>
    </OptionChoice>
  )

  return (
    <RowBar
      modifiers={['border-bottom-none', 'enlarged']}
      content={
        <React.Fragment>
          {(props.projectPersonnelCount === 0 || currentActor.isAllowedFeature('digital_signatures')) && (<InfoIcon width={20} height={20} className='m-r-10 className="[&_path]:tw-fill-cyan-600"'/>)}
          <span className='truncated-text-container p-r-10'>
            {displayText}
          </span>
        </React.Fragment>
      }
      actions={
        <React.Fragment>
          {isTrainingButtonVisible && (
            <div className='tooltip-parent m-r-15'>
              <TrainingDownloadOptions
                trainingEvidenceProcessed={trainingEvidenceProcessed}
                setTrainingEvidenceProcessed={setTrainingEvidenceProcessed}
                evidenceStatusUrl={`/projects/${props.projectId}/training_evidence_status`}
                documentGeneratorUrl={`/projects/${props.projectId}/training_report_pdfs`}
                emailReportUrl={props.latestVersion && `/versions/${props.latestVersion.id}/messages/new?training_report_included=true`}
                documentGeneratorParams={{}}
                disabled={!!disabledReason}
              />
              {disabledReason && (<Tooltip placement='top' trigger='hover' tooltip={disabledTooltip} />)}
            </div>
          )}
          {currentActor.isAllowedFeature('digital_signatures') && (
            props.projectPersonnelCount === 0 || props.projectPersonnelReviews.haveSignedLatestVersion ? (
              <div className='tw-bg-white tw-border-white options__toggle options__toggle--btn options__toggle--btn-disabled m-r-15'>
                <OutlinedButton size='sm' color='grey' disabled={true} onClick={() => {}}>
                  <SignatureIcon className='m-l--6' width={24} height={24} />
                  <span className='p-l-4 p-r-4'>Get signatures</span>
                  <ChevronDownIcon className='m-r--8' width={24} height={24} style={{ display: 'inline-block' }} />
                </OutlinedButton>
              </div>
            ) : (
              <Options
                key={'requestSignatures'}
                toggleStyle={'m-r-15'}
                btnToggle={
                  (isOpen) => {
                    return (
                      <OutlinedButton size='sm' color='grey' onClick={() => {}}>
                        <SignatureIcon className='m-l--6' width={24} height={24} />
                        <span className='p-l-4 p-r-4'>Get signatures</span>
                        <ChevronUpIcon className='m-r--8' width={24} height={24} style={{ display: isOpen ? 'inline-block' : 'none' }} />
                        <ChevronDownIcon className='m-r--8' width={24} height={24} style={{ display: isOpen ? 'none' : 'inline-block' }} />
                      </OutlinedButton>
                    )
                  }
                }
              >
                {reminderOption}
                {signOption}
              </Options>
            )
          )}
          <SelectPersonnel
            {...props.selectPersonnelOptions}
          />
        </React.Fragment>
      }
    />
  )
}

ProjectPersonnelBar.propTypes = PropTypes.exact({
  latestVersion: PropTypes.object,
  personnelWithEmailExists: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  projectPersonnelCount: PropTypes.number.isRequired,
  projectPersonnelReviews: PropTypes.object,
  requestSignatures: PropTypes.func,
  requestedVersion: PropTypes.object,
  // pass through
  selectPersonnelOptions: selectPersonnelShape
})
