import React from 'react';
import PropTypes from 'prop-types';

import CoshhOptions from 'components/projectCoshh/CoshhOptions';

export default function CoshhRow({ coshhDocument, onDownloadClick, onDeleteClick, onCoshhDocumentRowClick }) {
  return (
    <>
      <tr className='handshq__tr--clickable handshq__tr--taller options__toggle-container tw-group/row hover:tw-bg-grey-025' onClick={() => onCoshhDocumentRowClick(coshhDocument)}>
        <td className='tw-font-medium tw-text-grey-900 tw-border-grey-100 py-4'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              { coshhDocument.attributes.title }
            </span>
          </div>
        </td>
        <td className='tw-border-grey-100 py-4'>
          <div className='fh-40 flex flex--vertically-centered'>
            <span className='truncated-text-container--wrapped truncated-text-container--two-lines'>
              { coshhDocument.attributes.manufacturer }
            </span>

          </div>
        </td>
        <td className='tw-border-grey-100'>
          <div className='fh-32 flex flex--vertically-centered'>
            { coshhDocument.attributes.reference }
          </div>
        </td>
        <td className='tw-border-grey-100'>
          <CoshhOptions
            coshhDocument={coshhDocument}
            onDownloadClick={onDownloadClick}
            onDeleteClick={onDeleteClick}
          />
        </td>
      </tr>
    </>
  )
}

CoshhRow.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCoshhDocumentRowClick: PropTypes.func.isRequired
};
