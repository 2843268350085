import React from "react";
import PropTypes from "prop-types";
import Toast from "components/application/Toast";
import TickIcon from "-!svg-react-loader?name=TickIcon!icons/ic-tick.svg";

export default function SuccessToast({
  message,
  onBurnToast,
  toastKey
}) {
  return (
    <Toast
      toastKey={toastKey}
      burn={onBurnToast}
      modifiers='no-undo'
      burnTimer={7000}
      contents={
        <React.Fragment>
          <div className="toast__icon tw-bg-green-500"><TickIcon className="[&_polygon]:tw-fill-white" width={20} height={20} /></div>
          <div className="toast__text">{message}</div>
        </React.Fragment>
      }
    />
  )
}

SuccessToast.propTypes = {
  message: PropTypes.node
}
