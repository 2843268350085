export default function useValidatedStore({
  getStore,
  initialStore,
  schema,
}) {
  const store = getStore()

  if (!store) return initialStore

  return schema.safeParse(store).success ? store : initialStore
}
