import ProjectExporter from './project-exporter';

$( document ).ready(function() {
  addPopover( '.projects .actions a' );
  addPopover( '#documents .actions a' );
  addPopover( '.method-text-image-rotate i' );
  addPopover( '.method-text-image-delete i' );
  addPopover( '.popover-holder' );

  if ($('.export-request__request').length) { new ProjectExporter(); }
});
