import HandshqApp from '../handshq-app/handshq-app';
import RiskSelector from './risk-selector';

$(function() {
  if ($('[data-inline-nested-attributes]').length) {
    const inlineNestedAttributes = {
      setup: function() {
        this.enableAddEvent();
        this.removeEventListener();
        this.enableRemoveEvent();
      },

      enableAddEvent: function() {
        const _this = this;
        const addButton = $("button[name='add-nested-attributes']");

        addButton.click(function() {
          _this.addAttributes();
          _this.enableRemoveEvent();

          if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
            // animation issues on mobile devices, so no animation used
            $('html, body').scrollTop($('.content-wrapper').height());
          } else {
            $('html, body').animate({
              scrollTop: $('.content-wrapper').height()
            }, 'slow');
          }
        });
      },

      removeEventListener: function() {
        const _this = this;
        this.removeClickEnabler.call(this);

        $('[data-inline-nested-attributes]').on('modifiedattributes', function () {
          _this.removeClickEnabler();
        });
      },

      triggerModified: function() {
        $('[data-inline-nested-attributes]').trigger('modifiedattributes');
      },

      removeClickEnabler: function() {
        const _this = this;

        if ($('[data-nested-attributes]').length > 1) {
          _this.enableRemoveClick();
        } else {
          _this.disableRemoveClick();
        }
      },

      addPopover: function(button) {
        $(button).popover({
          content: 'A risk assessment must have at least one task',
          trigger: 'hover',
          placement: 'top'
        });

        $(button).data('bs.popover').tip().addClass('custom-popover');
      },

      disableRemoveClick: function() {
        const _this = this;

        $(['[data-remove-nested-attributes]']).each(function(index, button) {
          $(button).addClass('disabled hover-popover');
          $(button).attr('data-disabled', true);
          _this.addPopover(button);
        });
      },

      enableRemoveClick: function() {
        $(['[data-remove-nested-attributes]']).each(function(index, button) {
          if ($(button).hasClass('disabled')) {
            $(button).removeClass('disabled hover-popover');
            $(button).attr('data-disabled', false);
            $(button).popover('destroy');
          }
        });
      },

      addAttributes: function() {
        const _this = this;
        const nestedAttributes = $('[data-inline-nested-attributes]');
        const lastAttributes = $('[data-nested-attributes]').last();
        const objectIndex = lastAttributes.data('object-index');
        const objectName = nestedAttributes.data('object-name');
        const removeLink = "<a href='#0' class='tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--red tw-apply-outlined-button--on-light pull-right' data-remove-nested-attributes='true' data-toggle='modal' data-target='#remove-nested-attributes-modal-" + objectIndex + "'><img src='" + HandshqApp.rails.trashIconSvgPath + "' aria-hidden='true'><span class='m-l-8'>Delete " + objectName + "</span></a>";
        const linkWrapper = "<div class='col-sm-8 col-xs-8 remove-record'><div class='form-group'>" + removeLink + "</div></div>";
        const clone = lastAttributes.clone(false);

        $(clone).insertAfter(lastAttributes);

        _this.modifyButtons(clone, linkWrapper);
        _this.modifyFields(clone);
        _this.modifyRiskAssessment(clone);
        _this.modifyNestedAttributesOptions(clone);
        _this.triggerModified();
      },

      removeAttributes: function() {
        const _this = this;
        $('#modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $(this).closest('[data-nested-attributes]').remove();
      },

      enableRemoveEvent: function() {
        const _this = this;
        const removeButton = $("button[name='remove-nested-attributes']").last();

        removeButton.click(function() {
          _this.removeAttributes.call(this);
          _this.triggerModified();
        });

        removeButton.closest('.remove-modal').keyup(function(event) {
          const keycode = (event.keyCode ? event.keyCode : event.which);

          if (keycode == '13') {
            _this.removeAttributes.call(this);
            _this.triggerModified();
          }

          event.stopPropagation();
        });
      },

      modifyButtons: function(clone, buttonWrapper) {
        this.removeRecord(clone, '.remove-record');
        $(clone).append(buttonWrapper);
        this.removeRecord(clone, '.delete-record');
      },

      removeRecord: function(clone, selector) {
        if ($(clone).find(selector).length) {
          $(clone).find(selector).remove();
        }
      },

      modifyFields: function(clone) {
        const _this = this;

        $(clone).each(function(index, elements) {
          $(elements).find('label').each(function() {
            _this.incrementAttributeNumbers.call(this, 'for');
          });

          $(elements).find('input, select, textarea').each(function() {
            _this.incrementAttributeNumbers.call(this, 'id');
            _this.incrementAttributeNumbers.call(this, 'name');
          });

          $(elements).find('.remove-modal').each(function() {
            _this.incrementAttributeNumbers.call(this, 'id');
          });

          $(elements).find('.remove-record a').each(function() {
            _this.incrementAttributeNumbers.call(this, 'data-target');
          });

          _this.incrementAttributeNumbers.call(this, 'data-object-index');
        });
      },

      modifyRiskAssessment: function(clone) {
        const firstEntry = clone.find('.numbers tbody tr:first td:first a');
        const entryValue = firstEntry.data('value');
        const entrySeverity = firstEntry.data('severity');

        if ($('.task-form').length) {

          $(clone).find('td.selected').each(function() {
            $(this).removeClass('selected');
          });

          $(clone).find('.risk-matrix-container').each(function() {
            new RiskSelector(this).setup();
            $(this).find('.numbers tbody tr:first td:first').addClass('selected');
          });

          $(clone).find('input.risk-reference').each(function() {
            this.value = entryValue;
          });

          $(clone).find('.risk-selector').each(function() {
            $(this).attr('value', entryValue);
          });

          $(clone).find('.risk-input').each(function() {
            this.innerHTML = entryValue;

            $(this).removeClass('negligible low medium-low medium medium-high high');
            $(this).addClass(entrySeverity);
          });
        }
      },

      modifyNestedAttributesOptions: function modifyNestedAttributesOptions(clone) {
        const nestedAttributesOptions = $(clone).find('[data-react-class="AssignedPersonsAtRisk/NestedAttributesOptions"]')
        nestedAttributesOptions.children().remove()

        const previousNestedAttributesOptionsProps = JSON.parse(nestedAttributesOptions.attr('data-react-props'))
        const previousNestedNameAttribute = previousNestedAttributesOptionsProps.nestedNameAttribute
        const digits = /(\d+)/g;
        const numbersInAttribute = parseInt(previousNestedNameAttribute.match(digits)[0])
        const incrementedNestedAttributeTreeName = previousNestedNameAttribute.replace(digits, numbersInAttribute + 1);

        const nestedAttributesOptionsProps = {
          personsAtRiskOptions: previousNestedAttributesOptionsProps.personsAtRiskOptions,
          assignedPersonsAtRisk: { data: [] },
          nestedNameAttribute: incrementedNestedAttributeTreeName,
          shouldInitiallySelectDefaultRisk: true
        }

        nestedAttributesOptions.attr('data-react-props', JSON.stringify(nestedAttributesOptionsProps));
        ReactRailsUJS.mountComponents();
      },

      incrementAttributeNumbers: function(attributeName) {
        const digits = /(\d+)/g;
        const currentAttribute = this.getAttribute(attributeName);

        if (currentAttribute) {
          const numbersInAttribute = currentAttribute.match(digits);

          if (numbersInAttribute) {
            const newIndex = parseInt(numbersInAttribute[0]) + 1;
            this.setAttribute(attributeName, currentAttribute.replace(digits, newIndex));
            this.value = '';
          }
        }
      }
    };

    inlineNestedAttributes.setup();
  };
});
