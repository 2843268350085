import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/application/Tabs';
import PersonnelSidePanelTeamsTab from 'components/personnel/PersonnelSidePanelTeamsTab';
import PersonnelDetailsTab from 'components/personnel/PersonnelDetails';
import PersonnelSidePanelLineManagerTab from 'components/personnel/PersonnelSidePanelLineManagerTab';
import PersonnelSidePanelSubordinatesTab from 'components/personnel/PersonnelSidePanelSubordinatesTab';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import { useBreadBoard } from 'components/contexts/Toaster';
import { useInView } from 'react-intersection-observer';
export default function PersonnelSidePanelTabs(props) {
  const breadBoard = useBreadBoard();
  const defaultSubordinate = { loaded: false, collection: [], metaData: { totalCount: null, isLastPage: false, currentPage: 0, totalPages: 0 }}
  const [currentSubordinates, setSubordinates] = useState(defaultSubordinate);
  const [loadMoreRef, loadMoreInView] = useInView();

  const {
    defaultDetails,
    currentPersonnel,
    teams,
    sidePanelIsOpen,
    availableFields,
    availableFieldOptions,
    isCompact,
    lineManager
  } = props;

  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  const fetchSubordinates = () => {
    axios
      .get(`/personnel/${currentPersonnel.id}/subordinates`, { params: { page: parseInt(currentSubordinates.metaData.currentPage) + 1 } })
      .then((response) => {
        setSubordinates(prevState => ({ loaded: true, collection: prevState.collection.concat(response.data.data), metaData: response.data.meta}))
      })
      .catch(breadBoard.addInedibleToast)
  }

  useEffect(() => {
    if (sidePanelIsOpen) {
      fetchSubordinates()
    } else {
      setSubordinates(defaultSubordinate)
    }
  }, [sidePanelIsOpen])

  useEffect(() => {
    if (!currentSubordinates.metaData.isLastPage && loadMoreInView) fetchSubordinates({ increment: true })
  }, [currentSubordinates.metaData.isLastPage, loadMoreInView])


  return (
    <div>
      <Tabs
        modifiers={['panelled']}
        navClassName='tw-bg-grey-050'
        activeTabIsReset={sidePanelIsOpen}
      >
        <PersonnelDetailsTab
          key='detailsTab'
          label='Details'
          defaultDetails={defaultDetails}
          isCompact={isCompact}
          currentPersonnel={currentPersonnel}
          availableFields={availableFields}
          availableFieldOptions={availableFieldOptions}
          isAllVisible={true}
        />
        { trainingRegisterResourceManagementContext.hasTeamViewableAccess &&
          <PersonnelSidePanelTeamsTab
            key='teamsTab'
            label='Teams'
            teams={teams}
          />
        }
        <PersonnelSidePanelLineManagerTab
          key='lineManagerTab'
          label='Line manager'
          lineManager={lineManager}
        />
        <PersonnelSidePanelSubordinatesTab
          key='subordinatesTab'
          label='Direct reports'
          subordinates={currentSubordinates.collection}
          disabled={!currentSubordinates.loaded}
          loadMoreRef={loadMoreRef}
          metaData={currentSubordinates.metaData}
        />
      </Tabs>
    </div>
  )
}

PersonnelSidePanelTabs.propTypes = {
  defaultDetails: PropTypes.array,
  currentPersonnel: PropTypes.object,
  availableLineManagers: PropTypes.array,
  teams: PropTypes.array,
  sidePanelIsOpen: PropTypes.bool,
  availableFields: PropTypes.object,
  availableFieldOptions: PropTypes.object,
  isCompact: PropTypes.bool,
  lineManager: PropTypes.object
};
