import React from 'react';
import ActionButton from 'components/buttons/ActionButton';
import ActionLink from 'components/buttons/ActionLink';
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import PdfDownloadContainer from 'components/messages/PdfDownloadContainer';
import { createDocumentResource } from 'components/helpers/resources/documents';
import moment from 'moment';
import filesize from 'filesize';

export default function DownloadContainer({ messageDetails, companyBranding, companyInfo, projectInfo }) {
  const branding = companyBranding.data
  const versionPdf = messageDetails.included.find(i => i.type === 'version_pdf') && createDocumentResource(messageDetails.included.find(i => i.type === 'version_pdf'))
  const trainingReport = messageDetails.included.find(i => i.type === 'trainingReport') && createDocumentResource(messageDetails.included.find(i => i.type === 'trainingReport'))

  const BrandedLink = withBranding(ActionLink, { color: branding.attributes.textLinkColour });
  const BrandedButton = withBranding(ActionButton, { backgroundColor: branding.attributes.buttonBgColour, color: branding.attributes.buttonTextColour });
  const downloadAll = () => window.location.assign(`/messages/${messageDetails.data.attributes.hashid}.zip`);

  const containerProps = (resource) => {
    return {
      createdAt: moment.parseZone(resource.attributes.createdAt).format('D MMM YYYY [at] H:mm'),
      fileSize: filesize(resource.attributes.fileSize || 0, { round: 0 }),
      viewHref: resource.links.content,
      BrandedLink: BrandedLink
    }
  }

  return (
    <div className='download-container tw-bg-white'>
      <div className='download-container__title tw-font-medium'>{ companyInfo.companyName }</div>
      <div className='download-container__name tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{ projectInfo.projectName }</div>
        <div className='download-container__sub-text tw-text-grey-600 m-t-20 m-b-24'>
          <ClockIcon className='[&_path]:tw-fill-grey-600 download-container__svg m-b-1 m-r-2' height={18} width={18} />
          Download expires on { moment.parseZone(messageDetails.data.attributes.expiryDate).format('D MMM YYYY') }
        </div>
        {versionPdf &&
          <PdfDownloadContainer
            title={`RAMS ${projectInfo.versionNumber === undefined ? '' : `- version ${projectInfo.versionNumber}`}`}
            downloadHref={`/messages/${messageDetails.data.attributes.hashid}/version_pdf?token=${messageDetails.data.attributes.token}`}
            {...containerProps(versionPdf)}
          />
        }
        {trainingReport &&
          <PdfDownloadContainer
            title={'Training report'}
            downloadHref={`/messages/${messageDetails.data.attributes.hashid}/training_report?token=${messageDetails.data.attributes.token}`}
            {...containerProps(trainingReport)}
          />
        }
        { (versionPdf && trainingReport) &&
          <div className='m-t-24'>
            <BrandedButton buttonText='Download all' onClick={downloadAll}/>
          </div>
        }
    </div>
  )
}

export const withBranding = function(Component, brandingStyle) {
  return function(props) {
    return (
      <Component {...props} style={brandingStyle}/>
    )
  }
}
