import voca from 'voca';

import httpClient from 'axios-settings/http-client';
import { sanitizeFilename } from 'helpers/files';

export default function Upload(options) {
  if (this instanceof Upload) {
    const _this = this;

    this.file = options.file;
    this.filename = sanitizeFilename(options.file.name);
    this.type = voca.includes(options.file.type, 'image') ? 'image' : 'pdf';
    this.onUpload = options.onUpload;
    this.onError = options.onError;

    const getAttachmentKey = (response) => {
      const xmlDoc = response.request.responseXML;

      return xmlDoc.getElementsByTagName('Key')[0].textContent
    };

    const uploadFileToRemote = ({ url, data }) => {
      httpClient.defaults.headers.common['Accept'] = 'application/xml';
      httpClient.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      httpClient.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';
      httpClient.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, max-age=0, must-revalidate';
      httpClient.defaults.headers.common['Pragma'] = 'no-cache';

      httpClient
        .post(url, data)
        .then(response => _this.onUpload(_this.type, getAttachmentKey(response)))
        .catch(error => _this.onError(error))
    };

    const getFormData = ({ response }) => {
      const formData = new FormData();
      const attributes = response.data.data.attributes;
      attributes.key = attributes.key.replace('${filename}', _this.filename);

      Object.entries(response.data.data.attributes).forEach(entry => formData.append(entry[0], entry[1]))
      formData.append('file', _this.file)

      return formData
    };

    this.start = () => {
      axios
        .get('/uploaders/new', { params: { type: _this.type } })
        .then(response => {
          uploadFileToRemote({
            url: response.data.links.uploadUrl,
            data: getFormData({ file: _this.file, response: response })
          })
        })
        .catch(error => _this.onError(error))
    };
  } else {
    throw new Error('Upload invoked without new');
  }
};
