import React from 'react';
import PropTypes from 'prop-types';

export default function ListSelectItem(props) {
  const {
    item,
    onClick
  } = props;

  return (
    <div className='app-chip b-standard tw-bg-white tw-border-grey-100 hover:tw-bg-grey-100' onClick={(_event) => onClick({ value: item.value })}>{item.value}</div>
  )
}

ListSelectItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};
