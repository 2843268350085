import React from 'react';
import { components } from 'react-select';
import AsyncCreatable from 'react-select/async-creatable';
import _ from 'lodash';

import Circle from 'components/application/Circle';
import { NoOptionsMessage, ClearIndicator, DropdownIndicator } from 'components/application/CollectionSelect';
import { personDisplayName } from 'components/helpers/users';
import { validEmailRegex } from 'constants/regex';

const invalidReasonDisplayText = {
  'userExists': 'A user with this email address already exists',
  'deletedUserExists': 'This user has previously been deleted. If you still want to add this user, please contact us via the chat box.'
}

export default function SearchImportablePersonnelField({ onPersonnelSelection, isUserEmail, removeErrorStyling }) {

  // mix of callback and promise approach, callback approach seems iffy to test
  const loadPersonnel = React.useCallback(
    _.debounce((inputValue, callback) => {
      if (isUserEmail && isUserEmail(inputValue)) {
        onPersonnelSelection({ isNotDerived: true, value: inputValue })
      } else {
        axios
          .get(searchPersonnelPath(inputValue))
          .then(response => parsePersonnelResponseToOptions(response))
          .then(options => callback(options))
      }
    }, 500, { maxWait: 1000}), []
  );

  return (
    <>
      <label className="collection-select__label collection-select__required tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal">
        Email address
      </label>
      <AsyncCreatable
        id={'user_email'}
        className={'collection-select__select-container'}
        classNamePrefix={'collection-select'}
        placeholder={''}
        value={null}
        cacheOptions={true}
        onFocus={() => {removeErrorStyling({target: {name:'email'}})}}
        onChange={onPersonnelSelection}
        isOptionDisabled={isOptionDisabled}
        components={{ NoOptionsMessage, ClearIndicator, DropdownIndicator, Option }}
        loadOptions={loadPersonnel}
      />

    </>
  )
}

// helpers

function isOptionDisabled(option) {
  return (
    option.invalidReason || (option.__isNew__ && !(option.value && validEmailRegex.test(option.value)))
  )
}

function parsePersonnelResponseToOptions(response) {
  let mapped;
  if (response.data.meta.blockingReason) {
    mapped = [{ invalidReason: response.data.meta.blockingReason }]
  } else {
    mapped = response.data.data.map((personnel) =>
      ({ label: personnel, value: personnel })
    )
  }
  return mapped
}

function searchPersonnelPath(search) {
  return `/importable_personnel?search=${search}`
}

// Custom Options

function Option(props) {
  if (props.options.some((opt) => opt.invalidReason) && !props.data.invalidReason) { return null }
  return (
    <components.Option {...props}>
      {props.data.__isNew__ ? (
        <CreateOption isDisabled={props.isDisabled} value={props.data.value} />
      ) : (
        props.data.invalidReason ? (
          <InvalidOption reason={invalidReasonDisplayText[props.data.invalidReason]} />
        ) : (
          <PersonnelOption personnel={props.data.value} />
        )
      )}
    </components.Option>
  );
}

const PersonnelOption = ({ personnel }) => {
  const { firstName, lastName } = personnel.attributes;
  const initials = `${firstName[0]} ${lastName && lastName[0]}`;

  return (
    <span className='l-if flex--vertically-centered'>
      <Circle className='tw-text-white tw-bg-blue-400'>{initials}</Circle>
      <span className='m-l-12 '>
        <div className='tw-font-medium tw-text-grey-900'>{personnel.attributes.email}</div>
        <div className='tw-text-s tw-font-normal tw-tracking-wide'>{personDisplayName(personnel.attributes)}</div>
      </span>
    </span>
  )
}

const CreateOption = ({value, isDisabled}) => {
  return (
    <span className='l-if flex--vertically-centered'>
      <Circle className={`tw-text-white ${isDisabled ? 'tw-bg-blue-200' : 'tw-bg-blue-400'}`}><span className='tw-text-xl tw-font-semibold tw-tracking-tight m-l-1 m-t--2'>+</span></Circle>
      <span className='m-l-12'>Add "<span className='tw-font-medium'>{value}</span>"</span>
    </span>
  )
}

function InvalidOption({ reason }) {
  return (
    <span className='l-if flex--vertically-centered'>
      <Circle className='tw-text-white tw-bg-red-025'><span className='tw-font-medium tw-text-red-800'>X</span></Circle>
      <span className='m-l-12 tw-text-red-800'>{reason}</span>
    </span>
  )
}
