import React from 'react';
import BlockList from 'components/application/BlockList';
import PersonnelTrainingStatusIndicator from 'components/personnel/PersonnelTrainingStatusIndicator';
import { subcontractorDisplayName } from 'components/helpers/companies';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import 'intersection-observer';

export default function RolesSidePanelPersonnelTab(props) {
  const {
    personnel,
    personnelMeta,
    loadMoreRef,
    isLoadMorePersonnelActive
  } = props;

  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  return (
    <div key='personnelTab' label='personnel'>
      {personnel.loaded && personnel.collection.length === 0 ? (
        <li className='block-list__item tw-border-grey-100'>
          <div className='block-list__item--flex-container'>
            <div className='block-list__item--align-center'>
              <span>No personnel have this role</span>
            </div>
          </div>
        </li>
      ) : (
        <>
          <BlockList
            collection={personnel.collection}
            blockListItemClasses={(person) => person.attributes.subcontractorId ? ' block-list__item--double' : '' }
            onItemClick={trainingRegisterResourceManagementContext.hasPersonnelViewableAccess ? ((person) => window.location.replace(`personnel/${person.id}`)) : undefined}
            onItemDisplay={(person) => {
              return (
                <div className='block-list__item--flex-container'>
                  <div className='block-list__item--align-center'>
                    <PersonnelTrainingStatusIndicator
                      status={person.attributes.trainingStatus}
                    />
                  </div>
                  <div className='block-list__item--text mw-300'>
                    <span className='truncated-text-container'>
                      {`${person.attributes.firstName} ${person.attributes.lastName}`}
                    </span>
                    {person.attributes.subcontractorId && (
                      <span className='block-list__item--sub-text truncated-text-container tw-text-s tw-text-grey-500 tw-tracking-wide'>{subcontractorDisplayName(person.attributes.subcontractorId, personnel.includedSubcontractors)}</span>
                    )}
                  </div>
                </div>
              )
            }}
          />
          {isLoadMorePersonnelActive && !personnelMeta.isLastPage && (
            <li ref={loadMoreRef} className='block-list__item tw-border-grey-100'>
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading personnel...</span>
                </div>
              </div>
            </li>
          )}
        </>
      )}
    </div>
  )
}
