import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/application/Table';
import RolesRow from 'components/roles/RolesRow';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function RolesTable(props) {
  const currentActor = useCurrentActor();

  return (
    <Table
      headers={
        <tr>
          { currentActor.isAllowedFeature('training_register') && (<th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'>Status</th>) }
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Role</th>
          { currentActor.isAllowedFeature('training_register') && (<th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-168 tw-text-center'>Courses</th>) }
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-168 tw-text-center'>Personnel</th>
        </tr>
      }
      rows={
        props.roles.map((role, index) =>
          <RolesRow
            key={index}
            role={role}
            setSidePanelContext={props.setSidePanelContext}
            setCurrentRole={props.setCurrentRole}
            resetRequestError={props.resetRequestError}
            openSidePanel={props.openSidePanel}
          />
        )
      }
    />
  )
}

RolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  setCurrentRole: PropTypes.func.isRequired,
  openSidePanel: PropTypes.func.isRequired
};
