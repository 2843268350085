import React, { useState } from 'react';
import TextField from 'components/application/TextField';
import FilledButton from 'components/application/buttons/FilledButton';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';
import ResourceChangedToast from 'components/application/ResourceChangedToast'
import useRequestError from 'components/hooks/useRequestError';

export default function BillingForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [billingEmail, setBillingEmail] = useState(props.email)
  const [requestError, _submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [toasts, _setToasts, addToast, handleBurnToast, addInedibleToast] = useToasts();

  const handleSubmit = () => {
    setIsSubmitting(true)
    axios.patch('/billing_account', { billing_account: { email: billingEmail } })
    .then((_response) => {
      resetRequestError()
      addToast(
        <ResourceChangedToast
          resource={`Billing email`}
          status={'updated'}
          onBurnToast={handleBurnToast}
        />
      )
    })
    .catch((e) => {
      handleRequestError(e)
      addInedibleToast({ errorTrigger: 'updating your billing email'})
    })
    .finally(() => {
      setIsSubmitting(false)
    })
  }

  const handleEmailChange = (e) => {
    removeErrorStyling(e)
    setBillingEmail(e.target.value)
  }

  return (
    <>
      {/* pending move to parent toast handler */}
      <ToastRack toasts={toasts} />
      <div className='mw-300'>
        <TextField label='Billing email' fieldError={requestError.validationErrors.billingEmail} name='billing_email' value={billingEmail} onChange={handleEmailChange} />
        <FilledButton color='mint' className='m-t-24' disabled={isSubmitting} onClick={handleSubmit}>
          Save changes
        </FilledButton>
      </div>
    </>
  )
}
