import React from "react";
import PropTypes from "prop-types";
import NewInfoPill from "components/application/pills/NewInfoPill";

export default function NewInfoCard({ title, text }) {
  return (
    <>
      <div className='pos-rel'>
        <div className='pos-abs pos-t--12 pos-l-12'>
          <NewInfoPill />
        </div>
        <div className="content-box layout--b p-20 m-b-24 mw-400 tw-border-grey-100 tw-bg-white">
          <div className="flex flex--justify-content__space-between field__label tw-font-medium">
            <div>{title}</div>
          </div>
          <div className="tw-text-s tw-text-grey-500 tw-tracking-wide">
            <span>{text}</span>
          </div>
        </div>
      </div>
    </>
  )
  }

NewInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
