import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import SegmentedControls from 'components/application/SegmentedControls';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';
import TrainingMatrixDownloader from 'components/training/trainingMatrixDownload/TrainingMatrixDownloader';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

export default function PersonnelBar(props) {
  const {
    actionButtonsVisible,
    isArchivedToggled,
    personnelSearch,
    onNewPersonnel,
    onSearchInputChange,
    onSearchReset,
    onArchiveToggle
  } = props;
  const activeToggleChoice = !isArchivedToggled ? 'Current' : 'Archived';
  const currentActor = useCurrentActor();
  const { hasArchivedPersonnelAccess } = useTrainingRegisterResources();

  const handleSegmentItemClick = (selection)  => {
    if (selection !== activeToggleChoice) onArchiveToggle()
  }

  return (
    <Fragment>
      <RowBar
        modifiers={['border-top-curved', 'border-bottom-none', 'flex-align-items-ie-fix']}
        content={
          <span className='separated-items flex flex--vertically-centered'>
            {hasArchivedPersonnelAccess &&
              <SegmentedControls
                choices={['Current', 'Archived']}
                activeChoice={activeToggleChoice}
                onItemClick={handleSegmentItemClick}
              />
            }
            <SearchField
              value={personnelSearch}
              onChange={onSearchInputChange}
              onReset={onSearchReset}
              name='personnelSearch'
              placeholder={`Search ${isArchivedToggled ? 'archived... ' : 'personnel or ID...'}`}
            />
          </span>
        }
        actions={
          <Fragment>
            {currentActor.isAllowedFeature('training_register') && actionButtonsVisible && <TrainingMatrixDownloader />}
            {actionButtonsVisible && (
              <OutlinedButton size='sm' color='grey' onClick={onNewPersonnel}>
                <PersonIcon className='m-l--8' width={24} height={24}/>
                <span>Add new personnel</span>
              </OutlinedButton>
            )}
          </Fragment>
        }
      />
    </Fragment>
  )
}

PersonnelBar.propTypes = {
  actionButtonsVisible: PropTypes.bool,
  isArchivedToggled: PropTypes.bool,
  onArchiveToggle: PropTypes.func,
  personnelSearch: PropTypes.string,
  onNewPersonnel: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
};
