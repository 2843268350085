import Polling from 'handshq-app/utilities/polling';

$(function() {
  if ( $( '#preview-document-container' ).length > 0 ) {
    const documentPreviewer = new DocumentPreviewer( '#preview-document-container' );
    documentPreviewer.start();
  }
});

function DocumentPreviewer(container) {
  const _this = this;
  this.container = $( container );
  this.errorsContainer = this.container.find('.errors');
  this.spinner = $('.loading-icon');
  this.documentUrl = this.container.data('latest-version-pdf-url');
  this.versionId = this.container.data('latest-version-id');
  this.downloadLink = $('#preview-download');
  this.statusUrl = this.container.data('status-url') || `/versions/${_this.versionId}`;

  this.start = function() {
    if ( this.documentUrl ) {
      _this.renderDocument();
    } else {
      _this.renderWhenReady()
    }
  };

  this.renderDocument = function() {
    const iframe = $( '<iframe/>', {
      id: 'hqPdfViewer',
      src: '/pdfjs/web/viewer.html?file=' + encodeURIComponent(this.documentUrl) + '&_=' + new Date().getTime() + '#zoom=auto',
      width: '100%',
      height: '100%',
      frameBorder: '0',
      scrolling: 'no'
    });

    iframe.id = "IF_" + new Date().getTime(); // prevent Safari from using old data.

    _this.container.html(iframe);
    _this.allowDownload();
  };

  this.allowDownload = function() {
    _this.downloadLink.attr('href', this.documentUrl);
    _this.downloadLink.attr('data-disabled', false);
  };

  this.renderWhenReady = function(){
    const _this = this;
    new Polling({
      interval: 1000,
      url: _this.statusUrl,
      responseCheck: function(response) {
        return response.data.relationships.version_pdf.data !== null;
      },
      successCallback: function(response) {
        _this.documentUrl = response.included[0].links.content;
        _this.renderDocument();
      },
      failureCallback: function(response) {
        const versionErrors = response.responseJSON && response.responseJSON.errors;
        _this.displayErrors(versionErrors)
      }
    }).start()
  };

  this.displayErrors = function(errors) {
    if(errors){
      _this.errorsContainer.html("The following errors are preventing the document from being previewed:");
      const errorsList = $('<ul></ul>');
      errors.forEach(function(error, _index) {
        const errorMessage = error.detail;
        errorsList.append("<li>" + errorMessage + "</li>");
      });
      _this.errorsContainer.append(errorsList);
      _this.errorsContainer.append("<span>Please fix them to continue</span>");
    } else {
      _this.errorsContainer.html("There was a problem previewing the PDF. Please retry and contact our support team if the problem persists.");
    }
    _this.errorsContainer.show();
    _this.spinner.hide();
  };
}

export default DocumentPreviewer;
