import React, { useState } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '-!svg-react-loader?name=CopyIcon!icons/copy.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';

export default function CopyableField({ name, value }) {
  const [copied, setCopied] = useState(false);

  return (
    <div className='flex'>
      <input
        className='field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto tw-bg-grey-050 tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300'
        type='text'
        id={voca.snakeCase(name)}
        name={name}
        value={value}
        readOnly='readonly'
      />
      <div className='copyable__hit-area tw-group/hit fw-40 fh-40 p-10' onMouseEnter={() => setCopied(false)}>
        <div className='copyable tw-text-s tw-font-medium tw-tracking-wide'>
          <div className='copyable__copy-icon-wrapper'>
            <CopyToClipboard text={value} onCopy={() => setCopied(true)}><CopyIcon className='[&_path]:tw-fill-grey-500 group-hover/hit:[&_path]:tw-fill-blue-500' width={20} height={20}/></CopyToClipboard>
          </div>
          <div className={`copyable__tooltip tw-text-white tw-bg-grey-900 copyable__tooltip--center${copied ? ' copyable__tooltip--ease-out' : ''}`}>
            {copied ? (
              <div className='copyable__tooltip-content'><TickIcon className='copyable__tick-icon [&_polygon]:tw-fill-white'/><span>Copied to clipboard</span></div>
            ) : (
              <span className='copyable__tooltip-content'><span>Click to copy to clipboard</span></span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CopyableField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
