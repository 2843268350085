import React from 'react';
import PropTypes from 'prop-types';

import Uploader from 'components/uploader/Uploader';
import CheckboxField from 'components/application/CheckboxField';
import ColourInput from 'components/application/ColourInput';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import FilledButton from 'components/application/buttons/FilledButton';
import TrashIcon from '-!svg-react-loader?name=TrashIcon!icons/ic-trash.svg';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';

export default function Form({ branding, isPoweredByEditable, resetDefaults, removeBrandingHref, invalidLogo, disabled, logoSrc, onSaveClick, onColourToggle, onColourChange, onUpload, onLabelToggle, onRemoveLogoClick }) {

  const colourFields = [
    { attribute: 'primaryBgColour', label: 'Header and background color' },
    { attribute: 'buttonBgColour', label: 'Button background colour' },
    { attribute: 'buttonTextColour', label: 'Button text colour' },
    { attribute: 'textLinkColour', label: 'Text link colour' },
  ]
  return (
    <React.Fragment>
      {
        colourFields.map(({ attribute, label }) => {
          const handleBrandingColourClick = () => onColourToggle(attribute);
          const handleBrandingColourChange = ({ colour }) => { onColourChange({ colour: colour, attributeName: attribute }) }
          return (
            <div key={`colourField--${attribute}`} className='field-stack mw-300'>
              <label className='field__label tw-font-medium'>{label}</label>
              <ColourInput
                name={`branding[${attribute}]`}
                isOpen={branding[attribute].toggled}
                onChange={handleBrandingColourChange}
                onClick={handleBrandingColourClick}
                colour={branding[attribute].value}
              />
            </div>
          )
        })
      }
      <div className='field-stack mw-300'>
        <label className='field__label tw-font-medium'>Logo</label>
        <div>
          {
            logoSrc ? (
              <React.Fragment>
                <div className='b-standard py-0 px-18 m-b-8' style={{ backgroundColor: branding.primaryBgColour.value }}>
                  <img className='image--bound-scaling' style={{maxHeight: '126px'}} src={logoSrc} alt='Logo used for company branding' />
                </div>
                <OutlinedButton size='sm' color='red' onClick={onRemoveLogoClick}>
                  <TrashIcon className='m-r-4' width={20} height={20} />
                  <span>Delete logo</span>
                </OutlinedButton>
              </React.Fragment>
            ) : (
              <Uploader
                mode='solo'
                fieldInvalid={invalidLogo}
                fileTypes={['png', 'jpg']}
                externalInputProps={{name: 'branding[logo]'}}
                showIcon={false}
                uploadsCount={ 0 }
                onError={() => {}}
                onCancel={() => {}}
                onUpload={onUpload}
              />
            )
          }
          {invalidLogo && <div className='attachments__uploader-error-message tw-text-red-800'>A logo must be included</div>}
        </div>
      </div>
      <div className='field-stack mw-300'>
        <label className='field__label tw-font-medium'>
          Powered by HandsHQ
          <span className={'hhq-checkbox__tooltip hhq-checkbox__tooltip--left'}>
            <Tooltip placement='top' trigger='hover' tooltip='This is displayed at the bottom of emails and download pages'>
              <CircleQuestion />
            </Tooltip>
          </span>
        </label>
        <CheckboxField
          label='Show Powered by HandsHQ'
          labelProps={{ className: 'hhq-checkbox__label--no-disabled-state' }}
          name='branding[handshqLabel]'
          value='true'
          disabled={!isPoweredByEditable}
          checked={branding.handshqLabel || !isPoweredByEditable}
          onChange={onLabelToggle}
        />
        {
          !isPoweredByEditable &&
          <div className='m-l-32'>
            <div className='m-t-8 tw-text-s tw-font-normal tw-tracking-wide'>This can be removed by upgrading your plan. <a className='app-link tw-font-medium tw-text-blue-500 hover:tw-text-blue-300 va-baseline' target='blank' href={removeBrandingHref}>Contact support</a> to find out more.</div>
          </div>
        }
      </div>
      <FilledButton className='tw-w-max m-b-12' color='blue' disabled={disabled || invalidLogo} onClick={onSaveClick}>
        Save changes
      </FilledButton>
      <button className={'app-link app-link--left-aligned tw-text-s tw-font-medium tw-tracking-wide tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300'} onClick={resetDefaults}>Reset to HandsHQ branding</button>
    </React.Fragment>
  )
}

Form.propTypes = {
  branding: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  logoSrc: PropTypes.string,
  onSaveClick: PropTypes.func.isRequired,
  onColourToggle: PropTypes.func.isRequired,
  onColourChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onLabelToggle: PropTypes.func.isRequired,
  onRemoveLogoClick: PropTypes.func.isRequired
}

Form.defaultProps = {
  branding: {
    "primaryBgColour": { value: "#FFFFFF", toggled: false },
    "buttonBgColour": { value: "#FFFFFF", toggled: false },
    "buttonTextColour": { value: "#FFFFFF", toggled: false },
    "textLinkColour": { value: "#FFFFFF", toggled: false },
    "handshqLabel": true
  },
  disabled: false,
  onSaveClick: () => {},
  onColourToggle: () => {},
  onColourChange: () => {},
  onUpload: () => {},
  onLabelToggle: () => {},
  onRemoveLogoClick: () => {}
}
