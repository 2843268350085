import React from 'react';
import PropTypes from 'prop-types';

import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ImagesIcon from '-!svg-react-loader?name=ImagesIcon!icons/ic-images.svg';

export default function AttachmentsButton({ onClick }) {
  return (
    <OutlinedButton size='sm' color='grey' className='attachments__uploader-init' onClick={onClick}>
      <ImagesIcon className='m-r-4' height={12} width={14} />
      <span>Add images</span>
    </OutlinedButton>
  )
}

AttachmentsButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
