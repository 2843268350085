import React, { useEffect, useMemo, useState } from 'react';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';
import ModalBox from 'components/application/ModalBox';
import CardWrapper from 'components/procore/fileDestination/components/cards/CardWrapper';
import ProcoreLogo from '-!svg-react-loader?name=ProcoreLogo!icons/procore-logo-white.svg';
import DisabledProcoreLogo from '-!svg-react-loader?name=ProcoreLogo!icons/procore-logo-disabled.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import axios from 'axios';
import moment from 'moment';
import { sendAnalytics } from 'components/helpers/analytics';
import setupPolling from 'utilities/setupPolling';

const IntegrationButton = ({href, method, rel, text, onClick}) => {
  return (
    <a className={`tw-apply-filled-button tw-apply-filled-button--on-light tw-apply-filled-button--md tw-apply-filled-button--procore`} href={href} data-method={method} rel={rel} onClick={onClick}>
      <ProcoreLogo className='m-r-18' />
      {text}
    </a>
  )
}

const noProjectLinkModalText = (pluralRamsName) => `No ${pluralRamsName} on HandsHQ are currently linked to Procore.`
const singularProjectLinkModalText = (singularRamsName) => `${singularRamsName} on HandsHQ is currently linked to Procore, and this link will be removed. This cannot be undone.`
const pluralProjectLinkModalText = (pluralRamsName) => `${pluralRamsName} on HandsHQ are currently linked to Procore, and these links will be removed. This cannot be undone.`

export default function IntegratedUserCard({ pluralRamsName, singularRamsName, currentUser, procoreSession, noOfLinkedProjects, missingAccountRequirement, accountIntegration, procoreIdentity, accountIntegratedBy }) {
  const [user, setUser] = useState({ loaded: false, data: null, errored: false, error: null })
  const [modalOpen, setModalOpen] = useState(false)
  const [toasts, , , , addInedibleToast] = useToasts();

  const moreThanOneProcoreProjectLink = noOfLinkedProjects > 1
  const userLookupErrored = user.loaded && user.errored
  const accountIntegrationAndUserIntegration = accountIntegration && user.data
  const accountIntegrationNoUserIntegration = accountIntegration && !user.data && !user.errored
  const userIntegrationNoAccountIntegration = !accountIntegration && user.data && !user.errored
  const noAccountIntegrationWithMissingRequirement = !accountIntegration && !procoreIdentity && missingAccountRequirement && procoreSession
  const noAccountIntegrationNoMissingRequirement = !accountIntegration && !missingAccountRequirement

  const userPolling = useMemo(() => {
    return setupPolling({
      requestFn: () => axios.get('/procore/user'),
      testFn: (response) => { return ['completed', 'failed'].includes(response.data.meta.request_status) },
      intervalTime: 1000
    })
  }, [])

  const fetchUserInfo = () => {
    userPolling
      .startPolling()
      .then((response) => {
        if (response.data.meta.request_status === 'completed') {
          setUser({ loaded: true, data: response.data.data })
        } else {
          handlePollingFailure(response.data.meta.error_reason === 'not_found' ? 'permissions' : 'server')
        }
      })
      .catch(() => {
        handlePollingFailure('server')
      })
  }

  const handlePollingFailure = (error) => {
    setUser({ error, errored: true, loaded: true });
  }

  useEffect(() => {
    procoreIdentity ? fetchUserInfo() : setUser({ loaded: true, data: null })
  }, [])

  useEffect(() => {
    return () => { userPolling.endPolling() }
  }, [])

  const unlinkProcoreAccount = () => {
    axios.delete(`/integrations/external_identities/${procoreIdentity.id}`)
      .then(() => { setUser({loaded: true, data: null}) })
      .catch(addInedibleToast)
  }

  const handleUnlink = () => {
    unlinkProcoreAccount()
    setModalOpen(false)
  }

  const button = (text) => {
    if (procoreSession) {
      return <IntegrationButton href='/integrations/iframe_journeys/new' text={text} onClick={() => sendAnalytics('Integrate with Procore clicked from within embedded app', {currentUser: currentUser})}/>
    } else {
      return <IntegrationButton href='/users/auth/procore' method='post' rel='no-follow' text={text} onClick={() => sendAnalytics('Integrate with Procore clicked from outside embedded app', {currentUser: currentUser})}/>
    }
  }

  const missingAccountRequirementText = () => {
    switch(missingAccountRequirement){
      case 'approvals_feature_required':
        return 'your company does not have the Approvals feature enabled.'
      case 'procore_integration_feature_required':
        return 'your company does not have the Procore feature enabled.'
      case 'admin_access_required':
        return 'an admin user needs to integrate with Procore to set this feature up for your account.'
    }
  }

  const userCard = (
    <CardWrapper isInactive={false}>
      <div className='tw-text-grey-900 tw-font-medium m-b-4'>{user.loaded && user.data && user.data.attributes.login}</div>
      <p className='tw-text-s tw-font-normal tw-tracking-wide m-b-16'>{`Your account was connected on ${procoreIdentity && moment.parseZone(procoreIdentity.created_at).format('DD MMM YYYY')}`}</p>
      <OutlinedButton size='sm' color='red' onClick={() => { setModalOpen(true) }}>Unlink</OutlinedButton>
    </CardWrapper>
  )

  function procoreIdentityShow() {
    if (accountIntegrationAndUserIntegration) {
      return userCard
    } else if (userLookupErrored) {
      if (user.error === 'permissions') {
        if (accountIntegration){
          return <CardWrapper isInactive={false}>
            <div className='tw-text-red-600 tw-font-medium m-b-4'>Your account is not connected</div>
            <p className='tw-text-s tw-font-normal tw-tracking-wide m-b-16'>This may be because the feature was turned off. <a className='app-link va-bottom tw-text-s tw-font-medium tw-tracking-wide' id='intercom-launcher'>Speak to your HandsHQ account manager</a> to turn the feature back on</p>
            <OutlinedButton size='sm' color='red' onClick={() => { setModalOpen(true) }}>Disconnect</OutlinedButton>
          </CardWrapper>
        } else {
          return (
            <>
              <p>Company level integration has been removed</p>
              <CardWrapper isInactive={false}>
                <div className='tw-text-red-600 tw-font-medium m-b-4'>Your account is not connected</div>
                <p className='tw-text-s tw-font-normal tw-tracking-wide m-b-16'>This may be because the feature was turned off. <a className='app-link va-bottom tw-text-s tw-font-medium tw-tracking-wide' id='intercom-launcher'>Speak to your HandsHQ account manager</a> to turn the feature back on</p>
                <OutlinedButton size='sm' color='red' onClick={() => { setModalOpen(true) }}>Disconnect</OutlinedButton>
              </CardWrapper>
            </>
          )
        }

      } else if (user.error === 'server') {
        return <CardWrapper isInactive={false}>
          <div className='tw-text-red-600 tw-font-medium m-b-4'>Sorry we couldn't find your details</div>
          <p className='tw-text-s tw-font-normal tw-tracking-wide m-b-16'>This may be because access to your Procore account was revoked for HandsHQ</p>
          <OutlinedButton size='sm' color='red' onClick={() => { setModalOpen(true) }}>Disconnect</OutlinedButton>
        </CardWrapper>
      }
    } else if (accountIntegrationNoUserIntegration) {
      return button('Connect my account')
    } else if (userIntegrationNoAccountIntegration) {
      return (
        <>
          <p className='m-b-16'>Company level integration has been removed but your user account is still connected. This means that you can continue to work as per usual, but there might need to be an administrator set up for any new integrations to be put into place.</p>
          { userCard }
        </>
      )
    } else if (noAccountIntegrationWithMissingRequirement) {
      return (
        <>
          <p className='m-b-16'>{`You can't integrate because ${missingAccountRequirementText()}`}</p>
          <div>
            <p className='m-b-16'></p>
            <button disabled={true} className='tw-apply-filled-button tw-apply-filled-button--on-light tw-apply-filled-button--md tw-apply-filled-button--procore'>
              <DisabledProcoreLogo className='m-r-18' />
              Integrate with Procore
            </button>
          </div>
        </>
      )
    }
  }

  const modalFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={() => { setModalOpen(false) }}>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleUnlink}>
        Unlink Procore
      </FilledButton>
    </div>
  )

  return (
    <>
      <h3 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto m-t-0 m-b-24'>Integrations</h3>
      <h3 className='tw-text-m tw-font-semibold tw-tracking-auto m-t-0 m-b-8'>Procore</h3>
      { accountIntegration && <p className='m-b-16'>{`Company level integration set up by ${accountIntegratedBy} on ${moment.parseZone(accountIntegration.created_at).format('DD MMM YYYY')}`}</p> }
      { (!user.data && noAccountIntegrationNoMissingRequirement) && <p className='m-b-16'>You'll need to have a Procore account with Admin permissions to set up this integration</p> }
      { (!user.data && noAccountIntegrationNoMissingRequirement) && button('Integrate with Procore') }
      { user.loaded && procoreIdentityShow() }
      <ToastRack toasts={toasts}/>
      <ModalBox
        mode={'flexible'}
        usingSlimDimensions={true}
        isOpen={modalOpen}
        customFooter={modalFooter}
        onClose={() => {setModalOpen(false)}}
      >
        <div className='p-32'>
          <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-t-0 m-b-16'>Are you sure you want to unlink Procore?</h2>
          <p className='m-b-0'>
            {
              noOfLinkedProjects > 0 ?
                moreThanOneProcoreProjectLink ? `${noOfLinkedProjects} ${pluralProjectLinkModalText(pluralRamsName)}`
                : `1 ${singularProjectLinkModalText(singularRamsName)}`
              : noProjectLinkModalText(pluralRamsName)
            }
          </p>
        </div>
      </ModalBox>
    </>
  )
}
