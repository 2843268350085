import React from 'react';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function MultiCategoriesSingleDivisionEditable ({categories, riskAssessment}) {
  return (
    <InfoBanner
      title={'Editing this risk assessment will affect other categories'}
    >
      {riskAssessment.attributes.name} is a risk assessment shared with:
      <ExpandableList
        collection={categories}
        renderItem={(category) => <li key={`li--${category.id}`}>{category.attributes.name}</li>}
      />
    </InfoBanner>
  )
}

MultiCategoriesSingleDivisionEditable.propTypes = {
  categories: PropTypes.arrayOf(resourceShape('trade')).isRequired,
  riskAssessment: resourceShape('masterActivity').isRequired,
}
