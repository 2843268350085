var React = require('react');

function ProgressIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M9 12C7.33333 12 5.90625 11.4097 4.71875 10.2292C3.53125 9.04861 2.95833 7.59722 3 5.875L1.8125 7.0625L0.75 6L3.75 3L6.75 6L5.6875 7.0625L4.5 5.875C4.47222 7.18056 4.90278 8.27778 5.79167 9.16667C6.68056 10.0556 7.75 10.5 9 10.5C9.30556 10.5 9.59722 10.4722 9.875 10.4167C10.1528 10.3611 10.4236 10.2778 10.6875 10.1667L11.7917 11.2708C11.375 11.4931 10.934 11.6701 10.4687 11.8021C10.0035 11.934 9.51389 12 9 12ZM14.25 9L11.25 6L12.3125 4.9375L13.5 6.125C13.5278 4.81944 13.0972 3.72222 12.2083 2.83333C11.3194 1.94444 10.25 1.5 9 1.5C8.70833 1.5 8.42014 1.52778 8.13542 1.58333C7.85069 1.63889 7.57639 1.72222 7.3125 1.83333L6.20833 0.729167C6.625 0.506944 7.06944 0.329861 7.54167 0.197917C8.01389 0.0659722 8.5 0 9 0C10.6806 0 12.1111 0.590278 13.2917 1.77083C14.4722 2.95139 15.0417 4.40278 15 6.125L16.1875 4.9375L17.25 6L14.25 9Z","fill":"black"},React.createElement("animateTransform",{"attributeName":"transform","type":"rotate","from":"0 9 6","to":"360 9 6","dur":"2s","repeatCount":"indefinite"})));
}

ProgressIcon.defaultProps = {"className":"spinner-icon","width":"18","height":"12","viewBox":"0 0 18 12","fill":"none","id":"spinner"};

module.exports = ProgressIcon;

ProgressIcon.default = ProgressIcon;
