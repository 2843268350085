import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import useJobProcessor from 'components/hooks/useJobProcessor';
import Portal from 'components/application/Portal'
import ProjectDuplicationRow from 'components/projectDuplication/ProjectDuplicationRow'
import ProjectDuplicationSuccessRow from 'components/projectDuplication/ProjectDuplicationSuccessRow'
import ActionsPill from 'components/application/ActionsPill';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ProjectError from '-!svg-react-loader?name=ProjectError!icons/ic-project-error.svg';

export default function ProjectBackgroundDuplicationManager(props) {
  const {
    originalProjectId,
    jobId,
    initialJobStatus,
    name,
    ramsSingularName,
    projectTableWidth,
    status,
    statusDisplayText,
    includeClient,
    client,
    reference,
    modified,
    riskRegister,
    activitiesIndicatorCssClass,
    activitiesTextCssClass,
    activitiesDisplayText,
    approval,
    approvalStateCssClass,
    approvalDisplayText,
  } = props

  const [jobStatus, setUrl, resourceId] = useJobProcessor()
  const [failedRowDismissed, setFailedRowDismissed] = useState(false)

  useEffect(() => {
    if (jobId && initialJobStatus !== 'failed') {
      setUrl(`/jobs/status/${jobId}`)
    }
  }, [])

  useEffect(() => {
    if (jobStatus === 'completed') {
      resetDuplicationRequest()
    }
  }, [jobStatus])

  const resetDuplicationRequest = () => {
    axios.delete((`/projects/${originalProjectId}/duplication_process`))
  }

  const processingActionsPill = () => {
    return (
      <div className='l-if m-l-12'>
        <ActionsPill
          color='grey'
          displayText={`Creating ${ramsSingularName}`}
          tooltipText="The project is being created and can't be edited until the process has finished"
          icon={<LoadingIcon height={12} width={12} />}
          iconStyling='tw-apply-loading-spinner--grey-heavy'
        />
      </div>
    )
  }

  const failedActionsPill = () => {
    return (
      <div className='l-if m-l-12'>
        <ActionsPill
          color='red'
          displayText={`${voca.titleCase(ramsSingularName)} not created`}
          tooltipText="Please try again or contact support for help"
          icon={<ProjectError height={12} width={12} />}
        />
      </div>
    )
  }

  const handleFailedDuplication = () => {
    resetDuplicationRequest()
    setFailedRowDismissed(true)
  }

  const failedActions = () => <a onClick={handleFailedDuplication} className='app-link tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 tw-text-center tw-text-s tw-font-medium tw-tracking-wide'>Dismiss</a>

  const displayStatus = () => {
    if (jobStatus === 'completed') {
      return 'completed'
    } else if (failedRowDismissed) {
      return 'dismissed'
    } else if (initialJobStatus === 'failed' || jobStatus === 'failed') {
      return 'failed'
    } else if (initialJobStatus === 'working' || jobStatus === 'working') {
      return 'working'
    }
  }

  return (
    <>
     {
       {
         'working': (
            <Portal containerSelector='#project-duplication'>
              <ProjectDuplicationRow
                name={name}
                actionsPill={processingActionsPill()}
                projectTableWidth={projectTableWidth}
              />
            </Portal>
         ),
         'failed': (
            <Portal containerSelector='#project-duplication'>
              <ProjectDuplicationRow
                name={name}
                actionsPill={failedActionsPill()}
                actions={failedActions()}
                projectTableWidth={projectTableWidth}
              />
            </Portal>
         ),
         'completed': (
            <Portal containerSelector='#project-duplication'>
              <ProjectDuplicationSuccessRow
                name={name}
                status={status}
                statusDisplayText={statusDisplayText}
                includeClient={includeClient}
                client={client}
                reference={reference}
                modified={modified}
                riskRegister={riskRegister}
                activitiesIndicatorCssClass={activitiesIndicatorCssClass}
                activitiesTextCssClass={activitiesTextCssClass}
                activitiesDisplayText={activitiesDisplayText}
                approval={approval}
                approvalStateCssClass={approvalStateCssClass}
                approvalDisplayText={approvalDisplayText}
                resourceId={resourceId}
              />
            </Portal>
         ),
         'dismissed': null
       }[displayStatus()]
     }
    </>
  )
}

ProjectBackgroundDuplicationManager.defaultProps = {
  ramsSingularName: 'project'
};

ProjectBackgroundDuplicationManager.propTypes = {
  jobId: PropTypes.string,
  ramsSingularName: PropTypes.string,
  projectTableWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  client: PropTypes.string,
  reference: PropTypes.string,
  modified: PropTypes.string,
  activitiesStatus: PropTypes.string,
  activitiesIndicatorCssClass: PropTypes.string,
  activitiesTextCssClass: PropTypes.string,
  activitiesDisplayText: PropTypes.string,
  approvalStateCssClass: PropTypes.string,
  approvalDisplayText: PropTypes.string
}
