import React, { useState, useEffect, useReducer, Fragment } from 'react';
import moment from 'moment';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import { TrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { TrainingRegisterResourceManagementContext } from 'components/contexts/TrainingRegisterResourceManagementContext';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import useSidePanel from 'components/hooks/useSidePanel';
import useModal from 'components/hooks/useModal';
import usePersonnelForm from 'components/hooks/usePersonnelForm';
import useTrackedPersonnel from 'components/hooks/useTrackedPersonnel';

import PersonnelPanel from 'components/personnel/PersonnelPanel';
import TrackedPersonnelUsageBanner from 'components/personnel/TrackedPersonnelUsageBanner';
import DestroyModal from 'components/application/DestroyModal';
import TrainingDestroyModal from 'components/training/TrainingDestroyModal';
import CourseDestroyModal from 'components/training/CourseDestroyModal';
import BookingDestroyModal from 'components/training/BookingDestroyModal';
import BookingELearningModal from 'components/training/BookingELearningModal';
import BookingELearningNoCreditsModal from 'components/training/BookingELearningNoCreditsModal';
import BookingReminderModal from 'components/training/BookingReminderModal';
import ArchiveModal from 'components/archive/ArchiveModal';
import UnarchiveBlockedModal from 'components/archive/UnarchiveBlockedModal';
import UndoableToast from 'components/application/UndoableToast';
import PersonnelSidePanel from 'components/personnel/PersonnelSidePanel';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import SuccessToast from 'components/application/SuccessToast';
import TrainingSidePanel from 'components/training/TrainingSidePanel';
import TrainingRequirementsSidePanel from 'components/training/TrainingRequirementsSidePanel';
import BookingSidePanel from 'components/training/BookingSidePanel';
import TrainingRecords from 'components/training/TrainingRecords';
import Portal from 'components/application/Portal';

import { personDisplayName } from 'components/helpers/users';
import { personnelParams, sortRolesByPrimaryAndPosition, sortRoleIdsByPosition } from 'components/helpers/personnel';
import { sendAnalytics } from 'components/helpers/analytics';

import { personnelShowPageReducer, personnelShowPageInitialStore } from 'reducers/personnelShowPageReducer';

const defaultSubcontractor = { subcontractorName: '' }

const defaultTraining = {
  id: null,
  personnelId: '',
  courseId: '',
  course: null,
  startDate: null,
  expiryDate: null,
  notes: '',
  attachmentIds: []
}

const defaultTrainingRequirement = {
  userCourseId: null,
  isCourseRequired: true,
  isRecordingTraining: false
}

const defaultBooking = {
  date: '',
  courseId: '',
  notes: '',
  autoEnrolled: null
};

const defaultBookingELearningModal = { isOpen: false, course: null, eLearningCourse: null };
const defaultBookingELearningNoCreditsModal = { isOpen: false };

export default function PersonnelShowPage(props) {
  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();
  const trainingRegisterContextValues = (({ hasPersonnelEditableAccess, hasRoleEditableAccess, hasTeamViewableAccess, hasBookingEditableAccess, personnelId, isAutoEnrolmentIncludingMissingTraining }) => {
    return { hasPersonnelEditableAccess, hasRoleEditableAccess, hasTeamViewableAccess, hasBookingEditableAccess, personnelId, isAutoEnrolmentIncludingMissingTraining }
  })(props)

  const [store, dispatch] = useReducer(personnelShowPageReducer, personnelShowPageInitialStore);
  const [coursesMeta, setCoursesMeta] = useState({ loaded: false, info: {} });
  const [trainingEvidenceProcessed, setTrainingEvidenceProcessed] = useState(false);

  // application
  const [currentSubcontractor, setCurrentSubcontractor] = useForm(defaultSubcontractor);
  const [isSubcontractor, setIsSubcontractor] = useState(false);
  const [availableFields, setAvailableFields] = useState({ loaded: false, collection: [] });
  const [availableFieldOptions, setAvailableFieldOptions] = useState({ loaded: false, collection: [] });
  const [newCompanyRoles, setNewCompanyRoles] = useForm([])
  const [currentTraining, setCurrentTraining, handleTrainingInputChange, , , handleTrainingDateChange] = useForm(defaultTraining);
  const [currentTrainingRequirement, setCurrentTrainingRequirement, handleTrainingRequirementInputChange] = useForm(defaultTrainingRequirement);
  const [currentBooking, setCurrentBooking, handleBookingInputChange, , , handleBookingDateChange] = useForm(defaultBooking);
  const [formPersonnel, setFormPersonnel, handlePersonnelInputChange, handlePersonnelOptionChange, handlePersonnelDateChange, handleFieldValueInputChange, handleDateFieldChange, handleFieldOptionChange] = usePersonnelForm({})
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [eLearningAllowance, setELearningAllowance] = useState({ loaded: false, currentCredits: null, hasError: false });
  const [trackedPersonnelUsage, refreshTrackedPersonnelUsage, hasReachedTrackedPersonnelUsageThreshold, hasReachedTrackedPersonnelLimit] = useTrackedPersonnel();

  const courseAttributes = (currentTraining.course && currentTraining.course.attributes) || {};

  const handleTrainingCalendarClose = (calendarName) => {
    if (currentTraining.startDate != null || currentTraining.expiryDate != null) {
      const newCurrentTraining = { ...currentTraining };

      if (courseAttributes.expires) {
        const shouldAutoAssignExpiryDate = (calendarName === 'startDate' && currentTraining.expiryDate === null);
        const shouldAutoAssignStartDate = (calendarName === 'expiryDate' && currentTraining.startDate === null);

        if (shouldAutoAssignExpiryDate) {
          const newExpiryDate = moment.parseZone(currentTraining.startDate).add(courseAttributes.renewalFrequency, courseAttributes.renewalInterval).toDate();
          newCurrentTraining.expiryDate = newExpiryDate

        } else if (shouldAutoAssignStartDate) {
          const newStartDate = moment.parseZone(currentTraining.expiryDate).subtract(courseAttributes.renewalFrequency, courseAttributes.renewalInterval).toDate();
          if (newStartDate <= moment.now()) {
            newCurrentTraining.startDate = newStartDate
          }
        }
      };

      setCurrentTraining(newCurrentTraining)
    }
  };

  // ui
  const [trainingSidePanelIsOpen, setTrainingSidePanelIsOpen, openTrainingSidePanel, closeTrainingSidePanel, _resetTrainingSidePanelContext, trainingSidePanelContext, setTrainingSidePanelContext] = useSidePanel(false, 'new');
  const [personnelSidePanelIsOpen, _setPersonnelSidePanelIsOpen, openPersonnelSidePanel, closePersonnelSidePanel, _resetPersonnelSidePanelContext, _personnelSidePanelContext, setPersonnelSidePanelContext] = useSidePanel(false, 'new');
  const [trainingRequirementsSidePanelIsOpen, setTrainingRequirementsSidePanelIsOpen, openTrainingRequirementsSidePanel, closeTrainingRequirementsSidePanel, , trainingRequirementsSidePanelContext] = useSidePanel(false, 'new');
  const [bookingSidePanelIsOpen, _setBookingSidePanelIsOpen, openBookingSidePanel, closeBookingSidePanel, resetBookingSidePanelContext, bookingSidePanelContext, setBookingSidePanelContext] = useSidePanel(false, 'new');
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);
  const [trainingDestroyModalIsOpen, setTrainingDestroyModalIsOpen] = useState(false);
  const [courseDestroyModalIsOpen, setCourseDestroyModalIsOpen] = useState(false);
  const [bookingDestroyModalIsOpen, , openBookingDestroyModal, closeBookingDestroyModal] = useModal(false);
  const [bookingELearningModal, setBookingELearningModal] = useState(defaultBookingELearningModal);
  const [bookingELearningNoCreditsModal, setBookingELearningNoCreditsModal] = useState(defaultBookingELearningNoCreditsModal);
  const [bookingReminderModalIsOpen, , openBookingReminderModal, closeBookingReminderModal] = useModal(false);
  const [archiveModalIsOpen, , openArchiveModal, closeArchiveModal] = useModal(false);
  const [unarchiveBlockedModalIsOpen, , openUnarchiveBlockedModal, closeUnarchiveBlockedModal] = useModal(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null)


  // use effects

  useEffect(() => {
    if (currentActor.isAllowedFeature('e_learning')) { fetchELearningAllowance() }
  }, [])

  useEffect(() => {
    fetchAssignableCompanies()
    fetchAssignableCompanyRoles()
    fetchPersonnelTraining()
    fetchPersonnel()
    fetchPersonnelEvents()
    fetchCoursesMeta()
    fetchFields()
  }, [])

  useEffect(() => {
    setTrainingEvidenceProcessed(false)
  }, [JSON.stringify(store.domain.attachments)])

  // data management

  const formatPersonnelData = (data, fieldValues) => {
    if (!data) return
    const attributes = data.attributes;
    const lineManagerData = data.relationships.lineManager.data;
    const companyRolesData = data.relationships.companyRoles.data;
    const teamsData = data.relationships.teams.data;

    const formattedFieldValues = Object.values(fieldValues).reduce((list, value) => {
      list[value.relationships.fieldAttribute.data.id] = {
        id: value.id,
        value: value.attributes.valueType == 'date' ? moment.parseZone(value.attributes.value).toDate() : value.attributes.value,
        valueType: value.attributes.valueType,
        fieldAttributeId: value.relationships.fieldAttribute.data.id,
        fieldOptionId: value.relationships.fieldOption.data && value.relationships.fieldOption.data.id,
        personnelId: value.relationships.entity.data.id
      }

      return list
    }, {})

    return {
      id: data.id,
      firstName: (attributes.firstName || ''),
      lastName: (attributes.lastName || ''),
      externalId: (attributes.externalId || ''),
      email: (attributes.email || ''),
      lineManagerId: (lineManagerData ? lineManagerData.id : ''),
      fieldValues: formattedFieldValues,
      company: {
        companyId: (attributes.companyId || ''),
        subcontractorId: (attributes.subcontractorId || '')
      },
      primaryCompanyRoleId: '',
      companyRoleIds: (companyRolesData ? companyRolesData.map(companyRole => companyRole.id) : []),
      teamsIds: (teamsData ? teamsData.map(team => team.id) : []),
      archivedAt: attributes.archivedAt,
      isParticipatingInOngoingProjects: attributes.isParticipatingInOngoingProjects,
    }
  };

  const handleNewCompanyRoleAdd = (newRole) => {
    setNewCompanyRoles(newCompanyRoles.concat([newRole]))
  };

  const assignResources = ({ data, included }) => {
    const fieldValues = included.reduce((object, resource) => {
      if (resource.type == 'fieldValue') {
        object[resource.id] = resource
      }

      return object
    }, {})

    const personnelData = formatPersonnelData(data, fieldValues);

    const primaryRole = included.find(({ type, attributes }) => type === 'userCompanyRole' && attributes.primary);
    setFormPersonnel({ ...personnelData, primaryCompanyRoleId: primaryRole ? primaryRole.relationships.companyRole.data.id : '' })

    dispatch({
      type: 'LOAD_PERSONNEL',
      payload: {
        member: data,
        included: {
          companyRoles: included.filter(resource => resource.type === 'companyRole'),
          courseCompanyRoles: included.filter(resource => resource.type === 'courseCompanyRole'),
          userCompanyRoles: included.filter(resource => resource.type === 'userCompanyRole'),
          userCourses: included.filter(resource => resource.type === 'userCourse'),
          autoEnrolmentConfigurations: included.filter(resource => resource.type === 'autoEnrolmentConfiguration'),
          autoEnrolmentExclusions: included.filter(resource => resource.type === 'autoEnrolmentExclusion'),
          lineManagers: included.filter(resource => resource.type === 'lineManager'),
          companies: included.filter(resource => resource.type === 'company'),
          subcontractors: included.filter(resource => resource.type === 'subcontractor'),
          courses: included.filter(resource => resource.type === 'course'),
          eLearningCourses: included.filter(resource => resource.type === 'eLearningCourse'),
          bookings: included.filter(resource => resource.type === 'booking'),
          teams: included.filter(resource => resource.type === 'team'),
          registrations: included.filter(resource => resource.type === 'registration'),
          fieldValues: included.filter(resource => resource.type === 'fieldValue'),
          bookers: included.filter(resource => resource.type === 'simpleUser')
        }
      }
    })
  };

  const refreshResources = () => {
    fetchPersonnelTraining()
    fetchPersonnelEvents()
  };

  const handleAssignableRoleAdd = (role) => {
    dispatch({ type: 'UPDATE_ASSIGNABLE_ROLES', payload: { role: role } })
  };

  // computed
  const hasELearningBookings = currentActor.isAllowedFeature('e_learning') && store.domain.personnel && store.domain.personnel.attributes.eLearningBookingCount > 0;
  const currentBookings = Object.values(store.domain.bookings);
  const allTraining = store.domain.training.allDataIds.map(trainingId => store.domain.training.data[trainingId]);
  const displayPersonnel = formatPersonnelData(store.domain.personnel, store.domain.fieldValues);
  const isArchived = displayPersonnel && !!displayPersonnel.archivedAt;
  const isUnarchivingBlocked = isArchived && hasReachedTrackedPersonnelLimit && (allTraining.length > 0);
  const isCurrentPersonnelTracked = !isArchived && (currentBookings.length > 0 || allTraining.length > 0);
  const selectedBooking = store.domain.bookings[selectedBookingId];
  const selectedBooker = selectedBooking && selectedBooking.relationships.booker.data ? store.domain.bookers[selectedBooking.relationships.booker.data.id] : null;
  const selectedBookingCourse = selectedBooking ? store.domain.courses[selectedBooking.relationships.course.data.id] : store.domain.courses[currentBooking.courseId];
  const selectedBookingAutoEnrolmentConfigurationId = selectedBookingCourse && selectedBookingCourse.relationships?.autoEnrolmentConfiguration?.data?.id
  const selectedBookingAutoEnrolmentExclusion = selectedBookingAutoEnrolmentConfigurationId && Object.values(store.domain.autoEnrolmentExclusions).find(exclusion => exclusion.relationships.autoEnrolmentConfiguration.data.id === selectedBookingAutoEnrolmentConfigurationId)

  const fetchPersonnel = () => {
    axios
      .get(`/personnel/${props.personnelId}?with_course_connections=true`)
      .then(response => assignResources(response.data))
      .catch(breadBoard.addInedibleToast)
  };

  const fetchAssignableCompanies = () => {
    axios
      .get('/assignable_companies')
      .then(response => dispatch({ type: 'LOAD_ASSIGNABLE_COMPANIES', payload: { collection: response.data.data } }))
      .catch(breadBoard.addInedibleToast)
  };

  const fetchAssignableCompanyRoles = () => {
    axios
      .get('/assignable_company_roles')
      .then(response => dispatch({ type: 'LOAD_ASSIGNABLE_ROLES', payload: { collection: response.data.data } }))
      .catch(breadBoard.addInedibleToast)
  };

  const fetchPersonnelTraining = () => {
    axios
      .get(`/personnel/${props.personnelId}/trainings`)
      .then((response) => {
        dispatch({
          type: 'LOAD_TRAINING',
          payload: {
            collection: response.data.data,
            included: {
              courses: response.data.included.filter(obj => obj.type === 'course'),
              attachments: response.data.included.filter(obj => obj.type === 'attachment'),
              coverImages: response.data.included.filter(obj => obj.type === 'coverImage'),
              eLearningCourses: response.data.included.filter(obj => obj.type === 'eLearningCourse'),
              autoEnrolmentConfigurations: response.data.included.filter(resource => resource.type === 'autoEnrolmentConfiguration')
            }
          }
        })
      })
      .catch(breadBoard.addInedibleToast)
  };

  const fetchCoursesMeta = () => {
    axios
      .get('/courses/?meta_only=true')
      .then((response) => {
        setCoursesMeta({
          loaded: true,
          info: response.data.meta
        })
      })
      .catch(breadBoard.addInedibleToast)
  };

  const fetchFields = () => {
    axios
      .get('/custom_fields/available_field_attributes')
      .then((response) => {
        setAvailableFieldOptions({ loaded: true, collection: response.data.included.filter(object => object.type === 'fieldOption') })
        setAvailableFields({ loaded: true, collection: response.data.data })
      })
      .catch(breadBoard.addInedibleToast)
  };

  const fetchPersonnelEvents = (page = 1) => {
    axios
      .get(`/personnel/${props.personnelId}/events`, { params: { page: page } })
      .then((response) => {
        dispatch({
          type: 'LOAD_PERSONNEL_EVENTS',
          payload: { collection: response.data.data, meta: response.data.meta }
        })
      })
  };

  const fetchELearningAllowance = () => {
    axios
      .get("/e_learning/allowance")
      .then(response => setELearningAllowance({ ...eLearningAllowance, loaded: true, currentCredits: response.data.data.attributes.currentCredits }))
      .catch(_error => setELearningAllowance({ ...eLearningAllowance, hasError: true }))
  };

  const createTraining = () => {
    axios
      .post('/trainings', {
        training: {
          personnel_id: currentTraining.personnelId,
          course_id: currentTraining.courseId,
          start_date: moment.parseZone(currentTraining.startDate).format('DD/MM/YYYY'),
          expiry_date: currentTraining.expiryDate && moment.parseZone(currentTraining.expiryDate).format('DD/MM/YYYY'),
          notes: currentTraining.notes,
          attachment_ids: currentTraining.attachmentIds
        }
      })
      .then((response) => {
        if (response.data.data.relationships.booking.data) {
          deleteBooking({ bookingId: response.data.data.relationships.booking.data.id, sendEmails: false, isNotified: false })
        }
        refreshResources()
        refreshTrackedPersonnelUsage()
        closeTrainingSidePanel()
      })
      .catch(handleRequestError)
  };

  const createTrainingRequirement = () => {
    const training_requirement_params = {
      personnel_id: currentTraining.personnelId,
      course_id: currentTraining.courseId,
      user_course_attributes: {
        required: currentTrainingRequirement.isCourseRequired
      }
    };
    if (currentTrainingRequirement.isRecordingTraining === true) {
      training_requirement_params.training_attributes = {
        start_date: moment.parseZone(currentTraining.startDate).format('DD/MM/YYYY'),
        expiry_date: currentTraining.expiryDate && moment.parseZone(currentTraining.expiryDate).format('DD/MM/YYYY'),
        notes: currentTraining.notes,
        attachment_ids: currentTraining.attachmentIds
      };
    }

    axios
      .post('/training_requirements', { training_requirement: training_requirement_params })
      .then((response) => {
        dispatch({
          type: 'CREATE_TRAINING_REQUIREMENT',
          payload: {
            userCourse: response.data.included.find(resource => resource.type === 'userCourse'),
            training: response.data.included.find(resource => resource.type === 'training'),
            attachments: response.data.included.filter(obj => obj.type === 'attachment'),
            coverImages: response.data.included.filter(obj => obj.type === 'coverImage'),
            eLearningCourses: response.data.included.filter(resource => resource.type === 'eLearningCourse'),
            course: response.data.included.find(resource => resource.type === 'course'),
            autoEnrolmentConfiguration: response.data.included.find(resource => resource.type === 'autoEnrolmentConfiguration')
          }
        })
        fetchPersonnelEvents()
        refreshTrackedPersonnelUsage()
        closeTrainingRequirementsSidePanel()
      })
      .catch(handleRequestError)
  };

  const createAutoEnrolmentExclusion = ({ personnelId, autoEnrolmentConfigurationId }) => {
    axios
      .post('/auto_enrolment_exclusions', {
        auto_enrolment_exclusion: {
          personnel_id: personnelId,
          auto_enrolment_configuration_id: autoEnrolmentConfigurationId
        }
      })
      .then(response => {
        dispatch({type: 'CREATE_AUTO_ENROLMENT_EXCLUSION', payload: { member: response.data.data }})
      })
      .catch(handleRequestError)
  };

  const deleteAutoEnrolmentExclusion = ({ autoEnrolmentExclusion }) => {
    axios
      .delete(`/auto_enrolment_exclusions/${autoEnrolmentExclusion.id}`)
      .then(_response => {
        dispatch({type: 'DELETE_AUTO_ENROLMENT_EXCLUSION', payload: { member: autoEnrolmentExclusion }})
      })
      .catch(breadBoard.addInedibleToast)
  };

  const updateTraining = () => {
    axios
      .patch(`/trainings/${currentTraining.id}`, {
        training: {
          start_date: moment.parseZone(currentTraining.startDate).format('DD/MM/YYYY'),
          expiry_date: moment.parseZone(currentTraining.expiryDate).format('DD/MM/YYYY'),
          notes: currentTraining.notes,
          attachment_ids: currentTraining.attachmentIds
        }
      })
      .then((_response) => {
        refreshResources()
        closeTrainingSidePanel()
      })
      .catch(handleRequestError)
  };

  const deleteTraining = () => {
    axios
      .delete(`/trainings/${currentTraining.id}`)
      .then(_response => {
        refreshResources()
        refreshTrackedPersonnelUsage()
      })
      .catch(breadBoard.addInedibleToast)
  };

  const createUserCourse = ({ course, params }) => {
    const joinParams = { ...params, personnel_id: displayPersonnel.id, course_id: course.id };

    axios
      .post('/user_courses', { user_course: joinParams })
      .then(response => {
        dispatch({
          type: 'CREATE_USER_COURSE',
          payload: {
            member: response.data.data,
            course: response.data.included.find(resource => resource.type === 'course')
          }
        })
      })
      .catch(breadBoard.addInedibleToast)
  };

  const updateUserCourse = ({ userCourse, params }) => {
    axios
      .patch(`/user_courses/${userCourse.id}`, { user_course: params })
      .then(response => {
        dispatch({
          type: 'UPDATE_USER_COURSE',
          payload: { member: response.data.data }
        })
        fetchPersonnelEvents()
      })
      .catch(breadBoard.addInedibleToast)
  };

  const deleteUserCourse = () => {
    const userCourse = store.domain.userCourses[currentTrainingRequirement.userCourseId];

    axios
      .delete(`/user_courses/${userCourse.id}`)
      .then(_response => {
        dispatch({ type: 'DELETE_USER_COURSE', payload: { member: userCourse } })
        fetchPersonnelEvents()
      })
      .catch(breadBoard.addInedibleToast)
  };

  const createBooking = (courseName) => {
    axios
      .post(`/personnel/${props.personnelId}/bookings`, {
        booking: {
          course_id: currentBooking.courseId,
          date: moment.parseZone(currentBooking.date).format('DD/MM/YYYY'),
          notes: currentBooking.notes
        }
      })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>{formPersonnel.firstName}</span> has been enrolled on the course <span className='tw-font-medium'>{courseName}</span>
              </>
            }
            onBurnToast={breadBoard.onBurnToast}
          />
        )
        fetchPersonnelEvents()
        refreshTrackedPersonnelUsage()
        handleCloseBookingSidePanel()
        const payload = { member: response.data.data, booker: response.data.included.find((i) => i.type == 'simpleUser') };
        dispatch({ type: 'CREATE_BOOKING', payload: payload })
      })
      .catch(handleRequestError)
  };

  const createELearningBooking = ({ course }) => {
    axios
      .post(`/personnel/${props.personnelId}/bookings`, { booking: { course_id: course.id } })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>{formPersonnel.firstName}</span> has been enrolled on the eLearning course <span className='tw-font-medium'>{course.attributes.name}</span>
              </>
            }
            onBurnToast={breadBoard.onBurnToast}
          />
        )
        fetchPersonnelEvents()
        refreshTrackedPersonnelUsage()
        fetchELearningAllowance()
        dispatch({ type: 'CREATE_E_LEARNING_BOOKING', payload: { member: response.data.data } })
      })
      .catch(breadBoard.addInedibleToast)
  };

  const deleteBooking = ({ bookingId, sendEmails = true, isNotified = false }) => {
    const booking = store.domain.bookings[bookingId]
    const course = store.domain.courses[booking.relationships.course.data.id]
    axios
      .delete(`/personnel/${props.personnelId}/bookings/${booking.id}`, { params: { sendEmails: sendEmails } })
      .then(response => {
        if (isNotified) {
          breadBoard.addToast(
            <SuccessToast
              message={
                <>
                  Booking has been removed for <span className='tw-font-medium'>{formPersonnel.firstName}</span> for <span className='tw-font-medium'>{course.attributes.name}</span>
                </>
              }
              onBurnToast={breadBoard.onBurnToast}
            />
          )
        }
        dispatch({
          type: !!course.relationships.eLearningCourse.data ? 'DELETE_E_LEARNING_BOOKING' : 'DELETE_BOOKING',
          payload: { member: booking }
        })

        const includedAutoEnrolmentExclusion = response.data.included && response.data.included.find(resource => resource.type === 'autoEnrolmentExclusion')
        if (!!includedAutoEnrolmentExclusion) {
          dispatch({
            type: 'CREATE_AUTO_ENROLMENT_EXCLUSION',
            payload: { member: includedAutoEnrolmentExclusion }
          })
        }

        fetchPersonnelEvents()
        setCurrentBooking(defaultBooking)
        refreshTrackedPersonnelUsage()
        handleCloseBookingSidePanel()
        if (!!course.relationships.eLearningCourse.data) { fetchELearningAllowance() }
      })
      .catch(_error => breadBoard.addInedibleToast())
  };

  const handlePersonnelUpdateSubmit = () => {
    const originalUserCompanyRoles = store.domain.personnel.relationships.userCompanyRoles.data.map(ucr => store.domain.userCompanyRoles[ucr.id])
    const originalCompanyRoleIds = store.domain.personnel.relationships.companyRoles.data.map(cr => store.domain.companyRoles[cr.id].id);

    const params = personnelParams({
      currentPersonnel: formPersonnel,
      fieldValues: formPersonnel.fieldValues,
      isSubcontractor: isSubcontractor,
      currentSubcontractor: currentSubcontractor,
      newCompanyRoles: newCompanyRoles,
      originalUserCompanyRoles,
      originalCompanyRoleIds,
    })

    axios
      .patch(`/personnel/${formPersonnel.id}?with_course_connections=true`, params)
      .then((response) => {
        closePersonnelSidePanel()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={personDisplayName(response.data.data.attributes)}
            onBurnToast={breadBoard.onBurnToast}
            status={'edited'}
          />
        )
        setCurrentSubcontractor(defaultSubcontractor)
        setNewCompanyRoles([])
        fetchAssignableCompanies()
        fetchAssignableCompanyRoles()
        assignResources(response.data)
        resetRequestError()
      })
      .catch(handleRequestError)
  };

  const archivePersonnel = ({ isToastMessageDisplayed = true }) => {
    axios
      .patch(`/personnel/${formPersonnel.id}?with_course_connections=true`, { personnel: { archived_at: moment().format('DD/MM/YYYY') } })
      .then((response) => {
        closeArchiveModal()
        assignResources(response.data)
        refreshTrackedPersonnelUsage()
        fetchPersonnelEvents()
        sendAnalytics('Personnel archived', { currentUser: currentActor.user, personnelId: props.personnelId })
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              toastText={<Fragment><span className='tw-font-medium'>{personDisplayName(response.data.data.attributes)}</span> <span>is now archived</span></Fragment>}
              onBurnToast={breadBoard.handleBurnToast}
              onUndoAction={() => unarchivePersonnel({ isToastMessageDisplayed: false })}
            />
          )
        }
      })
      .catch((_error) => {
        closeArchiveModal()
        breadBoard.addInedibleToast({ fullMessage: `${personDisplayName(displayPersonnel)} was not archived. Please try again.` })
      })
  };

  const unarchivePersonnel = ({ isToastMessageDisplayed = true }) => {
    axios
      .patch(`/personnel/${formPersonnel.id}?with_course_connections=true`, { personnel: { archived_at: null } })
      .then((response) => {
        closeArchiveModal()
        assignResources(response.data)
        fetchPersonnelEvents()
        refreshTrackedPersonnelUsage()
        sendAnalytics('Personnel unarchived', { currentUser: currentActor.user, personnelId: props.personnelId })
        if (isToastMessageDisplayed) {
          breadBoard.addToast(
            <UndoableToast
              toastText={<Fragment><span className='tw-font-medium'>{personDisplayName(response.data.data.attributes)}</span> <span>is now active</span></Fragment>}
              onBurnToast={breadBoard.onBurnToast}
              onUndoAction={() => archivePersonnel({ isToastMessageDisplayed: false })}
            />
          )
        }
      })
      .catch((_error) => {
        closeArchiveModal()
        breadBoard.addInedibleToast({ fullMessage: `${personDisplayName(displayPersonnel)} was not unarchived. Please try again.` })
      })
  };

  const destroyPersonnel = () => {
    axios
      .delete(`/personnel/${formPersonnel.id}`)
      .then((_response) => window.location.replace('/personnel'))
      .catch(breadBoard.addInedibleToast)
  };

  // handlers

  const handleCancel = () => {
    setFormPersonnel(displayPersonnel)
    resetRequestError()
    closePersonnelSidePanel()
  };

  const handleCancelTraining = () => {
    closeTrainingSidePanel()
    resetRequestError()
  }

  const handleCloseBookingSidePanel = () => {
    resetRequestError()
    setCurrentBooking(defaultBooking)
    resetBookingSidePanelContext()
    setSelectedBookingId(null)
    closeBookingSidePanel()
  };

  const handleTrainingEvidenceProcessed = () => {
    setTrainingEvidenceProcessed(true)
  }

  const handleNewTrainingClick = ({ booking, course }) => {
    setTrainingSidePanelContext('new')
    resetRequestError()

    const startDate = booking?.attributes.date &&
      moment(booking.attributes.date).isSameOrBefore(new Date(), 'day')
        ? moment.parseZone(booking.attributes.date).toDate()
        : null

    const expiryDate = startDate && course.attributes.expires
      ? moment.parseZone(startDate).add(course.attributes.renewalFrequency, course.attributes.renewalInterval).toDate()
      : null

    setCurrentTraining({
      ...defaultTraining,
      personnelId: displayPersonnel.id,
      course,
      courseId: course.id,
      expiryDate,
      startDate
    })

    openTrainingSidePanel()
  };

  const handleEditTrainingClick = ({ training, course }) => {
    setTrainingSidePanelContext('edit')
    resetRequestError()
    const trainingAttributes = training.attributes;
    setCurrentTraining({
      id: training.id,
      personnelId: trainingAttributes.personnelId,
      courseId: trainingAttributes.courseId,
      course: course,
      startDate: moment.parseZone(trainingAttributes.startDate).toDate(),
      expiryDate: trainingAttributes.expiryDate && moment.parseZone(trainingAttributes.expiryDate).toDate(),
      notes: trainingAttributes.notes,
      attachmentIds: trainingAttributes.attachmentIds
    })
    openTrainingSidePanel()
  };

  const handleAddBooking = ({ course }) => {
    setBookingSidePanelContext('new')
    resetRequestError()
    setCurrentBooking({ ...defaultBooking, courseId: course.id, courseName: course.attributes.name, notes: course.attributes.defaultBookingNote ? course.attributes.defaultBookingNote : undefined })
    openBookingSidePanel()
  };

  const handleViewBookingClick = (bookingId) => {
    setBookingSidePanelContext('show')
    resetRequestError()
    setSelectedBookingId(bookingId)
    const editedBooking = store.domain.bookings[bookingId]
    setCurrentBooking({ ...defaultBooking, id: editedBooking.id, date: moment.parseZone(editedBooking.attributes.date).toDate(), notes: editedBooking.attributes.notes })
    openBookingSidePanel()
  }

  const handleEditBookingClick = () => {
    setBookingSidePanelContext('edit')
  }

  const handleBookingUpdateSubmit = () => {
    axios
      .patch(`/personnel/${props.personnelId}/bookings/${currentBooking.id}`, {
        booking: {
          date: moment.parseZone(currentBooking.date).format('DD/MM/YYYY'),
          notes: currentBooking.notes,
        }
      })
      .then((response) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                Booking has been edited for <span className='tw-font-medium'>{personDisplayName(formPersonnel)}</span>
              </>
            }
            onBurnToast={breadBoard.onBurnToast}
          />
        )
        refreshResources()
        dispatch({ type: 'UPDATE_BOOKING', payload: { member: response.data.data } })
        handleCloseBookingSidePanel()
      })
      .catch(handleRequestError)
  };

  const handleAddELearningBooking = ({ course, eLearningCourse }) => {
    if (!eLearningCourse.attributes.custom && eLearningAllowance.loaded && eLearningAllowance.currentCredits <= 0) {
      setBookingELearningNoCreditsModal({ isOpen: true })
    } else {
      setBookingELearningModal({ isOpen: true, course, eLearningCourse })
    }
  }

  const handleRemoveBooking = (bookingId) => {
    if (bookingId && !selectedBookingId) { setSelectedBookingId(bookingId) }
    openBookingDestroyModal()
  };

  const handleBookingReminder = (bookingId) => {
    if (bookingId && !selectedBookingId) { setSelectedBookingId(bookingId) }
    openBookingReminderModal()
  };

  const handleDeleteTrainingClick = ({ training, course }) => {
    setCurrentTraining({
      ...defaultTraining,
      id: training.id,
      personnelId: displayPersonnel.id,
      courseId: course.id,
      course: course
    })
    setTrainingDestroyModalIsOpen(true)
  };

  const handleDeleteCourseClick = ({ userCourse, course }) => {
    setCurrentTrainingRequirement({
      ...currentTrainingRequirement,
      userCourseId: userCourse.id,
    })
    setCurrentTraining({
      ...currentTraining,
      course: course
    })
    setCourseDestroyModalIsOpen(true)
  };


  const sendBookingReminder = ({ booking, course }) => {
    const isCourseELearning = !!course.relationships.eLearningCourse.data;

    axios
      .post(`/bookings/${booking.id}/reminders`)
      .then(() => {
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                <span className='tw-font-medium'>{formPersonnel.firstName}</span> has been sent a reminder for the{isCourseELearning && ' eLearning'} course <span className='tw-font-medium'>{course.attributes.name}</span>
              </>
            }
            onBurnToast={breadBoard.onBurnToast}
          />
        )
        fetchPersonnelEvents()
      })
      .catch(() => breadBoard.addInedibleToast({ fullMessage: 'Reminder could not be sent. Please try again.' }))
  };

  const handleCourseSelectChange = ({ selected }) => {
    setCurrentTraining({
      ...defaultTraining,
      personnelId: displayPersonnel.id,
      courseId: selected.value.resource.id,
      course: selected.value.resource,
    })
    setCurrentTrainingRequirement(defaultTrainingRequirement)
    resetRequestError()
    openTrainingRequirementsSidePanel()
  };

  let personnelCompanyRoles = [];
  let courseCompanyRoles = [];
  if (!store.application.pending.personnelFetch) {
    const userCompanyRoles = store.domain.personnel.relationships.userCompanyRoles.data.map(ucr => store.domain.userCompanyRoles[ucr.id]);
    const companyRoles = store.domain.personnel.relationships.companyRoles.data.map(cr => store.domain.companyRoles[cr.id]);
    personnelCompanyRoles = sortRolesByPrimaryAndPosition(userCompanyRoles, companyRoles);
    courseCompanyRoles = store.domain.personnel.relationships.courseCompanyRoles.data.map(ccr => store.domain.courseCompanyRoles[ccr.id]);
  };

  const handleArchiveButtonClick = () => {
    if (isUnarchivingBlocked) {
      openUnarchiveBlockedModal()
    } else {
      openArchiveModal()
    }
  };

  const handleCompanyRolesOptionChange = (values) => {
    if (formPersonnel.primaryCompanyRoleId === "" && formPersonnel.companyRoleIds.length === 0) {
      setFormPersonnel({ ...formPersonnel, primaryCompanyRoleId: values[0].value, companyRoleIds: values.map(v => v.value) })
    } else {
      setFormPersonnel({ ...formPersonnel, companyRoleIds: values.map(v => v.value) })
    }
  }

  function handleSelectPrimary(id) {
    setFormPersonnel({ ...formPersonnel, primaryCompanyRoleId: id })
  }

  function handleRemoveRole(id) {
    const filteredCompanyRoleIds = formPersonnel.companyRoleIds.filter(roleId => roleId !== id);
    const newPrimaryCompanyRoleId = filteredCompanyRoleIds.length > 0 ? sortRoleIdsByPosition(store.domain.assignableRoles, filteredCompanyRoleIds)[0] : "";
    setFormPersonnel({
      ...formPersonnel,
      companyRoleIds: filteredCompanyRoleIds,
      primaryCompanyRoleId: formPersonnel.primaryCompanyRoleId === id ? newPrimaryCompanyRoleId : formPersonnel.primaryCompanyRoleId
    })
  }

  function handleDestroyModalClose() {
    if (selectedBookingCourse?.relationships?.eLearningCourse?.data?.id) {
      setCurrentBooking(defaultBooking)
      setSelectedBookingId(null)
    }
    closeBookingDestroyModal()
  }

  function handleReminderModalClose() {
    if (selectedBookingCourse?.relationships?.eLearningCourse?.data?.id) {
      setCurrentBooking(defaultBooking)
      setSelectedBookingId(null)
    }
    closeBookingReminderModal()
  }

  function handleDeletePersonnelClick() {
    setDestroyModalIsOpen(true)
  }

  function handleSubcontractorSelect(selection) {
    setCurrentSubcontractor(selection)
  }

  return (
    <Fragment>
      {!store.application.pending.assignableCompaniesFetch && !store.application.pending.personnelFetch && (
        <TrackedPersonnelContext.Provider value={{ isCurrentPersonnelTracked: isCurrentPersonnelTracked, usage: trackedPersonnelUsage, hasReachedLimit: hasReachedTrackedPersonnelLimit }}>
          <div className='col-sm-3 p-l-0'>
            <TrainingRegisterResourceManagementContext.Provider value={trainingRegisterContextValues}>
              <PersonnelPanel
                currentPersonnel={displayPersonnel}
                personnel={store.domain.personnel}
                division={store.domain.companies[store.domain.personnel?.relationships?.company?.data?.id]}
                subcontractor={store.domain.subcontractors[store.domain.personnel?.relationships?.subcontractor?.data?.id]}
                lineManager={store.domain.lineManagers[displayPersonnel.lineManagerId]}
                teams={store.domain.teams}
                roles={personnelCompanyRoles}
                openSidePanel={openPersonnelSidePanel}
                onArchiveButtonClick={handleArchiveButtonClick}
                onDeleteClick={handleDeletePersonnelClick}
                availableFields={availableFields}
                availableFieldOptions={availableFieldOptions}
              />
            </TrainingRegisterResourceManagementContext.Provider>
          </div>
          {coursesMeta.loaded && !store.application.pending.trainingFetch && !store.application.pending.personnelEventsFetch && (
            <div className='col-sm-9 p-r-0'>
              {hasReachedTrackedPersonnelUsageThreshold && (['primary', 'administrator'].includes(currentActor.user.attributes.accessType) || hasReachedTrackedPersonnelLimit) && (
                <TrackedPersonnelUsageBanner usage={trackedPersonnelUsage.value} hasReachedLimit={hasReachedTrackedPersonnelLimit} />
              )}
              <TrainingRegisterResourceManagementContext.Provider value={trainingRegisterContextValues}>
                <TrainingRecords
                  hasTrainingEditableAccess={props.hasTrainingEditableAccess}
                  trainingEvidenceProcessed={trainingEvidenceProcessed}
                  onTrainingEvidenceProcessed={handleTrainingEvidenceProcessed}
                  currentPersonnel={displayPersonnel}
                  personnelEvents={{ collection: store.domain.personnelEvents, meta: store.domain.personnelEventsMeta }}
                  currentBookings={currentBookings}
                  currentRegistrations={Object.values(store.domain.registrations)}
                  creditInfoUnavailable={eLearningAllowance.hasError}
                  fetchPersonnelEvents={fetchPersonnelEvents}
                  roles={personnelCompanyRoles}
                  courses={store.domain.courses}
                  bookers={store.domain.bookers}
                  autoEnrolmentConfigurations={store.domain.autoEnrolmentConfigurations}
                  autoEnrolmentExclusions={store.domain.autoEnrolmentExclusions}
                  eLearningCourses={store.domain.eLearningCourses}
                  eLearningAllowance={eLearningAllowance}
                  courseCompanyRoles={courseCompanyRoles}
                  userCourses={Object.values(store.domain.userCourses)}
                  training={allTraining}
                  attachments={Object.values(store.domain.attachments)}
                  coursesMeta={coursesMeta}
                  updateUserCourse={updateUserCourse}
                  createUserCourse={createUserCourse}
                  onNewTrainingClick={handleNewTrainingClick}
                  onEditTrainingClick={handleEditTrainingClick}
                  onDeleteTrainingClick={handleDeleteTrainingClick}
                  onDeleteCourseClick={handleDeleteCourseClick}
                  onCourseSelectChange={handleCourseSelectChange}
                  onRemoveBooking={handleRemoveBooking}
                  onAddBooking={handleAddBooking}
                  onShowBooking={handleViewBookingClick}
                  onAddELearningBooking={handleAddELearningBooking}
                  onBookingReminder={handleBookingReminder}
                  onCourseSelectError={breadBoard.addInedibleToast}
                  onAddRole={openPersonnelSidePanel}
                  createAutoEnrolmentExclusion={createAutoEnrolmentExclusion}
                  deleteAutoEnrolmentExclusion={deleteAutoEnrolmentExclusion}
                />
              </TrainingRegisterResourceManagementContext.Provider>
            </div>
          )}
          <TrainingRegisterResourceManagementContext.Provider value={trainingRegisterContextValues}>
            <PersonnelSidePanel
              currentPersonnel={formPersonnel}
              division={store.domain.companies[store.domain.personnel?.relationships?.company?.data?.id]}
              personnel={store.domain.personnel}
              setCurrentPersonnel={setFormPersonnel}
              availableFields={availableFields}
              availableFieldOptions={availableFieldOptions}
              requestError={requestError}
              roles={personnelCompanyRoles}
              lineManagers={Object.values(store.domain.lineManagers)}
              assignableCompanies={store.domain.assignableCompanies}
              onAssignableRoleAdd={handleAssignableRoleAdd}
              assignableRoles={store.application.pending.assignableRolesFetch ? [] : store.domain.assignableRoles}
              currentSubcontractor={currentSubcontractor}
              onSubcontractorSelect={handleSubcontractorSelect}
              isSubcontractor={isSubcontractor}
              setIsSubcontractor={setIsSubcontractor}
              sidePanelContext={'edit'}
              sidePanelIsOpen={personnelSidePanelIsOpen}
              closeSidePanel={closePersonnelSidePanel}
              onPersonnelInputChange={handlePersonnelInputChange}
              onPersonnelOptionChange={handlePersonnelOptionChange}
              onPersonnelDateChange={handlePersonnelDateChange}
              onPersonnelFieldChange={handleFieldValueInputChange}
              onFieldOptionChange={handleFieldOptionChange}
              onDateFieldChange={handleDateFieldChange}
              onCompanyRolesOptionChange={handleCompanyRolesOptionChange}
              onSelectPrimary={handleSelectPrimary}
              onRemoveRole={handleRemoveRole}
              onCancel={handleCancel}
              onPersonnelUpdateSubmit={handlePersonnelUpdateSubmit}
              onNewCompanyRoleAdd={handleNewCompanyRoleAdd}
              onEditClick={() => setPersonnelSidePanelContext('edit')}
              submitDisabled={submitDisabled}
              removeErrorStyling={removeErrorStyling}
              onDeleteClick={handleDeletePersonnelClick}
            />
          </TrainingRegisterResourceManagementContext.Provider>
          <TrainingSidePanel
            currentTraining={currentTraining}
            includedAttachments={Object.values(store.domain.attachments)}
            includedCoverImages={Object.values(store.domain.coverImages)}
            requestError={requestError}
            resetRequestError={resetRequestError}
            sidePanelIsOpen={trainingSidePanelIsOpen}
            setSidePanelIsOpen={setTrainingSidePanelIsOpen}
            closeSidePanel={closeTrainingSidePanel}
            createTraining={createTraining}
            updateTraining={updateTraining}
            onInputChange={handleTrainingInputChange}
            onTrainingDateChange={handleTrainingDateChange}
            onError={breadBoard.addInedibleToast}
            sidePanelContext={trainingSidePanelContext}
            submitDisabled={submitDisabled}
            removeErrorStyling={removeErrorStyling}
            personnelId={props.personnelId}
            onCancelTraining={handleCancelTraining}
            onTrainingCalendarClose={handleTrainingCalendarClose}
          />
          <TrainingRequirementsSidePanel
            currentTraining={currentTraining}
            currentTrainingRequirement={currentTrainingRequirement}
            includedAttachments={Object.values(store.domain.attachments)}
            includedCoverImages={Object.values(store.domain.coverImages)}
            requestError={requestError}
            resetRequestError={resetRequestError}
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
            sidePanelIsOpen={trainingRequirementsSidePanelIsOpen}
            setSidePanelIsOpen={setTrainingRequirementsSidePanelIsOpen}
            closeSidePanel={closeTrainingRequirementsSidePanel}
            createTrainingRequirement={createTrainingRequirement}
            onTrainingInputChange={handleTrainingInputChange}
            onError={breadBoard.addInedibleToast}
            onTrainingRequirementInputChange={handleTrainingRequirementInputChange}
            sidePanelContext={trainingRequirementsSidePanelContext}
            onTrainingDateChange={handleTrainingDateChange}
            onTrainingCalendarClose={handleTrainingCalendarClose}
          />
          <TrainingRegisterResourceManagementContext.Provider value={trainingRegisterContextValues}>
            <BookingSidePanel
              personnel={formPersonnel}
              domainBooking={selectedBooking}
              domainBooker={selectedBooker}
              domainCourse={selectedBookingCourse}
              currentBooking={currentBooking}
              onCancel={handleCloseBookingSidePanel}
              onSubmit={createBooking}
              requestError={requestError}
              removeErrorStyling={removeErrorStyling}
              submitDisabled={submitDisabled}
              sidePanelIsOpen={bookingSidePanelIsOpen}
              onInputChange={handleBookingInputChange}
              onDateChange={handleBookingDateChange}
              sidePanelContext={bookingSidePanelContext}
              onBookingUpdateSubmit={handleBookingUpdateSubmit}
              onEditBookingClick={handleEditBookingClick}
              onRemoveBooking={handleRemoveBooking}
              onBookingReminder={handleBookingReminder}
            />
          </TrainingRegisterResourceManagementContext.Provider>
          {bookingELearningModal.course && (
            <BookingELearningModal
              isOpen={bookingELearningModal.isOpen}
              setIsClosed={() => { setBookingELearningModal(defaultBookingELearningModal) }}
              identifier={displayPersonnel.firstName}
              course={bookingELearningModal.course}
              eLearningCourse={bookingELearningModal.eLearningCourse}
              currentCredits={eLearningAllowance.currentCredits}
              createELearningBooking={createELearningBooking}
            />
          )}
          {eLearningAllowance.loaded && eLearningAllowance.currentCredits <= 0 && (
            <BookingELearningNoCreditsModal
              isOpen={bookingELearningNoCreditsModal.isOpen}
              setIsClosed={() => { setBookingELearningNoCreditsModal(defaultBookingELearningNoCreditsModal) }}
            />
          )}
          <DestroyModal
            isOpen={destroyModalIsOpen}
            displayText={`Are you sure you want to delete ${displayPersonnel.firstName}?`}
            confirmationText={`Delete ${displayPersonnel.firstName}`}
            additionalContent={hasELearningBookings ? (
              <p className='tw-border-0 tw-rounded-lg tw-text-red-800 tw-bg-red-025 tw-p-3 m-0 m-t-20'>
                This personnel has eLearning booked which will be cancelled. Any courses in progress will not be refunded.
              </p>
            ) : null}
            onClose={() => setDestroyModalIsOpen(false)}
            onDestroy={destroyPersonnel}
          />
          <TrainingDestroyModal
            isOpen={trainingDestroyModalIsOpen}
            setIsOpen={setTrainingDestroyModalIsOpen}
            currentTraining={currentTraining}
            deleteTraining={deleteTraining}
          />
          <CourseDestroyModal
            isOpen={courseDestroyModalIsOpen}
            setIsOpen={setCourseDestroyModalIsOpen}
            currentTraining={currentTraining}
            deleteUserCourse={deleteUserCourse}
          />
          <BookingDestroyModal
            isOpen={bookingDestroyModalIsOpen}
            onClose={handleDestroyModalClose}
            personnel={displayPersonnel}
            booking={selectedBooking}
            course={selectedBookingCourse}
            autoEnrolmentExclusion={selectedBookingAutoEnrolmentExclusion}
            onDestroy={deleteBooking}
          />
          <BookingReminderModal
            isOpen={bookingReminderModalIsOpen}
            onClose={handleReminderModalClose}
            booking={selectedBooking}
            personnel={displayPersonnel}
            course={selectedBookingCourse}
            onSendReminderConfirmation={sendBookingReminder}
          />

          {
            isUnarchivingBlocked ? (
              <UnarchiveBlockedModal
                isOpen={unarchiveBlockedModalIsOpen}
                closeModal={closeUnarchiveBlockedModal}
                usage={trackedPersonnelUsage.value}
              />
            ) : (
              <ArchiveModal
                isOpen={archiveModalIsOpen}
                closeModal={closeArchiveModal}
                isArchived={isArchived}
                longName={personDisplayName(displayPersonnel)}
                shortName={displayPersonnel.firstName}
                ramsPluralName={props.ramsPluralName}
                hasELearningBookings={hasELearningBookings}
                onArchive={archivePersonnel}
                onUnarchive={unarchivePersonnel}
              />
            )
          }
          <Portal containerSelector='[data-portal="page-title"]'>{personDisplayName(displayPersonnel)}</Portal>
        </TrackedPersonnelContext.Provider>
      )}
    </Fragment>
  )
}
