import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from "react-qr-code";

import { oneTimePasswordShape } from 'components/hooks/useOneTimePassword';
import { validationErrorsShape } from 'components/hooks/useValidationErrors';

import ModalBox from 'components/application/ModalBox';
import OneTimePasswordSignInCard from 'components/users/twoFactorAuthentication/OneTimePasswordSignInCard';
import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';

import CrossIcon from '-!svg-react-loader?name=CrossIcon!icons/ic-close.svg';

export default function EnableModal({ isOpen, provisioningUri, oneTimePasswordProps, validationErrorsProps, closeModal, onFormSubmit }) {
  useEffect(() => {
    oneTimePasswordProps.inputRefs[0].current.focus()
  }, [isOpen])

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={closeModal}
      withFooter={false}
    >
      <div className='tw-flex tw-p-6 tw-justify-between tw-items-center'>
        <h2 className='tw-text-grey-900 tw-text-xl tw-font-semibold tw-m-0 tw-w-96'>
          Set up two-factor authentication
        </h2>
        <CrossIcon onClick={closeModal} className='tw-block tw-object-right tw-cursor-pointer' />
      </div>

      <hr className='tw-w-full tw-my-0' />

      {Object.keys(validationErrorsProps.validationErrors).length > 0 && (
        <ValidationErrorsMessages className="tw-m-6 tw-border-solid tw-border-1 tw-border-red-200" validationErrors={validationErrorsProps.validationErrors} />
      )}

      <div className='tw-p-6 tw-pb-10'>
        <div className='tw-block tw-text-center'>
          {provisioningUri &&
            <QRCode value={provisioningUri} className='tw-w-40 tw-h-40' />}
        </div>

        <p className='tw-text-grey-700 tw-text-sm tw-font-small tw-text-center tw-my-5'>
          To get started, scan this code. Proceed using your authenticator app to get the verification code and enter below.
        </p>

        <OneTimePasswordSignInCard
          inputRefs={oneTimePasswordProps.inputRefs}
          otpCode={oneTimePasswordProps.otpCode}
          onInputKeyUp={oneTimePasswordProps.handleInputKeyUp}
          onFormPasteCapture={oneTimePasswordProps.handleFormPasteCapture}
          onInputClick={oneTimePasswordProps.handleInputClick}
          onSubmit={onFormSubmit}
        />
      </div>
    </ModalBox>
  )
}

EnableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  provisioningUri: PropTypes.string.isRequired,
  oneTimePasswordProps: oneTimePasswordShape,
  validationErrorsProps: validationErrorsShape,
  closeModal: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};
