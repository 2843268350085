import React from "react";
import PropTypes from "prop-types";

import { useRamsAnalyticsDashboardContext } from "components/contexts/RamsAnalyticsDashboardContext";

import useSize from "components/hooks/useSize";
import useBodyOverflowToggle from "components/hooks/useBodyOverflowToggle";
import useEscapeKey from "components/hooks/useEscapeKey";

import CloseIcon from "-!svg-react-loader?name=CloseIcon!icons/ic-close.svg";
import ProjectsTable from "components/projects/components/ProjectsTable";

export default function ProjectsModal({ isOpen, title, subTitle, projects = [], hasNextPage, onFetchNextPage, closeModal, onProjectClick }) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();

  const { isResizing, size: bodySize } = useSize(document.body);

  useBodyOverflowToggle(isOpen)
  useEscapeKey(closeModal, [isOpen])

  const handleModalWrapperClick = (event) => {
    if (event.target.dataset.elementName === "projects-modal-modal-wrapper") closeModal()
  }

  const handleTableWrapperScroll = ({ target }) => {
    const isScrollBottom = Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1;

    if (isScrollBottom && hasNextPage) onFetchNextPage()
  }

  return (
    <div className={isOpen ? "tw-block" : "tw-hidden"}>
      <div className="tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-[9998] tw-bg-grey-900 tw-opacity-75"></div>
      <div data-element-name="projects-modal-modal-wrapper" className={`tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 ${isResizing ? "tw-hidden" : "tw-flex"} tw-justify-center tw-p-20 tw-min-w-[594px] tw-z-[9999]`} onClick={handleModalWrapperClick}>
        <div className="tw-flex tw-flex-col tw-w-full tw-max-w-[1140px] tw-h-full tw-max-h-[640px] tw-overflow-hidden tw-rounded-2xl tw-bg-white">
          <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-px-5 tw-py-4">
            <div>
              <div className="tw-text-l tw-font-semibold tw-tracking-auto tw-text-grey-900 tw-mb-1">{title}</div>
              <div>{subTitle}</div>
            </div>
            <CloseIcon
              data-element-name="projects-modal-close"
              className="tw-cursor-pointer tw-border-1 tw-border-solid tw-rounded-md tw-bg-white tw-border-white hover:tw-border-grey-100 [&_polygon]:tw-fill-grey-700"
              width={24}
              height={24}
              onClick={closeModal}
            />
          </div>
          <div data-element-name="projects-modal-table-wrapper" className="tw-w-full tw-h-full tw-overflow-y-auto tw-overflow-x-hidden" onScroll={handleTableWrapperScroll}>
            <ProjectsTable
              projects={projects}
              isResizing={isResizing}
              bodySize={bodySize}
              ramsSingularName={ramsAnalyticsDashboardContext.ramsSingularName}
              withClient={ramsAnalyticsDashboardContext.isClientEnabled}
              withRiskAssessments={ramsAnalyticsDashboardContext.isRiskRegisterEnabled}
              withReviewDate={ramsAnalyticsDashboardContext.isProjectReviewDatesEnabled}
              withApproval={ramsAnalyticsDashboardContext.isApprovalsEnabled}
              onProjectClick={onProjectClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ProjectsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  projects: PropTypes.array,
  hasNextPage: PropTypes.bool,
  onFetchNextPage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onProjectClick: PropTypes.func.isRequired
};
