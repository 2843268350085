import HandshqApp from 'handshq-app/handshq-app';

import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';

import useToasts from 'components/hooks/useToasts';

import ToastRack from 'components/application/ToastRack';
import GlobalToast from 'components/toastMessages/GlobalToast';

class GlobalToastRackAPI extends Component {
  constructor(props) {
    super(props)
  }

  addInedibleToast = () => {
    this.props.addInedibleToast()
  }

  render() {
    return (
      <ToastRack toasts={this.props.toasts} />
    )
  }
}

export default function GlobalToastRack({ messages }) {
  const [toasts, , addToast, handleBurnToast, addInedibleToast] = useToasts();

  useEffect(() => {
    if (messages && messages.length > 0) {
      messages.forEach(message => {
        addToast(
          <GlobalToast
            messageType={message.type}
            messageProps={message.data}
            onBurnToast={handleBurnToast}
          />
        )
      })
    }
  }, [])

  return (
    <GlobalToastRackAPI
      ref={node => HandshqApp.GlobalToastRackAPI = node}
      toasts={toasts}
      addInedibleToast={addInedibleToast}
    />
  )
}

GlobalToastRack.propTypes = {
  messages: PropTypes.array
};

GlobalToastRack.defaultProps = {
  messages: []
};
