export function submitSAMLRequest({ identityProviderUuid }) {
  const form = document.createElement('form');
  const tokenInput = document.createElement('input');
  const csrfToken = document.querySelector('meta[name="csrf-token"]') || {};
  tokenInput.value = csrfToken.content;
  tokenInput.name = 'authenticity_token';
  form.action = `/users/auth/saml/${identityProviderUuid}`;
  form.hidden = true;
  form.method = 'POST';

  form.appendChild(tokenInput)
  document.body.appendChild(form)
  form.submit()
}
