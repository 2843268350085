import React from 'react';
import PropTypes from 'prop-types';

import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import AttachmentIcon from '-!svg-react-loader?name=AttachmentIcon!icons/attachment.svg';

import Tooltip from 'components/application/Tooltip';
import ActionsPill from 'components/application/ActionsPill';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

const tooltippableCourseLength = 50;
const tooltippableCourseLengthWithEvidence = 40;
const tooltippableCourseLengthWithRequiredEvidence = 22;
export default function TrainingName(props) {
  const {
    isHistorical,
    training,
    attachments,
    course,
    isRequired,
    showEvidenceRequirement,
    onAttachmentsToggleClick
  } = props;

  let appliesTooltip = false;
  const requiresEvidence = !isHistorical && showEvidenceRequirement && course.attributes.requiresEvidence && training;

  if (attachments.length > 0) {
    appliesTooltip = course.attributes.name.length > tooltippableCourseLengthWithEvidence;
  } else if ((attachments.length === 0) && requiresEvidence) {
    appliesTooltip = course.attributes.name.length > tooltippableCourseLengthWithRequiredEvidence;
  } else {
    appliesTooltip = course.attributes.name.length > tooltippableCourseLength;
  }

  return (
    <>
      {isHistorical && <div className='flex flex--vertically-centered m-r-4'><ClockIcon width={16} height={16} className='[&_path]:tw-fill-grey-500' /></div>}
      <div className='truncated-text-container truncated-text-container--auto tooltip-parent'>
        <span className={isHistorical ? 'tw-text-m' : 'tw-text-m tw-text-grey-900 tw-font-medium'}>{course.attributes.name}</span>

        {!isRequired && <p className='tw-text-xs tw-mb-0'>Optional</p>}
        {appliesTooltip && (<Tooltip placement='top' trigger='hover' tooltip={course.attributes.name} />) }
      </div>
      {attachments.length > 0 ? (
        <OutlinedButton className='m-l-12' size='xs' color='grey' onClick={onAttachmentsToggleClick}>
          <AttachmentIcon width={16} height={16} />
          <span className='m-l-6 tw-text-xs tw-font-semibold tw-tracking-wide'>{attachments.length}</span>
        </OutlinedButton>
      ) : (
        requiresEvidence && (
          <div className='l-if m-l-12'>
            <ActionsPill
              color='cyan'
              displayText='0'
              icon={<AttachmentIcon width={16} height={16} />}
              tooltipText='Evidence must be uploaded for this course'
            />
          </div>
        )
      )}
    </>
  )
}

TrainingName.propTypes = {
  isHistorical: PropTypes.bool,
  training: PropTypes.object,
  attachments: PropTypes.array,
  course: PropTypes.object.isRequired,
  onAttachmentsToggleClick: PropTypes.func
}

TrainingName.defaultProps = {
  attachments: [],
  showEvidenceRequirement: true
}
