import React from 'react';
import Toast from 'components/application/Toast';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';

export default function ProjectPersonnelRemovedToast(props) {
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  return(
    <Toast
      toastKey={props.toastKey}
      burn={breadBoard.handleBurnToast}
      modifiers='no-undo'
      burnTimer={8000}
      contents={
        <React.Fragment>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>
            <span className='tw-font-medium'>
              {props.displayName}
            </span>
            &nbsp;was successfully removed from the {currentActor.division.attributes.singularRamsName || 'RAMS'}.
          </div>
        </React.Fragment>
      }
    />
  )
}
