import React from 'react';
import PropTypes from 'prop-types';

export default function BlockList(props) {
  return(
    <ul className='block-list tw-border-grey-100'>
      {props.collection.map((item, index) => {
        return (
          <li
            className={`block-list__item tw-py-4 tw-border-grey-100${props.onItemClick ? ' tw-cursor-pointer hover:tw-bg-grey-025' : ''}${props.blockListItemClasses(item)}`}
            key={`block-list__item--${index}`}
            onClick={() => { props.onItemClick && props.onItemClick(item) }}
          >
            {props.onItemDisplay ? props.onItemDisplay(item) : item}
          </li>
        )
      })}
    </ul>
  )
}

BlockList.defaultProps = {
  collection: [],
  blockListItemClasses: (_item) => { return '' }
};

BlockList.propTypes = {
  collection: PropTypes.array.isRequired,
  additionalClasses: PropTypes.string
};
