import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources';

import { groupAttachmentsByAttachableId } from 'components/helpers/resources/attachments';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import useToggle from 'components/hooks/useToggle';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { autoEnrolmentBlockers as autoEnrolmentBlockersList, additionalAutoEnrolmentBlockers as additionalAutoEnrolmentBlockersList } from 'components/helpers/resources/autoEnrolments';

import TrainingRow from 'components/training/TrainingRow';
import TrainingStatus from 'components/training/TrainingStatus';
import TrainingBookings from 'components/training/TrainingBookings';
import ELearningBookings from 'components/training/ELearningBookings';
import TrainingOptions from 'components/training/TrainingOptions';
import MinimalTrainingOptions from 'components/training/MinimalTrainingOptions';
export default function CourseTraining({
  currentPersonnel,
  personnelId,
  course,
  userCourse,
  eLearningCourse,
  eLearningAllowance,
  autoEnrolmentConfiguration,
  hasTrainingEditableAccess,
  attachments,
  booking,
  booker,
  training,
  isRequired,
  isRequiredByAnything,
  isRequiredByAnotherRole,
  isRoleRequired,
  requiredSource,
  registration,
  creditInfoUnavailable,
  autoEnrolmentExclusion,
  isAdditional,
  onAddBooking,
  onAddELearningBooking,
  onShowBooking,
  onNewTrainingClick,
  onEditTrainingClick,
  onDeleteTrainingClick,
  onDeleteCourseClick,
  updateUserCourse,
  createUserCourse,
  onRemoveBooking,
  onBookingReminder,
  createAutoEnrolmentExclusion,
  deleteAutoEnrolmentExclusion,
  setModalBoxData,
}) {
  const currentActor = useCurrentActor();

  const [isHistoricalTrainingVisible, toggleHistoricalTraining] = useToggle(false);
  const trackedPersonnelContext = useTrackedPersonnelContext();
  const { isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();

  const isArchived = !!currentPersonnel.archivedAt;

  const isReadOnlyOrArchived = !hasTrainingEditableAccess || isArchived;
  const isBookingAllowedWithinTrackedPersonnelLimits = !trackedPersonnelContext.hasReachedLimit || trackedPersonnelContext.isCurrentPersonnelTracked;

  const attachmentsByAttachableId = groupAttachmentsByAttachableId({ attachments: attachments });
  const courseHasELearningCourse = currentActor.isAllowedFeature('e_learning') && !!eLearningCourse;
  const isELearningNonCustom = courseHasELearningCourse && !eLearningCourse.attributes.custom;
  const latestTraining = training[0];

  const autoEnrolmentBlockers = autoEnrolmentBlockersList({
    autoEnrolmentConfiguration: autoEnrolmentConfiguration,
    isExpiringCourse: course.attributes.expires,
    courseHasELearningCourse: courseHasELearningCourse,
    training: latestTraining,
    isSubcontractor: !!currentPersonnel.company.subcontractorId,
    isRequired: isRequired || isRequiredByAnything,
    isAutoEnrolmentIncludingMissingTraining,
    isBookingAllowedWithinTrackedPersonnelLimits
  })

  const additionalAutoEnrolmentBlockers = additionalAutoEnrolmentBlockersList({
    isELearningNonCustom,
    isCreditInfoUnavailable: creditInfoUnavailable,
    isPersonnelEmailUnavailable: !currentPersonnel.email,
    currentELearningCredits: eLearningAllowance?.currentCredits
  })

  const renderStatus = ({ currentTrainingStatus }) => {
    return (
      <TrainingStatus
        currentTrainingStatus={isArchived ? 'archived' : currentTrainingStatus}
        isRequired={isRequired}
        requiredSource={requiredSource}
      />
    )
  };

  const renderBookings = ({ currentTrainingStatus }) => {
    if (isArchived) return null

    const isEligibleForManualBooking = (
      (isRequired || isRequiredByAnything) && isBookingAllowedWithinTrackedPersonnelLimits && hasTrainingEditableAccess &&
      !currentPersonnel.company.subcontractorId &&
      !['valid', 'expiryDateMissing'].includes(currentTrainingStatus)
    );

    return courseHasELearningCourse ? (
      <ELearningBookings
        booking={booking}
        booker={booker}
        course={course}
        eLearningCourse={eLearningCourse}
        registration={registration}
        latestTraining={latestTraining}
        currentTrainingStatus={currentTrainingStatus}
        currentPersonnel={currentPersonnel}
        isCreditInfoUnavailable={isELearningNonCustom && creditInfoUnavailable}
        isEligibleForManualBooking={isEligibleForManualBooking}
        isEligibleForAutoEnrolment={autoEnrolmentBlockers.length === 0}
        autoEnrolmentConfiguration={autoEnrolmentConfiguration}
        autoEnrolmentExclusion={autoEnrolmentExclusion}
        eLearningAllowance={eLearningAllowance}
        onAddELearningBooking={onAddELearningBooking}
      />
    ) : (
      <TrainingBookings
        booking={booking}
        course={course}
        currentTrainingStatus={currentTrainingStatus}
        isEligibleForManualBooking={isEligibleForManualBooking}
        onAddBooking={onAddBooking}
        onShowBooking={onShowBooking}
      />
    )
  };

  const renderRegularTrainingActions = ({ training: optionsTraining }) => {
    if (isReadOnlyOrArchived && training.length <= 1) return null

    return (
      <TrainingOptions
        showHistoricalTrainingOnly={isReadOnlyOrArchived}
        isRoleRequired={isRoleRequired}
        isRequiredByAnotherRole={isRequiredByAnotherRole}
        isRequiredStatus={isRequired}
        isAdditional={isAdditional}
        isMultipleTraining={training.length > 1}
        isHistoricalTrainingVisible={isHistoricalTrainingVisible}
        courseHasELearningCourse={courseHasELearningCourse}
        personnelId={personnelId}
        currentPersonnel={currentPersonnel}
        training={optionsTraining}
        course={course}
        eLearningCourse={eLearningCourse}
        booking={booking}
        registration={registration}
        userCourse={userCourse}
        updateUserCourse={updateUserCourse}
        createUserCourse={createUserCourse}
        creditInfoUnavailable={isELearningNonCustom && creditInfoUnavailable}
        toggleHistoricalTraining={toggleHistoricalTraining}
        onNewTrainingClick={onNewTrainingClick}
        onEditTrainingClick={onEditTrainingClick}
        onDeleteTrainingClick={onDeleteTrainingClick}
        onDeleteCourseClick={onDeleteCourseClick}
        onAddBooking={onAddBooking}
        onAddELearningBooking={onAddELearningBooking}
        onRemoveBooking={onRemoveBooking}
        onBookingReminder={onBookingReminder}
        isEligibleForAutoEnrolment={autoEnrolmentBlockers.length === 0 || (autoEnrolmentBlockers.length === 1 && autoEnrolmentBlockers[0] === 'courseRequirementScope')}
        autoEnrolmentConfiguration={autoEnrolmentConfiguration}
        autoEnrolmentBlockers={autoEnrolmentBlockers}
        additionalAutoEnrolmentBlockers={additionalAutoEnrolmentBlockers}
        autoEnrolmentExclusion={autoEnrolmentExclusion}
        createAutoEnrolmentExclusion={createAutoEnrolmentExclusion}
        deleteAutoEnrolmentExclusion={deleteAutoEnrolmentExclusion}
      />
    )
  };

  const renderHistoricalTrainingActions = ({ training }) => {
    if (isReadOnlyOrArchived) return null
    return (
      <MinimalTrainingOptions
        onEditTrainingClick={() => onEditTrainingClick({ training, course })}
        onDeleteTrainingClick={() => onDeleteTrainingClick({ training, course })}
      />
    )
  };

  useEffect(() => {
    if (training.length <= 1 && isHistoricalTrainingVisible) toggleHistoricalTraining(false)
  }, [JSON.stringify(training)])

  return (
    <Fragment>
      <TrainingRow
        isArchived={isArchived}
        training={latestTraining}
        attachments={latestTraining && attachmentsByAttachableId[latestTraining.id]}
        course={course}
        isRequired={isRequired}
        setModalBoxData={setModalBoxData}
        hasTrainingEditableAccess={hasTrainingEditableAccess}
        renderStatus={renderStatus}
        renderBookings={renderBookings}
        renderActions={renderRegularTrainingActions}
      />
      {isHistoricalTrainingVisible && training.length > 1 && training.slice(1).map(historicalTraining => {
        return (
          <TrainingRow
            key={`historicalTraining--${historicalTraining.id}`}
            isHistorical={true}
            isArchived={isArchived}
            training={historicalTraining}
            attachments={attachmentsByAttachableId[historicalTraining.id]}
            course={course}
            isRequired={isRequired}
            setModalBoxData={setModalBoxData}
            renderActions={renderHistoricalTrainingActions}
          />
        )
      })}
    </Fragment>
  )
}

CourseTraining.propTypes = {
  isRequired: PropTypes.bool,
  isRoleRequired: PropTypes.bool,
  course: PropTypes.object.isRequired,
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  autoEnrolmentExclusion: resourceShape('autoEnrolmentExclusion'),
  eLearningCourse: resourceShape('eLearningCourse'),
  eLearningAllowance: PropTypes.object,
  training: PropTypes.array,
  booker: PropTypes.object,
  booking: PropTypes.object,
  registration: PropTypes.object,
  attachments: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminder: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  createAutoEnrolmentExclusion: PropTypes.func,
  deleteAutoEnrolmentExclusion: PropTypes.func
}

CourseTraining.defaultProps = {
  hasTrainingEditableAccess: true,
  isRequired: true,
  isRoleRequired: true,
  attachments: [],
  training: [],
  booking: null,
  registration: null
}
