import { enableAllPlugins } from 'immer';
enableAllPlugins()
import { produce } from 'immer';

import {
  baseStoreState,
  resourcesAsDomainData,
  populateResources,
  removeStaleResourceType,
  removeResource,
  replaceResource,
  findHasManyRelations
} from 'components/helpers/storeHelpers';

import { combinedSequencesList } from 'components/helpers/resources/sequences';

export const initialState = {
  ...baseStoreState,
  domain: resourcesAsDomainData([
    'project',
    'projectTrade',
    'trade',
    'masterMethodSequence',
    'methodSequence',
    'methodStep',
    'attachment',
    'coverImage'
  ]),
  application: {
    areAttachmentsAllowed: undefined,
    editingContent: {},
    errors: { data: {}, allDataKeys: [] },
    newAttachments: {},
    pending: {
      projectTradeFetch: true
    },
    projectId: undefined,
    toasts: []
  },
  ui: {
    adding: {},
    ordering: {},
    focused: {},
    styling: { height: 'auto', paddingTop: 0 },
    toggling: {}
  }
}

// candidates for re-usable reducer?
function loadfromPayload(draftState, payload, resourceType) {
  populateResources(draftState, payload.data, resourceType)
  removeStaleResourceType(draftState, payload.data, resourceType)
  populateResources(draftState, payload.included)
  draftState.application.pending[`${resourceType}Fetch`] = false;
}

function addFromPayload(draftState, payload, options = {}) {
  draftState.domain[`${options['collectionType'] || payload.data.type}Collection`].data[payload.data.id] = payload.data
  draftState.domain[`${options['collectionType'] || payload.data.type}Collection`].allDataIds.push(payload.data.id)
  populateResources(draftState, payload.included)
}

function setMethodStepOrderGroups(draftState) {
  draftState.domain.methodSequenceCollection.allDataIds.forEach((methodSequenceId) => {
    const methodSteps = findHasManyRelations(draftState, draftState.domain.methodSequenceCollection.data[methodSequenceId], 'methodStep')
    draftState.ui.ordering[`methodSequenceSteps--${methodSequenceId}`] = { list: methodSteps.map((step) => step.id), inProgress: false };
  })
}

function setAttachmentOrderGroups(draftState) {
  draftState.domain.methodStepCollection.allDataIds.forEach((methodStepId) => {
    const step = draftState.domain.methodStepCollection.data[methodStepId];
    const attachmentIds = step.relationships.attachments.data.map(a => a.id);

    draftState.ui.ordering[`methodStepAttachments--${methodStepId}`] = { list: attachmentIds, inProgress: false };
  })
}

function setCombinedSequenceOrderGroups(draftState) {
  draftState.domain.projectTradeCollection.allDataIds.forEach((projectTradeId) => {
    const projectTrade = draftState.domain.projectTradeCollection.data[projectTradeId];
    const projectTradeMasterSequenceIds = findHasManyRelations(draftState, { type: 'trade', id: projectTrade.relationships.trade.data.id }, 'masterMethodSequence').map((mms) => mms.id);
    const existingMethodSequences = findHasManyRelations(draftState, { type: 'trade', id: projectTrade.relationships.trade.data.id }, 'methodSequence');

    const list = combinedSequencesList({
      projectTradeMasterSequenceIds: projectTradeMasterSequenceIds,
      existingMethodSequences: existingMethodSequences
    })

    draftState.ui.ordering[`projectTradeSequences--${projectTradeId}`] = { list: list, inProgress: false }
  })
}

function deriveOrderings(draftState) {
  setMethodStepOrderGroups(draftState)
  setAttachmentOrderGroups(draftState)
  setCombinedSequenceOrderGroups(draftState)
}

function addSequenceToCombinedList(draftState, payload, projectTradeId) {
  // for regular vs custom method sequences
  const existingList = draftState.ui.ordering[`projectTradeSequences--${projectTradeId}`].list;
  if (payload.data.relationships.masterMethodSequence.data) {
    const combinedSequence = existingList.find((combinedSequence) => {
      return combinedSequence.masterSequenceId === payload.data.relationships.masterMethodSequence.data.id
    })
    combinedSequence.sequenceId = payload.data.id;
  } else {
    existingList.push({ sequenceId: payload.data.id, masterSequenceId: null })
  }
}

function removeSequenceFromCombinedList(draftState, payload, projectTradeId) {
  const existingList = draftState.ui.ordering[`projectTradeSequences--${projectTradeId}`].list;
  const combinedSequencePosition = existingList.findIndex((combinedSequence) => combinedSequence.sequenceId === payload.id)

  if (existingList[combinedSequencePosition].masterSequenceId) {
    existingList[combinedSequencePosition].sequenceId = null;
  } else {
    existingList.splice(combinedSequencePosition, 1)
  }
}

function assignStepAttachmentOrdering(draftState, step) {
  const attachmentIds = step.relationships.attachments.data.map(a => a.id);

  draftState.ui.ordering[`methodStepAttachments--${step.id}`] = { list: attachmentIds, inProgress: false };
}

function removeFromStepAttachmentOrdering(draftState, methodStepId, attachmentId) {
  const attachmentIndex = draftState.ui.ordering[`methodStepAttachments--${methodStepId}`].list.findIndex(id => id === attachmentId);

  draftState.ui.ordering[`methodStepAttachments--${methodStepId}`].list.splice(attachmentIndex, 1)
}

function assignSequenceStepOrdering(draftState, payload) {
  draftState.ui.ordering[`methodSequenceSteps--${payload.data.id}`] = payload.included.reduce((accumulator, currentValue) => {
    if (currentValue.type === 'methodStep') {
      accumulator.list.push(currentValue.id)
      assignStepAttachmentOrdering(draftState, currentValue)
    }
    return accumulator
  }, { inProgress: false, list: [] })
}

function removeSequenceStepsOrdering(draftState, sequenceId) {
  delete draftState.ui.ordering[`methodSequenceSteps--${sequenceId}`]
}

function addStepToOrderedGroup(draftState, sequenceId, stepId) {
  draftState.ui.ordering[`methodSequenceSteps--${sequenceId}`].list.push(stepId)
}

function addNewAttachmentFromPayload(draftState, methodStepId, attachmentKey, payload) {
  draftState.application.newAttachments[attachmentKey] = payload;
  if (!draftState.ui.adding[`methodStepAttachmentKeys--${methodStepId}`]) {
    draftState.ui.adding[`methodStepAttachmentKeys--${methodStepId}`] = [];
  }
  draftState.ui.adding[`methodStepAttachmentKeys--${methodStepId}`].push(attachmentKey)
}

function replaceAttachment(draftState, methodStepId, attachmentId, attachmentKey) {
  draftState.ui.ordering[`methodStepAttachments--${methodStepId}`].list.push(attachmentId)
  const newAttachmentKey = draftState.ui.adding[`methodStepAttachmentKeys--${methodStepId}`].findIndex(key => key === attachmentKey);
  draftState.ui.adding[`methodStepAttachmentKeys--${methodStepId}`].splice(newAttachmentKey, 1)
}

export function orderingStyling({ containerRef, node, index, collapsedItemSpace }) {
  const heightOptions = { height: containerRef.current.offsetHeight, paddingTop: (node.offsetTop - (index * collapsedItemSpace)) };
  return heightOptions;
}

export default function sequenceOfOperationsPageReducer(state = initialState, action) {
  return (
    produce(state, draftState => {
      switch (action.type) {
        case 'LOAD_COLLECTION':
          loadfromPayload(draftState, action.payload, action.resourceType)
          deriveOrderings(draftState)
          break;
        case 'ADD_RESOURCE':
          addFromPayload(draftState, action.payload)
          break;
        case 'REMOVE_RESOURCE':
          removeResource(draftState, action.payload)
          break;
        case 'UPDATE_RESOURCE':
          replaceResource(draftState, action.payload.data)
          populateResources(draftState, action.payload.included)
          break;
        case 'ADD_METHOD_SEQUENCE':
          addFromPayload(draftState, action.payload)
          addSequenceToCombinedList(draftState, action.payload, action.projectTradeId)
          assignSequenceStepOrdering(draftState, action.payload)
          break;
        case 'REMOVE_METHOD_SEQUENCE':
          removeResource(draftState, action.payload)
          removeSequenceFromCombinedList(draftState, action.payload, action.projectTradeId)
          removeSequenceStepsOrdering(draftState, action.payload.id)
          break;
        case 'ADD_METHOD_STEP':
          addFromPayload(draftState, action.payload)
          addStepToOrderedGroup(draftState, action.methodSequenceId, action.payload.data.id)
          draftState.ui.ordering[`methodStepAttachments--${action.payload.data.id}`] = { list: [], inProgress: false }
          break;
        case 'ADD_NEW_ATTACHMENT':
          addNewAttachmentFromPayload(draftState, action.methodStepId, action.attachmentKey, action.payload)
          break;
        case 'REPLACE_NEW_ATTACHMENT':
          addFromPayload(draftState, action.payload, { collectionType: 'attachment' })
          replaceAttachment(draftState, action.methodStepId, action.attachmentId, action.attachmentKey)
          break;
        case 'UPDATE_ATTACHMENT':
          draftState.domain.attachmentCollection.data[action.attachmentId].attributes[action.name] = action.value
          break;
        case 'REMOVE_ATTACHMENT_FROM_LIST':
          removeFromStepAttachmentOrdering(draftState, action.methodStepId, action.attachmentId)
          break;
        case 'UPDATE_ATTACHMENT_ORDERING':
          draftState.ui.ordering[`methodStepAttachments--${action.methodStepId}`] = {
            list: action.newOrder,
            inProgress: false
          }
          break;
        case 'EDITING_CONTENT_CHANGE':
          draftState.application.editingContent[action.key] = action.value;
          break;
        case 'UI_TOGGLE':
          draftState.ui.toggling[action.key] = action.value;
          break;
        case 'CLEAR_TOGGLE_CONTENT':
          draftState.ui.toggling[action.key] = false;
          delete draftState.application.editingContent[action.key]
          break;
        case 'START_STEPS_ORDERING':
          draftState.ui.ordering.anyOrdering = true;
          draftState.ui.ordering[`methodSequenceSteps--${action.methodSequenceId}`].inProgress = true;
          draftState.ui.styling.projectTrades = orderingStyling(action.options);
          break;
        case 'FINISH_STEPS_ORDERING':
          draftState.ui.ordering.anyOrdering = false;
          draftState.ui.ordering[`methodSequenceSteps--${action.methodSequenceId}`] = {
            list: action.newOrder,
            inProgress: false
          }
          draftState.ui.styling.projectTrades = { height: 'auto', paddingTop: 0 };
          break;
        case 'START_PROJECT_TRADE_ORDERING':
          draftState.ui.ordering.anyOrdering = true;
          draftState.ui.ordering.projectTrades = true;
          draftState.ui.styling.projectTrades = orderingStyling(action.options);
          break;
        case 'FINISH_PROJECT_TRADE_ORDERING':
          draftState.ui.ordering.anyOrdering = false;
          draftState.domain.projectTradeCollection.allDataIds = action.newOrder;
          draftState.ui.ordering.projectTrades = false;
          draftState.ui.styling.projectTrades = { height: 'auto', paddingTop: 0 };
          break
        case 'START_SEQUENCE_ORDERING':
          draftState.ui.ordering.anyOrdering = true;
          draftState.ui.ordering[`projectTradeSequences--${action.projectTradeId}`].inProgress = true;
          draftState.ui.styling.projectTrades = orderingStyling(action.options);
          break;
        case 'FINISH_SEQUENCE_ORDERING':
          draftState.ui.ordering.anyOrdering = false;
          draftState.ui.ordering[`projectTradeSequences--${action.projectTradeId}`] = {
            inProgress: false,
            list: action.newOrdering
          }
          draftState.ui.styling.projectTrades = { height: 'auto', paddingTop: 0 };
          break;
        case 'SET_UI_FOCUSED':
          draftState.ui.focused[action.key] = action.value
          break;
        case 'ADD_ERROR':
          draftState.application.errors.data[action.errorKey] = action.payload;
          draftState.application.errors.allDataKeys.push(action.errorKey)
          break;
        case 'REMOVE_ERROR':
          const errorIndex = draftState.application.errors.allDataKeys.findIndex(errorKey => errorKey === action.errorKey);
          delete draftState.application.errors.data[action.errorKey]
          errorIndex > -1 && draftState.application.errors.allDataKeys.splice(errorIndex, 1)
          break;
      }
    })
  )
}
