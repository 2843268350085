import React from 'react';
import PropTypes from 'prop-types';

export default function TruncatedPill({ className = '', children }) {
  return (
    <span className={`truncated-pill m-r-8${className ? ` ${className}` : ''}`}>
      <span className='truncated-text-container'>{children}</span>
    </span>
  )
}

TruncatedPill.propTypes = {
  className: PropTypes.string
}
