import React from 'react';

export default function PanelIcon(props) {
  const iconFade = props.scrolledAmount < 20 ? 'popup--fade-in' : 'popup--fade-out';

  return(
    <div className={`popup__porthole tw-border-white tw-bg-mint-500 [&_svg_path]:tw-fill-white transition-enabled ${iconFade}`}>
      <props.Icon width={32} height={32}/>
    </div>
  )
}
