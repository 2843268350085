import React from 'react';
import PropTypes from 'prop-types';
import TextArea from 'components/application/TextArea';
import DatePickerField from 'components/application/DatePickerField';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import ErrorMessage from 'components/application/ErrorMessage';

export default function BookingSidePanelForm(props) {
  const {
    requestError,
    currentBooking,
    removeErrorStyling,
    onDateChange,
    onInputChange
  } = props;

  return (
    <div className='popup__body-form--light'>
      <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-b-20'>
        <div className='m-r-12 fh-20'>
          <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
        </div>
        <p className='m-0'>
          Email notifications will be sent to the personnel and their line manager (if they have email addresses).
        </p>
      </div>
      <div className={'m-b-24'}>
        <ErrorMessage validationErrors={requestError.validationErrors} isFallback={requestError.isFallback} modifiers='side-panel__alert side-panel__alert--danger side-panel__alert--wide' />
      </div>
      <div className='form-container'>
        <div className='flex flex--justify-content__flex-start'>
          <div className='m-r-12'>
            <DatePickerField
              label='Date'
              name='date'
              value={currentBooking.date}
              fieldError={requestError.validationErrors.date}
              isRequired={true}
              removeErrorStyling={removeErrorStyling}
              onChange={onDateChange}
              earliestDate={new Date()}
            />
          </div>
        </div>
        <TextArea
          label='Booking notes'
          name='notes'
          value={currentBooking.notes}
          onChange={onInputChange}
          tooltip='Booking notes are included in the email'
        />
      </div>
    </div>
  )
}

BookingSidePanelForm.propTypes = {
  requestError: PropTypes.object,
  currentBooking: PropTypes.object,
  removeErrorStyling: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
}
