import tailwindConfig from "stylesheets/tailwind.config.js";

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import voca from "voca";
import qs from "qs";

import { BarChart, XAxis, YAxis, Bar, Cell, Label, LabelList } from "recharts";

import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useRamsAnalyticsDashboardContext } from "components/contexts/RamsAnalyticsDashboardContext";
import { useBreadBoard } from "components/contexts/Toaster";
import useFilters from "components/ramsAnalytics/hooks/useFilters";
import useKeyHighlight from "components/ramsAnalytics/hooks/useKeyHighlight";
import useProjectsModal from "components/ramsAnalytics/hooks/useProjectsModal";

import { filtersListFormatter, filtersFormatter } from "components/ramsAnalytics/helpers/reportHeader";
import { formatPaginatedProjects } from "components/ramsAnalytics/helpers/projectsModal";
import { getNextPageParam } from "components/ramsAnalytics/helpers/reactQuery";

import Card from "components/ramsAnalytics/components/Card";
import ReportHeader from "components/ramsAnalytics/components/ReportHeader";
import ProjectsModal from "components/ramsAnalytics/components/ProjectsModal";
import NoDataMessage from "components/ramsAnalytics/components/NoDataMessage";

import Tooltip from "components/application/Tooltip";

const colours = tailwindConfig.theme.colors;

export default function TopRisksCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({ live: true, future: true, endedAndArchived: false });

  const filterParams = Object.keys(filters).reduce((acc, filter) => {
    filter.split("And").forEach((subFilter) => acc[voca.lowerCase(subFilter)] = filters[filter])
    return acc
  }, {})

  const fetchTopRisksReport = () => axios.post("/rams_analytics/top_risks_report", { top_risks_report: filterParams });

  const {
    data: topRisksReport
  } = useQuery({
    queryKey: ["topRisksReport", filterParams],
    queryFn: fetchTopRisksReport,
    keepPreviousData: true,
    onError: breadBoard.addInedibleToast
  });

  const {
    countBreakdown = {},
    liveFilterCount,
    futureFilterCount,
    endedAndArchivedFilterCount
  } = topRisksReport ? topRisksReport.data.data.attributes : {};

  const filterCounts = { live: liveFilterCount, future: futureFilterCount, endedAndArchived: endedAndArchivedFilterCount };
  const filterDots = { live: "green", future: "amber", endedAndArchived: "grey" };

  const chartProps = useMemo(() => {
    const data = Object.keys(countBreakdown).map((risk) => ({ name: risk, count: countBreakdown[risk] }));
    for (let i = data.length; i < 5; i++) data.push({ name: null, count: null });

    const appliedFilters = Object.keys(filters).filter((filter) => filters[filter]);
    const totalAppliedFilterCount = appliedFilters.reduce((acc, filter) => acc + filterCounts[filter], 0);
    const xAxisDomain = [0, totalAppliedFilterCount];

    return { data, xAxisDomain }
  }, [JSON.stringify(topRisksReport)]);

  const { projectsModal, openProjectsModal, closeProjectsModal } = useProjectsModal();

  const fetchProjects = (page) => {
    const queryString = qs.stringify({ filter: { ...filterParams, ...projectsModal.filters }, page });

    return axios.get(`/rams_analytics/top_risks_report/projects?${queryString}`)
  }

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects
  } = useInfiniteQuery({
    queryKey: ["projects", "topRisksReport", { ...filterParams, ...projectsModal.filters }],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast
  });

  const handleSegmentClick = ({ entry }) => {
    openProjectsModal({
      title: `Top risks: ${entry.name}`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: entry.count }),
      appliedFilters: { activity_name: { eq: entry.name } }
    })

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Top risks",
      segment: entry.name,
      filters: filters
    })
  }

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: project click", {
      chart: "Top risks",
      project: project.name,
      filters: filters
    })
  }

  return (
    <Card>
      <ReportHeader
        title="Top risks"
        subTitle={filtersListFormatter({ filters, ramsPluralName: ramsAnalyticsDashboardContext.ramsPluralName })}
        filters={filtersFormatter({ filters, filterCounts, filterDots })}
        minChecked={1}
        onFilterChange={handleFilterChange}
      />
      {topRisksReport && (
        Object.keys(countBreakdown).length > 0 ? (
          <ReportBody chartProps={chartProps} countBreakdown={countBreakdown} onSegmentClick={handleSegmentClick} />
        ) : (
          <NoDataMessage message="Please check the applied filters" />
        )
      )}
      <ProjectsModal
        isOpen={projectsModal.isOpen}
        title={projectsModal.title}
        subTitle={projectsModal.subTitle}
        projects={projects}
        hasNextPage={hasNextPageOfProjects}
        closeModal={closeProjectsModal}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
      />
    </Card>
  )
}

function ReportBody({ chartProps, countBreakdown, onSegmentClick }) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const { highlightedKey, handleKeyHighlight, handleKeyUnhighlight } = useKeyHighlight(null);

  const cellFill = (key) => {
    return (highlightedKey && key !== highlightedKey) ? colours.grey[100] : colours.amber[100]
  }

  return (
    <div className="tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-pr-6 tw-pb-5 tw-pl-6 -tw-ml-1 -tw-mr-1">
      <BarChart
        layout="vertical"
        width={522}
        height={212}
        data={chartProps.data}
        barSize={32}
        margin={{ top: 0, right: 6, left: 0, bottom: 0 }}
        onMouseLeave={handleKeyUnhighlight}
        // unhighlight when over axis (event exists only when over bar chart, not when over axis)
        onMouseMove={(event) => { if (!event && highlightedKey) handleKeyUnhighlight() }}
      >
        <XAxis
          type="number"
          height={32}
          axisLine={false}
          tickLine={{ stroke: colours.grey[400], strokeWidth: 2 }}
          tick={false}
          domain={chartProps.xAxisDomain}
        >
          <Label
            value={`${voca.capitalize(ramsAnalyticsDashboardContext.ramsPluralName)} using risk assessment`}
            position="insideBottom"
            style={{ fill: colours.grey[500], fontFamily: "Inter", fontSize: "12px", fontStyle: "normal", fontWeight: 400 }}
          />
        </XAxis>
        <Bar dataKey="count" background={{ fill: colours.grey["050"] }} >
          {chartProps.data.map((entry) => (
            <Cell key={entry.name} cursor={entry.count ? "pointer" : "default"} fill={cellFill(entry.name)} onMouseEnter={() => handleKeyHighlight(entry.name)} onClick={() => onSegmentClick({ entry })} />
          ))}
          <LabelList dataKey="name" position="right" content={(props) => <CustomizedLabel {...props} countBreakdown={countBreakdown} />} />
        </Bar>
        <YAxis
          type="category"
          width={6}
          tick={false}
          axisLine={{ stroke: colours.grey[400], strokeWidth: 1 }}
        />
      </BarChart>
    </div>
  )
}

ReportBody.propTypes = {
  chartProps: PropTypes.shape({
    data: PropTypes.array.isRequired,
    xAxisDomain: PropTypes.array.isRequired
  }),
  countBreakdown: PropTypes.object.isRequired,
  onSegmentClick: PropTypes.func.isRequired
};

export function CustomizedLabel({ x, y, height, value, countBreakdown }) {
  return (
    <g className="tw-pointer-events-none">
      <foreignObject
        x={x}
        y={y}
        width={510}
        height={height}
      >
        <div className="tw-flex tw-items-center tw-font-inter tw-text-s tw-font-medium tw-tracking-wide tw-h-8 tw-px-3">
          {value ? (
            <>
              <div className="tooltip-parent tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-cursor-pointer tw-text-grey-700">
                {value}
                {value.length > 64 && <Tooltip placement="top" trigger="hover" tooltip={value} />}
              </div>
              <div className="tw-ml-3">{countBreakdown[value]}</div>
            </>
          ) : (
            <div className="tw-text-grey-400">&ndash;</div>
          )}
        </div>
      </foreignObject>
    </g>
  )
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  value: PropTypes.string,
  countBreakdown: PropTypes.object.isRequired
};
