import React from 'react';
import PropTypes from 'prop-types';
import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

ResetPasswordModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default function ResetPasswordModal({ firstName, email, onReset, onClose }) {
  return(
    <ModalBox
      mode='letterbox'
      isOpen={true}
      usingStandardDimensions={true}
      onClose={onClose}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton color='red' onClick={onReset}>
            Reset password
          </FilledButton>
        </div>
      }
    >
      <>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
            {`Reset Password for ${firstName}?`}
          </h2>
        </div>
        <div className='modalbox-body'>
          {`An email will be sent to ${email} with a link to change their password. Their password won't change until they access the link and create a new one.`}
        </div>
      </>
    </ModalBox>
  )
}
