import { useState } from 'react';
import PropTypes from 'prop-types';

export default function useValidationErrors(initialValidationErrors) {
  const [validationErrors, setValidationErrors] = useState(initialValidationErrors);

  const handleValidationErrorsAssignment = (messages) => {
    const newValidationErrors = { ...validationErrors };

    Object.keys(messages).forEach((key) => {
      newValidationErrors[key] = { messages: messages[key] };
    })

    setValidationErrors(newValidationErrors)
  }

  const handleValidationErrorsReset = () => {
    setValidationErrors(initialValidationErrors)
  }

  return {
    validationErrors,
    handleValidationErrorsAssignment,
    handleValidationErrorsReset
  }
}

export const validationErrorsShape = PropTypes.shape({
  validationErrors: PropTypes.object.isRequired,
  handleValidationErrorsAssignment: PropTypes.func.isRequired,
  handleValidationErrorsReset: PropTypes.func.isRequired
});
