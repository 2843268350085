import React, {useState} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import _ from 'lodash';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import ErrorMessage from 'components/application/ErrorMessage';
import ProjectContentReviewFooter from 'components/projectReviews/ProjectContentReviewFooter';
import { personDisplayName } from '../helpers/users.js'

// fields
import { components } from 'react-select';
import Async from 'react-select/async';
import TextArea from 'components/application/TextArea';
import RadioField from 'components/application/RadioField';
import CheckboxField from 'components/application/CheckboxField';
import DatePickerField from 'components/application/DatePickerField';
import { NoOptionsMessage, ClearIndicator } from 'components/application/CollectionSelect';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={`collection-select__option_container`}>
        {props.label}
      </div>
    </components.Option>
  );
};

const AssignButton = (user, handleReviewOptionChange) => {
  const onClick = () => {
    handleReviewOptionChange({
      value: {
        firstName: user.data.attributes.firstName,
        lastName: user.data.attributes.lastName,
        id: user.data.id
      }
    },
      {
        name: 'assignedUser'
      }
    )
  }

  return (
    <button
        className={'app-link tw-align-baseline tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300 p-0 b-0'}
        onClick={onClick}
      >
      {`${user['data']['attributes']['firstName']} ${user['data']['attributes']['lastName']}`}
    </button>
  )
}

const scheduledContentReviewRequestDueIn = (reviewDueDate) => {
  const dueDate = moment(reviewDueDate).startOf('day')
  const tomorrow = moment().add(1, 'day').startOf('day')
  const now = moment().startOf('day')

  if (dueDate.isSame(now)) {
    return 'due today'
  } else if (dueDate.isSame(tomorrow)) {
    return 'due tomorrow'
  } else if (dueDate.isAfter(tomorrow)) {
    return `due ${now.to(dueDate)}`
  } else {
    return 'overdue'
  }
};

export default function ProjectContentReviewManager({projectContentReview, isReviewing, companyProjectDisplayName}) {
  const {
    defaultProjectContentReviewFrequency,
    suggestedDueDate,
    scheduledContentReviewRequest,
    projectCreator,
    currentUser,
    isEditableHistory,
    reasonIsRequired,
    projectContentReviewsAllowed,
    projectId,
    projectEndDate
  } = projectContentReview['data']['attributes']

  const defaultReview = {
    isScheduling: null,
    isReviewing: isReviewing,
    dueDate: moment(suggestedDueDate).isValid() && moment.parseZone(suggestedDueDate).toDate(),
    reason: '',
    assignedUser: {
      firstName: '',
      lastName: '',
      id: ''
    }
  }

  const [requestError, submitDisabled, removeErrorStyling, , handleRequestError] = useRequestError();
  const [currentReview, setCurrentReview, handleReviewInputChange, handleReviewOptionChange, , handleReviewDateChange] = useForm(defaultReview);
  const [contentReviewSubmitting, setContentReviewSubmitting] = useState(false)

  const shoulDisplayCompleteReview = projectContentReviewsAllowed && scheduledContentReviewRequest
  const shouldDisplayScheduleReview = (scheduledContentReviewRequest && currentReview.isReviewing) || (!scheduledContentReviewRequest) && projectContentReviewsAllowed

  const shouldShowDateWarningMessage = currentReview.dueDate > moment.parseZone(projectEndDate) || currentReview.dueDate > moment.parseZone(suggestedDueDate).toDate()

  const dateWarningMessage = () => {
    if (currentReview.dueDate > moment.parseZone(projectEndDate)) {
      return 'Date is after RAMS ends so review notifications won\'t be sent'
    } else if (currentReview.dueDate > moment.parseZone(suggestedDueDate).toDate()) {
      return 'Date exceeds recommended review frequency';
    }
  };

  const setProjectContentReviewParams = () => {
    const params = { schedule_option_selected: currentReview.isScheduling }

    if (currentReview.isReviewing && scheduledContentReviewRequest['data']) { params['scheduled_content_review_request'] = { id: scheduledContentReviewRequest['data']['id'] }  };
    if (currentReview.isScheduling === 'checked') { params['new_content_review_request'] = { due_date: moment.parseZone(currentReview.dueDate).format('DD/MM/YYYY'), assignee_id: currentReview.assignedUser.id } };
    if (currentReview.reason) { params['new_history'] = { reason: currentReview.reason } };

    return { project_content_review: params }
  };

  const submitProjectContentReview = () => {
    setContentReviewSubmitting(true)
    axios
      .post(`/projects/${projectId}/project_content_reviews`, setProjectContentReviewParams())
      .then(response => {
        if (response.status === 201) { window.location.assign(`/projects/${projectId}`) }
      })
      .catch(handleRequestError)
      .finally(() => {
        setContentReviewSubmitting(false)
      })
  }

  const fetchAssignableUsers = React.useCallback(
    _.debounce((inputValue, callback) => {
      axios
        .get(`/users?search=${inputValue}`)
        .then(response => response.data.data.map(user => ({ label: personDisplayName(user['attributes']), value: { firstName: user['attributes']['firstName'], lastName: user['attributes']['lastName'], id: user['id'] } })))
        .then(callback)
        .catch(console.error)
    }, 500, { maxWait: 1000}), []
  );

  return (
    <>
      <div className='content-box p-40 tw-border-grey-100 tw-bg-white'>
        <ErrorMessage
          validationErrors={requestError.validationErrors}
          isFallback={requestError.isFallback}
          containerClassnames='m-b-32 fw-376'
        />
        {
          isEditableHistory && (
            <div className='fw-376 m-b-32'>
              <TextArea
                label={'Reason for editing'}
                name={'reason'}
                fieldError={requestError.validationErrors.reasonForEditing}
                isRequired={reasonIsRequired}
                isOptional={!reasonIsRequired}
                value={currentReview.reason}
                onChange={handleReviewInputChange}
                onFocus={() => removeErrorStyling({ target: { name: 'reasonForEditing' } })}
              />
            </div>
          )
        }
        {
          shoulDisplayCompleteReview &&
            <div className='fw-260'>
              <CheckboxField
                label='Entire document was reviewed'
                name='isReviewing'
                disabled={false}
                checked={currentReview.isReviewing}
                onChange={handleReviewInputChange}
              />
              <div className='tw-text-s p-l-32 m-b-24'>
                Next review is { scheduledContentReviewRequestDueIn(scheduledContentReviewRequest['data']['attributes']['dueDate']) }
              </div>
            </div>
        }
        {
          shouldDisplayScheduleReview && (
            <div className={currentReview.isReviewing ? 'p-l-32' : ''}>
              <div className='tw-font-medium'>
                Schedule next review
              </div>
              <div className='tw-text-s m-t-4'>
                { companyProjectDisplayName } should be reviewed every { defaultProjectContentReviewFrequency }
              </div>
              <div>
                <div className='m-b-16'>
                  <RadioField label={'Schedule now'} checked={currentReview.isScheduling === 'checked'} onChange={() => { setCurrentReview({...currentReview, isScheduling: 'checked'})} } name={'isScheduling'} />
                </div>
                  {
                    currentReview.isScheduling === 'checked' && (
                      <div className='m-l-30'>
                        <div className='m-b-24'>
                          <DatePickerField
                            label='Next review date'
                            name='dueDate'
                            value={currentReview.dueDate}
                            onChange={handleReviewDateChange}
                            earliestDate={new Date()}
                            fieldError={requestError.validationErrors.nextReviewDate}
                            removeErrorStyling={() => removeErrorStyling({ target: { name: 'nextReviewDate' } })}
                          />
                        </div>
                        <div className='fw-348'>
                          {shouldShowDateWarningMessage &&
                            <p className='tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 m-0 m-t-16 m-b-24'>
                              { dateWarningMessage() }
                            </p>
                          }
                        </div>
                        <div className='m-b-24'>
                          <span className='field__label tw-font-medium'>
                            Assigned to
                          </span>
                          <div className='mw-348 m-b-8'>
                            <Async
                              isClearable
                              name={'assignedUser'}
                              className={`collection-select__select-container ${requestError.validationErrors.assignedUser ? (requestError.validationErrors.assignedUser.fieldHighlighted ? ' field__invalid' : '') : ''}`}
                              classNamePrefix='collection-select'
                              value={currentReview.assignedUser.firstName && { label: `${currentReview.assignedUser.firstName} ${currentReview.assignedUser.lastName}`, value: { id: currentReview.assignedUser.id } }}
                              cacheOptions={true}
                              placeholder={'Type to search...'}
                              openMenuOnClick={false}
                              onChange={handleReviewOptionChange}
                              onFocus={() => removeErrorStyling({ target: { name: 'assignedUser' } })}
                              components={{ NoOptionsMessage, ClearIndicator, DropdownIndicator: () => null, Option }}
                              loadOptions={fetchAssignableUsers}
                            />
                          </div>
                          <span className="flex">
                            <span className="tw-font-normal">Assign to {AssignButton(currentUser, handleReviewOptionChange)}</span>
                            { projectCreator && <span className="tw-font-normal ws-pre-wrap"> or {AssignButton(projectCreator, handleReviewOptionChange)}</span> }
                          </span>
                        </div>
                      </div>
                    )
                  }
                <RadioField label={'Don\'t schedule'} checked={currentReview.isScheduling === 'notChecked'} onChange={() => setCurrentReview({...currentReview, isScheduling: 'notChecked'})} name={'isScheduling'}/>
              </div>
            </div>
          )
        }
      </div>
      <ProjectContentReviewFooter projectId={projectId} submitProjectContentReview={submitProjectContentReview} disabled={submitDisabled} contentReviewSubmitting={contentReviewSubmitting} isReviewing={isReviewing}/>
    </>
  )
}

ProjectContentReviewManager.propTypes = {
  isReviewing: PropTypes.bool.isRequired,
  companyProjectDisplayName: PropTypes.string.isRequired,
  projectContentReview: PropTypes.exact({
    data: PropTypes.exact({
      attributes: PropTypes.exact({
        defaultProjectContentReviewFrequency: PropTypes.string.isRequired,
        suggestedDueDate: PropTypes.string,
        scheduledContentReviewRequest: PropTypes.exact({
          data: PropTypes.exact({
            id: PropTypes.string.isRequired,
            type: PropTypes.string,
            attributes: PropTypes.exact({
              completed: PropTypes.bool.isRequired,
              dueDate: PropTypes.string.isRequired
            }),
            relationships: PropTypes.object
          })
        }),
        projectCreator: PropTypes.object,
        currentUser: PropTypes.exact({
          data: PropTypes.exact({
            id: PropTypes.string.isRequired,
            type: PropTypes.string,
            attributes: PropTypes.exact({
              email: PropTypes.string,
              firstName: PropTypes.string.isRequired,
              lastName: PropTypes.string
            })
          })
        }),
        isEditableHistory: PropTypes.bool.isRequired,
        reasonIsRequired: PropTypes.bool.isRequired,
        projectContentReviewsAllowed: PropTypes.bool.isRequired,
        projectId: PropTypes.number.isRequired,
        projectEndDate: PropTypes.string
      }),
      id: PropTypes.string.isRequired,
      type: PropTypes.string
    })
  })
}
