import React from 'react';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import Tooltip from 'components/application/Tooltip';
import CustomPersonsSidePanel from 'components/riskRegister/CustomPersonsSidePanel';
import useSidePanel from 'components/hooks/useSidePanel';
import useCollection from 'components/hooks/useCollection';
import arrayMove from 'array-move';
import useToasts from 'components/hooks/useToasts';
import ToastRack from 'components/application/ToastRack';

export default function CustomPersons(props) {
  const { isPrimaryDivision, primaryDivision, isEditable } = props;

  const defaultPersonsAtRisks = [
    { name: 'All site operatives', position: 1 },
    { name: 'Client employees', position: 2 },
    { name: 'Public', position: 3 },
    { name: 'User', position: 4 }
  ];

  const [personsAtRisks, loadCollection, handleChange, handleModify, cancelChanges, _setCollectionErrors, collectionErrors, submitDisabled, isValidationError, setIsFallback, resetCollectionErrors, removeErrorStyling] = useCollection([]);
  const [sidePanelIsOpen, , openSidePanel, closeSidePanel] = useSidePanel(false);
  const isDisabled = (!isPrimaryDivision || (isEditable === false));
  const validationErrorMessages = { isBlank: "Entry can't be blank", isDuplicate: "Entries can't be the same" };
  const [toasts, , , , addInedibleToast] = useToasts();

  const tooltipText = () => {
    if (!isPrimaryDivision) {
      return `Changes can only be made by ${primaryDivision.name}`
    } else if (isEditable === false) {
      return "Changes can't be made when using shared content"
    }
  };

  const handleOpenClick = () => {
    if (!personsAtRisks.loaded) { getPersonsAtRisks() }

    openSidePanel()
  };

  const handleDelete = (index) => {
    handleModify({ action: 'remove', index: index })
  };

  const handleAddButtonClick = () => {
    handleModify({ action: 'add', newData: { name: "", position: null } })
  };

  const handleCancelButtonClick = () => {
    closeSidePanel()
    cancelChanges()
  };

  const handleResetDefaults = () => {
    loadCollection(defaultPersonsAtRisks)
    resetCollectionErrors()
  };

  const personsAtRisksSettingsParams = ({ personsAtRisksResponse }) => {
    const existingPersonsAtRisks = [...personsAtRisksResponse.data.data];

    const persons_at_risks_attributes = personsAtRisks.collection.map((member, index) => {
      const existingMember = existingPersonsAtRisks.find(resource => trimmed(resource.attributes.name) === trimmed(member.name));
      existingMember && existingPersonsAtRisks.splice(existingPersonsAtRisks.indexOf(existingMember), 1)

      return {
        id: existingMember ? existingMember.id : null,
        name: trimmed(member.name),
        position: index + 1
      }
    });

    existingPersonsAtRisks.forEach((resource) => {
      persons_at_risks_attributes.push({ id: resource.id, _destroy: '1' })
    })

    return {
      persons_at_risks_settings: {
        persons_at_risks_attributes: persons_at_risks_attributes
      }
    }
  };

  const trimmed = (word) => {
    return word.trim()
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newList = [...personsAtRisks.collection];
    newList = arrayMove(newList, oldIndex, newIndex);
    loadCollection(newList)
  };

  const isBlank = (member) => {
    return member.name === '' ? 'isBlank' : false
  };

  const isDuplicate = (member, collection) => {
    const names = collection.map((item) => trimmed(item.name));
    const numOfMatches = names.filter((item) => item === trimmed(member.name)).length;

    return numOfMatches > 1 ? 'isDuplicate' : false
  };

  const displayValidationErrors = collectionErrors.errorList.reduce((acc, item) => {
    if (item) {
      acc[item.errorMessage] = {error: { detail: validationErrorMessages[item.errorMessage] }};
    }
    return acc
  }, {});

  const getPersonsAtRisks = () => {
    axios
      .get('/persons_at_risks')
      .then(response => {
        if (response.data.data.length > 0) {
          const newCollection = response.data.data.map(resource => resource.attributes);

          loadCollection(newCollection)
        } else {
          loadCollection(defaultPersonsAtRisks)
        }
      })
      .catch(_error => addInedibleToast())
  };

  const updatePersonsAtRisks = () => {
    axios
      .get('/persons_at_risks')
      .then(getResponse => {
        axios
          .patch('/persons_at_risks_settings', personsAtRisksSettingsParams({ personsAtRisksResponse: getResponse }))
          .then(patchResponse => {
            const newCollection = patchResponse.data.data.map(resource => resource.attributes);

            loadCollection(newCollection)
            resetCollectionErrors()
            closeSidePanel()
          })
          .catch(_error => {
            if (!isValidationError([isBlank, isDuplicate])) {
              setIsFallback(true)
            }
          })
      })
  };

  return (
    <React.Fragment>
      <div className='tooltip-parent'>
        <OutlinedButton size='sm' color='grey' onClick={isDisabled ? () => {} : handleOpenClick} disabled={isDisabled}>Edit person(s) at risk</OutlinedButton>
        {isDisabled && <Tooltip placement='top' trigger='hover' tooltip={tooltipText()} />}
      </div>
      <CustomPersonsSidePanel
        sidePanelIsOpen={sidePanelIsOpen}
        closeSidePanel={closeSidePanel}
        collection={personsAtRisks.collection}
        onAdd={handleAddButtonClick}
        onListInputChange={handleChange}
        onSave={updatePersonsAtRisks}
        onDelete={handleDelete}
        onCancel={handleCancelButtonClick}
        displayValidationErrors={displayValidationErrors}
        collectionErrors={collectionErrors}
        submitDisabled={submitDisabled}
        isFallback={collectionErrors.isFallback}
        resetDefaults={handleResetDefaults}
        onSortEnd={onSortEnd}
        removeErrorStyling={removeErrorStyling}
      />
      <ToastRack
        toasts={toasts}
      />
    </React.Fragment>
  )
}
