import React from 'react';
import PropTypes from 'prop-types';
import IconSelection from './IconSelection';

export default function PpeIconSelectionManager({ icons, iconAllocations }) {
  return (
    <div className="tw-grid tw-grid-cols-5 tw-gap-x-2 tw-gap-y-3">
      {icons.map((icon, index) => {
        const iconAllocation = iconAllocations.find(allocation =>
          String(allocation.attributes.iconId) === String(icon.attributes.id)
        );
        return (
          <IconSelection
            key={icon.id}
            icon={icon}
            iconAllocation={iconAllocation}
            index={index}
          />
        );
      })}
    </div>
  );
}

PpeIconSelectionManager.propTypes = {
  icons: PropTypes.array.isRequired,
  iconAllocations: PropTypes.array.isRequired,
};
