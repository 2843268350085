import React from 'react';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function TrainingDestroyModal(props) {
  const { isOpen, setIsOpen, currentTraining, deleteTraining } = props;

  const closeModalBox = () => { setIsOpen(false) };

  const handleDeleteClick = () => {
    closeModalBox()
    deleteTraining()
  };

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={closeModalBox}>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={handleDeleteClick}>
        Yes, delete existing training
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='letterbox'
      isOpen={isOpen}
      onClose={closeModalBox}
      customFooter={customFooter}
      usingStandardDimensions={true}
    >
      <React.Fragment>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
            Delete training for {currentTraining.course && currentTraining.course.attributes.name}
          </h2>
        </div>
        <div className='modalbox-body'>
          Deleting this training will remove all dates, notes and uploaded evidence. This action cannot be undone.
        </div>
      </React.Fragment>
    </ModalBox>
  )
}
