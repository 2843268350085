import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'components/application/CheckboxField';
import HomeIcon from '-!svg-react-loader?name=HomeIcon!icons/ic-home.svg';
import Tooltip from 'components/application/Tooltip';
import { uniqueValuesInArray } from 'components/helpers/arrays';

const standardHeaderClasses = 'content-table__header content-table__header--sticky content-table__header--padded tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-bg-grey-050 tw-border-grey-100'
const completePermissionsList = ['administrator', 'projectEditor', 'projectApprover', 'contentEditor', 'rolesAndCoursesEditor', 'personnelEditor', 'personnelViewer']
const accountWidePermissions = ['rolesAndCoursesEditor']
const defaultTooltipProps = { tooltipType: 'hover', tooltipDelay: 500 }

const accountwideAssignablePermissions = (divisions) => {
  const allPermissions = divisions.reduce((acc, curr) => {
    acc = acc.concat(curr.attributes.assignablePermissions)
    return acc
  }, [])

  return completePermissionsList.filter(value => uniqueValuesInArray(allPermissions).includes(value))
}

const calculateColSize = (isPermissionColumnShown) => {
  return [isPermissionColumnShown(['projectEditor']), isPermissionColumnShown(['projectApprover']), isPermissionColumnShown(['contentEditor'])].filter(value => value).length.toString()
}

const PermissionCell = ({ isAccessSelected, divisionPermissionsSelection, accountRolesSelection, division, accessAttribute, onAccessChange, onAccountRoleChange }) => {
  function checkedStatus() {
    switch(accessAttribute) {
      case 'projectEditor':
        return isAccessSelected
      case 'personnelViewer':
        return divisionPermissionsSelection['personnelViewer'] || divisionPermissionsSelection['personnelEditor'] || divisionPermissionsSelection['administrator']
      case 'rolesAndCoursesEditor':
        return accountRolesSelection['rolesAndCoursesEditor']
      default:
        return divisionPermissionsSelection[accessAttribute] || divisionPermissionsSelection['administrator']
    }
  }

  const isAvailablePermission = division.attributes.assignablePermissions.find(i => i === accessAttribute)
  const isAccountWidePermission = accountWidePermissions.includes(accessAttribute)

  function disabledStatus() {
    switch(accessAttribute) {
      case 'projectEditor':
        return isAccessSelected
      case 'administrator':
        return false
      case 'personnelViewer':
        return divisionPermissionsSelection['personnelEditor'] || divisionPermissionsSelection['administrator']
      default:
        return divisionPermissionsSelection['administrator']
    }
  }

  const tooltipProps = () => {
    let tooltip;

    switch(true) {
    case ('projectEditor' === accessAttribute) && !isAvailablePermission:
      tooltip = 'Projects are not enabled for this division'
      break;
    case ('projectEditor' === accessAttribute) && (divisionPermissionsSelection && (isAccessSelected && !divisionPermissionsSelection['administrator'])):
      tooltip = 'All users with access to a division can edit projects'
      break;
    case ('projectApprover' === accessAttribute) && !isAvailablePermission:
      tooltip = 'Approvals are not enabled for this division'
      break;
    case ('contentEditor' === accessAttribute) && !isAvailablePermission:
      tooltip = 'Content editing is turned off for this division'
      break;
    case ('rolesAndCoursesEditor' === accessAttribute):
      tooltip = `This is an account-wide permission. This allows users to edit roles${division.attributes.isCourseApplicableResource ? ' and courses' : ''} shared across all divisions in the account`
      break;
    case ('personnelViewer' === accessAttribute) && (divisionPermissionsSelection && divisionPermissionsSelection['personnelEditor']):
      tooltip = 'All users with permissions to edit personnel can view them'
      break;
    default:
      if ((divisionPermissionsSelection && divisionPermissionsSelection['administrator']) && (accessAttribute !== 'administrator') && isAvailablePermission ) {
        tooltip = 'Division administrators have all permissions local to the division'
        break;
      }
    }

    return { ...defaultTooltipProps, tooltip: tooltip }
  }

  let permissionCellContent;

  if (isAccountWidePermission) {
    permissionCellContent = <CheckboxField
                              name={accessAttribute}
                              checked={checkedStatus()}
                              disabled={false}
                              onChange={() => onAccountRoleChange(accessAttribute, !accountRolesSelection[accessAttribute], division.id)}
                              labelTextProps={{ className: 'tw-font-medium' }} {...tooltipProps()}
                            />
  } else {
    if (isAvailablePermission) {
      permissionCellContent = <CheckboxField
                                name={accessAttribute}
                                checked={isAccessSelected && checkedStatus()}
                                disabled={isAccessSelected && disabledStatus()}
                                onChange={() => onAccessChange(division, divisionPermissionsSelection, accessAttribute)}
                                labelTextProps={{ className: 'tw-font-medium' }} {...tooltipProps()}
                              />
    } else {
      permissionCellContent = <CheckboxField
                                name={accessAttribute}
                                checked={false}
                                onChange={() => {}}
                                disabled={true}
                                labelProps={{className: 'hhq-checkbox__container--disabled-inactive-filled'}}
                                labelTextProps={{ className: 'tw-font-medium' }} {...tooltipProps()}
                              />
    }
  }

  return (
    <td className='content-table__cell tw-border-grey-100'>
      <div className='fw-24 m-auto'>
        {permissionCellContent}
      </div>
    </td>
  )
}

const AccessControlRow = ({onDivisionSelection, isAccessSelected, divisionPermissionsSelection, accountRolesSelection, division, divisions, onAccessChange, onAccountRoleChange}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const hasAtLeastOnePermission = Object.values({ ...divisionPermissionsSelection, ...accountRolesSelection }).some(Boolean)
  const isProjectEditableDivision = division.attributes.assignablePermissions.includes('projectEditor')
  const tooltipProps = !isProjectEditableDivision && isAccessSelected && !hasAtLeastOnePermission && { ...defaultTooltipProps, tooltip: 'Set permissions for user to be able to utilise access to division' }

  return (
    <tr key={`accessControlRow--${division.id}`} className='content-table__row'>
      <td className='content-table__cell tw-border-grey-100'>
        <div className='fw-24 m-auto'>
          <CheckboxField
            onChange={() => onDivisionSelection({ id: division.id, selected: !isAccessSelected })}
            checked={isAccessSelected}
            value={`${division.id}`}
            name={'access'}
            labelTextProps={{ className: 'tw-font-medium' }}
            {...tooltipProps}
          />
        </div>
      </td>
      <td className='content-table__cell content-table__cell--padded tw-font-medium tw-border-grey-100 tw-text-grey-900'>
        <div className='flex'>
          <div className='flex flex--vertically-centered' onMouseEnter={() => setIsTooltipVisible(true)} onMouseLeave={() => setIsTooltipVisible(false)}>
            {division.attributes.primary && <div className='p-r-8'><Tooltip  tooltipShown={isTooltipVisible} placement='top' trigger={'none'} tooltip='Primary division'><HomeIcon width={14} height={12} className='[&_path]:tw-fill-grey-900' /></Tooltip></div>}
            {division.attributes.name}
          </div>
        </div>
      </td>
      {accountwideAssignablePermissions(divisions).map((attribute) =>
        <PermissionCell key={`access--${attribute}`} {...{ isAccessSelected, divisionPermissionsSelection, accountRolesSelection, division, onAccessChange: onAccessChange, onAccountRoleChange, accessAttribute: attribute }} />
      )}
    </tr>
  )
}

export default function AccessControlTable ({ formUser, divisions, onDivisionSelection, onAccessChange, onAccountRoleChange, personnelText, projectDisplayNameSingular, projectDisplayNamePlural }) {
  const isPermissionColumnShown = (permissions) => {
    return accountwideAssignablePermissions(divisions).includes(...permissions)
  }

  const isCourseApplicableResourceForAtLeastOneDivisionWithinAccount = divisions.some(division => division.attributes.isCourseApplicableResource)

  const assignablePermissionsDisplayText = {
    administrator: 'Administrator',
    projectEditor: `${projectDisplayNameSingular} editor`,
    projectApprover: `${projectDisplayNameSingular} approver`,
    contentEditor: 'Content editor',
    rolesAndCoursesEditor: isCourseApplicableResourceForAtLeastOneDivisionWithinAccount ? 'Roles & courses editor' : 'Roles editor',
    personnelEditor: 'Personnel editor',
    personnelViewer: 'Personnel viewer'
  }

  const handleAccessChange = (division, divisionPermissionsSelection, accessType) => {
    onAccessChange({
      id: division.id,
      accessSelection: { [accessType]: !divisionPermissionsSelection || !divisionPermissionsSelection[accessType] }
    })
  }

  return(
    <div className='m-l-32'>
      <table className='content-table m-t-40 w-100'>
        <thead>
          <tr>
            <th colSpan='3'></th>
            { isPermissionColumnShown(['projectEditor', 'projectApprover', 'contentEditor']) &&
                <th
                  className='content-table__header content-table__header--super content-table__header--grouped tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-bg-grey-050 tw-border-grey-100'
                  colSpan={calculateColSize(isPermissionColumnShown)}
                  scope='colgroup'
                >
                  {projectDisplayNamePlural}
                </th>
            }

            <th
              className='content-table__header content-table__header--super content-table__header--grouped tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-bg-grey-050 tw-border-grey-100'
              colSpan='3'
              scope='colgroup'
            >
              {personnelText}
            </th>
          </tr>
          <tr>
            <th className={`${standardHeaderClasses} content-table__header--x-small`}>Access</th>
            <th className={`${standardHeaderClasses} content-table__header--left`}>Division</th>
            {
              accountwideAssignablePermissions(divisions).map(permission => {
                return (
                  <th key={`accessControlHeader--${permission}`} className={`${standardHeaderClasses} content-table__header--small`}>{assignablePermissionsDisplayText[permission]}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          { divisions.map((division) => {
            const divisionPermissionsSelection = formUser.accessControls[division.id];
            const isAccessSelected = !!divisionPermissionsSelection;
            const accountRolesSelection = { rolesAndCoursesEditor: formUser.rolesAndCoursesEditor };

            return (
              <AccessControlRow
                key={`accessControlRow--${division.id}`}
                onDivisionSelection={onDivisionSelection}
                isAccessSelected={isAccessSelected}
                divisionPermissionsSelection={divisionPermissionsSelection}
                accountRolesSelection={accountRolesSelection}
                division={division} divisions={divisions}
                onAccessChange={handleAccessChange}
                onAccountRoleChange={onAccountRoleChange}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

AccessControlTable.propTypes = {
  formUser: PropTypes.object.isRequired,
  divisions: PropTypes.array.isRequired,
  onDivisionSelection: PropTypes.func.isRequired,
  onAccessChange: PropTypes.func.isRequired,
  onAccountRoleChange: PropTypes.func.isRequired,
  personnelText: PropTypes.string.isRequired
}

export {
  PermissionCell,
  AccessControlRow,
  accountwideAssignablePermissions
}
