import React, { useState, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SubcontractorsForm from './SubcontractorsForm';
import SubcontractorsShow from './SubcontractorsShow';
import PanelIcon from 'components/application/PanelIcon';
import SubcontractorIcon from '-!svg-react-loader?name=SubcontractorIcon!icons/ic-sub-contractors-large.svg';

export default function SubcontractorsSidePanel(props) {
  const {
    sidePanelContext,
    setSidePanelContext,
    currentSubcontractor,
    requestError,
    submitDisabled,
    removeErrorStyling,
    closeSidePanel,
    createSubcontractor,
    updateSubcontractor,
    sidePanelIsOpen,
    onSubcontractorInputChange,
    onDeleteClick,
    allowShowFooterContents
  } = props

  const [scrolledAmount, setScrolledAmount] = useState(0);
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();
  const openEditSubcontractor = () => { setSidePanelContext('edit')};

  const iconHeaderContent = (
    <PanelIcon
      Icon={SubcontractorIcon}
      scrolledAmount={scrolledAmount}
    />
  );

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary'>
      <h1 className='tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>{sidePanelContext === 'edit' ? 'Edit sub-contractor' : 'Add new sub-contractor'}</h1>
    </div>
  );

  const showBodyContent = (
    <SubcontractorsShow
      currentSubcontractor={currentSubcontractor}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  );

  const formBodyContent = (
    <SubcontractorsForm
      currentSubcontractor={currentSubcontractor}
      requestError={requestError}
      removeErrorStyling={removeErrorStyling}
      submitDisabled={submitDisabled}
      onSubcontractorInputChange={onSubcontractorInputChange}
      sidePanelFieldRef={sidePanelFieldRef}
    />
  );

  const newFooterContent = (
    <FormFooter
      text='Add new sub-contractor'
      onCancel={closeSidePanel}
      onSubmit={createSubcontractor}
      submitButtonDisabled={submitDisabled}
    />
  );

  const showFooterContent = (
    allowShowFooterContents &&
      <ShowFooter
        editType='sub-contractor'
        resource={currentSubcontractor.name}
        onEditClick={openEditSubcontractor}
        onDeleteClick={onDeleteClick}
        deleteDisabled={currentSubcontractor.isDeleteProhibited}
        deleteDisabledTooltip='Sub-contractors with personnel cannot be deleted'
      />
  )

  const editFooterContent = (
    <FormFooter
      text='Save changes'
      onCancel={closeSidePanel}
      onSubmit={updateSubcontractor}
      submitButtonDisabled={submitDisabled}
    />
  )

  const footerContent = () => {
    switch(sidePanelContext) {
      case 'new': return newFooterContent
      case 'show': return showFooterContent
      case 'edit': return editFooterContent
    }
  };

  const onBackdropClick = () => {
    const panelLocked = (['new', 'edit'].includes(sidePanelContext))
    if (!panelLocked) { closeSidePanel() }
  }

  return (
    <SidePanel
      color={sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={sidePanelIsOpen}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onBackdropClick}
      closeCallback={closeSidePanel}
      headerContent={sidePanelContext === 'show' ? iconHeaderContent : titleHeaderContent}
      bodyContent={sidePanelContext === 'show' ? showBodyContent : formBodyContent}
      bodyRef={sidePanelBodyRef}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      scrollHeader={sidePanelContext === 'show'}
      footerContent={footerContent()}
      contentContext={sidePanelContext}
      submitDisabled={submitDisabled}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
    />
  )
}
