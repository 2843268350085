import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DropdownIndicator, Option } from 'components/application/CollectionSelect';

export default function StateWrappedCollectionSelect(props) {
  const [value, setValue] = useState({ value: props.value, label: props.value })

  const options = () => {
    return props.options.map((value) => {
      return { value: value, label: value }
    })
  }

  const handleChange = (selectedValue) => {
    setValue({ value: selectedValue.value, label: selectedValue.label })
  }

  return (
    <Select
      className='collection-select__select-container'
      classNamePrefix='collection-select'
      id={props.id}
      name={props.name}
      value={value}
      options={options()}
      onChange={handleChange}
      components={{ DropdownIndicator, Option }}
    />
  )
}

StateWrappedCollectionSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string)
}
