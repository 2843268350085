import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ActionLink from 'components/buttons/ActionLink';

import UnsavedChangesModal from 'components/project/UnsavedChangesModal';

export default function SequenceOfOperationsFooter(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [action, setAction] = useState('')

  // find a better way to do this!
  const actionButton = () => {
    switch (action) {
      case "Next":
        return <ActionLink disableAfterClick url={`method${props.isReviewing ? '?review=true' : ''}`} styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light' linkText='Yes, leave page' />
      case "Previous":
        return <ActionLink disableAfterClick url={`risk_assessment?previous=true${props.isReviewing ? '&review=true' : ''}`} styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light' linkText='Yes, leave page' />
      case "Save and exit":
        return <ActionLink disableAfterClick url={`/projects/${props.projectId}?save=true${props.isReviewing ? '&review=true' : ''}`} rel={'no-follow'} dataMethod={'put'} styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--red tw-apply-filled-button--on-light' linkText='Yes, leave page' />
    }
  }

  const openModal = (action) => {
    setAction(action)
    setModalIsOpen(true)
  }

  return (
    <React.Fragment>
      <PortaledActionButtons direction={'left'}>
        <ActionLink
          disableAfterClick
          linkText={'Previous'}
          url={`risk_assessment?previous=true${props.isReviewing ? '&review=true' : ''}`}
          styleClasses={'tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--blue-grey tw-apply-outlined-button--on-dark nav-button'}
          performCheck={props.anyUnsaved}
          openUnsavedChangesModal={openModal}
        />
      </PortaledActionButtons>
      <PortaledActionButtons direction={'right'}>
        <ActionLink
          disableAfterClick
          linkText={'Next'}
          url={`method${props.isReviewing ? '?review=true' : ''}`}
          styleClasses={'tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--blue-grey tw-apply-outlined-button--on-dark nav-button m-r-12'}
          performCheck={props.anyUnsaved}
          openUnsavedChangesModal={openModal}
        />
        <ActionLink
          disableAfterClick
          linkText={'Save and exit'}
          url={`/projects/${props.projectId}?save=true${props.isReviewing ? '&review=true' : ''}`}
          dataMethod={'put'}
          rel={'no-follow'}
          styleClasses={'tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-dark nav-button'}
          performCheck={props.anyUnsaved}
          openUnsavedChangesModal={openModal}
        />
      </PortaledActionButtons>
      {(props.anyUnsaved && modalIsOpen) &&
        <UnsavedChangesModal
          header={<strong>Are you sure you want to leave the page?</strong>}
          body={(<p>Any unsaved changes will be lost</p>)}
          isOpen={modalIsOpen}
          onClose={() => {setModalIsOpen(false)}}
          actionButton={ actionButton() }
        />
      }
    </React.Fragment>
  )
}

const PortaledActionButtons = (props) => {
  const buttonLocation = document.getElementsByClassName(`project-actions-${props.direction}`)
  return ReactDOM.createPortal(props.children, buttonLocation[0])
}
