import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/application/Tabs';
import RolesSidePanelPersonnelTab from 'components/roles/RolesSidePanelPersonnelTab';
import RolesSidePanelCoursesTab from 'components/roles/RolesSidePanelCoursesTab';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import useWindowStorage from 'components/hooks/useWindowStorage';

import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

const defaultPersonnel = { loaded: false, collection: [], includedSubcontractors: [] };
const defaultPersonnelMeta = { currentPage: 0, isLastPage: false };

export default function RolesShow(props) {
  const {
    currentRole,
    assignableCourses,
    sidePanelIsOpen
  } = props;

  const currentActor = useCurrentActor();
  const breadBoard = useBreadBoard();

  const [getInitialActiveTab, setInitialActiveTab] = useWindowStorage('rolesSidePanelInitialTab', { store: window.sessionStorage });
  const initialActiveTab = getInitialActiveTab() || 'personnel';

  const [personnel, setPersonnel] = useState(defaultPersonnel);
  const [personnelMeta, setPersonnelMeta] = useState(defaultPersonnelMeta);

  const [loadMoreRef, loadMoreInView] = useInView();
  const [isLoadMorePersonnelActive, setIsLoadMorePersonnelActive] = useState(false);

  const handleTabClick = (tab) => {
    setInitialActiveTab(tab);
  }

  const fetchPersonnel = () => {
    setIsLoadMorePersonnelActive(false)
    axios
      .get(`/company_roles/${currentRole.id}/personnel`, { params: { page: parseInt(personnelMeta.currentPage) + 1 } })
      .then(response => {
        setPersonnel(prevState => ({
          loaded: true,
          collection: prevState.collection.concat(response.data.data),
          includedSubcontractors: prevState.includedSubcontractors.concat(response.data.included.filter(obj => obj.type === 'subcontractor'))
        }))
        setPersonnelMeta(response.data.meta)
        setIsLoadMorePersonnelActive(true)
      })
      .catch(breadBoard.addInedibleToast)
  };

  const resetPersonnel = () => {
    setPersonnel(defaultPersonnel)
    setPersonnelMeta(defaultPersonnelMeta)
  };

  useEffect(() => {
    if (sidePanelIsOpen) {
      resetPersonnel()
      setIsLoadMorePersonnelActive(true)
    }
  }, [sidePanelIsOpen])

  useEffect(() => {
    if (currentRole.id && !personnelMeta.isLastPage && loadMoreInView) fetchPersonnel()
  }, [currentRole.id, personnelMeta.isLastPage, loadMoreInView])

  const courseTabDisabled = !currentRole.id || currentRole.courseCompanyRoles.length === 0;
  const coursesTabTooltip = courseTabDisabled && 'No courses have been added to this role';

  // todo tabs as HOC to tack on labels to avoid having wrapper component?

  // get course name for selected courseCompanyRoles
  let courseCollection = []
  if(assignableCourses.length > 0){
    courseCollection = currentRole.courseCompanyRoles.map((ccr) => {
      const course = assignableCourses.find(course => course.id == ccr.courseId);
      return { courseName: course.attributes.name, required: ccr.required }
    })
  }

  return (
    <div className='popup__body-show tw-p-0'>
      {currentActor.isAllowedFeature('training_register') ? (
        <div className='popup__body-content p-0'>
          <Tabs
            modifiers={['panelled']}
            navClassName='tw-bg-grey-050'
            activeTabIsReset={sidePanelIsOpen}
            initialActiveTab={initialActiveTab}
            onTabClick={handleTabClick}
          >
            <RolesSidePanelPersonnelTab
              key='personnelTab'
              label='personnel'
              personnel={personnel}
              personnelMeta={personnelMeta}
              loadMoreRef={loadMoreRef}
              isLoadMorePersonnelActive={isLoadMorePersonnelActive}
            />
            <RolesSidePanelCoursesTab
              key='coursesTab'
              label='courses'
              disabled={courseTabDisabled}
              tooltipText={coursesTabTooltip}
              courseCollection={courseCollection}
            />
          </Tabs>
        </div>
      ) : (
        <div className='popup__body-content p-b-0'>
          <h3 className='popup__body--sub tw-text-l tw-font-semibold'>Personnel with this role</h3>
          {personnel.loaded && personnel.collection.length === 0 ? (
            <span>No personnel have this role</span>
          ) : (
            <ul className='popup__body--ul'>
              {personnel.collection.map((person, index) => {
                return (
                  <li className='popup__body--li' key={index}>
                    {`${person.attributes.firstName} ${person.attributes.lastName}`}
                  </li>
                )
              })}
              {isLoadMorePersonnelActive && !personnelMeta.isLastPage && (
                <li ref={loadMoreRef} className='popup__body--li'>
                  Loading personnel...
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

RolesShow.propTypes = {
  currentRole: PropTypes.object.isRequired,
  sidePanelIsOpen: PropTypes.bool
};
