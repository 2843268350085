import HandshqApp from '../handshq-app';

HandshqApp.domHelper = {
  toggleElements: function(elToHide, elToShow) {
    elToHide.hide();
    elToShow.show();
  },

  hoverToggleEvent: function(element, toggle) {
    if (!HandshqApp.mobileDetect.mobile()) {
      $(element).hover(function (_event) {
        $(this).find(toggle).css('visibility', 'visible');
      }, function (_event) {
        $(this).find(toggle).css('visibility', 'hidden');
      });
    }
  },

  clickStopPropagation: function(elements) {
    elements.on('click', function(event) {
      event.stopPropagation();
    });
  }
}
