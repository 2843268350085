function validateFileType( input, form, displayButton ) {
  const allowedTypes = [ 'jpg', 'jpeg', 'gif', 'png' ];
  const inputFile = $( input );
  const inputFileType = input.files[0].type.split('/').pop().toLowerCase();
  const inputFileSize = input.files[0].size;

  if ( $.inArray( inputFileType, allowedTypes) >= 0 && inputFileSize <= 10000000 ) {
    disableAddFileButton(displayButton);
    form.submit();

  } else if ( $.inArray( inputFileType, allowedTypes) < 0 ) {
    $(".image-warning").modal();
    inputFile.replaceWith(inputFile.val( '' ).clone( true ) );
  } else {
    $(".file-warning").modal();
    inputFile.replaceWith(inputFile.val( '' ).clone( true ) );
  }
}

// Get the url parameters
function getUrlParameter(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');

  for (let i = 0; i < sURLVariables.length; i++) {
      const sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] == sParam) {
          return sParameterName[1];
      }
  }
}
// Disabling add file button while the request is fired
function disableAddFileButton( displayButton ) {
  $( displayButton ).attr("data-disabled", true);
  $( displayButton ).children().last().text( "Uploading..." );
}

export { validateFileType, getUrlParameter, disableAddFileButton };
