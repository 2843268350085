import React from 'react';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import DivisionItem from 'components/riskRegister/sharedResourceNotices/shared/DivisionItem';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function SingleCategoryMultiDivisionsEditable ({ category, divisions, riskAssessment }) {
  return (
    <InfoBanner
      title={'Editing this risk assessment will affect other divisions'}
    >
      {riskAssessment.attributes.name} belongs to {category.attributes.name}, which is a category shared with:
      <ExpandableList
        collection={divisions}
        renderItem={(division) => <li key={`li--${division.id}`}><DivisionItem division={division} scopedCategories={[]} /></li> }
      />
    </InfoBanner>
  )
}
SingleCategoryMultiDivisionsEditable.propTypes = {
  category: resourceShape('trade').isRequired,
  divisions: PropTypes.arrayOf(resourceShape('company')).isRequired,
  riskAssessment: resourceShape('masterActivity').isRequired
}
