import React, { useEffect, useState } from 'react';
import DownloadModal from 'components/application/DownloadModal';
import FilledButton from 'components/application/buttons/FilledButton';
import { sendAnalytics } from 'components/helpers/analytics';
import PropTypes from 'prop-types';
import ProgressIcon from '-!svg-react-loader?name=ProgressIcon!icons/progress.svg';
import FailedIcon from '-!svg-react-loader?name=FailedIcon!icons/exclamation-circle.svg';

export default function DownloadModalWrapper(props) {

  const {
    isLandscape,
    modalIsOpen,
    closeModal,
    headerText,
    downloadingBodyText,
    successBodyText,
    errorBodyText,
    downloadStatus,
    retryAction,
    downloadAction,
    resetAction,
    analyticTrackingId,
    analyticProps
  } = props;

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const downloadAndCloseModal = () => {
    downloadAction()
    closeModal()
    resetAction()
    sendAnalytics(analyticTrackingId, analyticProps)
  };

  const resetAndCloseModal = () => {
    closeModal()
    resetAction()
  };

  const handleActionButtonClick = () => {  downloadStatus.downloadFailed ? retryAction() : downloadAndCloseModal() };

  const setButtonDisabledState = () => {
    downloadStatus.downloadInProgress ? setButtonDisabled(true) : setButtonDisabled(false)
  };

  useEffect(() => {
    downloadStatus && setButtonDisabledState()
  },[downloadStatus])

  const body = (
    downloadStatus.downloadSucceeded ? successBodyText : (downloadStatus.downloadFailed ? errorBodyText : downloadingBodyText)
  );

  const errorIcon = (
    <FailedIcon className={'icon--within-text'}/>
  );

  const inProgressIcon = (
    <ProgressIcon width={18} height={18} className={`${downloadStatus.downloadSucceeded || downloadStatus.downloadFailed ? 'hide' : 'tw-mr-1'}`} />
  );

  return (
    <DownloadModal
      header={headerText}
      body={body}
      bodyIcon={downloadStatus.downloadFailed ? errorIcon : ''}
      isOpen={modalIsOpen}
      onClose={resetAndCloseModal}
      actionButton={
        <FilledButton
          color='mint'
          disabled={buttonDisabled}
          onClick={handleActionButtonClick}
        >
          {inProgressIcon}
          {downloadStatus.downloadSucceeded ? 'Download now' : (downloadStatus.downloadFailed ? 'Retry' : 'Please wait...')}
        </FilledButton>
      }
      isLandscape={isLandscape}
      isFailed={downloadStatus.downloadFailed}
    />
  )
}

DownloadModalWrapper.propsTypes = {
  isLandscape: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  downloadingBodyText: PropTypes.string,
  successBodyText: PropTypes.string,
  errorBodyText: PropTypes.string,
  downloadStatus: PropTypes.string.isRequired,
  retryAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired
}

DownloadModalWrapper.defaultProps = {
  headerText: "Download document",
  downloadingBodyText: "Downloading...",
  successBodyText: "PDF ready to download",
  errorBodyText: "There was a problem creating the PDF. Please try again",
  isLandscape: true
}
