$( document ).ready( function() {
	$('[data-approval-submission]').each( function( x, approvalButton ) {
		approvalButton.addEventListener( 'click', appendApprovalStatus );
	});

	function appendApprovalStatus( e ) {
		const hiddenField = document.createElement( 'input' );
		const form = $(e.target).closest('form')[0];
		// prevent submission
		e.preventDefault();
		// contruct hidden field
		hiddenField.setAttribute( 'name', 'decision' );
		hiddenField.setAttribute( 'type', 'hidden' );
		hiddenField.setAttribute( 'value', $( this ).val() );
		// append and submit
		form.appendChild( hiddenField );
		$( form ).submit();
	}
});
