import ProjectRow from './project-row';
import { setUpTooltips } from '../../shared/tooltip';

function ProjectTab(element) {
  this.el = $(element);
  this.name = this.el.attr('id');
  this.projectRows = [];
  this.maxSize = 25;
  this.init();
}

ProjectTab.prototype.init = function() {
  this.assignCurrentRows();
  this.spinOnNav();
  this.setupTooltips();
  this.notifyIfEmpty();
  setProjectDeleteClickEvents();
  setUpTooltips();
};

ProjectTab.prototype.replaceContents = function(contentsHtml) {
  const _this = this;
  const contents = _this.currentContents();
  contents.fadeOut(250, function() {
    contents.replaceWith(contentsHtml);
    _this.init();
    _this.currentContents().hide().fadeIn(250, function() {
      ReactRailsUJS.mountComponents();
    });
  });
};

ProjectTab.prototype.currentContents = function() {
  return this.el.find('.projects');
};

ProjectTab.prototype.setupTooltips = function() {
  addPopover('#' + this.name + ' .projects .actions a');
  addPopover('#' + this.name + ' .popover-holder');
  addPopover('#' + this.name + ' .duplicate');
};

ProjectTab.prototype.assignCurrentRows = function() {
  this.projectRows = [];
  const currentRows = this.el.find('.projects tbody tr.project-row');
  this.projectRows = currentRows.toArray().map(function(row) {
    return new ProjectRow(row);
  });
};

ProjectTab.prototype.getRow = function(projectId) {
  return this.projectRows.find(row => row.projectId === parseInt(projectId, 10));
};

ProjectTab.prototype.spinOnNav = function() {
  const nav = $('#' + this.name + ' .pagination span a');
  nav.on('click', function() {
    const parent = $(this).parents('nav.pagination');
    parent.children().toggleClass('hide');
  })
};

ProjectTab.prototype.insertSortedRow = function(timestamp, rowHtml) {
  let preceedingRow;
  const sortedRows = [...this.projectRows].sort((row1, row2) => row2.timestamp - row1.timestamp);

  if (sortedRows.length !== 0) {
    // attempt to find position
    preceedingRow = sortedRows.find(row => row.timestamp < timestamp);

    if (preceedingRow !== undefined) {
      preceedingRow.el.before(rowHtml);
    } else if (sortedRows.length < this.maxSize) {
      sortedRows[sortedRows.length-1].el.after(rowHtml);
    }

  } else if (!this.hasEmptyResults()) {
    this.currentContents().find('table tbody').not('#project-duplication').append(rowHtml);
  }
  this.init();
};

ProjectTab.prototype.empty = function(emptyHtml) {
  this.clearEmptyMessage();
  const contents = this.currentContents();
  const table = contents.find('table');
  table.addClass('no-results');
  contents.find('.remove.modal, .duplicate.modal, .hands-pagination').remove();
  table.find('tbody:not(#project-duplication)').html(emptyHtml); //temporary fix: using additional tbody to enable react portalling of project duplication row
  this.projectRows = [];
  this.enableFollowUpSearch();
};

ProjectTab.prototype.hasEmptyResults = function() {
  return this.currentContents().find('table').hasClass('no-results');
};

ProjectTab.prototype.notifyIfEmpty = function() {
  if (this.el.find('tbody tr.project-row').not('.hide').length === 0) {
    (this.el.find('.no-results').length === 0) ? this.showEmptyMessage() : this.empty()
  } else {
    this.clearEmptyMessage()
  }
};

ProjectTab.prototype.showEmptyMessage = function() {
  this.el.find('.empty-projects-message').removeClass('hide');
  this.el.find('.projects').addClass('hide');
};

ProjectTab.prototype.clearEmptyMessage = function() {
  this.el.find('.empty-projects-message').addClass('hide');
  this.el.find('.projects').removeClass('hide');
};

ProjectTab.prototype.notifyTraversal = function() {
  this.clearArchivedRows();
  this.init();
};

ProjectTab.prototype.clearArchivedRows = function() {
  this.el.find('tr.archived-project-row').addClass('hide');
};

ProjectTab.prototype.enableFollowUpSearch = function() {
  this.el.find('.follow-search-trigger').on('click', function() {
    const tab = ProjectsIndex.getTab($(this).data('tabName'));
    tab.navigateTo();
    tab.triggerSearch($(this).data('query'));
  });
};

ProjectTab.prototype.navigateTo = function() {
  $('a[href="#' + this.name + '"]').tab('show');
};

ProjectTab.prototype.triggerSearch = function(query) {
  $('#' + this.name + ' #search_project').val(query).trigger('input');
};

function setProjectDeleteClickEvents() {
  setTimeout(function() {
    setDeleteHandlers();
    focusConfirmationOnModalOpen();
  }, 1000);
}

$(document).ajaxComplete(function () {
  // TODO: Get rid of this, it's not shared and is wasteful
  setTimeout(function () {
    setDeleteHandlers();
  }, 300);
});

function setDeleteHandlers() {
  // clear existing handlers
  $('.confirm_pdf_delete').off('click');
  $('.confirm_pdf_delete').on('click', pdfDelete);
}

function pdfDelete() {
  const url = '/pdfs/' + $(this).data('id');
  const redirectPath = $(this).data('redirect-path');

  this.disabled = true;
  this.textContent = 'removing...';
  $.ajax({
    type: "DELETE",
    url: url,
    success: () => {
      redirectPath ? window.location.href = redirectPath : window.location.reload()
    }
  });
}

export default ProjectTab;
