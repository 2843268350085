import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useForm from 'components/hooks/useForm';
import useSidePanel from 'components/hooks/useSidePanel';
import useToasts from 'components/hooks/useToasts';
import useRequestError from 'components/hooks/useRequestError';

import ProjectCoshhActionsBar from 'components/projectCoshh/ProjectCoshhActionsBar';
import ProjectCoshhTable from 'components/projectCoshh/ProjectCoshhTable';
import ProjectCoshhBlankNotice from 'components/projectCoshh/ProjectCoshhBlankNotice';
import ProjectCoshhDestroyModal from 'components/projectCoshh/ProjectCoshhDestroyModal';
import ProjectCoshhDownloadModal from 'components/projectCoshh/ProjectCoshhDownloadModal';
import ProjectCoshhSidePanel from 'components/projectCoshh/ProjectCoshhSidePanel';
import ToastRack from 'components/application/ToastRack';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import { defaultCoshhDocument, updatedPersonsAtRiskValues, formatPersonsAtRiskValues } from 'components/helpers/resources/coshhDocuments';

export default function ProjectCoshhTab(props) {
  const {
    projectId,
    masterCoshhIndexPath,
    workerExposureTimeOptions,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    secondaryFieldLabel,
    secondaryFieldDefaultValue,
    personsAtRiskOptions,
    currentUser,
  } = props;

  const [currentCoshhDocument, setCurrentCoshhDocument, handleCoshhInputChange, handleCoshhOptionChange, , , handleOptionSelect ] = useForm(defaultCoshhDocument);
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [coshhDocuments, setCoshhDocuments] = useState({ data: [], included: [] });
  const [initialPersonsAtRisk, setInitialPersonsAtRisk] = useState([]);
  const [toasts, _setToasts, addToast, handleBurnToast, addInedibleToast] = useToasts();

  const [sidePanelIsOpen, setSidePanelIsOpen, _openSidePanel, closeSidePanel, _resetSidePanelContext, sidePanelContext, setSidePanelContext] = useSidePanel(false, 'show')
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isSidePanelSubmitDisabled, setIsSidePanelSubmitDisabled] = useState(false);
  const [projectCoshhDestroyModalIsOpen, setProjectCoshhDestroyModalIsOpen] = useState(false);
  const [projectCoshhDownloadModalIsOpen, setProjectCoshhDownloadModalIsOpen] = useState(false);

  const coshhDocumentUpdateParams = () => {
    const updatedPersonsAtRisk = updatedPersonsAtRiskValues(initialPersonsAtRisk, currentCoshhDocument.personsAtRisk);

    return {
      quantity_on_site: currentCoshhDocument.quantityOnSite,
      quantity_to_be_used: currentCoshhDocument.quantityToBeUsed,
      substance_quantity_per_day: currentCoshhDocument.substanceQuantityPerDay,
      uses: currentCoshhDocument.uses,
      method_of_use_other: currentCoshhDocument.uses === secondaryFieldLabel ? currentCoshhDocument.methodOfUseOther : '',
      staff_number: currentCoshhDocument.staffNumber,
      worker_exposure_time: currentCoshhDocument.workerExposureTime,
      worker_exposure_time_other: currentCoshhDocument.workerExposureTime === secondaryFieldLabel ? currentCoshhDocument.workerExposureTimeOther : '',
      exposure_frequency: currentCoshhDocument.exposureFrequency,
      exposure_frequency_other: currentCoshhDocument.exposureFrequency === secondaryFieldLabel ? currentCoshhDocument.exposureFrequencyOther : '',
      location: currentCoshhDocument.location,
      level_of_risk: currentCoshhDocument.levelOfRisk,
      additional_information: currentCoshhDocument.additionalInformation,
      assigned_persons_at_risks_attributes: updatedPersonsAtRisk,
      persons_at_risk_other: Boolean(updatedPersonsAtRisk.find((persons) => persons.name === secondaryFieldLabel && persons['_destroy'] === '1')) ? '' : currentCoshhDocument.personsAtRiskOther
    }
  };

  const defaultCoshhDocumentRowData = (coshhDocument, formattedPersonsAtRisk) => {
    const { id, attributes } = coshhDocument;

    const coshhAttributes = Object.entries(attributes).reduce((accumulator, [key, value]) => {
      accumulator[key] = value || '';
      return accumulator;
    }, {})

    return {
      ...coshhAttributes,
      id: id,
      personsAtRisk: formattedPersonsAtRisk
    }
  };

  const fetchCoshhDocuments = () => {
    axios
      .get(`/projects/${projectId}/coshh.json`)
      .then((response) => {
        setCoshhDocuments(response.data)
        setPageLoaded(true)
        setIsSidePanelSubmitDisabled(false)
      })
      .catch(() => addInedibleToast())
  };

  useEffect(() => {
    fetchCoshhDocuments()
  }, [])

  const deleteCoshhDocument = () => {
    axios
      .delete(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`)
      .then(() => {
        setSidePanelIsOpen(false)
        fetchCoshhDocuments()
        addToast(
          <ResourceChangedToast
            resource={currentCoshhDocument.title}
            additionalText={'COSHH assessment'}
            onBurnToast={handleBurnToast}
            status={'deleted'}
            resourceMaxLength={64}
          />
        )
        setCurrentCoshhDocument(defaultCoshhDocument)
      })
      .catch(() => addInedibleToast())
  };

  const updateCoshhDocument = () => {
    setIsSidePanelSubmitDisabled(true)

    axios
      .patch(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`, { coshh_document: coshhDocumentUpdateParams() })
      .then(() => {
        setSidePanelIsOpen(false)
        fetchCoshhDocuments()
        resetRequestError()
        addToast(
          <ResourceChangedToast
            resource={currentCoshhDocument.title}
            additionalText={'COSHH assessment'}
            onBurnToast={handleBurnToast}
            status={'updated'}
          />
        )
        setCurrentCoshhDocument(defaultCoshhDocument)
      })
      .catch(handleRequestError)
  };

  const handleCoshhDocumentRowClick = (coshhDocument) => {
    const includedResources = coshhDocuments.included && coshhDocuments.included.filter((includedResource) => includedResource.relationships.personable.data.id === coshhDocument.id);
    const formattedPersonsAtRisk = formatPersonsAtRiskValues(includedResources, 'assignedPersonsAtRisk', 'name');

    setInitialPersonsAtRisk(formattedPersonsAtRisk)
    setCurrentCoshhDocument(defaultCoshhDocumentRowData(coshhDocument, formattedPersonsAtRisk))
    setSidePanelContext('show')
    setSidePanelIsOpen(true)
  };

  const handleDownloadClick = ({ coshhDocument }) => {
    setCurrentCoshhDocument({
      ...defaultCoshhDocument,
      id: coshhDocument.id,
      title: coshhDocument.attributes.title
    })

    setProjectCoshhDownloadModalIsOpen(true)
  };

  const handleDeleteClick = ({ coshhDocument }) => {
    setCurrentCoshhDocument({
      ...defaultCoshhDocument,
      id: coshhDocument.id,
      title: coshhDocument.attributes.title
    })

    setProjectCoshhDestroyModalIsOpen(true)
  };

  const handleCloseProjectCoshhSidePanel = () => {
    resetRequestError()
    closeSidePanel()
  };

  useEffect(() => {
    if (requestError) {
      setIsSidePanelSubmitDisabled(false)
    }
  }, [requestError])

  return (
    <>
      {
        pageLoaded &&
          <>
            <ProjectCoshhActionsBar masterCoshhIndexPath={masterCoshhIndexPath} />
            {
              (coshhDocuments.data.length > 0) ?
                <ProjectCoshhTable
                  coshhDocuments={coshhDocuments.data}
                  onDownloadClick={handleDownloadClick}
                  onDeleteClick={handleDeleteClick}
                  onCoshhDocumentRowClick={handleCoshhDocumentRowClick}
                />
              :
                <ProjectCoshhBlankNotice />
            }
            <ProjectCoshhDestroyModal
              isOpen={projectCoshhDestroyModalIsOpen}
              setIsOpen={setProjectCoshhDestroyModalIsOpen}
              currentCoshhDocument={currentCoshhDocument}
              deleteCoshhDocument={deleteCoshhDocument}
            />
            <ProjectCoshhDownloadModal
              isOpen={projectCoshhDownloadModalIsOpen}
              setIsOpen={setProjectCoshhDownloadModalIsOpen}
              currentCoshhDocument={currentCoshhDocument}
              projectId={projectId}
              currentUser={currentUser}
            />
            <ProjectCoshhSidePanel
              coshhDocument={currentCoshhDocument}
              sidePanelIsOpen={sidePanelIsOpen}
              closeSidePanel={handleCloseProjectCoshhSidePanel}
              sidePanelContext={sidePanelContext}
              setSidePanelContext={setSidePanelContext}
              onDeleteProjectCoshhClick={setProjectCoshhDestroyModalIsOpen}
              onUpdateCoshhDocument={updateCoshhDocument}
              onCoshhInputChange={handleCoshhInputChange}
              onCoshhOptionChange={handleCoshhOptionChange}
              onOptionSelect={handleOptionSelect}
              workerExposureTimeOptions={workerExposureTimeOptions}
              exposureFrequencyOptions={exposureFrequencyOptions}
              locationOptions={locationOptions}
              methodOfUseOptions={methodOfUseOptions}
              personsAtRiskOptions={personsAtRiskOptions}
              secondaryFieldLabel={secondaryFieldLabel}
              secondaryFieldDefaultValue={secondaryFieldDefaultValue}
              requestError={requestError}
              removeErrorStyling={removeErrorStyling}
              submitDisabled={submitDisabled || isSidePanelSubmitDisabled}
            />
            <ToastRack
              toasts={toasts}
            />
          </>
      }
    </>
  )
}

ProjectCoshhTab.propTypes = {
  projectId: PropTypes.number.isRequired,
  masterCoshhIndexPath: PropTypes.string.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired
};
