import React from 'react';
import InfoBanner from 'components/application/InfoBanner';
import ExpandableList from 'components/application/ExpandableList';
import DivisionItem, { prepareDivisionItemProps } from 'components/riskRegister/sharedResourceNotices/shared/DivisionItem';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function MultiCategoriesMultiDivisionsEditable ({ riskAssessment, divisions, categories }) {
  return (
    <InfoBanner
      title={'Editing this risk assessment will affect other categories and other divisions'}
    >
      {riskAssessment.attributes.name} is a risk assessment shared with:
      <ExpandableList
        collection={divisions}
        renderItem={(division) =>
          <li key={`divItem--${division.id}`}>
            <DivisionItem {...prepareDivisionItemProps(division, riskAssessment, categories)} />
          </li>
        }
      />
    </InfoBanner>
  )
}

MultiCategoriesMultiDivisionsEditable.propTypes = {
  riskAssessment: resourceShape('masterActivity').isRequired,
  divisions: PropTypes.arrayOf(resourceShape('company')).isRequired,
  categories: PropTypes.arrayOf(resourceShape('trade')).isRequired
}
