import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { findAssignedCompany } from 'components/helpers/companies';
import PersonnelCustomFieldsForm from 'components/personnel/PersonnelCustomFieldsForm';
import SelectSubcontractor from 'components/personnel/SelectSubcontractor';
import SelectDivision from 'components/personnel/SelectDivision';
import SelectCompanyRoles from 'components/personnel/SelectCompanyRoles';
import UserCompanyRolesList from 'components/personnel/UserCompanyRolesList';
import SelectLineManager from 'components/personnel/SelectLineManager';
import ErrorMessage from 'components/application/ErrorMessage';
import TextField from 'components/application/TextField';
import RadioField from 'components/application/RadioField';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

export default function PersonnelForm(props) {
  const {
    onNewCompanyRoleAdd,
    onAssignableRoleAdd,
    assignableCompanies,
    assignableRoles,
    availableFields,
    availableFieldOptions,
    currentPersonnel,
    onCompanyRolesOptionChange,
    onDateFieldChange,
    onFieldOptionChange,
    onPersonnelInputChange,
    onPersonnelOptionChange,
    onPersonnelFieldChange,
    onRemoveRole,
    onSelectPrimary,
    isCreatableCompanyRole,
    isSubcontractor,
    lineManagers,
    personnel,
    removeErrorStyling,
    requestError,
    onSubcontractorSelect,
    setIsSubcontractor,
    sidePanelContext,
    sidePanelFieldRef,
    currentSubcontractor,
    setCurrentPersonnel,
  } = props;

  const assignedDivision = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    companyId: currentPersonnel.company.companyId
  });

  const assignedSubcontractor = findAssignedCompany({
    assignableCompanies: assignableCompanies,
    subcontractorId: currentPersonnel.company.subcontractorId
  });

  const primaryDivision = assignableCompanies.find(company => company.attributes.resourceType === 'Company' && company.attributes.primary);
  const subcontractors = assignableCompanies.filter(company => company.attributes.resourceType === 'Subcontractor')
  const companies = assignableCompanies.filter(company => company.attributes.resourceType === 'Company');

  const focusableFields = ['firstName', 'lastName', 'externalId', 'email']

  const firstEmptyField = (
    focusableFields.find(field => !currentPersonnel[field])
  )

  const showDivisionTeamWarning = () => {
    return !!personnel?.relationships?.teams && personnel.relationships.teams.data.length > 0 && currentPersonnel.company.companyId != personnel.relationships.company.data.id;
  }

  useEffect(() => {
    if (sidePanelContext === 'new') { setCurrentPersonnel({ ...currentPersonnel, lineManagerId: '', companyRoleIds: [] }) }
  }, [currentPersonnel.company.companyId])

  useEffect(() => {
    if (sidePanelContext === 'edit' && currentPersonnel.company.subcontractorId) {
      setIsSubcontractor(true)
    }
  }, [])

  const selectDivision = (
    <>
      <SelectDivision
        label={'Division'}
        sidePanelContext={sidePanelContext}
        companies={companies}
        assignedDivision={assignedDivision}
        onChange={onPersonnelOptionChange}
      />
      {showDivisionTeamWarning() &&
        <div className='m-l-30 tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 tw-flex'>
          <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
            <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
          </div>
          Personnel will be removed from existing teams when moving them to a new division.
        </div>}
    </>
  )

  const selectSubcontractor = (
    <SelectSubcontractor
      label={'Subcontractor company'}
      sidePanelContext={sidePanelContext}
      subcontractors={subcontractors}
      primaryDivision={primaryDivision}
      assignedSubcontractor={assignedSubcontractor}
      subcontractor={currentSubcontractor}
      onSubcontractorSelect={onSubcontractorSelect}
      isSubcontractor={isSubcontractor}
      fieldError={requestError.validationErrors.name}
      removeErrorStyling={removeErrorStyling}
      onChange={onPersonnelOptionChange}
    />
  )

  return (
    <div className='popup__body-form popup__body-form--no-side-padding'>
      <div className='form-container'>
        <div className='form-container-section'>
          <ErrorMessage validationErrors={requestError.validationErrors} isFallback={requestError.isFallback} withSeparateNestedErrors={false} />
        </div>
        <div className='form-container-section'>
          <TextField label='First name' name='firstName' inputRef={firstEmptyField === 'firstName' ? sidePanelFieldRef : null} value={currentPersonnel.firstName} isRequired={true} fieldError={requestError.validationErrors.firstName} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange}/>
          <TextField label='Last name' name='lastName' inputRef={firstEmptyField === 'lastName' ? sidePanelFieldRef : null} value={currentPersonnel.lastName} isRequired={true} fieldError={requestError.validationErrors.lastName} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange}/>
          <TextField label='Personnel ID' name='externalId' inputRef={firstEmptyField === 'externalId' ? sidePanelFieldRef : null} value={currentPersonnel.externalId} fieldError={requestError.validationErrors.externalId} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange} />
          <TextField label='Email address' name='email' inputRef={firstEmptyField === 'email' ? sidePanelFieldRef : null} value={currentPersonnel.email} fieldError={requestError.validationErrors.email} removeErrorStyling={removeErrorStyling} onChange={onPersonnelInputChange}/>
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-my-8 tw-border-0' />
        <div className='form-container-section'>
          <label className='collection-select__label tw-font-medium m-t-32 m-b-24'>Personnel Type</label>
          <div className={'side-panel__radio-buttons-container'}>
            <RadioField label={'Employee'} checked={!isSubcontractor} onChange={() => { setIsSubcontractor(false) }} value='employee' name={'personnelType'} />
            {!isSubcontractor && companies.length > 1 && selectDivision}
            <RadioField label={'Sub-contractor'} checked={isSubcontractor} onChange={() => { setIsSubcontractor(true) }} value='subcontractor' name={'personnelType'} />
            {isSubcontractor && selectSubcontractor}
          </div>
          <SelectLineManager isDisabled={isSubcontractor} currentPersonnel={currentPersonnel} lineManagers={lineManagers} onChange={onPersonnelOptionChange} />
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-mt-8 tw-border-0' />
        <div className='form-container-section'>
          <label className='collection-select__label tw-font-medium m-t-32'>Roles</label>
          <SelectCompanyRoles assignableRolesCollection={assignableRoles} onAssignableRoleAdd={onAssignableRoleAdd} primaryDivisionId={primaryDivision.id} onNewCompanyRoleAdd={onNewCompanyRoleAdd} currentPersonnel={currentPersonnel} isCreatable={isCreatableCompanyRole} onChange={onCompanyRolesOptionChange} fieldError={requestError.validationErrors.base} />
          <UserCompanyRolesList
            currentPersonnel={currentPersonnel}
            assignableRolesCollection={assignableRoles}
            onSelectPrimary={onSelectPrimary}
            onRemoveRole={onRemoveRole}
          />
        </div>
        <hr className='tw-h-px tw-bg-grey-100 tw-mt-8 tw-border-0' />
        <div className='form-container-section'>
          <PersonnelCustomFieldsForm
            availableFields={availableFields}
            availableFieldOptions={availableFieldOptions}
            currentPersonnel={currentPersonnel}
            onPersonnelFieldChange={onPersonnelFieldChange}
            onFieldOptionChange={onFieldOptionChange}
            onDateFieldChange={onDateFieldChange}
            requestError={requestError}
            removeErrorStyling={removeErrorStyling}
          />
        </div>
      </div>
    </div>
  )
}

PersonnelForm.propTypes = {
  assignableCompanies: PropTypes.array,
  currentPersonnel: PropTypes.object,
  sidePanelContext: PropTypes.string,
  isSubcontractor: PropTypes.bool,
  setIsSubcontractor: PropTypes.func,
  requestError: PropTypes.object,
  removeErrorStyling: PropTypes.func,
  currentSubcontractor: PropTypes.object,
  onSubcontractorSelect: PropTypes.func,
  onPersonnelOptionChange: PropTypes.func,
  onPersonnelInputChange: PropTypes.func,
  isCreatableCompanyRole: PropTypes.bool,
  lineManagers: PropTypes.array,
  sidePanelBodyRef: PropTypes.any,
  setCurrentPersonnel: PropTypes.func,
  onNewCompanyRoleAdd: PropTypes.func,
  assignableRoles: PropTypes.array,
  onAssignableRoleAdd: PropTypes.func,
  availableFields: PropTypes.object,
  availableFieldOptions: PropTypes.object,
  onPersonnelFieldChange: PropTypes.func,
  onFieldOptionChange: PropTypes.func,
  onDateFieldChange: PropTypes.func,
};
