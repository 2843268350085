import React, { Fragment }  from 'react';
import PropTypes from 'prop-types';

import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';

export default function DivisionAccessDisallowed({ defaultCompanyName, sessionCompanyName }) {
  return(
    <Fragment>
      <div className='toast__icon'>
        <InfoIcon className='inner-icon [&_path]:tw-fill-cyan-600' width={20} height={20} />
      </div>
      <div className='toast__text'>
        <span>You are now viewing <strong>{defaultCompanyName}</strong></span>
        {sessionCompanyName ? <span> because you no longer have access to <strong>{sessionCompanyName}</strong>. Please contact your account admin for more information.</span> : <span>.</span>}
      </div>
    </Fragment>
  )
}

DivisionAccessDisallowed.propTypes = {
  defaultCompanyName: PropTypes.string.isRequired,
  sessionCompanyName: PropTypes.string
};
