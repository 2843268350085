import React, { useEffect, useState } from 'react';
// components
import Paginator from 'components/application/Paginator';
import SubcontractorsBar from './SubcontractorsBar';
import SubcontractorsTable from './SubcontractorsTable';
import SubcontractorsSidePanel from './SubcontractorsSidePanel';
import DestroyModal from 'components/application/DestroyModal';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import ResourceBlankNotice from 'components/application/ResourceBlankNotice';
// hooks
import useRequestError from 'components/hooks/useRequestError';
import useDebounce from 'components/hooks/useDebounce';
import useForm from 'components/hooks/useForm';
import useSidePanel from 'components/hooks/useSidePanel';
import useWindowStorage from 'components/hooks/useWindowStorage';
import useValidatedStore from 'components/hooks/useValidatedStore';
// contexts
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';
// packages
import { z } from 'zod';

const defaultSubcontractor = {
  id: '',
  name: '',
  isDeleteProhibited: false
};

const initialTabStore = {
  currentSearch: '',
  page: 1,
  currentResourceId: '',
  currentResourceName: '',
  sidePanelIsOpen: false,
};

const tabSchema = z.object({
  currentResourceId: z.string().nullable(),
  currentResourceName: z.string(),
  sidePanelIsOpen: z.boolean(),
  page: z.number().or(z.null()),
  currentSearch: z.string()
});

export default function SubcontractorsTab({ label }) {
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const breadBoard = useBreadBoard();

  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, { store: window.sessionStorage });
  const tabStore = useValidatedStore({ getStore, initialStore: initialTabStore, schema: tabSchema });

  const initialCurrentSearch = { subcontractorsSearch: tabStore.currentSearch };
  const initialDefaultSubcontractor = {
    ...defaultSubcontractor,
    id: tabStore.currentResourceId,
    name: tabStore.currentResourceName
  };
  const initialSidePanelIsOpen = tabStore.sidePanelIsOpen || false;

  const [subcontractors, setSubcontractors] = useState({ loaded: false, collection: [] });
  const [metaData, setMetaData] = useState({ currentPage: null, totalPages: null, scopedCount: 0, totalCount: 0 });
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [currentSearch, setCurrentSearch, handleSearchInputChange] = useForm(initialCurrentSearch);
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(currentSearch, 250);
  const [currentSubcontractor, setCurrentSubcontractor, handleSubcontractorInputChange] = useForm(initialDefaultSubcontractor);
  const [sidePanelIsOpen, setSidePanelIsOpen, openSidePanel, closeSidePanel, resetSidePanelContext, sidePanelContext, setSidePanelContext] = useSidePanel(initialSidePanelIsOpen, 'show');
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);

  useEffect(() => {
    fetchSubcontractors(tabStore.page)
  }, [])

  useEffect(() => {
    if (subcontractors.loaded && debouncedCurrentSearch) { fetchSubcontractors() }
  }, [debouncedCurrentSearch])

  const fetchSubcontractors = (page = 1) => {
    axios
      .get('/subcontractors', { params: { search: currentSearch.subcontractorsSearch, page: page } })
      .then(response => {
        setSubcontractors({ loaded: true, collection: response.data.data })
        setMetaData(response.data.meta)
        resetSidePanelContext()
      })
      .catch(_error => breadBoard.addInedibleToast())
  };

  const createSubcontractor = () => {
    axios
      .post('/subcontractors', { subcontractor: { name: currentSubcontractor.name } })
      .then(response => {
        closeSidePanel()
        resetRequestError()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'added'}
          />
        )
        fetchSubcontractors()
      })
      .catch(error => {
        if (error.response.status === 422) {
          handleRequestError(error)
        } else {
          breadBoard.addInedibleToast()
          closeSidePanel()
        }
      })
  };

  const updateSubcontractor = () => {
    axios
      .patch(`/subcontractors/${currentSubcontractor.id}`, { subcontractor: { name: currentSubcontractor.name } })
      .then(response => {
        closeSidePanel()
        resetRequestError()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'edited'}
          />
        )
        fetchSubcontractors(metaData.currentPage)
      })
      .catch(handleRequestError)
  };

  const destroySubcontractor = () => {
    axios
      .delete(`/subcontractors/${currentSubcontractor.id}`)
      .then(() => {
        closeSidePanel()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={currentSubcontractor.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'deleted'}
          />
        )
        fetchSubcontractors(metaData.currentPage)
      })
      .catch(() => {
        breadBoard.addInedibleToast()
        closeSidePanel()
      })
  };

  const handleNewSubcontractor = () => {
    setCurrentSubcontractor(defaultSubcontractor)
    setSidePanelContext('new')
    resetRequestError()
    openSidePanel()
  };

  const handleSearchReset = (_event) => {
    setCurrentSearch({ ...currentSearch, 'subcontractorsSearch': '' })
  };

  const handlePageChange = (event) => {
    fetchSubcontractors(event.currentTarget.getAttribute('data-page'))
  };

  const viewSubcontractor = (subcon) => {
    setCurrentSubcontractor({ id: subcon.id, name: subcon.attributes.name, isDeleteProhibited: subcon.attributes.isDeleteProhibited })
    setSidePanelContext('show')
    resetRequestError()
    openSidePanel()
  };

  useEffect(() => {
    setStore({
      currentSearch: currentSearch.subcontractorsSearch,
      page: metaData.currentPage,
      currentResourceId: currentSubcontractor.id,
      currentResourceName: currentSubcontractor.name,
      sidePanelIsOpen: sidePanelIsOpen
    });
  }, [currentSearch.subcontractorsSearch, metaData.currentPage, currentSubcontractor.id, currentSubcontractor.name, sidePanelIsOpen])

  const blankMessage = (trainingRegisterResourceManagementContext.hasPersonnelEditableAccess ?
    'Create a list of the sub-contractors used by your company.'
    :
    'Any sub-contractors that have been created will be listed here'
  );

  return (
    <React.Fragment>
      {subcontractors.loaded && (
        <>
          {metaData.totalCount > 0 && (
            <SubcontractorsBar
              actionButtonsVisible={trainingRegisterResourceManagementContext.hasPersonnelEditableAccess}
              onNewSubcontractor={handleNewSubcontractor}
              subcontractorsCount={metaData.totalCount}
              subcontractorsSearch={currentSearch.subcontractorsSearch}
              onSearchInputChange={handleSearchInputChange}
              onSearchReset={handleSearchReset}
            />
          )}
          {subcontractors.collection.length > 0 ? (
            <>
              <SubcontractorsTable
                subcontractors={subcontractors.collection}
                onRowClick={(event, subcontractor) => { viewSubcontractor(subcontractor) }}
              />
              {
                subcontractors.loaded && subcontractors.collection.length > 0 && metaData.totalPages > 1 && (
                  <div className='m-t-80 text-center'>
                    <Paginator
                      currentPage={metaData.currentPage}
                      totalPages={metaData.totalPages}
                      onClick={handlePageChange}
                    />
                  </div>
                )
              }
            </>
          ) : (
            <ResourceBlankNotice
              displayReadOnlyContents={!trainingRegisterResourceManagementContext.hasPersonnelEditableAccess}
              totalCount={metaData.totalCount}
              onAdd={handleNewSubcontractor}
              addMessage={blankMessage}
              resource={'sub-contractor'}
            />
          )}
          <SubcontractorsSidePanel
            allowShowFooterContents={trainingRegisterResourceManagementContext.hasPersonnelEditableAccess}
            sidePanelContext={sidePanelContext}
            setSidePanelContext={setSidePanelContext}
            sidePanelIsOpen={sidePanelIsOpen}
            setSidePanelIsOpen={setSidePanelIsOpen}
            closeSidePanel={closeSidePanel}
            currentSubcontractor={currentSubcontractor}
            setCurrentSubcontractor={setCurrentSubcontractor}
            requestError={requestError}
            onSubcontractorInputChange={handleSubcontractorInputChange}
            onDeleteClick={() => setDestroyModalIsOpen(true)}
            createSubcontractor={createSubcontractor}
            updateSubcontractor={updateSubcontractor}
            submitDisabled={submitDisabled}
            removeErrorStyling={removeErrorStyling}
          />
          {sidePanelContext === 'show' && (
            <DestroyModal
              isOpen={destroyModalIsOpen}
              displayText={`Are you sure you want to delete ${currentSubcontractor.name}?`}
              confirmationText='Delete sub-contractor'
              onClose={() => setDestroyModalIsOpen(false)}
              onDestroy={destroySubcontractor}
            />
          )}
        </>
      )}
    </React.Fragment>
  )
}
