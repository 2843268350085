import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/application/Tooltip';

export default function DisableableOptionChoice({ label, warning, disabled, disabledTooltipText = '' }) {
  return (
    <span className='tooltip-parent'>
      {disabled && <Tooltip placement='left' trigger='hover' tooltip={disabledTooltipText} className='tooltip-dark--max-w-xxs' />}
      <a className={`tw-text-m tw-font-medium tw-tracking-auto ${warning ? 'tw-text-red-600 hover:tw-text-red-600' : 'tw-text-grey-700 hover:tw-text-grey-700'}`} href={'#0'}>{label}</a>
    </span>
  )
}

DisableableOptionChoice.propTypes = {
  label: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  disabledTooltipText: PropTypes.string
};
