import React from 'react';
import PropTypes from 'prop-types';

export default function SmallStubPill(props) {
  return <span className={`small-stub-pill small-stub-pill--nowrap tw-text-xs tw-font-semibold tw-tracking-wide ${props.className}`}>{props.children}</span>
}

SmallStubPill.defaultProps = {
  className: ''
}

SmallStubPill.propTypes = {
  className: PropTypes.string
}
