import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'components/application/ActionModal';
import ActionButton from 'components/buttons/ActionButton';
import TextButton from 'components/application/buttons/TextButton';

export default function DuplicationWithOutOfDateActivitiesModal(props) {
  const {
    originalProjectName,
    ramsSingularName,
    isOpen,
    closeModal,
    outOfDateActivities,
    onClick
  } = props

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        closeModal={closeModal}
        closeable={true}
      >
        <div className='action-modal__box tw-bg-white'>
          <section className='action-modal__header truncated-text-container tw-text-grey-900 tw-text-l tw-font-semibold tw-border-grey-100'>
            {`Duplicate ${originalProjectName}`}
          </section>
          <section className='action-modal__form-body tw-border-grey-100'>
            <div>
              <span>This {ramsSingularName} contains out of date risk assessments:</span>
              <ul>{outOfDateActivities.map((outOfDateActivity) => <li key={outOfDateActivity.id}>{outOfDateActivity.attributes.name}</li>)}</ul>
              <span>Are you sure you want to duplicate?</span>
            </div>
          </section>
          <section className='action-modal__form-footer'>
            <>
              <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
                Cancel
              </TextButton>
              <ActionButton
                styleClasses='tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-light confirm_project_duplicate'
                onClick={onClick}
                buttonText={`Yes, duplicate ${ramsSingularName}`}
              />
            </>
          </section>
        </div>
      </ActionModal>
    </>
  )
}

DuplicationWithOutOfDateActivitiesModal.defaultProps = {
  ramsSingularName: 'project'
};

DuplicationWithOutOfDateActivitiesModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  ramsSingularName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  outOfDateActivities: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};
