import React, { useReducer, Fragment } from 'react';
import { produce } from 'immer';

import { shareSettingsReducer, shareSettingsInitialStore } from 'reducers/shareSettingsReducer';
import { masterActivityTradesAttributes } from 'components/helpers/resources/masterActivities';

import useToasts from 'components/hooks/useToasts';

import FilledButton from 'components/application/buttons/FilledButton';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import CheckboxField from 'components/application/CheckboxField';
import Tooltip from 'components/application/Tooltip';
import Portal from 'components/application/Portal';
import ToastRack from 'components/application/ToastRack';

export default function RiskAssessmentShareSettings(props) {
  const {
    masterActivity,
    selectableTrades,
    tradesUpdatePolicy,
    referrerTradeId
  } = props;

  const initialStore = produce(shareSettingsInitialStore, draftStore => {
    const masterActivityTrades = masterActivity.included.filter(obj => obj.type === 'masterActivityTrade');
    const associatedTradeIds = masterActivityTrades.map(masterActivityTrade => masterActivityTrade.relationships.trade.data.id);

    draftStore.domain.masterActivity = masterActivity.data;

    masterActivityTrades.forEach((masterActivityTrade) => {
      draftStore.domain.masterActivityTrades.data[masterActivityTrade.id] = masterActivityTrade;
      draftStore.domain.masterActivityTrades.allDataIds.push(masterActivityTrade.id)
    })

    selectableTrades.data.forEach((trade) => {
      draftStore.domain.selectableTrades.data[trade.id] = trade;
      draftStore.domain.selectableTrades.allDataIds.push(trade.id)
      draftStore.ui.form[trade.id] = associatedTradeIds.includes(trade.id);
    })
  });

  const [store, dispatch] = useReducer(shareSettingsReducer, initialStore);
  const [toasts, , , , addInedibleToast] = useToasts();

  const masterActivityTrades = store.domain.masterActivityTrades.allDataIds.map(masterActivityTradeId => store.domain.masterActivityTrades.data[masterActivityTradeId]);
  const selectableTradeIds = store.domain.selectableTrades.allDataIds;
  const selectedTradeIds = Object.keys(store.ui.form).filter((key) => store.ui.form[key]);

  const handleInputChange = (event) => {
    const target = event.target;

    dispatch({ type: 'UPDATE_FORM', key: target.name, value: target.checked })
  };

  const saveChanges = () => {
    const params = {
      master_activity_share_settings: {
        master_activity_trades_attributes: masterActivityTradesAttributes({ masterActivityTrades, selectableTradeIds, selectedTradeIds })
      }
    };

    dispatch({ type: 'UPDATE_SUBMITTING', value: true })

    axios
      .patch(`/risk_assessments/${store.domain.masterActivity.id}/share_settings`, params)
      .then(() => {
        const tradeId = selectedTradeIds.includes(referrerTradeId) ? referrerTradeId : selectedTradeIds[0];

        window.location.href = `/categories/${tradeId}/risk_assessments/${store.domain.masterActivity.id}`;
      })
      .catch(() => {
        dispatch({ type: 'UPDATE_SUBMITTING', value: false })

        addInedibleToast()
      })
  };

  return (
    <Fragment>
      <div className='p-t-30 tw-bg-white'>
        <div className="tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-0 m-b-24">
          <div className='m-r-12 fh-20'>
            <InfoIcon width={20} height={20} className="[&_path]:tw-fill-cyan-800" />
          </div>
          <p className='m-0'>
            <span className='tw-font-medium'>{store.domain.masterActivity.attributes.name}</span>&nbsp;
            <span>is shared with the following categories. A risk assessment must be shared with at least 1 category.</span>
          </p>
        </div>
        <div className='header-bar header-bar--border header-bar--border-top-curved tw-bg-grey-050 tw-border-grey-100'>
          <span className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Category</span>
        </div>
        {store.domain.selectableTrades.allDataIds.map((tradeId) => {
          const isSelected = store.ui.form[tradeId];
          const isChangeDisallowed = !tradesUpdatePolicy[tradeId];
          const isDisabled = (isSelected && selectedTradeIds.length === 1) || isChangeDisallowed;

          return (
            <div key={tradeId} className='row-bar row-bar--border-top-none row-bar--flex-align-items-ie-fix tw-border-grey-100 hover:tw-bg-grey-025'>
              <div className='row-bar__content'>
                <div className={`tooltip-parent${isChangeDisallowed ? ' tooltip-parent--disallowed' : ''}`}>
                  <CheckboxField
                    height={'short'}
                    value={tradeId}
                    name={tradeId}
                    checked={isSelected}
                    disabled={isDisabled}
                    onChange={handleInputChange}
                    labelTextProps={{ className: 'tw-font-medium' }}
                  />
                  {isChangeDisallowed && <Tooltip placement='top' trigger='hover' tooltip='You do not have access to all divisions who are using this category' />}
                </div>
                <span className='row-bar__weighted-text truncated-text-container tw-font-medium m-l-18'>
                  {store.domain.selectableTrades.data[tradeId].attributes.name}
                </span>
              </div>
            </div>
          )
        })}
        <Portal containerSelector='.form-actions-right'>
          <OutlinedButton size='md' color='blue-grey' onDark={true} href={`/categories/${referrerTradeId}/risk_assessments/${store.domain.masterActivity.id}`}>
            Cancel
          </OutlinedButton>
        </Portal>
        <Portal containerSelector='.form-actions-right'>
          <FilledButton color='mint' onDark={true} onClick={saveChanges} disabled={store.ui.submitting}>
            Save changes
          </FilledButton>
        </Portal>
      </div>
      <ToastRack toasts={toasts} />
    </Fragment>
  )
}
