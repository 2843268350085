import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/application/Toast';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';

export default function UndoableToast(props) {
  const {
    toastKey,
    toastText,
    onBurnToast,
    onUndoAction

  } = props;

  const undo = () => {
    onBurnToast(toastKey)
    onUndoAction()
  };

  return(
    <Toast
      toastKey={toastKey}
      burn={onBurnToast}
      modifiers='with-undo'
      burnTimer={8000}
      contents={
        <Fragment>
          <div className='toast__icon tw-bg-green-500'><TickIcon className='[&_polygon]:tw-fill-white' width={20} height={20} /></div>
          <div className='toast__text'>{toastText}</div>
          <div className='p-l-32 p-t-12'>
            <OutlinedButton size='sm' color='grey' onClick={undo}>Undo</OutlinedButton>
          </div>
        </Fragment>
      }
    />
  )
}

UndoableToast.propTypes = {
  toastKey: PropTypes.string,
  toastText: PropTypes.object.isRequired,
  onBurnToast: PropTypes.func.isRequired,
  onUndoAction: PropTypes.func.isRequired
};
