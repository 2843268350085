$(document).ready(function () {
  setDeleteHandlers();
  focusConfirmationOnModalOpen();
});

function focusConfirmationOnModalOpen() {
  $('.modal').on('shown.bs.modal', function () {
    $(this).find('.confirmmodalsubmission').focus();
  });
}

// TODO brought this back from previous commit nearly all of this should go, it's not shared and is pretty wasteful. Will be removed in dashboard
$(document).ajaxComplete(function () {
  setTimeout(function () {
    setDeleteHandlers();
  }, 1000);
});

function setDeleteHandlers() {
  // clear existing handlers
  $('.confirm_project_delete').off('click');
  $('.confirm_pdf_delete').off('click');

  $('.confirm_project_delete').on('click', projectDelete);
  $('.confirm_pdf_delete').on('click', pdfDelete);
}

function projectDelete() {
  const url = '/projects/' + $(this).data('id');

  this.disabled = true;
  this.textContent = 'deleting...';
  deleteItem(url);
}

function pdfDelete() {
  const url = '/pdfs/' + $(this).data('id');
  const redirectPath = $(this).data('redirectpath');

  this.disabled = true;
  this.textContent = 'removing...';
  deleteItem(url, redirectPath);
}

function deleteItem(url, redirectPath) {
  $.ajax({
    type: "DELETE",
    url: url,
    success: redirectPath ? function () { redirectPage(redirectPath) } : refreshPage
  });
}

function redirectPage(redirectPath) {
  window.location.href = redirectPath;
}

function refreshPage() {
  window.location.reload();
}

export {
  setDeleteHandlers,
  focusConfirmationOnModalOpen
 }
