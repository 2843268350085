import React from 'react';
import BlockList from 'components/application/BlockList';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';

export default function SubcontractorSidePanelPersonnelTab(props) {
  const {
    personnel,
    personnelMeta,
    loadMoreRef,
    isLoadMorePersonnelActive
  } = props;

  return (
    <div key='personnelTab' label='personnel'>
      {personnel.loaded && personnel.collection.length === 0 ? (
        <li className='block-list__item tw-border-grey-100'>
          <div className='block-list__item--flex-container'>
            <div className='block-list__item--align-center'>
              <span>No personnel are assigned to this sub-contractor</span>
            </div>
          </div>
        </li>
      ) : (
        <>
          <BlockList
            collection={personnel.collection}
            onItemClick={(person) => { window.location.replace(`personnel/${person.id}`) }}
            onItemDisplay={(person) => {
              return (
                <div className='block-list__item--flex-container'>
                  <div className='block-list__item--align-center'>
                    <PersonnelCourseTrainingStatusIndicator
                      status={person.attributes.trainingStatus}
                    />
                  </div>
                  <div className='block-list__item--text mw-300'>
                    <span className='truncated-text-container'>
                      {`${person.attributes.firstName} ${person.attributes.lastName}`}
                    </span>
                  </div>
                </div>
              )
            }}
          />
          {isLoadMorePersonnelActive && !personnelMeta.isLastPage && (
            <li ref={loadMoreRef} className='block-list__item tw-border-grey-100'>
              <div className='block-list__item--flex-container'>
                <div className='block-list__item--align-center'>
                  <span>Loading personnel...</span>
                </div>
              </div>
            </li>
          )}
        </>
      )}
    </div>
  )
}
