import classNames from "classnames";
import DOMPurify from "dompurify";
import React, { useState, useEffect, Fragment } from 'react';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import AttachmentsButton from 'components/attachments/AttachmentsButton';
import AvailableAttachmentList from 'containers/AvailableAttachmentList';
import CheckboxField from 'components/application/CheckboxField';
import SubmittableStepField from "./SubmittableStepField";
import Toggleable from 'components/application/Toggleable';
import ToggleableRow from 'components/application/ToggleableRow';

export default function StepRow({
  addError,
  areAttachmentsAllowed,
  dispatch,
  isEditingToggled,
  step,
  stepFieldText,
  stepNameKey,
  stepsListIsOrdering
}) {
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(false);
  const [areAttachmentsVisible, setAreAttachmentsVisible] = useState(false);

  const setAttachmentsVisible = () => setAreAttachmentsVisible(true);
  const setAttachmentsInvisible = () => setAreAttachmentsVisible(false);

  const handleStepNameChange = ({ content }) => {
    dispatch({ key: `stepName--${step.id}`, type: 'EDITING_CONTENT_CHANGE', value: content })
  }

  const handleChange = (event) => {
    setCheckBoxDisabled(true)

    axios
      .patch(`/method_steps/${step.id}`, {
        method_step: {
          selected: event.target.checked
        }
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data })
      })
      .catch(handleError)
  }

  const requestStepUpdate = () => {
    axios
      .patch(`/method_steps/${step.id}`, {
        method_step: {
          text: stepFieldText
        }
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data })
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: stepNameKey })
      })
      .catch(handleError)
  }

  const handleError = () => addError(uuidv4(), { fullMessage: genericErrorMessage });

  useEffect(() => {
    setCheckBoxDisabled(false)
  }, [step.attributes.selected])

  return (
    <ToggleableRow
      modifiers={['secondary', 'border-top-none']}
      isOrdering={stepsListIsOrdering}
      checkboxField={
        <CheckboxField
          disabled={checkBoxDisabled}
          height={'short'}
          value={step.id}
          name='stepSelected'
          checked={!!(step.attributes.selected)}
          onChange={handleChange}
          labelTextProps={{ className: 'tw-font-medium' }}
        />
      }
      toggleableField={
        <Fragment>
          <div className='tw-flex'>
            <div className='tw-flex-1 tw-min-w-0'>
              <Toggleable
                isToggled={isEditingToggled}
                primary={
                  <div className='toggleable-field__item-wrapper'>
                    <div className={classNames('rich-text tw-border-grey-500', stepsListIsOrdering && 'truncated-text-container tw-h-5')}>
                      {parse(DOMPurify.sanitize(step.attributes.text))}
                    </div>
                  </div>
                }
                secondary={
                  <SubmittableStepField
                    onCancel={() => dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: stepNameKey })}
                    onChange={handleStepNameChange}
                    onSubmit={requestStepUpdate}
                    submitButtonText='Update'
                    value={stepFieldText}
                  />
                }
                setIsToggled={(toggleValue) => dispatch({ key: stepNameKey, type: 'UI_TOGGLE', value: toggleValue })}
              />
            </div>
            {areAttachmentsAllowed && !areAttachmentsVisible && <div className='tw-ml-4'><AttachmentsButton onClick={setAttachmentsVisible} /></div>}
          </div>
          {areAttachmentsAllowed && (
            <React.Fragment>
              {isEditingToggled && areAttachmentsVisible && <hr className='tw-h-px tw-bg-grey-100 tw-mt-4 tw-mb-4 tw-border-0' />}
              {!stepsListIsOrdering && <AvailableAttachmentList methodStepId={step.id} previewerLocation={'modalRoot'} areAttachmentsVisible={areAttachmentsVisible} setAttachmentsVisible={setAttachmentsVisible} setAttachmentsInvisible={setAttachmentsInvisible} />}
            </React.Fragment>
          )}
        </Fragment>
      }
    />
  )
}
