import React from "react";
import PropTypes from "prop-types";
import OpenInNewIcon from "-!svg-react-loader?name=OpenInNewIcon!icons/open-in-new.svg";

export default function InfoCard({ linkAddress, title, children = null }) {
  return (
    <>
      <a href={linkAddress} target="_blank" className="content-box__card-info layout--b p-20 m-b-24 mw-400 tw-bg-white tw-border-grey-300 hover:tw-border-blue-300 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300">
        <div className="flex flex--justify-content__space-between tw-text-l m-b-18 fwt-600">
          <div className="tw-text-grey-900">{title}</div>
          <div className="m-2">
            <OpenInNewIcon width={15} height={15}/>
          </div>
        </div>
        {children}
      </a>
    </>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  linkAddress: PropTypes.string.isRequired,
  children: PropTypes.node
}
