export function resourceIdsAsPositionParams(resourceIds = []) {
  return (
    resourceIds.reduce((accumulator, currentValue, index) => {
      if (currentValue) {
        accumulator[currentValue] = { 'position': index };
      }
      return accumulator
    }, {})
  )
}
