function scrolledFraction(scrollableDistance, scrolledAmount) {
  let fraction;
  if (scrolledAmount > scrollableDistance) {
    fraction = 0;
  } else if (scrolledAmount === 0) {
    fraction = 1;
  } else {
    fraction = (scrollableDistance - scrolledAmount) / scrollableDistance;
  }
  return fraction
}

function scrollToTop(ref) {
  ref.current.scrollIntoView(true)
}

function scrollToBottom(ref) {
  ref.current.scrollTop = ref.current.scrollHeight;
}

function asyncScrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" })

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      reject()
    }, 3000);

    const scrollHandler = () => {
      if (window.scrollY === 0) {
        window.removeEventListener("scroll", scrollHandler)
        clearTimeout(failed)
        resolve()
      }
    };

    if (window.scrollY === 0) {
      clearTimeout(failed)
      resolve()
    } else {
      window.addEventListener("scroll", scrollHandler)
    }
  })
}

export {
  scrolledFraction, scrollToTop, scrollToBottom, asyncScrollToTop
}
