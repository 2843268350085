function ProjectRow(element) {
  this.el = $(element);
  this.link = this.el.find('td.project a, td.document a');
  this.projectId = this.el.data('projectId');
  this.timestamp = this.el.data('timestamp');
  this.init();
}

ProjectRow.prototype.init = function() {
  const _this = this;
  this.redirectOnRowClick();
  this.el.find('.archive_project').on('click', _this.visualiseArchiving.bind(_this));
  this.el.find('.project-restore').on('click', _this.visualiseRestoring.bind(_this));
};

ProjectRow.prototype.redirect = function() {
  window.location.href = this.link.attr('href');
};

ProjectRow.prototype.archived = function() {
  // for when refactoring to js framework
  this.visualiseArchived();
};

ProjectRow.prototype.restored = function() {
  this.visualiseRestoration();
};

ProjectRow.prototype.redirectOnRowClick = function() {
  const _this = this;
  _this.el.find('td').each(function() {
    if (!$(this).hasClass('actions') && !$(this).hasClass('cover_sheet')) {
      $(this).on('click', _this.redirect.bind(_this));
    }
  });
};

ProjectRow.prototype.visualiseRestoring = function() {
  this.el.find('.archive-actions').addClass('hide');
  this.el.find('.pending-restore').removeClass('hide');
};

ProjectRow.prototype.visualiseArchiving = function() {
  // cant toggle all children due to propogation needed for ajax
  this.el.find('.regular-archive').addClass('hide');
  this.el.find('.other-options').addClass('blink');
};

ProjectRow.prototype.visualiseArchived = function() {
  if (this.el.parents('.project-tab').attr('id') === 'archived-projects-tab'){
    this.el.addClass('archived-project');
    this.el.find('.regular-actions').addClass('hide');
    this.el.find('.archive-actions').removeClass('hide');
  } else {
    this.el.next('.archived-project-row').removeClass('hide');
    this.el.addClass('hide');
  }
};

ProjectRow.prototype.visualiseRestoration = function() {
  this.el.removeClass('hide');
  this.el.removeClass('archived-project');

  this.el.next('.archived-project-row').addClass('hide');

  this.el.removeClass('archived-project');

  this.el.find('.regular-actions').removeClass('hide');
  this.el.find('.archive-actions').addClass('hide');

  this.el.find('.regular-archive').removeClass('hide');
  this.el.find('.pending-archive').addClass('hide');
  this.el.find('.pending-restore').addClass('hide');

  addPopover(`.project-row-${this.projectId} .actions a`);
  addPopover(`.project-row-${this.projectId} .project .hover-popover`)
};

export default ProjectRow;
