import React from 'react';
import ActionsPill from 'components/application/ActionsPill';

export default function ReviewStatus({ review, personnel, reviewedVersion, latestVersion, onShowAcceptedReview, onShowRejectedReview }) {
  const stateText = review && review.attributes.state;
  const isReviewed = stateText === 'accepted' || stateText === 'rejected';

  const emailCheckedSignatureStatusText = () => {
    if (personnel && personnel.attributes.email) {
      return review ? 'Sent' : 'Not sent'
    } else {
      return 'Unable to send'
    }
  };

  const signatureStatusText = () => {
    if (review) {
      switch (stateText) {
        case 'accepted':
          return `Signed V${reviewedVersion.attributes.displayNumber}`
        case 'rejected':
          return `Rejected V${reviewedVersion.attributes.displayNumber}`
        default:
          return emailCheckedSignatureStatusText()
      }
    } else {
      return 'Not sent'
    }
  };

  const acceptedActionsPillColor = () => {
    if (latestVersion.attributes.displayNumber > reviewedVersion.attributes.displayNumber) {
      return 'amber'
    } else {
      return 'green'
    }
  };

  const actionsPillColor = () => {
    if (review) {
      switch (stateText) {
        case 'accepted':
          return acceptedActionsPillColor()
        case 'rejected':
          return 'red'
        default:
          return personnel.attributes.email ? 'cyan' : 'grey'
      }
    } else {
      return 'grey'
    }
  };

  const acceptedTooltipText = () => {
    if (latestVersion.attributes.displayNumber > reviewedVersion.attributes.displayNumber) {
      return 'Signed previous version. Click to view'
    } else {
      return 'Signed latest version. Click to view'
    }
  };

  const emailCheckedTooltipText = () => {
    if (personnel && personnel.attributes.email) {
      return review ? 'Document has been sent but not signed' : 'Document has not been sent'
    } else {
      return 'No email address for personnel'
    }
  };

  const tooltipText = () => {
    if (review) {
      switch (stateText) {
        case 'accepted':
          return acceptedTooltipText()
        case 'rejected':
          return 'Rejected version. Click to view reason'
        default:
          return emailCheckedTooltipText()
      }
    } else {
      return 'Document has not been sent'
    }
  };

  const handleClick = () => {
    switch (stateText) {
      case 'accepted':
        return onShowAcceptedReview(review.id)
      case 'rejected':
        return onShowRejectedReview(review.id)
      default:
        return null
    }
  };

  return latestVersion && (
    <ActionsPill
      color={actionsPillColor()}
      displayText={signatureStatusText()}
      tooltipText={tooltipText()}
      onClick={isReviewed ? handleClick : undefined}
    />
  )
}
