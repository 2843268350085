import React from 'react';
import PropTypes from 'prop-types';
import RowBar from 'components/application/RowBar';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';

export default function RolesBar(props) {
  return (
    <RowBar
      modifiers={['border-top-curved', 'border-bottom-none', 'flex-align-items-ie-fix']}
      content={
        <SearchField
          value={props.rolesSearch}
          onChange={props.onSearchInputChange}
          onReset={props.onSearchReset}
          name='rolesSearch'
          placeholder='Search your roles...'
          additionalClasses='search-field__lg'
        />
      }
      actions={props.actionButtonsVisible && (
        <OutlinedButton size='sm' color='grey' onClick={props.onNewRole}>
          <AddIcon className='m-l--8' width={24} height={24}/>
          <span>Add new role</span>
        </OutlinedButton>
      )}
    />
  )
}

RolesBar.propTypes = {
  onNewRole: PropTypes.func.isRequired
};
