import React, { useEffect, useState } from 'react';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';

import useModal from 'components/hooks/useModal';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import useDebounce from 'components/hooks/useDebounce';
import useSidePanel from 'components/hooks/useSidePanel';
import useRequestError from 'components/hooks/useRequestError';
import useValidatedStore from 'components/hooks/useValidatedStore';
import useWindowStorage from 'components/hooks/useWindowStorage';

import ResourceChangedToast from 'components/application/ResourceChangedToast';
import Paginator from 'components/application/Paginator';

import TeamsBar from './TeamsBar';
import TeamsTable from './TeamsTable';
import TeamsSidePanel from './TeamsSidePanel';
import DestroyModal from 'components/application/DestroyModal';

import ResourceBlankNotice from 'components/application/ResourceBlankNotice';

import { z } from 'zod';

const initialTabStore = {
  currentSearch: '',
  page: 1,
  currentResourceId: '',
  currentResourceName: '',
  sidePanelIsOpen: false,
};

const tabSchema = z.object({
  currentResourceId: z.string().nullable(),
  currentResourceName: z.string(),
  sidePanelIsOpen: z.boolean(),
  page: z.number().or(z.null()),
  currentSearch: z.string()
});

const initialTeamsState = {
  loaded: false,
  collection: [],
  includedCompanies: [],
  includedManagers: [],
  metaData: { currentPage: null, totalPages: null, totalCount: null },
};

export default function TeamsTab({ label }) {
  const currentActor = useCurrentActor();

  const defaultTeam = {
    id: '',
    name: '',
    companyId: currentActor.division.id,
    members: []
  };

  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, { store: window.sessionStorage });
  const tabStore = useValidatedStore({ getStore, initialStore: initialTabStore, schema: tabSchema });
  const initialCurrentSearch = { teamsSearch: tabStore.currentSearch };
  const initialDefaultTeam = {
    ...defaultTeam,
    id: tabStore.currentResourceId,
    name: tabStore.currentResourceName
  };
  const initialSidePanelIsOpen = tabStore.sidePanelIsOpen;

  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const breadBoard = useBreadBoard();
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [currentTeam, setCurrentTeam] = useState(initialDefaultTeam);
  const [currentSearch, setCurrentSearch] = useState(initialCurrentSearch);
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(currentSearch, 250);
  const [sidePanelIsOpen, _setSidePanelIsOpen, openSidePanel, closeSidePanel, _resetSidePanelContext, sidePanelContext, setSidePanelContext] = useSidePanel(initialSidePanelIsOpen, 'show');
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useModal(false);
  const [teams, setTeams] = useState(initialTeamsState);

  function fetchTeams(page=1) {
    const params = currentSearch.teamsSearch ? { search: currentSearch.teamsSearch, page } : { page };

    axios
    .get('/dashboards/teams', { params })
    .then((response) => {
      const teamsData = {
        loaded: true,
        collection: response.data.data,
        includedCompanies: response.data.included.filter(object => object.type === 'company'),
        includedManagers: response.data.included.filter(object => object.type === 'simpleUser'),
        metaData: response.data.meta,
      }
      setTeams(teamsData)
    })
    .catch(breadBoard.addInedibleToast)
  }

  function createTeam() {
    axios
    .post('/teams', {
      team: {
        name: currentTeam.name,
        company_id: currentTeam.companyId,
        personnel_ids: currentTeam.members.filter(member => member.personnelId).map(member => member.personnelId),
        manager_ids: currentTeam.members.filter(member => member.isManager).map(member => member.userId)
      }
    })
    .then(response => {
      closeSidePanel()
      breadBoard.addToast(
        <ResourceChangedToast
          resource={response.data.data.attributes.name}
          handleBurnToast={breadBoard.handleBurnToast}
          status={'added'}
        />
      )
      fetchTeams()
      setCurrentTeam(defaultTeam)
    })
    .catch(handleRequestError)
  };

  function updateTeam() {
    axios
    .patch(`/teams/${currentTeam.id}`, {
      team: {
        name: currentTeam.name,
        company_id: currentTeam.companyId,
        personnel_ids: currentTeam.members.filter(member => member.personnelId).map(member => member.personnelId),
        manager_ids: currentTeam.members.filter(member => member.isManager).map(member => member.userId)
      }
    })
    .then(response => {
      closeSidePanel()
      breadBoard.addToast(
        <ResourceChangedToast
          resource={response.data.data.attributes.name}
          handleBurnToast={breadBoard.handleBurnToast}
          status={'updated'}
        />
      )
      fetchTeams()
      setCurrentTeam(defaultTeam)
    })
    .catch(handleRequestError)
  }

  function destroyCurrentTeam() {
    axios
      .delete(`/teams/${currentTeam.id}`)
      .then(_response => {
        closeSidePanel()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={currentTeam.name}
            handleBurnToast={breadBoard.handleBurnToast}
            status={'deleted'}
          />
        )
        fetchTeams()
        setCurrentTeam(defaultTeam)
      })
      .catch(breadBoard.addInedibleToast)
  };

  function assignCurrentTeam(teamId) {
    const team = teams.collection.find(team => team.id === teamId);

    setCurrentTeam({
      id: team.id,
      name: team.attributes.name,
      companyId: team.relationships.company.data.id,
      members: []
    })
  }

  function handleTeamSelection(teamId) {
    assignCurrentTeam(teamId)
    setSidePanelContext('show')
    openSidePanel()
  };

  function handleTeamNameInputChange(event) {
    setCurrentTeam({ ...currentTeam, name: event.target.value })
  }

  function handleTeamCompanyOptionChange(companyId) {
    setCurrentTeam({
      ...currentTeam,
      companyId: companyId,
      members: [],
    })
  }

  function handleTeamMemberSelect(member) {
    const newMember = { ...member, persisted: false }
    const newMembers = [newMember, ...currentTeam.members]

    setCurrentTeam({
      ...currentTeam,
      members: newMembers
    });
  }

  function handleTeamMemberStatusChange({ member, isManager }) {
    const currentMembers = [...currentTeam.members]
    const currentMemberIndex = currentMembers.findIndex(currentMember => currentMember.id === member.id)
    currentMembers[currentMemberIndex] = { ...member, isManager }

    setCurrentTeam({
      ...currentTeam,
      members: currentMembers
    });
  }

  function handleTeamMemberDelete(memberId) {
    const newMembers = currentTeam.members.filter(member => member.id !== memberId);

    setCurrentTeam({
      ...currentTeam,
      members: newMembers
    });
  }

  function handleTeamMemberFetch({ members, replace }) {
    const newMembers = members.map(member => {
      return {
        id: member.id,
        userId: member.relationships.user.data && member.relationships.user.data.id,
        personnelId: member.relationships.personnel.data && member.relationships.personnel.data.id,
        isManager: !!(member.relationships.teamManager.data),
        persisted: true,
      }
    })

    setCurrentTeam(prevTeam => ({
      ...prevTeam,
      members: replace ? newMembers : prevTeam.members.concat(newMembers),
    }))
  }

  function handleEditClick() {
    resetRequestError()
    setSidePanelContext('edit')
  }

  function handleDeleteClick() {
    setDestroyModalIsOpen(true)
  }

  function handleAddTeamClick(event) {
    event.currentTarget.blur()
    setCurrentTeam(defaultTeam)
    resetRequestError()
    setSidePanelContext('new')
    openSidePanel()
  };

  function handleSearchInputChange(event) {
    setCurrentSearch({ teamsSearch: event.target.value})
  };

  function handleSearchReset() {
    setCurrentSearch({ teamsSearch: '' })
  };

  function handlePageChange(event) {
    const page = event.currentTarget.getAttribute('data-page');
    fetchTeams(page)
  };

  useEffect(() => {
    fetchTeams(tabStore.page)
  }, [])

  useEffect(() => {
    if (teams.loaded && debouncedCurrentSearch) { fetchTeams() }
  }, [debouncedCurrentSearch])

  useEffect(() => {
    setStore({
      currentSearch: currentSearch.teamsSearch,
      page: teams.metaData.currentPage,
      currentResourceId: currentTeam.id,
      currentResourceName: currentTeam.name,
      sidePanelIsOpen
     })
  }, [currentSearch.teamsSearch, teams.metaData.currentPage, currentTeam.id, currentTeam.name, sidePanelIsOpen])

  return (
    <>
      <div className={'col-sm-12 p-l-0 p-r-0'}>
        {teams.metaData.totalCount > 0 && (
          <TeamsBar
            teamsSearch={currentSearch.teamsSearch}
            teams={teams}
            onSearchInputChange={handleSearchInputChange}
            onSearchReset={handleSearchReset}
            actionButtonsVisible={trainingRegisterResourceManagementContext.hasTeamEditableAccess}
            onAddTeamClick={handleAddTeamClick}
          />
        )}

        {teams.loaded &&
          (teams.collection.length > 0 ? (
            <>
              <TeamsTable
                teams={teams}
                onTeamSelection={handleTeamSelection}
              />
              {
                teams.metaData.totalPages > 1 && (
                  <div className='m-t-80 text-center'>
                    <Paginator
                      currentPage={teams.metaData.currentPage}
                      totalPages={teams.metaData.totalPages}
                      onClick={handlePageChange}
                    />
                  </div>
                )
              }
            </>
          ) : (
            <ResourceBlankNotice
              displayReadOnlyContents={!trainingRegisterResourceManagementContext.hasTeamEditableAccess}
              totalCount={teams.metaData.totalCount}
              onAdd={handleAddTeamClick}
              resource={'team'}
              addMessage={
                trainingRegisterResourceManagementContext.hasTeamEditableAccess
                ? 'Create a list of the teams used in your company.'
                : 'Any teams that have been created will be listed here'
              }
            />
          ))
        }
      </div>
      <TeamsSidePanel
        team={currentTeam}
        teamCompanyName={(teams.loaded && teams.length > 0) ? teams.includedCompanies.find(company => company.id === currentTeam.companyId).attributes.name  : ''}
        onTeamNameInputChange={handleTeamNameInputChange}
        onTeamCompanyOptionChange={handleTeamCompanyOptionChange}
        onTeamMemberSelect={handleTeamMemberSelect}
        onTeamMemberStatusChange={handleTeamMemberStatusChange}
        onTeamMemberDelete={handleTeamMemberDelete}
        onTeamMemberFetch={handleTeamMemberFetch}
        onCreateClick={createTeam}
        onUpdateClick={updateTeam}
        sidePanelIsOpen={sidePanelIsOpen}
        sidePanelContext={sidePanelContext}
        closeCallback={closeSidePanel}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
        submitDisabled={submitDisabled}
        requestError={requestError}
        removeErrorStyling={removeErrorStyling}
      />
      {sidePanelContext === 'show' && (
        <DestroyModal
          isOpen={destroyModalIsOpen}
          displayText={`Are you sure you want to delete ${currentTeam.name}?`}
          confirmationText='Delete team'
          onClose={() => setDestroyModalIsOpen(false)}
          onDestroy={destroyCurrentTeam}
        />
      )}
    </>
  )
}
