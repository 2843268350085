import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PdfIcon from '-!svg-react-loader?name=PdfIcon!icons/ic-pdf.svg';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';
import ImagesIcon from '-!svg-react-loader?name=ImagesIcon!icons/ic-images.svg';

const isPreviewable = { 'default': true, 'compact': false };
const loadingIconSize = { 'default': 60, 'compact': 40 };
const imagesIconSize = { 'default': 20, 'compact': 14 };

export default function Image(props) {
  const { mode, attachment, previewAttachment } = props;

  const isProcessed = attachment.attachedProcessed === true;
  const isPdf = attachment.recordType === 'pdf';

  const [timestamp, setTimestamp] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let thumbnailUrl;
  if (attachment.imageThumbUrl && timestamp && isProcessed ) {
    thumbnailUrl = attachment.imageThumbUrl
  }else if(attachment.originalImageThumbUrl && !attachment.imageThumbUrl && timestamp && !isProcessed) {
    thumbnailUrl = attachment.originalImageThumbUrl
  }

  const handlePreview = (_event) => {
    isPreviewable[mode] && !!thumbnailUrl && previewAttachment()
  }

  useEffect(() => {
    setTimestamp(moment().unix())
  }, [attachment.imageThumbUrl])

  return (
    <div
      className={`attachments__viewer-attachment attachments__viewer-attachment--${mode}-mode`}
      onMouseEnter={() => { isPreviewable[mode] ? setIsButtonVisible(true) : void(0) }}
      onMouseLeave={() => { isPreviewable[mode] ? setIsButtonVisible(false) : void(0) }}
    >
      {thumbnailUrl ? (
        <Fragment>
          <img className='attachments__viewer-image' src={`${thumbnailUrl}?timestamp=${timestamp}`} />
          {isPdf && <PdfIcon className='attachments__pdf-icon [&_rect]:tw-fill-red-500' height={18} width={36}/>}
          {isButtonVisible && (
            <button className='attachments__viewer-preview tw-text-white tw-border-white tw-bg-grey-900' onClick={handlePreview} disabled={!thumbnailUrl}>
              Preview
            </button>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className='attachments__icon-wrapper tw-apply-loading-spinner--blue-light'>
            <LoadingIcon className='animated-spinner' width={loadingIconSize[mode]} height={loadingIconSize[mode]} />
          </div>
          <div className='attachments__icon-wrapper'>
            <ImagesIcon className='[&_path]:tw-fill-grey-500' width={imagesIconSize[mode]} height={imagesIconSize[mode]} />
          </div>
        </Fragment>
      )}
    </div>
  )
}

Image.propTypes = {
  mode: PropTypes.string,
  attachment: PropTypes.object.isRequired,
  previewAttachment: PropTypes.func
}

Image.defaultProps = {
  mode: 'default'
}
