import React, { useRef } from 'react';
import PropTypes from 'prop-types'
import SearchIcon from '-!svg-react-loader?name=SearchIcon!icons/search.svg';

export default function SearchField(props) {

  const inputRef = useRef()

  const handleKeyDown = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      props.onReset(event);
      inputRef.current.blur();
    };
  }

  return(
    <div className={`search-field tw-border-grey-200 hover:tw-border-grey-300 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 ${props.additionalClasses ? ` ${props.additionalClasses}` :''}`}>
      {!props.withoutIcon && <span className='search-field__icon-container'><SearchIcon width={24} height={24} className='search-field__icon [&_path]:tw-fill-grey-700' /></span>}
      <input
        autoFocus={props.autoFocus}
        ref={inputRef}
        name={props.name}
        className='search-field__input tw-text-grey-900 placeholder:tw-text-grey-300'
        type='text'
        value={props.value}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        autoComplete='off'
        data-attr-name={props.name}
      />
      <div className='search-field__clear-container'>
        { props.value.length > 0 &&
          <div
            className="search-field__clear circle--remove tw-bg-transparent hover:tw-bg-red-600 before:tw-content-[''] before:tw-bg-grey-700 hover:before:tw-bg-white after:tw-content-[''] after:tw-bg-grey-700 hover:after:tw-bg-white"
            data-attr-name={props.name}
            onClick={props.onReset}
          >
          </div>
        }
      </div>
    </div>
  )
}

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  withoutIcon: PropTypes.bool,
  additionalClasses: PropTypes.string,
  autoFocus: PropTypes.bool
}

SearchField.defaultProps = {
  autoFocus: false
}
