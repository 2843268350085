// external
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

// shared
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import CircleQuestion from 'components/application/CircleQuestion';
import Glimmer from 'components/application/Glimmer';
import setupPolling from 'utilities/setupPolling';
import Tooltip from 'components/application/Tooltip';
import useToggle from 'components/hooks/useModal';

// contextual
import { sendAnalytics } from 'components/helpers/analytics';
import InactiveCard from '../cards/InactiveCard';
import InputFields from './InputFields';
import Modal from '../modal/Modal';
import SelectedCard from '../cards/SelectedCard';
import SelectionButton from './SelectionButton';
import * as procoreAPI from '../../requests'

// ************************** Top Level Component
const defaultAssignedDetails = { assignerID: null, company: null, folder: null, project: null };
const defaultSeedDetails = { canManage: false, loaded: false };

const pollingInterval = 1000
const pollingMaxTime = 600000

export default function Manager({ procoreIdentityID, integration }) {
  // state
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const [assignedDetails, setAssignedDetails] = useState(defaultAssignedDetails)
  const [isSelecting, setIsSelecting] = useToggle(false)
  const [seedDetails, setSeedDetails] = useState(defaultSeedDetails)
  const [unlinkedAt, setUnlinkedAt] = useState(null)

  // computed
  const hasProcoreIdentity = !!(procoreIdentityID)
  const isSubmittable = !!(assignedDetails.company && assignedDetails.project && assignedDetails.folder);
  const isUpdating = !!(integration)
  const isDeleting = !!(isUpdating && unlinkedAt && !isSubmittable);

  let seededLocation;
  let integrationID;

  if (integration) {
    seededLocation = {
      companyID: integration.data.relationships.procoreCompany.data.id,
      projectID: integration.data.relationships.procoreProject.data.id,
      folderID: integration.data.relationships.procoreFolder.data.id,
      assignerID: integration.data.relationships.externalIdentity.data.id
    }
    integrationID = integration.data.id;
  }

  const status = (function () {
    switch (true) {
      case (!hasProcoreIdentity):
        return seededLocation ? 'noUserIntegerationWithSeed' : 'noUserIntegerationWithoutSeed';
      case (seededLocation && !seedDetails.loaded):
        return 'loading';
      case (seededLocation && !seedDetails.canManage):
        return 'noPermission';
      case (isSubmittable):
        return 'submittable'
      default:
        return 'unselected';
    }
  })();

  // handlers

  const handleUnlink = () => {
    setUnlinkedAt(moment().unix())
    setAssignedDetails(defaultAssignedDetails)
    sendAnalytics('Procore folder unlinked', { currentUser: currentActor.user })
  }

  const handlePollingResponseCheck = (response) => {
    return ['completed', 'failed'].includes(response.data.meta.request_status)
  }

  const polling = (requestFn) => {
    return setupPolling({
      requestFn,
      testFn: handlePollingResponseCheck,
      intervalTime: pollingInterval,
      maxTime: pollingMaxTime
    })
  }

  const bouncerPolling = useMemo(() => {
    return seededLocation && polling(() => procoreAPI.fetchProcorePermissions({ companyID: seededLocation.companyID, projectID: seededLocation.projectID }))
  }, [seededLocation])

  const folderPolling = useMemo(() => {
    return seededLocation && polling(() => procoreAPI.fetchProcoreDocument({ companyID: seededLocation.companyID, folderID: seededLocation.folderID, projectID: seededLocation.projectID }))
  }, [seededLocation])

  const projectPolling = useMemo(() => {
    return seededLocation && polling(() => procoreAPI.fetchProcoreCompanyProject({ companyID: seededLocation.companyID, projectID: seededLocation.projectID }))
  }, [seededLocation])

  const fetchAllSeedRequirements = () => {
    return Promise.all([
      projectPolling.startPolling(),
      folderPolling.startPolling(),
      bouncerPolling.startPolling()
    ])
  }

  const handleSeedResourcesFetched = (responses) => {
    if (responses.every((response) => response.data.meta.request_status === 'completed')) {
      const [projectsResponse, documentsResponse, bouncerResponse] = responses;

      setAssignedDetails({
        project: projectsResponse.data.data,
        folder: documentsResponse.data.data,
        // yeah I know that's cheating, don't @ me
        company: { id: seededLocation.companyID },
        assignerID: seededLocation.assignerID,
      })

      setSeedDetails({ canManage: bouncerResponse.data.data.attributes.canManageFolders, loaded: true })
    } else if (responses.some((response) => response.data.meta.error_reason === 'not_found')) {
      setSeedDetails({ canManage: false, loaded: true })
    } else {
      breadBoard.addInedibleToast()
    }
  }

  useEffect(() => {
    if (hasProcoreIdentity && seededLocation) {
      fetchAllSeedRequirements()
        .then(handleSeedResourcesFetched)
        .catch((error) => error.response.status !== 404 ? breadBoard.addInedibleToast() : (() => { throw error })())
        .catch(() => setSeedDetails({ canManage: false, loaded: true }))
    }
  }, [])

  useEffect(() => {
    return () => {
      bouncerPolling.endPolling()
      folderPolling.endPolling()
      projectPolling.endPolling()
    }
  }, [])

  const statusContent = (() => {
    switch(status) {
      case 'noUserIntegerationWithoutSeed': return (
        <Tooltip
          placement='top'
          trigger='hover'
          tooltip='Please connect your account with Procore in settings to select a Procore folder'
        >
          <SelectionButton disabled={true} />
        </Tooltip>
      )
      case 'noUserIntegerationWithSeed': return <InactiveCard reason='Connect your Procore account to edit' />
      case 'loading': return <InactiveCard reason={<span>Loading...<Glimmer /></span>} />
      case 'noPermission': return <InactiveCard reason='Contact your admin for access to Procore' />
      case 'submittable': return (
        <SelectedCard
          projectName={assignedDetails.project.attributes.name}
          folderName={assignedDetails.folder.attributes.name}
          onEdit={() => setIsSelecting(true)}
          onUnlink={handleUnlink}
        />
      )
      case 'unselected': return <SelectionButton onClick={() => setIsSelecting(true)} />
    }
  })();

  const integrationOptions = integrationID ? {
    id: integrationID,
    mode: isDeleting ? 'delete' : 'update'
  } : null;

  return (
    <>
      <div className='flex'>
        <span
          className={`${isSubmittable ? 'p-t-17' : 'p-t-10'} ta-r col-sm-3 tw-text-m tw-font-medium tw-tracking-auto`}>
          Link to Procore project
        </span>
        {hasProcoreIdentity && <InputFields {...{ procoreIdentityID, assignedDetails, existingIntegration: integrationOptions }} />}
        <div className='flex col-sm-8'>
          {statusContent}
          <div className='tooltip-parent m-t-10 m-l-12'>
            <CircleQuestion />
            <Tooltip placement='top' trigger='hover' tooltip='On approval, a copy of this document will auto-upload to a designated location on Procore' />
          </div>
        </div>
      </div>
      <Modal
        key={`locationSelectorModal--${unlinkedAt}`}
        currentUser={currentActor.user}
        seededLocation={unlinkedAt ? null : seededLocation}
        isSelecting={isSelecting}
        onCancel={() => setIsSelecting(false)}
        onSelection={({ company, project, folder }) => {
          setAssignedDetails({ company, project, folder, loaded: true, assignerID: procoreIdentityID })
          sendAnalytics('Procore folder selected for a project', { currentUser: currentActor.user });
          setIsSelecting(false)
        }}
      />
    </>
  )
}
