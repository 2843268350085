import React from 'react';
import PropTypes from 'prop-types';
import InfoBanner from 'components/application/InfoBanner';

export default function NoUserPermissionsSetBanner({ divisionName }) {
  return (
    <InfoBanner title='User permissions not set' flavour='warning' size='medium'>
      <div>{ `It appears that you have access to the division ${divisionName} but no permissions have been set for you.` }</div>
      <div>Contact your account administrator to get the required permissions for this division.</div>
    </InfoBanner>
  )
}

NoUserPermissionsSetBanner.propTypes = {
  divisionName: PropTypes.string.isRequired
};
