import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '-!svg-react-loader?name=LockIcon!icons/ic-lock.svg';
import RedLockIcon from '-!svg-react-loader?name=RedLockIcon!icons/ic-red-lock.svg';
import TextField from 'components/application/TextField';

export default function PasswordField({ validationError, passwordInput, onPasswordInputChange, children, errorMessage, instructionMessage, onInputKeyDown }) {

  return(
    <>
      <div className={`download-container__sub-text ${validationError ? 'tw-text-red-600' : 'tw-text-grey-600'} m-t-20`}>
        {validationError ?
          <RedLockIcon className='download-container__svg m-b-3 m-r-8 [&_path]:tw-fill-red-600' height={18} width={16} />
          :
          <LockIcon className='download-container__svg m-b-3 m-r-8 [&_path]:tw-fill-grey-600' height={18} width={16} />
        }
        { validationError ? errorMessage : instructionMessage }
      </div>
      <div className='m-t-24 download-container__input'>
        <TextField
          fieldError={validationError}
          label='Password'
          name='password'
          type='password'
          value={passwordInput.password}
          onChange={onPasswordInputChange}
          onKeyDown={onInputKeyDown}
        />
      </div>
      { children }
    </>
  )
}

PasswordField.propsTypes = {
  passwordInput: PropTypes.object.isRequired,
  onPasswordInputChange: PropTypes.func.isRequired
}
