import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useForm from 'components/hooks/useForm';
import useSidePanel from 'components/hooks/useSidePanel';
import useDebounce from 'components/hooks/useDebounce';
import useRequestError from 'components/hooks/useRequestError';

import Table from 'components/application/Table';
import MasterCoshhRow from 'components/masterCoshh/MasterCoshhRow';
import MasterCoshhSearchBlankNotice from 'components/masterCoshh/MasterCoshhSearchBlankNotice'
import Paginator from 'components/application/Paginator';
import ProjectCoshhDestroyModal from 'components/projectCoshh/ProjectCoshhDestroyModal';
import ProjectCoshhSidePanel from 'components/projectCoshh/ProjectCoshhSidePanel';
import { defaultCoshhDocument, updatedPersonsAtRiskValues, formatPersonsAtRiskValues } from 'components/helpers/resources/coshhDocuments';
import SearchField from 'components/application/SearchField';
import RowBar from 'components/application/RowBar';

const initialMasterCoshhDocumentsState = { loaded: false, data: [], metaData: { currentPage: null, totalPages: null } };
const initialCoshhDocumentsState = { loaded: false, data: [], included: [], allMasterCoshhDocumentIds: {} };

export default function MasterCoshhTable(props) {
  const {
    projectId,
    workerExposureTimeOptions,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    personsAtRiskOptions,
    addInedibleToast,
    onCoshDocumentChange,
    secondaryFieldLabel,
    secondaryFieldDefaultValue,
  } = props;

  const [masterCoshhDocuments, setMasterCoshhDocuments] = useState(initialMasterCoshhDocumentsState);
  const [coshhDocuments, setCoshhDocuments] = useState(initialCoshhDocumentsState);
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [currentCoshhDocument, setCurrentCoshhDocument, handleCoshhInputChange, handleCoshhOptionChange, , , handleOptionSelect ] = useForm(defaultCoshhDocument);
  const [initialPersonsAtRisk, setInitialPersonsAtRisk] = useState([]);

  const [isSidePanelSubmitDisabled, setIsSidePanelSubmitDisabled] = useState(false);
  const [sidePanelIsOpen, setSidePanelIsOpen, _openSidePanel, closeSidePanel, _resetSidePanelContext, sidePanelContext, setSidePanelContext] = useSidePanel(false, 'new');
  const [pageNumber, setPageNumber] = useState(1);
  const [coshhDestroyModalIsOpen, setCoshhDestroyModalIsOpen] = useState(false);

  const [currentSearch, setCurrentSearch, handleSearchInputChange] = useForm({ masterCoshhSearch: '' });
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(currentSearch, 250);

  const handleSearchReset = (event) => {
    const name = event.target.getAttribute('data-attr-name')
    setCurrentSearch({ ...currentSearch, [name]: '' })
  };

  const fetchMasterCoshhDocuments = (page = 1) => {
    axios
      .get(`/projects/${projectId}/master_coshh`, { params: { search: currentSearch.masterCoshhSearch ? currentSearch.masterCoshhSearch : null, page: page } })
      .then((response) => {
        setIsSidePanelSubmitDisabled(false)
        setMasterCoshhDocuments({ loaded: true, data: response.data.data, metaData: response.data.meta })
        setPageNumber(response.data.meta.currentPage)
        setCurrentCoshhDocument(defaultCoshhDocument)
      })
      .catch(() => addInedibleToast())
  };

  const fetchCoshhDocuments = () => {
    axios
      .get(`/projects/${projectId}/coshh`)
      .then((response) => {
        setSidePanelIsOpen(false)
        const allMasterCoshhDocumentIds = {}
        response.data.data.map(obj => allMasterCoshhDocumentIds[obj.relationships.masterCoshhDocument.data.id] = obj.id )

        setCoshhDocuments({
          loaded: true,
          data: response.data.data,
          included: response.data.included,
          allMasterCoshhDocumentIds: allMasterCoshhDocumentIds
        })
      })
      .catch(() => addInedibleToast())
  };

  const refreshResources = (pageNumber = 1) => {
    fetchCoshhDocuments()
    fetchMasterCoshhDocuments(pageNumber)
  };

  useEffect(() => {
    if (masterCoshhDocuments.loaded && debouncedCurrentSearch) { fetchMasterCoshhDocuments() }
  }, [debouncedCurrentSearch])

  useEffect(() => {
    refreshResources()
  }, [])

  const handlePageChange = (event) => {
    const page = event.currentTarget.getAttribute('data-page')
    fetchMasterCoshhDocuments(page)
  };

  const defaultCurrentCoshhDocument = (masterCoshhDocument) => {
    return {
      id: coshhDocuments.loaded && coshhDocuments.allMasterCoshhDocumentIds[masterCoshhDocument.id],
      title: masterCoshhDocument.attributes.title,
      masterId: masterCoshhDocument.id,
      coshhVersionId: masterCoshhDocument.relationships.latestCoshhVersion.data.id,
      longTermExposureLimit: masterCoshhDocument.attributes.longTermExposureLimit,
      shortTermExposureLimit: masterCoshhDocument.attributes.shortTermExposureLimit,
      quantityOnSite: '',
      quantityToBeUsed: '',
      substanceQuantityPerDay: '',
      uses: '',
      methodOfUseOther: '',
      staffNumber: '',
      personsAtRisk: [],
      personsAtRiskOther: '',
      workerExposureTime: '',
      workerExposureTimeOther: '',
      exposureFrequency: '',
      exposureFrequencyOther: '',
      location: '',
      levelOfRisk: '',
      additionalInformation: '',
    }
  };

  const coshhDocumentCreateParams = (
    {
      project_id: projectId,
      coshh_version_id: currentCoshhDocument.coshhVersionId,
      quantity_on_site: currentCoshhDocument.quantityOnSite,
      quantity_to_be_used: currentCoshhDocument.quantityToBeUsed,
      substance_quantity_per_day: currentCoshhDocument.substanceQuantityPerDay,
      uses: currentCoshhDocument.uses,
      method_of_use_other: currentCoshhDocument.uses === secondaryFieldLabel ? currentCoshhDocument.methodOfUseOther : '',
      staff_number: currentCoshhDocument.staffNumber,
      worker_exposure_time: currentCoshhDocument.workerExposureTime,
      worker_exposure_time_other: currentCoshhDocument.workerExposureTime === secondaryFieldLabel ? currentCoshhDocument.workerExposureTimeOther : '',
      exposure_frequency: currentCoshhDocument.exposureFrequency,
      exposure_frequency_other: currentCoshhDocument.exposureFrequency === secondaryFieldLabel ? currentCoshhDocument.exposureFrequencyOther : '',
      location: currentCoshhDocument.location,
      level_of_risk: currentCoshhDocument.levelOfRisk,
      additional_information: currentCoshhDocument.additionalInformation,
      assigned_persons_at_risks_attributes: currentCoshhDocument.personsAtRisk,
      persons_at_risk_other: Boolean(currentCoshhDocument.personsAtRisk.find((persons) => persons.name === secondaryFieldLabel)) ? currentCoshhDocument.personsAtRiskOther : ''
    }
  );

  const coshhDocumentUpdateParams = () => {
    const updatedPersonsAtRisk = updatedPersonsAtRiskValues(initialPersonsAtRisk, currentCoshhDocument.personsAtRisk);

    return {
      quantity_on_site: currentCoshhDocument.quantityOnSite,
      quantity_to_be_used: currentCoshhDocument.quantityToBeUsed,
      substance_quantity_per_day: currentCoshhDocument.substanceQuantityPerDay,
      uses: currentCoshhDocument.uses,
      method_of_use_other: currentCoshhDocument.uses === secondaryFieldLabel ? currentCoshhDocument.methodOfUseOther : '',
      staff_number: currentCoshhDocument.staffNumber,
      worker_exposure_time: currentCoshhDocument.workerExposureTime,
      worker_exposure_time_other: currentCoshhDocument.workerExposureTime === secondaryFieldLabel ? currentCoshhDocument.workerExposureTimeOther : '',
      exposure_frequency: currentCoshhDocument.exposureFrequency,
      exposure_frequency_other: currentCoshhDocument.exposureFrequency === secondaryFieldLabel ? currentCoshhDocument.exposureFrequencyOther : '',
      location: currentCoshhDocument.location,
      level_of_risk: currentCoshhDocument.levelOfRisk,
      additional_information: currentCoshhDocument.additionalInformation,
      assigned_persons_at_risks_attributes: updatedPersonsAtRisk,
      persons_at_risk_other: Boolean(updatedPersonsAtRisk.find((persons) => persons.name === secondaryFieldLabel && persons['_destroy'] === '1')) ? '' : currentCoshhDocument.personsAtRiskOther
    }
  };

  const assignCoshhDocumentResponseData = (response, formattedPersonsAtRisk) => {
    const { id, attributes } = response;

    return {
      id: id,
      reference: attributes.reference || '',
      title: attributes.title || '',
      quantityOnSite: attributes.quantityOnSite || '',
      quantityToBeUsed: attributes.quantityToBeUsed || '',
      substanceQuantityPerDay: attributes.substanceQuantityPerDay || '',
      uses: attributes.uses || '',
      methodOfUseOther: attributes.methodOfUseOther || '',
      staffNumber: attributes.staffNumber || '',
      personsAtRisk: formattedPersonsAtRisk,
      personsAtRiskOther: attributes.personsAtRiskOther || '',
      workerExposureTime: attributes.workerExposureTime || '',
      workerExposureTimeOther: attributes.workerExposureTimeOther || '',
      exposureFrequency: attributes.exposureFrequency || '',
      exposureFrequencyOther: attributes.exposureFrequencyOther || '',
      location: attributes.location || '',
      levelOfRisk: attributes.levelOfRisk || '',
      additionalInformation: attributes.additionalInformation || '',
      longTermExposureLimit: attributes.longTermExposureLimit,
      shortTermExposureLimit: attributes.shortTermExposureLimit
    }
  };

  const createCoshhDocument = () => {
    setIsSidePanelSubmitDisabled(true)

    axios
      .post(`/projects/${projectId}/coshh`, { master_id: currentCoshhDocument.masterId, coshh_document: coshhDocumentCreateParams })
      .then(() => {
        onCoshDocumentChange()
        resetRequestError()
        refreshResources(pageNumber)
      })
      .catch(handleRequestError)
  };

  const handleAddCoshhOnClick = (masterCoshhDocument) => {
    setSidePanelContext('new')
    setCurrentCoshhDocument(defaultCurrentCoshhDocument(masterCoshhDocument))
    setSidePanelIsOpen(true)
  };

  const handleEditCoshhOnClick = (masterCoshhDocument) => {
    const coshhDocument = coshhDocuments.data.find((coshhDoc) => coshhDoc.relationships.masterCoshhDocument.data.id === masterCoshhDocument.id);
    const assignedPersonsAtRiskValues = coshhDocuments.included.filter((includedResource) => includedResource.relationships.personable.data.id === coshhDocument.id);
    const formattedPersonsAtRisk = formatPersonsAtRiskValues(assignedPersonsAtRiskValues, 'assignedPersonsAtRisk', 'name');

    setInitialPersonsAtRisk(formattedPersonsAtRisk)
    setCurrentCoshhDocument(assignCoshhDocumentResponseData(coshhDocument, formattedPersonsAtRisk))
    setSidePanelContext('edit')
    setSidePanelIsOpen(true)
  };

  const handleUpdateCoshhDocument = () => {
    setIsSidePanelSubmitDisabled(true)

    axios
      .patch(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`, {
        coshh_document: coshhDocumentUpdateParams(),
        skip_version_generation: true
      })
      .then(() => {
        onCoshDocumentChange()
        refreshResources(pageNumber)
        setSidePanelIsOpen(false)
        resetRequestError()
      })
      .catch(handleRequestError)
  };

  const handleCloseProjectCoshhSidePanel = () => {
    resetRequestError()
    closeSidePanel()
  };

  const handleDeleteCoshhOnClick = (masterCoshhDocument) => {
    setCurrentCoshhDocument(defaultCurrentCoshhDocument(masterCoshhDocument))
    setCoshhDestroyModalIsOpen(true)
  };

  const deleteCoshhDocument = () => {
    axios
      .delete(`/projects/${projectId}/coshh/${currentCoshhDocument.id}`, { data: { skip_version_generation: true } })
      .then(() => {
        onCoshDocumentChange()
        refreshResources(pageNumber)
      })
      .catch(() => addInedibleToast())
  };

  useEffect(() => {
    if (requestError) {
      setIsSidePanelSubmitDisabled(false)
    }
  }, [requestError])

  return (
    <>
      {
        masterCoshhDocuments.loaded &&
          <>
            <RowBar
              modifiers={['border-top-curved', 'border-bottom-none']}
              additionalClasses={'m-t-30'}
              content={
                <SearchField
                  autoFocus={true}
                  value={currentSearch.masterCoshhSearch}
                  onChange={handleSearchInputChange}
                  additionalClasses={'search-field__coshh'}
                  onReset={handleSearchReset}
                  name='masterCoshhSearch'
                  placeholder='Search by substance, reference or manufacturer...'
                />
              }
            />
            <Table
              headers={
                <tr>
                  <th className='w-5 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'></th>
                  <th className='w-40 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>COSHH assessment</th>
                  <th className='w-25 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Manufacturer</th>
                  <th className='w-10 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Reference</th>
                  <th className='w-20 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-text-right'>
                    <span className='w-100 flex flex--justify-content__flex-end'>
                      <div className='fw-120 tw-text-center'>
                        Actions
                      </div>
                    </span>
                  </th>
                </tr>
              }
              rows={
                masterCoshhDocuments.data.map((masterCoshhDocument) =>
                  masterCoshhDocument.attributes.published &&
                    <MasterCoshhRow
                      key={masterCoshhDocument.id}
                      masterCoshhDocument={masterCoshhDocument}
                      onAdd={handleAddCoshhOnClick}
                      onEdit={handleEditCoshhOnClick}
                      onDelete={handleDeleteCoshhOnClick}
                      addedToProject={coshhDocuments.loaded && coshhDocuments.allMasterCoshhDocumentIds.hasOwnProperty(masterCoshhDocument.id)}
                    />
                )
              }
              blankState={currentSearch.masterCoshhSearch && <MasterCoshhSearchBlankNotice masterCoshhSearch={currentSearch.masterCoshhSearch}/>}
            />
            {
              masterCoshhDocuments.metaData.totalPages > 1 &&
                <div className='m-t-20 m-b-20 ta-center'>
                  <Paginator
                    currentPage={masterCoshhDocuments.metaData.currentPage}
                    totalPages={masterCoshhDocuments.metaData.totalPages}
                    onClick={handlePageChange}
                  />
                </div>
            }
            <ProjectCoshhDestroyModal
              isOpen={currentCoshhDocument.id ? coshhDestroyModalIsOpen : false}
              setIsOpen={setCoshhDestroyModalIsOpen}
              currentCoshhDocument={currentCoshhDocument}
              deleteCoshhDocument={deleteCoshhDocument}
            />
            <ProjectCoshhSidePanel
              coshhDocument={currentCoshhDocument}
              sidePanelIsOpen={currentCoshhDocument.title ? sidePanelIsOpen : false}
              closeSidePanel={handleCloseProjectCoshhSidePanel}
              sidePanelContext={sidePanelContext}
              setSidePanelContext={setSidePanelContext}
              onNewCoshhDocument={createCoshhDocument}
              onUpdateCoshhDocument={handleUpdateCoshhDocument}
              onCoshhInputChange={handleCoshhInputChange}
              onCoshhOptionChange={handleCoshhOptionChange}
              onOptionSelect={handleOptionSelect}
              workerExposureTimeOptions={workerExposureTimeOptions}
              exposureFrequencyOptions={exposureFrequencyOptions}
              locationOptions={locationOptions}
              methodOfUseOptions={methodOfUseOptions}
              personsAtRiskOptions={personsAtRiskOptions}
              secondaryFieldLabel={secondaryFieldLabel}
              secondaryFieldDefaultValue={secondaryFieldDefaultValue}
              requestError={requestError}
              removeErrorStyling={removeErrorStyling}
              submitDisabled={submitDisabled || isSidePanelSubmitDisabled}
            />
          </>
      }
    </>
  )
}

MasterCoshhTable.propTypes = {
  projectId: PropTypes.number.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  personsAtRiskOptions: PropTypes.array.isRequired,
  addInedibleToast: PropTypes.func.isRequired,
  onCoshDocumentChange: PropTypes.func.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired
}
