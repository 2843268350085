import React from 'react';
import PropTypes from 'prop-types';
import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';
import Tooltip from 'components/application/Tooltip';

export default function ProjectRowRegularActions(props) {
  const {
    projectID,
    projectArchived,
    duplicationBlocked,
    projectTab,
    ramsSingularName,
    duplicationEnabled,
    onRegularActionsClick,
    onDeleteClick,
    onDuplicateClick
  } = props;

  return (
    <div className={'dropdown__container pull-right regular-actions' + (projectArchived ? ' hide' : '')}>
      <div className='dropdown'>
        <Options onOpen={onRegularActionsClick}>
          {duplicationEnabled && (
            <OptionChoice disabled={duplicationBlocked} onClick={onDuplicateClick}>
              <div className='tooltip-parent'>
                <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto duplicate_project'>
                  Duplicate
                </a>
                {duplicationBlocked && <Tooltip placement='left' trigger='hover' tooltip={`This ${ramsSingularName} can't be duplicated because it uses categories, risk assessments or tasks which are no longer available`} className='tooltip-dark--max-w-xxs' />}
              </div>
            </OptionChoice>
          )}
          <OptionChoice>
            <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto archive_project' data-container='body' data-remote='true' rel='nofollow' data-method='put' href={`/projects/${projectID}/archive?tab=${projectTab}`}>
              Archive
            </a>
          </OptionChoice>
          <OptionChoice onClick={onDeleteClick}>
            <a className='tw-text-m tw-text-red-600 hover:tw-text-red-600 tw-font-medium tw-tracking-auto delete_project'>
              Delete
            </a>
          </OptionChoice>
        </Options>
      </div>
    </div>
  )
}

ProjectRowRegularActions.defaultProps = {
  ramsSingularName: 'project'
};

ProjectRowRegularActions.propTypes = {
  projectID: PropTypes.number.isRequired,
  projectArchived: PropTypes.bool.isRequired,
  duplicationBlocked: PropTypes.bool.isRequired,
  projectTab: PropTypes.string,
  ramsSingularName: PropTypes.string,
  duplicationEnabled: PropTypes.bool.isRequired,
  onRegularActionsClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDuplicateClick: PropTypes.func.isRequired
};
