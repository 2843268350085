import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/application/Tabs';
import TeamsSidePanelMembersTab from 'components/teams/TeamsSidePanelMembersTab';

export default function TeamSidePanelShow({
  team,
  teamCompanyName,
  teamMembers,
  sidePanelIsOpen,
  canLoadMoreTeamMembers,
  onLoadMoreTeamMembers,
  isFetchingTeamMembers
}) {
  return (
    <div className='popup__body-show'>
      <h2 className='popup__body-header  popup__body-header--height-unset tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight truncated-text-container--wrapped truncated-text-container--five-lines mh-140'>
        {team.name}
      </h2>
      <h3 className='popup__body-header popup__body-header--sub tw-text-m tw-font-normal tw-tracking-auto truncated-text-container truncated-text-container--block'>{teamCompanyName}</h3>
      <hr className='tw-h-px tw-bg-grey-100 tw-border-0 tw-mt-8 tw-mb-0'/>

        <div className='popup__body-content p-0'>
          <Tabs
            modifiers={['panelled']}
            navClassName='tw-bg-grey-050'
            activeTabIsReset={sidePanelIsOpen}
          >
            <TeamsSidePanelMembersTab
              key='members'
              label='members'
              teamMembers={teamMembers}
              onLoadMoreTeamMembers={onLoadMoreTeamMembers}
              canLoadMoreTeamMembers={canLoadMoreTeamMembers}
              isFetchingTeamMembers={isFetchingTeamMembers}
            />
          </Tabs>
        </div>
    </div>
  )
}

TeamSidePanelShow.propTypes = {
  team: PropTypes.object.isRequired,
  teamCompanyName: PropTypes.string.isRequired,
  teamMembers: PropTypes.object.isRequired,
  sidePanelIsOpen: PropTypes.bool,
  canLoadMoreTeamMembers: PropTypes.bool.isRequired,
  onLoadMoreTeamMembers: PropTypes.func.isRequired,
  isFetchingTeamMembers: PropTypes.bool.isRequired
};
