import React from 'react';
import PropTypes from 'prop-types'
import Tooltip from 'components/application/Tooltip';

export const breadCrumbTruncationLimit = 19;
export const baseClasses = ['mw-120', 'trunc-standard'];
export const selectableClasses = ['app-link', 'tw-align-baseline', 'tw-text-blue-500', 'hover:tw-text-blue-300'];
export const nonSelectableClassess = [''];
export const tooltippableClasses = ['tooltip-parent'];

const isTooltippableBreadCrumb = (text) => text.length > breadCrumbTruncationLimit;

export default function BreadCrumb({ children, onClick}) {
  const isSelectable = !!(onClick);
  const isTooltipped = isTooltippableBreadCrumb(children);

  let determinedClasses = [...baseClasses];
  determinedClasses = [...determinedClasses, ...(isSelectable ? selectableClasses : nonSelectableClassess)]

  if (isTooltipped) { determinedClasses = [...determinedClasses, ...tooltippableClasses] }

  return (
    <span
      className={determinedClasses.join(' ')}
      onClick={isSelectable ? onClick : undefined}
    >
      {isTooltipped && <Tooltip hideArrow={true} trigger='hover' placement='top' tooltip={children} />}
      {children}
    </span>
  )
}

export const propsShape = {
  children: PropTypes.any,
  onClick: PropTypes.func
}

BreadCrumb.propTypes = propsShape;
