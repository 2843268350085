import React, { useRef, useState, useEffect } from 'react';
import useOutsideClick from 'components/hooks/useOutsideClick';
import usePrevious from 'components/hooks/usePrevious';
import OtherOptions from 'components/OtherOptions';

export default function Options(props) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const prevIsOpen = usePrevious(isOpen);

  useOutsideClick(() => {
    setIsOpen(false)
  }, ref)

  const handleOptionClick = (optionCallback = () => { }, _child) => {
    optionCallback(setIsOpen)
  }

  const handleToggle = () => {
    if(!props.buttonDisabled) {
      setIsOpen(!isOpen)
    }
  }

  useEffect(() => {
    if ((prevIsOpen && !isOpen) && props.onClose) props.onClose()
    if ((!prevIsOpen && isOpen) && props.onOpen) props.onOpen()
  }, [prevIsOpen, isOpen])

  const handleTopLevelOptionClick = event => event.stopPropagation();

  const optionsAlignment = (() => {
    switch (props.align) {
      case ('left'):
        return 'tw-justify-start';
      case ('center'):
        return 'tw-justify-center'
      default:
        return 'tw-justify-end'
    }
  })();

  const optionsListAlignment = (() => {
    switch (props.align) {
      case ('left'):
        return 'tw-left-0';
      case ('center'):
        return 'tw-left-1/2 -tw-translate-x-1/2'
      default:
        return 'tw-right-0'
    }
  })();

  return (
    <div className={`options ${optionsAlignment}${props.toggleStyle ? ` ${props.toggleStyle}` : ''}`} ref={ref} onClick={handleTopLevelOptionClick}>
      <div className={`options__toggle ${props.btnToggle ? 'tw-bg-transparent tw-border-transparent' : 'tw-bg-white tw-border-white group-hover/row:tw-border-grey-100'}${isOpen ? ' options--open' : ''}${props.darkHover ? ' options__toggle--dark-hover' : ''}${props.btnToggle ? ` options__toggle--btn${props.buttonDisabled ? ' options__toggle--btn-disabled' : ''}` : ''}`} onClick={handleToggle}>
        {props.btnToggle ? (
          <React.Fragment>
            {props.btnToggle(isOpen)}
          </React.Fragment>
        ) : (
            <OtherOptions blink={false} />
          )
        }
      </div>
      {
        isOpen && (
          <ul className={`options__list tw-shadow-sm tw-bg-white tw-border-grey-100 ${optionsListAlignment}${props.listStyle ? ` ${props.listStyle}` : ''}`}>
            {React.Children.toArray(props.children).filter(Boolean).map((child, index) => (
              <li key={index}
                className={`options__choice tw-border-grey-100 ${child.props.hoverClassName || 'hover:tw-bg-blue-025'}${child.props.disabled ? ' tw-cursor-not-allowed' : ' tw-cursor-pointer'}`}
                onClick={() => { if(!child.props.disabled) handleOptionClick(child.props.onClick, child) }}
              >
                <div className={child.props.disabled ? 'tw-opacity-30' : ''}>
                  {child}
                </div>
              </li>
            ))}
          </ul>
        )
      }

      {
        isOpen && props.additional && (
          <div className='options__additional'>
            {props.additional}
          </div>
        )
      }
    </div>
  )
}
