import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ConditionalWrapper from 'components/application/ConditionalWrapper';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import RichTextEditor from 'components/application/RichTextEditor';
import SpinnerIcon from '-!svg-react-loader?name=SpinnerIcon!icons/spinner.svg';
import TextButton from 'components/application/buttons/TextButton';
import Tooltip from 'components/application/Tooltip';

export default function SubmittableStepField({ onCancel, onChange, onSubmit, placeholder, submitButtonText, value }) {
  const [isLoading, setIsLoading] = useState(true)

  const isSubmittable = Boolean(value) && value.trim().length > 0

  return (
    <React.Fragment>
      <RichTextEditor
        name='method_step[text]'
        onChange={onChange}
        options={{
          additionalContentStyleString: '.mce-content-body p:has(+ ol), .mce-content-body p:has(+ ul) { margin: 0; }',
          autoFocus: true,
          inline: true,
          onInit: () => setIsLoading(false),
          placeholder,
          toolbar: 'bold italic underline | bullist numlist | colorpreset'
        }}
        value={value}
      />
      {isLoading ? (
        <SpinnerIcon height={32} width={32} />
      ) : (
        <React.Fragment>
          <TextButton className='tw-mr-3 tw-mt-4' color='blue' onClick={onCancel} size='sm'>
            Cancel
          </TextButton>
          <ConditionalWrapper
            condition={!isSubmittable}
            wrapper={children => <Tooltip placement='top' tooltip="Text field can't be blank" trigger='hover'>{children}</Tooltip>}
          >
            <OutlinedButton color='grey' disabled={!isSubmittable} onClick={onSubmit} size='sm'>
              {submitButtonText}
            </OutlinedButton>
          </ConditionalWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

SubmittableStepField.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  value: PropTypes.string
}
