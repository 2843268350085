import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';

import { personDisplayName } from 'components/helpers/users.js';
import { resourceShape } from 'components/helpers/serialisableResources';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right-no-padding.svg';
import Tooltip from 'components/application/Tooltip';

const moreBreakpoint = 3;
export default function PersonnelManagementDetails(props) {
  const { currentPersonnel, lineManager } = props;
  const [currentSubordinates, setSubordinates] = useState({ loaded: false, collection: [], metaData: { currentPage: 0, isLastPage: false, totalCount: null } });
  const [showAllSubordinates, setShowAllSubordinates] = useState(false);
  const [loadMoreRef, loadMoreInView] = useInView();
  const breadBoard = useBreadBoard();

  const shouldViewAllSubordinates = (currentSubordinates.collection.length > moreBreakpoint && showAllSubordinates) || (currentSubordinates.collection.length <= moreBreakpoint);
  const viewableCollection = shouldViewAllSubordinates ? currentSubordinates.collection : currentSubordinates.collection.slice(0, 3)

  useEffect(() => {
    if (!currentSubordinates.loaded || (!currentSubordinates.metaData.isLastPage && loadMoreInView)) fetchSubordinates()
  }, [currentSubordinates.loaded, currentSubordinates.metaData.isLastPage, loadMoreInView])

  const fetchSubordinates = () => {
    axios
      .get(`/personnel/${currentPersonnel.id}/subordinates`, { params: { page: parseInt(currentSubordinates.metaData.currentPage) + 1 } })
      .then((response) => {
        setSubordinates(prevState => ({ loaded: true, collection: prevState.collection.concat(response.data.data), metaData: response.data.meta }))
      })
      .catch(breadBoard.addInedibleToast)
  }

  const lineManagerName = lineManager ? personDisplayName(lineManager.attributes) : 'Not set'

  return (
    <>
      <div className='tw-mb-6'>
        <h4 className='tw-text-m tw-font-semibold tw-mb-2'>Line Manager</h4>
        {lineManager ? (
          <LinkablePill
            clickablePersonnel={lineManager}
            displayText={lineManagerName}
            isDisabled={!lineManager.meta.isReadable}
            tooltipMessage={'You don’t have permission to view this personnel'}
          />
        ) : (
          <div className='popup__body-message tw-text-grey-500'>Not set</div>
        )}
      </div>
      <div className='tw-mb-6'>
        <h4 className='tw-text-m tw-font-semibold tw-mb-2'>Direct Reports</h4>
        <ul className='tw-text-left tw-list-none tw-p-0 tw-m-0'>
          {viewableCollection.map((subordinate) => (
            <li className='tw-mb-2' key={subordinate.id}>
              <LinkablePill
                clickablePersonnel={subordinate}
                displayText={personDisplayName(subordinate.attributes)}
                isDisabled={!subordinate.meta.isReadable}
                tooltipMessage={'You don’t have permission to view this personnel'}
              />
            </li>
          ))}
          {(!currentSubordinates.metaData.isLastPage && showAllSubordinates) && (
            <li ref={loadMoreRef} className='popup__body--li'>
              Loading subordinates...
            </li>
          )}
        </ul>
        {currentSubordinates.collection.length == 0 && (
          <div className='popup__body-message tw-text-grey-500'>Not set</div>
        )}

        {(!showAllSubordinates && currentSubordinates.loaded && currentSubordinates.collection.length > moreBreakpoint) && (
          <div className='flex flex--vertically-centered'>
            <button
              className='app-link tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
              onClick={() => setShowAllSubordinates(true)}
            >
              Show all ({currentSubordinates.metaData.totalCount - moreBreakpoint} more)
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export function LinkablePill(props) {
  const {
    clickablePersonnel,
    displayText,
    isDisabled,
    tooltipMessage
  } = props;

  return (
    <div className={`${isDisabled ? 'tooltip-parent' : ''} tw-inline-flex tw-items-center tw-bg-grey-050 hover:tw-bg-grey-100 active:tw-bg-grey-200 tw-border-none tw-rounded-[16px]`}>
      <a className='tw-py-2 tw-px-3 tw-text-grey-700 tw-inline-flex tw-min-h-8' data-disabled={isDisabled} href={`/personnel/${clickablePersonnel.id}`}>
        <div className='tw-inline-flex tw-items-center'>
          <div className='tw-max-w-[188px] tw-text-s tw-font-normal tw-tracking-wide'>{displayText}</div>
          {!isDisabled && (<ChevronRightIcon className='tw-flex-shrink-0 tw-ml-2' width={6} height={10} />)}
        </div>
      </a>
      {isDisabled && <Tooltip tooltip={tooltipMessage} placement='top' trigger='hover' className="tw-ml-6" arrow />}
    </div>
  )
}
PersonnelManagementDetails.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  lineManager: resourceShape('lineManager')
};

LinkablePill.propTypes = {
  clickablePersonnel: PropTypes.object.isRequired,
  displayText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipMessage: PropTypes.string.isRequired
};
