import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/application/Tabs';
import BookingSidePanelDetailsTab from 'components/training/BookingSidePanelDetailsTab';
import BookingSidePanelCourseTab from 'components/training/BookingSidePanelCourseTab';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function BookingSidePanelShow({booking, booker, course, sidePanelIsOpen}) {
  return (
    <Tabs
      modifiers={['panelled']}
      navClassName='tw-bg-grey-050'
      activeTabIsReset={!sidePanelIsOpen}
      initialActiveTab={'Booking details'}
    >
      <BookingSidePanelDetailsTab
        key='detailsTab'
        label='Booking details'
        booking={booking}
        booker={booker}
      />
      <BookingSidePanelCourseTab
        key='coursesTab'
        label='Course details'
        course={course}
      />
    </Tabs>
  )
}

BookingSidePanelShow.propTypes = {
  booking: resourceShape('booking'),
  booker: resourceShape('simpleUser'),
  sidePanelIsOpen: PropTypes.bool,
  course: resourceShape('course'),
}
