import React, { useEffect } from 'react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';

import { collectionsDiffer } from 'components/helpers/objects';
import { resourceIdsAsPositionParams } from 'components/helpers/ordering';
import { genericErrorMessage } from 'components/helpers/errors';

import usePrevious from 'components/hooks/usePrevious';

import AvailableSequence from 'containers/AvailableSequence';

const SortableItem = SortableElement(({ combinedSequence, projectTradeId, containerRef }) => {
  return (
    <AvailableSequence
      sequenceId={combinedSequence.sequenceId}
      masterSequenceId={combinedSequence.masterSequenceId}
      projectTradeId={projectTradeId}
      containerRef={containerRef}
    />
  )
})

const SortableList = SortableContainer(({ items, projectTradeId, isSorting, containerRef }) => {
  return (
    <ul className='block-list block-list--border-bottom-none pos-rel'>
      {
        items.map((combinedSequence, index) => {
          return (
            <React.Fragment key={`sequence--${index}`}>
              {isSorting && <div className='row-bar--ghost row-bar__ghost--sequence tw-border-blue-100 tw-bg-blue-025'></div>}
              <SortableItem
                combinedSequence={combinedSequence}
                index={index}
                projectTradeId={projectTradeId}
                containerRef={containerRef}
              />
            </React.Fragment>
          )
        })
      }
    </ul>
  )
});

const collapsedItemSpace = 52;

export default function SequenceList(props) {
  const {
    dispatch,
    combinedSequences,
    sequenceListIsOrdering,
    projectTradeId,
    containerRef,
    addError,
    projectId,
    tradeId
  } = props;

  // derived
  const _sequenceIds = combinedSequences.reduce((sequenceIds, combinedSequence) => {
    if (combinedSequence.sequenceId) sequenceIds.push(combinedSequence.sequenceId)
    return sequenceIds
  }, [])

  const wasPreviouslyMounted = usePrevious(true);
  const previousCombinedSequences = usePrevious(combinedSequences);

  useEffect(() => {
    const shouldReorder = !!wasPreviouslyMounted && collectionsDiffer(previousCombinedSequences, combinedSequences);
    if (shouldReorder) requestMethodSequenceReorder()
  }, [wasPreviouslyMounted, previousCombinedSequences, combinedSequences])


  const requestMethodSequenceReorder = () => {
    axios
      .post(`/projects/${projectId}/method_sequences_bulk_updates`, {
        method_sequences: {
          method_sequences_attributes: resourceIdsAsPositionParams(combinedSequences.map(combinedSequence => combinedSequence.sequenceId))
        }
      }, { params: { trade_id: tradeId } })
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }))
  };

  const handleSequenceOrder = ({ oldIndex, newIndex }) => {
    const newlyOrderedCombinedSequences = arrayMove(combinedSequences, oldIndex, newIndex);
    dispatch({
      type: 'FINISH_SEQUENCE_ORDERING',
      projectTradeId: projectTradeId,
      newOrdering: newlyOrderedCombinedSequences
    })
  }

  const handleSortStart = ({ node, index }) => {
    dispatch({ type: 'START_SEQUENCE_ORDERING', projectTradeId: projectTradeId, options: { containerRef, node, index, collapsedItemSpace } })
  };

  return (
    <SortableList
      useDragHandle
      lockAxis='y'
      items={combinedSequences}
      onSortEnd={handleSequenceOrder}
      updateBeforeSortStart={handleSortStart}
      projectTradeId={projectTradeId}
      isSorting={sequenceListIsOrdering}
      helperClass={'sortable-item--active'}
      getContainer={() => document.getElementById('main')}
      lockToContainerEdges={true}
      containerRef={containerRef}
    />

  )
}
