import { connect } from 'react-redux';

import { addError } from 'action-creators/ErrorActionCreators';

import SequenceList from 'components/sequences/SequenceList';

const mapStateToProps = (state, ownProps) => {
  const projectTrade = state.domain.projectTradeCollection.data[ownProps.projectTradeId];
  const projectId = projectTrade.attributes.projectId;
  const tradeId = projectTrade.attributes.tradeId;
  const combinedSequencesCollection = state.ui.ordering[`projectTradeSequences--${ownProps.projectTradeId}`]
  const combinedSequences = combinedSequencesCollection && combinedSequencesCollection.list;
  const sequenceListIsOrdering = combinedSequencesCollection && combinedSequencesCollection.inProgress;

  return {
    combinedSequences,
    sequenceListIsOrdering,
    projectId,
    tradeId
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload))
  }
}

const AvailableSequenceList = connect(mapStateToProps, mapDispatchToProps)(SequenceList);
export default AvailableSequenceList
