import React, { useState, useEffect, useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import AddIcon from '-!svg-react-loader?name=AddIcon!icons/add.svg';
import TickIcon from '-!svg-react-loader?name=TickIcon!icons/ic-tick.svg';
import CloseIcon from '-!svg-react-loader?name=CloseIcon!icons/ic-close.svg';

export default function Caption(props) {
  const { attachment, updateAttachment, onChange } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const inputRef = useRef(null);

  const handleTextChange = (event) => {
    const value = event.target.value;
    onChange({ target: { name: 'caption', value: value } }, { attachment: attachment })
  }

  const handleEdit = (_event) => {
    setInitialValue(attachment.caption)
    setIsEditing(true)
  };

  const handleUpdate = (_event) => {
    attachment.id && updateAttachment({ attachment: attachment, params: { caption: attachment.caption } })
    setIsEditing(false)
  };

  const handleKeyDown = (event) => event.keyCode === 13 && handleUpdate(event);

  const handleCancel = (_event) => {
    onChange({ target: { name: 'caption', value: initialValue } }, { attachment: attachment })
    setIsEditing(false)
  };

  useEffect(() => {
    isEditing && focusField(inputRef)
  }, [isEditing])

  return (
    <div className='attachments__viewer-caption'>
      {isEditing ?
        <div className='attachments__viewer-caption-edit [&_svg_polygon]:tw-fill-white'>
          <input className='tw-font-inter tw-text-m tw-font-normal tw-tracking-auto tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300' ref={inputRef} name='caption' placeholder='Caption' type='text' value={attachment.caption || ''} onKeyDown={handleKeyDown} onChange={handleTextChange}/>
          <button className='attachments__viewer-caption-update tw-bg-blue-500 hover:tw-bg-blue-500 focus:tw-bg-blue-500 m-r-4' onClick={handleUpdate} disabled={!attachment.id}>
            <TickIcon width={24} height={24} />
          </button>
          <button className='attachments__viewer-caption-cancel tw-bg-grey-400 hover:tw-bg-grey-400 focus:tw-bg-grey-400' onClick={handleCancel}>
            <CloseIcon width={24} height={24} />
          </button>
        </div>
      :
        attachment.caption ? (
          <div className='attachments__viewer-caption-text'>
            <span className='tw-border-grey-500' onClick={attachment.id ? handleEdit : null}>{attachment.caption}</span>
          </div>
        ) : (
          <button className='attachments__viewer-caption-add tw-text-grey-900 [&_svg_path]:tw-fill-grey-900 tw-bg-grey-050 hover:tw-bg-grey-100 focus:tw-bg-grey-100' onClick={handleEdit} disabled={!attachment.id}>
            <AddIcon width={20} height={20} /><span>Add Caption</span>
          </button>
        )
      }
    </div>
  )
}

Caption.propTypes = {
  index: PropTypes.number.isRequired,
  attachment: PropTypes.object.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
