import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { oneTimePasswordShape } from 'components/hooks/useOneTimePassword';
import { validationErrorsShape } from 'components/hooks/useValidationErrors';

import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import ValidationErrorsMessages from 'components/validationErrors/components/ValidationErrorsMessages';
import OneTimePasswordSignInCard from 'components/users/twoFactorAuthentication/OneTimePasswordSignInCard';


export default function DisableModal({ isOpen, closeModal, oneTimePasswordProps, validationErrorsProps, onFormSubmit }) {
  const [disableButton, setDisableButton] = useState(true);

  const handleFormValidate = (otpCode) => {
    // check if the code is valid, but doesn't disable it
    const otpCodeStr = otpCode.join('');
    axios
      .post('/settings/two_factor/validations', { 'otp_attempt': otpCodeStr })
      .then(() => {
        setDisableButton(false)
      })
      .catch(() => {
        oneTimePasswordProps.handleCleanupField()
        validationErrorsProps.handleValidationErrorsAssignment({ otp_attempt: ['Incorrect verification code'] })
      })
  }

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' href='#' onClick={closeModal}>
        Cancel
      </TextButton>

      <FilledButton color='red' disabled={disableButton} onClick={() => onFormSubmit(oneTimePasswordProps.otpCode)}>
        Turn off two-factor authentication
      </FilledButton>
    </div>
  )

  useEffect(() => {
    oneTimePasswordProps.inputRefs[0].current.focus()
  }, [isOpen])

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={closeModal}
      customFooter={customFooter}
    >
      <h2 className='tw-text-grey-900 tw-text-xl tw-font-semibold m-0 tw-px-8 tw-pt-8'>
        Turn off two-factor authentication
      </h2>

      <hr className='tw-w-full tw-mb-0' />

      {Object.keys(validationErrorsProps.validationErrors).length > 0 && (
        <ValidationErrorsMessages className="tw-m-6 tw-border-solid tw-border-1 tw-border-red-200" validationErrors={validationErrorsProps.validationErrors} />
      )}

      <div className='p-32'>
        <p className='tw-text-grey-700 tw-text-sm tw-font-small tw-mb-5 tw-text-center '>
          Use your authenticator app to get the <br />
          verification code and enter below
        </p>

        <OneTimePasswordSignInCard
          inputRefs={oneTimePasswordProps.inputRefs}
          otpCode={oneTimePasswordProps.otpCode}
          onInputKeyUp={oneTimePasswordProps.handleInputKeyUp}
          onFormPasteCapture={oneTimePasswordProps.handleFormPasteCapture}
          onInputClick={oneTimePasswordProps.handleInputClick}
          onSubmit={handleFormValidate}
        />
      </div>
    </ModalBox>
  )
}

DisableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  oneTimePasswordProps: oneTimePasswordShape,
  validationErrorsProps: validationErrorsShape,
  onFormSubmit: PropTypes.func.isRequired
};
