import HandshqApp from '../handshq-app';

HandshqApp.ancientAccounts = {
  setup: function() {
    this.selectAccountEvent()
    this.unmarkModalEvents()
  },

  checkboxes: function() {
    return $("input[type='checkbox'][name='account[marked_for_deletion]']:not(:disabled)")
  },

  checkbox: function(accountId) {
    return $("input[type='checkbox'][name='account[marked_for_deletion]'][data-account-id='" + accountId + "']:not(:disabled)")
  },

  unmarkModal: function(accountId) {
    return $('#unmark-account-modal-' + accountId)
  },

  unmarkCancelButtons: function() {
    return $('button.ancient-accounts__unmark-cancel')
  },

  unmarkConfirmButtons: function() {
    return $('button.ancient-accounts__unmark-confirm')
  },

  updateMarkForDeletion: function(options) {
    const url = '/nadmin/accounts/' + options.accountId + '/old.json';
    const data = { account: { marked_for_deletion: options.markForDeletion ? '1' : 'nil' } };

    $.ajax({
      type: 'PUT',
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(data)
    })
  },

  selectAccountEvent: function() {
    const _this = this;

    this.checkboxes().on('change', function(_event) {
      if (this.checked) {
        _this.updateMarkForDeletion({ accountId: this.dataset.accountId, markForDeletion: true })
      } else {
        _this.unmarkModal(this.dataset.accountId).modal({ backdrop: 'static', keyboard: false }).modal('show')
      }
    })
  },

  unmarkModalEvents: function() {
    const _this = this;

    this.unmarkCancelButtons().on('click', function(_event) {
      _this.checkbox(this.dataset.accountId).prop('checked', true)
    })

    this.unmarkConfirmButtons().on('click', function(_event) {
      _this.updateMarkForDeletion({ accountId: this.dataset.accountId, markForDeletion: false })
    })
  }
};

$(function() {
  if ($('.ancient-accounts').length) { HandshqApp.ancientAccounts.setup() }
})
