$(function() {
  const toggleableCheckbox = $( '.toggle-visibility--selector' );
  const radioGroup = $( '.radio-container--hidden' );

  if (toggleableCheckbox.is(':checked')) {
    radioGroup.show()
  }

  if (toggleableCheckbox.is(':disabled')) {
    $('.hhq-checkbox__span--styling').addClass('hhq-checkbox__toggle--disabled');
  }
});
