import React from 'react';
import PropTypes from 'prop-types';

export default function RowBar({
  additionalClasses,
  modifiers,
  content,
  actions,
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      className={`row-bar tw-border-grey-100 tw-bg-white${modifiers.map(modifier => ` row-bar--${modifier}`).join('')}${additionalClasses ? ` ${additionalClasses}` : ''}`}
    >
      <div className={'row-bar__content'}>
        {content}
      </div>
      <div className={'row-bar__actions'}>
        {actions}
      </div>
    </div>
  )
}

RowBar.propTypes = {
  modifiers: PropTypes.array,
  content: PropTypes.any,
  actions: PropTypes.any,
  additionalClasses: PropTypes.string,
  onClick: PropTypes.func
};

RowBar.defaultProps = {
  modifiers: [],
  content: '',
  actions: ''
}
