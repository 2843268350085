import React from "react";
import PropTypes from "prop-types";

import CheckboxField from "components/application/CheckboxField";
import Tooltip from "components/application/Tooltip";
import CircleQuestion from "components/application/CircleQuestion";

export default function CheckboxFields({ checkboxes, inline = false, minChecked = 1 }) {
  const isMinChecked = checkboxes.filter((f) => f.value).length === minChecked;

  return checkboxes.map((checkbox, index) => (
    <div key={checkbox.name} className={`${inline ? "tw-inline-flex" : "tw-flex"} tw-items-center`}>
      <CheckboxField
        key={checkbox.name}
        className={index === 0 ? "" : " tw-mt-4"}
        name={checkbox.name}
        label={checkbox.label}
        value={checkbox.name}
        checked={checkbox.value}
        disabled={isMinChecked && checkbox.value}
        onChange={checkbox.onChange}
      />
      {checkbox.tooltip && (
        <div className="tw-ml-2">
          <Tooltip placement="top" trigger="hover" tooltip={checkbox.tooltip}>
            <CircleQuestion />
          </Tooltip>
        </div>
      )}
    </div>
  ))
}

CheckboxFields.propTypes = {
  checkboxes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  })),
  inline: PropTypes.bool,
  minChecked: PropTypes.number
};
