import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

export default function IconGroup({ projectId, iconGroupId, iconGroupName, icons }) {
  const [iconAllocations, setIconAllocations] = useState({});
  const [disabledIcons, setDisabledIcons] = useState(new Set());

  function fetchIconAllocations() {
    axios
      .get(`/projects/${projectId}/icon_allocations`, {
        params: {
          icon_recipient_type: 'Project',
          icon_group_id: iconGroupId
        }
      })
      .then((response) => {
        const allocations = {};
        response.data.data.forEach(iconAllocation => {
          allocations[iconAllocation.attributes['iconId']] = iconAllocation;
        });
        setIconAllocations(allocations);
      })
      .catch((error) => {
        console.log('Icon Groups Not Found', error)
      });
  }

  function onIconCheckboxChange(event) {
    const checkbox = event.target;
    const iconId = parseInt(checkbox.id);
    setDisabledIcons(prevDisabledIcons => {
      const newDisabledIcons = new Set(prevDisabledIcons);
      newDisabledIcons.add(iconId)
      return newDisabledIcons
    })

    if (checkbox.checked) {
      addProjectIcon(iconId)
    } else {
      const iconAllocationId = parseInt(iconAllocations[iconId]['id']);
      removeProjectIcon(iconAllocationId, iconId)
    }
  }

  function addProjectIcon(iconId) {
    axios
      .post('/icon_allocations', {
        icon_allocation: {
          icon_id: iconId,
          icon_recipient_type: 'Project',
          icon_recipient_id: projectId
        }
      })
      .then((response) => {
        setDisabledIcons(prevDisabledIcons => {
          const newDisabledIcons = new Set(prevDisabledIcons);
          newDisabledIcons.delete(iconId)
          return newDisabledIcons
        })

        setIconAllocations(prevAllocations => ({
          ...prevAllocations,
          [iconId]: response.data.data
        }))
      })
  }

  function removeProjectIcon(iconAllocationId, iconId) {
    axios
      .delete(`/icon_allocations/${iconAllocationId}`, {
        params: {
          icon_recipient_type: 'Project'
        }
      })
      .then((_response) => {
        const newAllocations = { ...iconAllocations };
        delete newAllocations[iconId]

        const newDisabledIcons = new Set(disabledIcons);
        newDisabledIcons.delete(iconId)

        setIconAllocations(newAllocations)
        setDisabledIcons(newDisabledIcons)
      })
  }

  const existsByIconId = (iconId) => {
    let iconAllocationExists;
    if (iconAllocations.length !== 0) {
      iconAllocationExists = iconAllocations[iconId] !== undefined
    }
    return iconAllocationExists
  };

  useEffect(() => {
    fetchIconAllocations()
  }, [])

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-container tw-border-grey-100 tw-bg-white'>
          <div className='method_text_header clearfix'>
            <h3 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto'>{iconGroupName}</h3>
          </div>
          <fieldset className='inputs method-icons'>
            {icons.map(icon =>
              <Icon
                key={icon.id}
                disabled={disabledIcons.has(parseInt(icon.id))}
                iconId={parseInt(icon.id)}
                iconName={icon.attributes.name}
                iconImageAssetPath={icon.attributes.imageAssetPath}
                checkboxSelected={existsByIconId(icon.id)}
                onCheckboxChange={onIconCheckboxChange}
              />
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  projectId: PropTypes.number.isRequired,
  iconGroupId: PropTypes.number.isRequired,
  iconGroupName: PropTypes.string.isRequired,
  icons: PropTypes.array.isRequired
};
