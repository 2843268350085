export const accessTypeHierarchy = ['regular', 'administrator', 'primary'];

function personDisplayName(attributes) {
  return `${attributes.firstName}${attributes.lastName ? ` ${attributes.lastName}` : ''}`
}

function buildAccessControlAttributes({ key, domainUser, formUser }) {
  const domainAccessControl = domainUser && domainUser.included.find((i) => i.type === 'accessControl' && i.relationships.company.data.id === key);
  const formAccessControl = formUser && formUser.accessControls[key];

  const accessControlAttributes = domainAccessControl ? { id: domainAccessControl.id } : {};

  if (formAccessControl) {
    Object.assign(accessControlAttributes, {
      company_id: key,
      administrator: formAccessControl.administrator,
      project_approver: formAccessControl.projectApprover,
      content_editor: formAccessControl.contentEditor,
      personnel_editor: formAccessControl.personnelEditor,
      personnel_viewer: formAccessControl.personnelViewer
    })
  } else {
    Object.assign(accessControlAttributes, { '_destroy': true })
  }

  return accessControlAttributes
}

function userParamsFromState({ domainUser, formUser }) {
  const userParams = {
    user: {
      first_name: formUser.firstName,
      last_name: formUser.lastName,
      email: formUser.email,
      position: formUser.position,
      access_type: formUser.accessType
    }
  }

  if (formUser.accessType === 'regular') {
    userParams.user.access_controls_attributes = {};

    for (const key of Object.keys(formUser.accessControls)) {
      userParams.user.access_controls_attributes[key] = buildAccessControlAttributes({ key, domainUser, formUser });
    }

    userParams.user.roles_and_courses_editor = formUser.rolesAndCoursesEditor
  }

  return  userParams
}

function userFormParams(formUser, divisionId, withAccessControl = true) {
  const userParams = {
    user: {
      first_name: formUser.firstName,
      last_name: formUser.lastName,
      email: formUser.email,
      position: formUser.position,
      roles_and_courses_editor: formUser.rolesAndCoursesEditor
    }
  }

  if (withAccessControl) {
    userParams.user.access_controls_attributes = {
      "0": {
        id: formUser.domainAccessControlID,
        company_id: divisionId,
        administrator: formUser.administrator,
        project_approver: formUser.projectApprover,
        content_editor: formUser.contentEditor,
        personnel_viewer: formUser.personnelViewer,
        personnel_editor: formUser.personnelEditor
      }
    }
  }

  return userParams
}

export const accessTypeLabel = {
  'primary': 'owner',
  'administrator': 'account administrator'
}

export {
  personDisplayName, userParamsFromState, userFormParams
}
