/* eslint no-console:0 */

import 'jquery';
import 'jquery-ujs';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/progressbar';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'axios-settings/defaults';
import 'scrollit';
import 'jquery-placeholder';
import 'blueimp-file-upload/js/jquery.fileupload';
import 'blueimp-file-upload/js/jquery.fileupload-process';
import 'blueimp-file-upload/js/jquery.fileupload-validate';
import 'jquery-ui-touch-punch';
import 'moment';

require("expose-loader?$!jquery");
require("expose-loader?jQuery!jquery");
require("expose-loader?axios!axios");

import 'handshq-app';
import 'shared';
import 'application';

import 'stylesheets/application.scss'

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true)
const ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
