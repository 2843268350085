import tailwindConfig from "stylesheets/tailwind.config.js";

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import voca from "voca";
import moment from "moment";
import momentTimezone from "moment-timezone";
import qs from "qs";

import { BarChart, XAxis, YAxis, Bar, Cell, CartesianGrid, Tooltip, Label, LabelList } from "recharts";

import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useRamsAnalyticsDashboardContext } from "components/contexts/RamsAnalyticsDashboardContext";
import { useBreadBoard } from "components/contexts/Toaster";
import useFilters from "components/ramsAnalytics/hooks/useFilters";
import useKeyHighlight from "components/ramsAnalytics/hooks/useKeyHighlight";
import useProjectsModal from "components/ramsAnalytics/hooks/useProjectsModal";

import { generateChartProps, defaultChartProps } from "components/ramsAnalytics/helpers/projectsCreatedCard";
import { formatPaginatedProjects } from "components/ramsAnalytics/helpers/projectsModal";
import { getNextPageParam } from "components/ramsAnalytics/helpers/reactQuery";

import Card from "components/ramsAnalytics/components/Card";
import ReportHeader from "components/ramsAnalytics/components/ReportHeader";
import ProjectsModal from "components/ramsAnalytics/components/ProjectsModal";
import NoDataMessage from "components/ramsAnalytics/components/NoDataMessage";

const colours = tailwindConfig.theme.colors;

const dateRangeValueLabelMap = { "this_year": "This year", "last_year": "Last year", "all_time": "All time" };
const dateRangeOptions = Object.entries(dateRangeValueLabelMap).reduce((acc, valueLabel) => {
  const [value, label] = valueLabel;
  acc.push({ label, value })

  return acc
}, []);

const roundedTopBarRadii = [2, 2, 0, 0];

export default function ProjectsCreatedCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({ includeDeleted: false });
  const { projectsModal, openProjectsModal, closeProjectsModal } = useProjectsModal();

  const [dateRange, setDateRange] = useState("this_year");

  const filterParams = { date_range: dateRange, include_deleted: filters.includeDeleted };

  function fetchCurrentProjectsReport() {
    return axios.post("/rams_analytics/projects_created_report", { projects_created_report: filterParams })
  }

  const {
    data: projectsCreatedReport,
    isSuccess
  } = useQuery({
    queryKey: ["projectsCreatedReport", filterParams],
    queryFn: fetchCurrentProjectsReport,
    keepPreviousData: true,
    onError: breadBoard.addInedibleToast
  });

  const totalCount = isSuccess ? projectsCreatedReport.data.data.attributes.countBreakdown.reduce((totalCount, { project_count }) => totalCount + project_count, 0) : 0;

  const chartProps = isSuccess ? generateChartProps(projectsCreatedReport.data) : defaultChartProps;

  const fetchProjects = (page) => {
    const queryString = qs.stringify({ filter: { ...projectsModal.filters }, page });

    return axios.get(`/rams_analytics/projects_created_report/projects?${queryString}`)
  }

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects
  } = useInfiniteQuery({
    queryKey: ["projects", "projectsCreatedReport", { ...projectsModal.filters }],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast
  });

  const handleTotalCountClick = () => {
    const london = momentTimezone.tz("Europe/London");

    let formattedDate, createdAtFilter;

    switch (dateRange) {
      case "this_year":
        formattedDate = `${london.clone().year()}`;
        createdAtFilter = { gte: london.clone().startOf("year").format(), lte: london.clone().endOf("year").format() };
        break
      case "last_year":
        formattedDate = `${london.clone().subtract(1, "year").year()}`;
        createdAtFilter = { gte: london.clone().subtract(1, "year").startOf("year").format(), lte: london.clone().subtract(1, "year").endOf("year").format() };
        break
      case "all_time":
        formattedDate = `${moment(projectsCreatedReport.data.meta.accountCreatedAt).year()} - ${london.clone().year()}`;
        createdAtFilter = {};
        break
    }

    const subTitle = `${ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: totalCount })}${filters.includeDeleted ? ` (including ${ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: projectsCreatedReport.data.data.attributes.deletedCount, prefix: "deleted" })} not shown below)` : ""}`;

    openProjectsModal({
      title: `${voca.capitalize(ramsAnalyticsDashboardContext.ramsPluralName)} created: ${formattedDate}`,
      subTitle: subTitle,
      appliedFilters: { created_at: createdAtFilter }
    })

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Projects created",
      segment: formattedDate,
      filters: filters
    })
  }

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: project click", {
      chart: "Projects created",
      project: project.name,
      filters: filters
    })
  }

  return (
    <Card>
      <ReportHeader
        title={`${voca.capitalize(ramsAnalyticsDashboardContext.ramsPluralName)} created`}
        totalCount={totalCount > 0 ? totalCount : null}
        subTitle={`${filters.includeDeleted ? "Includes" : "Excludes"} deleted ${ramsAnalyticsDashboardContext.ramsPluralName}`}
        filters={[{
          name: "includeDeleted",
          label: "Include deleted RAMS",
          value: filters.includeDeleted,
          count: projectsCreatedReport && projectsCreatedReport.data.data.attributes.deletedCount
        }]}
        dropdownOptions={dateRangeOptions}
        dropdownValue={{ label: dateRangeValueLabelMap[dateRange], value: dateRange }}
        onTotalCountClick={totalCount > 0 ? handleTotalCountClick : null}
        onFilterChange={handleFilterChange}
        onSelectionChange={(selectedOption) => setDateRange(selectedOption.value)}
      />
      {projectsCreatedReport && (
        totalCount > 0 ? (
          <ReportBody chartProps={chartProps} dateRange={dateRange} filters={filters} openProjectsModal={openProjectsModal} />
        ) : (
          <NoDataMessage message="Please check the applied filters" />
        )
      )}
      <ProjectsModal
        isOpen={projectsModal.isOpen}
        title={projectsModal.title}
        subTitle={projectsModal.subTitle}
        projects={projects}
        hasNextPage={hasNextPageOfProjects}
        closeModal={closeProjectsModal}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
      />
    </Card>
  )
}

function ReportBody({ chartProps, dateRange, filters, openProjectsModal }) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const { highlightedKey, handleKeyHighlight, handleKeyUnhighlight } = useKeyHighlight(null);

  const [barPosition, setBarPosition] = useState({});

  const handleSegmentClick = ({ entry }) => {
    const formattedDate = moment(entry.yearMonth).format("MMMM YYYY");
    const createdAtFilter = { gte: moment.parseZone(entry.yearMonth).startOf("month").format(), lte: moment.parseZone(entry.yearMonth).endOf("month").format() };

    const subTitle = `${ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: entry.count })}${filters.includeDeleted ? ` (including ${ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: entry.deletedCount, prefix: "deleted" })} not shown below)` : ""}`;

    openProjectsModal({
      title: `${voca.capitalize(ramsAnalyticsDashboardContext.ramsPluralName)} created: ${formattedDate}`,
      subTitle: subTitle,
      appliedFilters: { created_at: createdAtFilter }
    })

    handleKeyUnhighlight()

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Projects created",
      segment: formattedDate,
      filters: filters
    })
  }

  return (
    <div className="tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-pr-6 tw-pb-5 tw-pl-6">
      <BarChart
        width={512}
        height={164}
        data={chartProps.data}
        margin={{ top: 16, right: 0, left: 0, bottom: 16 }}
        onMouseLeave={() => handleKeyUnhighlight()}
      >
        <CartesianGrid vertical={false} stroke={colours.grey[100]} />
        <Tooltip
          cursor={false}
          isAnimationActive={false}
          allowEscapeViewBox={{ x: true, y: true }}
          position={{ x: barPosition.x, y: barPosition.y - 12 }}
          content={dateRange === "all_time" ? <CustomizedTooltip isTooltipVisible={Object.keys(barPosition).length !== 0} pluralizeRamsCount={ramsAnalyticsDashboardContext.pluralizeRamsCount} /> : () => (null)}
        />
        <Bar
          dataKey="count"
          cursor="pointer"
        >
          <LabelList
            dataKey="count"
            content={dateRange === "all_time" ? () => (null) : CustomizedBarLabel}
          />
          {chartProps.data.map((entry) => {
            const isOtherBarHighlighted = highlightedKey && entry.countKey !== highlightedKey;
            const barFill = isOtherBarHighlighted ? colours.grey[100] : colours.cyan[700];

            return (
              <Cell
                key={entry.countKey}
                fill={barFill}
                radius={roundedTopBarRadii}
                onMouseEnter={(data) => {
                  handleKeyHighlight(entry.countKey)
                  setBarPosition({ x: data.target.getAttribute("x"), y: data.target.getAttribute("y") })
                }}
                onMouseLeave={() => setBarPosition({})}
                onClick={() => handleSegmentClick({ entry })}
              />
            )
          })}
        </Bar>
        <XAxis
          stroke={colours.grey[400]}
          tickLine={false}
          dataKey="name"
          padding={{ left: 32, right: 4 }}
          tick={dateRange === "all_time" ? false : { fill: colours.grey[500], fontFamily: "Inter", fontSize: "12px", fontStyle: "normal", fontWeight: 400, dy: 4 }}
          style={{
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: "400",
            color: colours.grey[100]
          }}
          width={512}
        >
          <Label
            position="bottom"
            offset={dateRange === "all_time" ? -12 : 4}
            style={{ fill: colours.grey[500], fontFamily: "Inter", fontSize: "12px", fontStyle: "normal", fontWeight: 400 }}
            value={chartProps.xAxisLabel}
          />
        </XAxis>
        <YAxis
          width={32}
          mirror={true}
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          tick={CustomizedYAxisTick}
        />
      </BarChart>
    </div>
  )
}

ReportBody.propTypes = {
  chartProps: PropTypes.shape({
    data: PropTypes.array.isRequired,
    xAxisLabel: PropTypes.node.isRequired
  }),
  dateRange: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  openProjectsModal: PropTypes.func.isRequired
};

function CustomizedTooltip({ payload, isTooltipVisible, pluralizeRamsCount }) {
  const [tooltipSize, setTooltipSize] = useState({});

  const ref = useCallback((node) => {
    setTooltipSize(node !== null ? { width: node.offsetWidth, height: node.offsetHeight } : {})
  }, [])

  if (isTooltipVisible && payload && payload.length > 0) {
    const formattedDate = moment(payload[0].payload.yearMonth).format("MMM YYYY");
    const count = payload[0].payload.count;

    return (
      <div ref={ref} className="tooltip-dark tw-w-24 tw-m-0 tw-rounded-md" style={{ transform: `translate(-${(tooltipSize.width / 2) + 10}px,-${tooltipSize.height}px)` }}>
        <div className="tooltip-dark-arrow" data-placement="top" style={{ left: `${(tooltipSize.width / 2) - 6}px` }}></div>
        <p className="tw-m-0">{formattedDate}</p>
        <p className="tw-m-0">{pluralizeRamsCount({ count })}</p>
      </div>
    );
  }

  return null;
}

CustomizedTooltip.propTypes = {
  payload: PropTypes.array,
  isTooltipVisible: PropTypes.bool.isRequired,
  pluralizeRamsCount: PropTypes.func.isRequired
};

function CustomizedBarLabel({ viewBox, value, width }) {
  return (
    <g>
      <foreignObject x={viewBox.x} y={viewBox.y && (viewBox.y - 16)} width={width} height={16}>
        <div className="tw-text-s tw-font-normal tw-tracking-wide tw-text-grey-500 tw-flex tw-justify-center">
          {value === 0 ? null : value}
        </div>
      </foreignObject>
    </g>
  )
}

CustomizedBarLabel.propTypes = {
  viewBox: PropTypes.object.isRequired,
  value: PropTypes.node,
  width: PropTypes.number.isRequired
};

function CustomizedYAxisTick({ payload, y }) {
  return (
    <g>
      <foreignObject x={0} y={y - 16} width={32} height={16}>
        <div className="tw-text-s tw-font-normal tw-tracking-wide tw-text-grey-500 tw-flex">
          {payload.value.toLocaleString()}
        </div>
      </foreignObject>
    </g>
  )
}

CustomizedYAxisTick.propTypes = {
  payload: PropTypes.array.isRequired,
  y: PropTypes.number.isRequired
};
