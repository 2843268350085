import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import RedInfoIcon from '-!svg-react-loader?name=RedInfoIcon!icons/info-red.svg';

AllowanceBar.propTypes = {
  currentCount: PropTypes.number.isRequired,
  allowance: PropTypes.number.isRequired,
  asRemaining: PropTypes.bool
}

export default function AllowanceBar({ currentCount, allowance, asRemaining }) {
  const formattedAllowance = allowance === 0 ? 'unlimited' : allowance;
  const remainingCount = allowance === 0 ? 'unlimited' : (allowance - currentCount);
  const isWarning = currentCount == formattedAllowance;

  return (
    <div className={`tw-flex tw-border-0 tw-rounded-lg tw-p-3 m-0 m-b-20 ${isWarning ? 'tw-text-red-800 tw-bg-red-025' : 'tw-text-cyan-800 tw-bg-cyan-025'}`}>
      <div className='fh-20 m-r-12'>
        {isWarning ? (
          <RedInfoIcon height={20} width={20} className='[&_path]:tw-fill-red-800' />
        )  : (
          <InfoIcon height={20} width={20} className='[&_path]:tw-fill-cyan-800' />
        )}
      </div>
      <p className='m-0'>
        {asRemaining ? (
          <>You have <span className='tw-font-medium'>{remainingCount} user accounts</span> remaining</>
        ) : (
          <>You have used <span className='tw-font-medium'>{currentCount} of your {formattedAllowance}</span> user accounts</>
        )}
      </p>
    </div>
  )
}
