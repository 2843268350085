import React from 'react';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import ProcoreLogo from '-!svg-react-loader?name=ProcoreLogo!icons/procore_logo.svg';

export default function SelectionButton({ onClick, disabled = false }) {
  return (
    <div>
      <OutlinedButton size='md' color='grey' disabled={disabled} onClick={disabled ? () => {} : onClick}>
        <ProcoreLogo height='12px' width='12px' />
        <span className='m-l-8 tw-text-m tw-font-medium tw-tracking-auto'>Select Procore folder</span>
      </OutlinedButton>
    </div>
  )
}
