import React, { useEffect, useState } from 'react';

export default function ELearningCredits() {
  const [eLearningAllowance, setELearningAllowance] = useState({ loaded: false, currentCredits: null, hasError: false });

  const fetchELearningAllowance = () => {
    axios
      .get("/e_learning/allowance")
      .then(response => setELearningAllowance({ ...eLearningAllowance, loaded: true, currentCredits: response.data.data.attributes.currentCredits }))
      .catch(_error => setELearningAllowance({ ...eLearningAllowance, hasError: true }))
  };

  let displayText;

  if (eLearningAllowance.loaded) {
    displayText = `${eLearningAllowance.currentCredits} eLearning credits remaining`;
  } else if (eLearningAllowance.hasError) {
    displayText = "There was a problem displaying your remaining credits - please try again later.";
  }

  useEffect(() => {
    fetchELearningAllowance()
  }, [])

  return (
    <div className="fh-20">
      <p>
        {displayText}
      </p>
    </div>
  )
}
