import PropTypes from 'prop-types';

export const destinationShape = PropTypes.shape({
  companyID: PropTypes.string.isRequired,
  projectID: PropTypes.string.isRequired,
  folderID: PropTypes.string.isRequired,
  assignerID: PropTypes.string
})

export const assignedDetailsShape = PropTypes.shape({
  company: PropTypes.shape({ id: PropTypes.string }),
  project: PropTypes.shape({ id: PropTypes.string }),
  folder: PropTypes.shape({ id: PropTypes.string }),
  assignerID: PropTypes.string
})
