var React = require('react');

function DownloadIcon (props) {
    return React.createElement("svg",props,React.createElement("path",{"fill":"#070707","fillRule":"evenodd","d":"M13,14.5857864 L16.2928932,11.2928932 L17.7071068,12.7071068 L12,18.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L11,14.5857864 L11,6 L13,6 L13,14.5857864 Z"}));
}

DownloadIcon.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = DownloadIcon;

DownloadIcon.default = DownloadIcon;
