import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import { getELearningCourseId, courseLearningMethod, isCourseEligibleForAutoEnrolment } from 'components/helpers/resources/courses';

import TableCell from 'components/application/TableCell';
import CourseTrainingStatusIndicator from 'components/courses/CourseTrainingStatusIndicator';
import Tooltip from 'components/application/Tooltip';
import IHascoBrandIcon from '-!svg-react-loader?name=IHascoBrandIcon!icons/ihasco-brand.svg';
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/double-arrow-rotate.svg';

export default function CourseRow({ course, originalCourse, onRowClick }) {
  const currentActor = useCurrentActor();
  const { isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();

  const renewalFrequencyValue = (course.attributes.renewalFrequency && course.attributes.renewalInterval) && (pluralize(course.attributes.renewalInterval, course.attributes.renewalFrequency, true));
  const courseProvider = course.attributes.provider;

  const courseELearningCourseId = currentActor.isAllowedFeature('e_learning') && getELearningCourseId(course);
  const originalCourseELearningCourseId = currentActor.isAllowedFeature('e_learning') && getELearningCourseId(originalCourse);

  return (
    <tr className='handshq__tr--clickable fh-49 hover:tw-bg-grey-025' onClick={() => { onRowClick(course, originalCourse) }}>
      <TableCell justifyContent='center'>
        <CourseTrainingStatusIndicator status={course.attributes.trainingStatus} />
      </TableCell>
      <TableCell>
        <>
          <div className='truncated-text-container truncated-text-container--auto tooltip-parent fh-20'>
            <span className='tw-text-grey-900 tw-text-m tw-font-medium'>{course.attributes.name}</span>
            {course.attributes.name.length > 60 && (<Tooltip placement='top' trigger='hover' tooltip={course.attributes.name} />)}
          </div>
          {currentActor.isAllowedFeature('e_learning') && !courseELearningCourseId && !!originalCourseELearningCourseId && (
            <div className='flex-none m-l-8'>
              <div className='flex flex--vertically-centered content-box fh-24 p-3 p-l-8 p-r-8 tw-border-grey-100 tw-bg-white'>
                <IHascoBrandIcon className='m-r-4' width={56} height={12} />
                <span className='tw-text-s tw-font-normal tw-tracking-wide'>available</span>
              </div>
            </div>
          )}
        </>
      </TableCell>
      <TableCell>
        {isCourseEligibleForAutoEnrolment({ currentActor, isAutoEnrolmentIncludingMissingTraining, course }) ? (
          <div className='truncated-text-container'>
            <span className='tw-text-m'>eLearning</span>
            <div className='tw-flex tw-items-center tw-text-s tw-text-grey-500'>
              <AutoEnrolIcon className='[&_path]:tw-fill-grey-500'/>
              <span>Auto-enrols</span>
            </div>
          </div>
        ) : (
          <span className='truncated-text-container tw-text-m'>
            {courseLearningMethod({ course })}
          </span>
        )}
      </TableCell>
      <TableCell>
        <span className='truncated-text-container tw-text-m'>{courseProvider}</span>
      </TableCell>
      <TableCell>
        <span className='tw-text-m'>{course.attributes.expires ? renewalFrequencyValue : 'Does not expire'}</span>
      </TableCell>
      <TableCell justifyContent='center'>
        <div className='tw-text-m'>{course.attributes.roleCount}</div>
      </TableCell>
      <TableCell justifyContent='center'>
        <div className='tw-text-m'>{course.attributes.personnelCount}</div>
      </TableCell>
    </tr>
  )
}

CourseRow.propTypes = {
  course: PropTypes.object.isRequired,
  originalCourse: PropTypes.object,
  onRowClick: PropTypes.func.isRequired
}
