var React = require('react');

function CrossIcon (props) {
    return React.createElement("svg",props,React.createElement("polygon",{"fill":"#858585","fillRule":"evenodd","points":"13.414 12 16.95 15.536 15.536 16.95 12 13.414 8.464 16.95 7.05 15.536 10.586 12 7.05 8.464 8.464 7.05 12 10.586 15.536 7.05 16.95 8.464"}));
}

CrossIcon.defaultProps = {"width":"24","height":"24","viewBox":"0 0 24 24"};

module.exports = CrossIcon;

CrossIcon.default = CrossIcon;
