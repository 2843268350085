import React from 'react';
import AppWrapper from 'components/apps/AppWrapper';
import PersonnelShowPage from 'components/pages/PersonnelShowPage';

export default function PersonnelShowApp({ wrappedProps, contextProps }) {
  return (
    <AppWrapper {...contextProps}>
      <PersonnelShowPage {...wrappedProps} />
    </AppWrapper>
  )
}
