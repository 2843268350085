import React from 'react';
import ChevronLeftIcon from '-!svg-react-loader?name=ChevronLeftIcon!icons/chevron-left.svg';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right.svg';


export default function Stepper({totalSteps, currentStep, onStepUp, onStepDown}) {
  const steppingDisabled = totalSteps === currentStep && totalSteps === 1;
  return(
    <div className={`stepper${steppingDisabled ? ' tw-opacity-30 tw-pointer-events-none' : ''}`}>
      <div className='stepper__nav stepper__left [&_svg_polygon]:tw-fill-grey-700 tw-bg-grey-050 [&_svg_polygon]:hover:tw-fill-grey-900 hover:tw-bg-blue-050' onClick={onStepDown}>
        <ChevronLeftIcon/>
      </div>
      <div className='stepper__info tw-font-medium tw-text-grey-600 tw-bg-grey-025'>{`${currentStep} of ${totalSteps}`}</div>
      <div className='stepper__nav stepper__right [&_svg_polygon]:tw-fill-grey-700 tw-bg-grey-050 [&_svg_polygon]:hover:tw-fill-grey-900 hover:tw-bg-blue-050' onClick={onStepUp}>
        <ChevronRightIcon/>
      </div>
    </div>
  )
}
