import React from 'react';
import PropTypes from 'prop-types';
import RadioField from 'components/application/RadioField';
import AccessControlTable from 'components/users/AccessControlTable';
import UserPermissionsAndDivisionAccessInfoCard from 'components/users/cards/UserPermissionsAndDivisionAccessInfoCard';
import TeamsAccessExplanationNotification from 'components/users/TeamsAccessExplanationNotification';

AccessManagerSection.propTypes = {
  divisions: PropTypes.array.isRequired,
  formUser: PropTypes.object.isRequired,
  onDivisionSelection: PropTypes.func.isRequired,
  onAccessChange: PropTypes.func.isRequired,
  personnelText: PropTypes.string.isRequired,
  isTeamsAllowed: PropTypes.bool.isRequired
}

export default function AccessManagerSection({ divisions, formUser, onAccessTypeSelection, onDivisionSelection, onAccessChange, onAccountRoleChange, isEditingSelf, personnelText, projectDisplayNameSingular, projectDisplayNamePlural, isTeamsAllowed }) {
  return (
    <>
      <h3 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto m-t-24 m-b-24'>Set permissions</h3>
      <UserPermissionsAndDivisionAccessInfoCard />
      {formUser.accessType === 'primary' ? (
        <>
          <p>You are the account owner</p>
          <p>To change the account owner, please contact the customer success team.</p>
        </>
      ) : (
        <>
          <RadioField
            checked={formUser.accessType === 'administrator'}
            name={'access_type'}
            onChange={() => onAccessTypeSelection('administrator')}
            label={
              <div className='m-l-4'>
                <div className='m-b-4 tw-font-medium'>Account administrator</div>
                <div className='tw-text-s tw-font-normal tw-tracking-wide'>Access and permissions across all divisions</div>
              </div>
            }
          />
          <div className='fh-1' />
          <RadioField
            onChange={() => onAccessTypeSelection('regular')}
            disabled={isEditingSelf}
            tooltip={'You cannot downgrade your own access and permissions'}
            checked={formUser.accessType === 'regular'}
            name={'access_type'}
            label={
              <div className='m-l-4'>
                <div className='m-b-4 tw-font-medium'>User</div>
                <div className='tw-text-s tw-font-normal tw-tracking-wide'>Access and permissions set independently for each division</div>
              </div>
            }
          />
          {
            isTeamsAllowed &&
            <TeamsAccessExplanationNotification />
          }
          {formUser.accessType === 'regular' && <AccessControlTable {...{ formUser: formUser, divisions, onDivisionSelection, onAccessChange, onAccountRoleChange, personnelText, projectDisplayNameSingular, projectDisplayNamePlural }} />}
        </>

      )}
    </>
  )
}
