import React from 'react';
import ReactDOM from 'react-dom';
import ActionLink from 'components/buttons/ActionLink';
import ActionButton from 'components/buttons/ActionButton';

export default function ProjectContentReviewFooter(props) {
  const openModal = (action) => {
    setAction(action)
    setModalIsOpen(true)
  }

  return (
    <React.Fragment>
      <PortaledActionButtons direction={'left'}>
        <ActionLink
          disableAfterClick
          linkText={'Previous'}
          url={`/projects/${props.projectId}/method${props.isReviewing ? '?review=true' : ''}`}
          styleClasses={'tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--blue-grey tw-apply-outlined-button--on-dark'}
          performCheck={props.anyUnsaved}
          openUnsavedChangesModal={openModal}
        />
      </PortaledActionButtons>
      <PortaledActionButtons direction={'right'}>
        <ActionButton
          styleClasses={'tw-apply-filled-button tw-apply-filled-button--md tw-apply-filled-button--mint tw-apply-filled-button--on-dark'}
          buttonText={'Finish'}
          onClick={props.submitProjectContentReview}
          disabled={props.contentReviewSubmitting}
        />
      </PortaledActionButtons>
    </React.Fragment>
  )
}

const PortaledActionButtons = (props) => {
  const buttonLocation = document.getElementsByClassName(`project-actions-${props.direction}`)
  return ReactDOM.createPortal(props.children, buttonLocation[0])
}
