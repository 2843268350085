import React from 'react';
import PropTypes from 'prop-types';

import LowIcon from '-!svg-react-loader?name=AddedIcon!icons/ic-low.svg';
import MediumIcon from '-!svg-react-loader?name=AddedIcon!icons/ic-medium.svg';
import HighIcon from '-!svg-react-loader?name=AddedIcon!icons/ic-high.svg';

export default function LevelOfRiskIcon({ option, subtextProps }) {
  let subtext, displayIcon;

  switch (option) {
    case 'Low':
      subtext = 'First aid required'
      displayIcon = <LowIcon className={'coshh-selector--icon'} height={32} width={32} />
      break;
    case 'Medium':
      subtext = 'Lost time, medical treatment required'
      displayIcon = <MediumIcon className={'coshh-selector--icon'} height={32} width={32} />
      break;
    case 'High':
      subtext = 'Serious illness, disease or fatality'
      displayIcon = <HighIcon className={'coshh-selector--icon'} height={32} width={32} />
  }

  return (
    <div className={'coshh-selector--container'}>
      { displayIcon }
      <div className={'coshh-selector--text'}>
        <p className={'m-0 tw-font-medium'}>
          {option}
        </p>
        <p className={`m-0 tw-text-s tw-font-normal tw-tracking-wide basic-truncated-text-container ${subtextProps.className}`} >
          {subtext}
        </p>
      </div>
    </div>
  )
}

LevelOfRiskIcon.propTypes = {
  option: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
  subtextProps: PropTypes.object
};

LevelOfRiskIcon.defaultProps = {
  subtextProps: {
    className: ''
  }
}
