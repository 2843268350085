import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ListSelectItem from 'components/application/ListSelectItem';

const moreBreakpoint = 3;

export default function ListSelect(props) {
  const {
    field,
    items,
    selectableItems,
    onChange,
    removeErrorStyling
  } = props;

  const [showMore, setShowMore] = useState(false);

  const addNewItem = ({ value }) => {
    if (value.length > 0) {
      const newItems = [...items];

      newItems.push({ value })
      onChange({ oldItems: items, newItems: newItems }, { target: { name: field } })
      removeErrorStyling({ target: { name: field } })
    }
  };

  const filteredSelectableItems = selectableItems.filter(selectableItem => !items.map(item => item.value).includes(selectableItem.value));
  const visibleItems = filteredSelectableItems.length < moreBreakpoint || showMore ? filteredSelectableItems : filteredSelectableItems.slice(0, moreBreakpoint);

  return (
    <div className='app-form__select-list'>
      {visibleItems.map((item, index) => <ListSelectItem key={index} item={item} onClick={addNewItem} />)}
      {filteredSelectableItems.length > moreBreakpoint && !showMore && (
        <div className='flex flex--vertically-centered'>
          <button className='app-link tw-align-baseline tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' onClick={() => setShowMore(true)}>Show more ({filteredSelectableItems.length - moreBreakpoint})</button>
        </div>
      )}
    </div>
  )
}

ListSelect.propTypes = {
  field: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selectableItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
