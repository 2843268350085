import React from 'react';
import PropTypes from 'prop-types';
import { personDisplayName } from 'components/helpers/users';
import BlockList from 'components/application/BlockList';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function PersonnelSidePanelSubordinatesTab(props) {
  const {
    subordinates,
    loadMoreRef,
    metaData
   } = props

  return (
    <>
      <div>
        { subordinates.length > 0 ?
          <BlockList
            collection={subordinates}
            onItemDisplay={(subordinate) => {
              return(
                <div className='block-list__item--flex-container'>
                  <span className='truncated-text-container truncated-text-container--width-auto'>
                    {`${personDisplayName(subordinate.attributes)}`}
                  </span>
                </div>
              )
            }}
          />
          :
          <div className='tw-py-6 tw-px-5 tw-text-grey-400'>
            Not set
          </div>
        }
        {!metaData.isLastPage && subordinates.length != 0 && (
          <li ref={loadMoreRef} className='block-list__item tw-border-grey-100'>
            <div className='block-list__item--flex-container'>
              <div className='block-list__item--align-center'>
                <span>Loading personnel...</span>
              </div>
            </div>
          </li>
        )}
      </div>
    </>
  )
}

PersonnelSidePanelSubordinatesTab.propTypes = {
  subordinates: PropTypes.arrayOf(resourceShape('subordinate')),
  loadMoreRef: PropTypes.func.isRequired,
  metaData: PropTypes.object.isRequired
};
