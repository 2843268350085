$(function() {
  if ($('#master_activity_reference').length !== 0) {
    const field = $('#ref').find('#master_activity_reference');
    const warning = $('.ra-heading').find('#reference-warning');
    const references = warning.data('references').filter(ref => ref !== field[0].value)

    field.on('input', function () {
      if (references.includes(this.value)) {
        warning.show();
        warning.html("<img src='/assets/icons/warning-icon.svg' class='tw-text-[12px]' alt='Warning Icon'> <span class='tw-text-xs tw-font-semibold tw-tracking-wide'>A risk assessment with the reference '" + this.value + "' already exists</span>");
      } else {
        warning.hide();
      }
    });

    field.on('blur', function() {
      warning.hide();
    });
  }
});
