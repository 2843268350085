import React from 'react';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function PersonnelSidePanelLineManagerTab(props) {
  const {
    lineManager
  } = props;

  return (
    <div className='tw-py-6 tw-px-5 tw-border-grey-100'>
      {lineManager ? <p>{`${personDisplayName(lineManager.attributes)}`}</p> : <div className='tw-text-grey-400'>Not set</div>}
    </div>
  )
}

PersonnelSidePanelLineManagerTab.propTypes = {
  lineManager: resourceShape('lineManager')
};
