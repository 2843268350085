import React from 'react';
import PersonalInfo from 'components/application/PersonalInfo';
import moment from 'moment';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources'

export default function BookingSidePanelDetailsTab({ booking, booker }) {
  const bookedByDate = moment.parseZone(booking?.attributes.createdAt).format('DD MMM YY [at] HH:mm');
  const bookingDate = moment.parseZone(booking?.attributes.date).format('DD MMM YY')

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      <PersonalInfo label='Training date' text={bookingDate} />
      <PersonalInfo label='Booking notes' isTruncated={false} text={booking?.attributes.notes} />
      <PersonalInfo label='Booked by' text={booker ? `${personDisplayName(booker?.attributes)} on ${bookedByDate}` : null} />
    </div>
  )
}

BookingSidePanelDetailsTab.propTypes = {
  booking: resourceShape('booking'),
  booker: resourceShape('simpleUser')
}
