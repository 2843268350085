export default (function() {
  return {
    queryStringParams: function() {
      const params = {};
      const rawParams = window.location.search;
      if(rawParams) {
        rawParams.slice(1).split("&").forEach(function(paramInfo) {
          const splitInfo = paramInfo.split("=");
          params[splitInfo[0]] = splitInfo[1];
        })
      }
      return params;
    }
  };
})();
