import React from 'react';
import PropTypes from 'prop-types';
import TeamMembersList from 'components/teams/TeamMembersList';

import 'intersection-observer';
import { InView } from 'react-intersection-observer';

export default function TeamsSidePanelMembersTab({
  teamMembers,
  canLoadMoreTeamMembers,
  onLoadMoreTeamMembers,
  isFetchingTeamMembers
  }) {
  return (
    <div key='personnelTab' label='personnel'>
      <>
        {teamMembers.loaded && teamMembers.collection.length === 0 ? (
          <li className='block-list__item tw-border-grey-100'>
            <div className='block-list__item--flex-container'>
              <div className='block-list__item--align-center'>
                <span>No members in this team</span>
              </div>
            </div>
          </li>
        ) : (
          <>
            <TeamMembersList teamMembers={teamMembers} />
            {canLoadMoreTeamMembers && (<InView as='div' onChange={onLoadMoreTeamMembers}/>)}
            {isFetchingTeamMembers && (
              <span className="block-list__item tw-border-grey-100">
                <div className="block-list__item--flex-container">
                  <div className="block-list__item--align-center">
                    <span>Loading members...</span>
                  </div>
                </div>
              </span>
            )}
          </>
        )}
      </>
    </div>
  )
}

TeamsSidePanelMembersTab.propTypes = {
  teamMembers: PropTypes.object.isRequired,
  canLoadMoreTeamMembers: PropTypes.bool.isRequired,
  onLoadMoreTeamMembers: PropTypes.func.isRequired,
  isFetchingTeamMembers: PropTypes.bool.isRequired
};
