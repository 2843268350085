import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sendAnalytics } from 'components/helpers/analytics';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import RedInfoIcon from '-!svg-react-loader?name=RedInfoIcon!icons/info-red.svg';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import * as IntercomHelperModule from 'components/helpers/intercom';

export default function TrackedPersonnelUsageBanner ({ usage, hasReachedLimit }) {
  const currentActor = useCurrentActor();
  const allowance = currentActor.subscription.attributes.trackedPersonnelAllowance;

  const handleUpgradeClick = () => {
    IntercomHelperModule.launchIntercomWithMessage("Hello, I'm interested in upgrading my Training Register plan.")
    sendAnalytics('Upgrade clicked from usage banner notification', { user: currentActor.user, division: currentActor.division, account: currentActor.account })
  }

  return (
    <div className='flex flex--vertically-centered py-12 px-20 m-b-12 b-standard br-3 tw-text-m tw-border-grey-100 tw-bg-white'>
      {hasReachedLimit ? <RedInfoIcon height="24px" width="24px" className="[&_path]:tw-fill-red-600" /> : <InfoIcon height="24px" width="24px" className="[&_path]:tw-fill-cyan-600" />}
      <span className='m-l-12'>
        <div className='tw-font-medium'>
          You {hasReachedLimit ? 'have reached' : 'are approaching'} your training limit
        </div>
        <span>
          {usage} personnel with recorded or booked training / {allowance} in your plan
        </span>
      </span>
      {["primary", "administrator"].includes(currentActor.user.attributes.accessType) && hasReachedLimit && (
        <OutlinedButton className='m-l-auto' size='sm' color='grey' onClick={handleUpgradeClick}>Upgrade plan</OutlinedButton>
      )}
    </div>
  )
}

TrackedPersonnelUsageBanner.propTypes = {
  usage: PropTypes.number.isRequired
}
