import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import voca from "voca";

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import CheckboxFilter from "components/filters/CheckboxFilter";
import MultiSelectFilter from "components/filters/MultiSelectFilter";

import Tooltip from "components/application/Tooltip";
import CircleQuestion from "components/application/CircleQuestion";
import Options from "components/application/Options";
import OptionChoice from "components/application/OptionChoice";
import OutlinedButton from "components/application/buttons/OutlinedButton";

import ChevronUpIcon from "-!svg-react-loader?name=ChevronUpIcon!icons/chevron-up.svg";
import ChevronDownIcon from "-!svg-react-loader?name=ChevronDownIcon!icons/chevron-down.svg";

const filterOptionMap = {
  divisionFilter: "Divisions",
  teamFilter: "Teams",
  personnelTypeFilter: "Personnel type",
  roleFilter: "Roles",
  courseFilter: "Courses",
  courseRequirementFilter: "Course requirement",
  lineManagerFilter: "Line managers"
};

export function sortLabelsAlphabetically(a, b) {
  return voca.lowerCase(a.label) > voca.lowerCase(b.label) ? 1 : -1
}

export function sortDivisions(a, b) {
  if (a.record.attributes.primary) {
    return -1
  } else if (b.record.attributes.primary) {
    return 1
  } else {
    return sortLabelsAlphabetically(a, b)
  }
}

function PersonnelTypeFilter({ filter, onChange, onRemoveFilter }) {
  return (
    <CheckboxFilter
      label="Personnel type"
      checkboxes={[
        { name: "personnel_type_filter_employees", label: "Employees", value: filter.employees, onChange: (event) => onChange("personnelTypeFilter", "employees", event.target.checked) },
        { name: "personnel_type_filter_sub_contractors", label: "Sub-contractors", value: filter.subcontractors, onChange: (event) => onChange("personnelTypeFilter", "subcontractors", event.target.checked) }
      ]}
      onRemove={() => onRemoveFilter("personnelTypeFilter")}
    />
  )
}

function RoleFilter({ filter, validationError, autoFocus, onChange, onSelect, onDeselect, onRemoveFilter, onFocus }) {
  return (
    <MultiSelectFilter
      id="role_filter_selected_ids"
      name="role_filter_selected_ids"
      label="Roles"
      placeholder="Search roles..."
      autoFocus={autoFocus}
      url="/training_register_reports/roles"
      selectedIds={filter.selectedIds}
      checkboxes={[
        { name: "role_filter_additional_training", label: "Include additional training", value: filter.additionalTraining, tooltip: "Not including additional training will only show courses linked to the role(s)", onChange: (event) => onChange("roleFilter", "additionalTraining", event.target.checked) }
      ]}
      resourceLabel={(resource) => resource.attributes.position}
      resourceSort={sortLabelsAlphabetically}
      fieldError={validationError}
      onSelect={(id) => onSelect("roleFilter", id)}
      onDeselect={(id) => onDeselect("roleFilter", id)}
      onRemove={() => onRemoveFilter("roleFilter")}
      onFocus={() => onFocus("roleFilter")}
    />
  )
}

function CourseFilter({ filter, validationError, autoFocus, onSelect, onDeselect, onRemoveFilter, onFocus }) {
  return (
    <MultiSelectFilter
      id="course_filter_selected_ids"
      name="course_filter_selected_ids"
      label="Courses"
      placeholder="Search courses..."
      autoFocus={autoFocus}
      url="/training_register_reports/courses"
      selectedIds={filter.selectedIds}
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortLabelsAlphabetically}
      fieldError={validationError}
      onSelect={(id) => onSelect("courseFilter", id)}
      onDeselect={(id) => onDeselect("courseFilter", id)}
      onRemove={() => onRemoveFilter("courseFilter")}
      onFocus={() => onFocus("courseFilter")}
    />
  )
}

function CourseRequirementFilter({ filter, onChange, onRemoveFilter }) {
  return (
    <CheckboxFilter
      label="Course requirement"
      checkboxes={[
        { name: "course_requirement_filter_required_training", label: "Required training", value: filter.required, onChange: (event) => onChange("courseRequirementFilter", "required", event.target.checked) },
        { name: "course_requirement_filter_optional_training", label: "Optional training", value: filter.optional, onChange: (event) => onChange("courseRequirementFilter", "optional", event.target.checked) }
      ]}
      onRemove={() => onRemoveFilter("courseRequirementFilter")}
    />
  )
}

function LineManagerFilter({ filter, validationError, division, autoFocus, onSelect, onDeselect, onRemoveFilter, onFocus }) {
  return (
    <MultiSelectFilter
      id="line_manager_filter_selected_ids"
      name="line_manager_filter_selected_ids"
      label="Line managers"
      placeholder="Search line managers..."
      autoFocus={autoFocus}
      url="/training_register_reports/line_managers"
      message={division.attributes.primary ? null : "If a line manager is in a different division to the personnel they manage, those personnel may not be included in the report."}
      selectedIds={filter.selectedIds}
      resourceLabel={(resource) => `${resource.attributes.firstName} ${resource.attributes.lastName}`}
      resourceSort={sortLabelsAlphabetically}
      fieldError={validationError}
      onSelect={(id) => onSelect("lineManagerFilter", id)}
      onDeselect={(id) => onDeselect("lineManagerFilter", id)}
      onRemove={() => onRemoveFilter("lineManagerFilter")}
      onFocus={() => onFocus("lineManagerFilter")}
    />
  )
}

function DivisionFilter({ filter, validationError, autoFocus, onSelect, onDeselect, onRemoveFilter, onFocus }) {
  return (
    <MultiSelectFilter
      id="division_filter_selected_ids"
      name="division_filter_selected_ids"
      label="Divisions"
      placeholder="Search divisions..."
      autoFocus={autoFocus}
      url="/training_register_reports/divisions"
      selectedIds={filter.selectedIds}
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortDivisions}
      fieldError={validationError}
      onSelect={(id) => onSelect("divisionFilter", id)}
      onDeselect={(id) => onDeselect("divisionFilter", id)}
      onRemove={() => onRemoveFilter("divisionFilter")}
      onFocus={() => onFocus("divisionFilter")}
    />
  )
}

function TeamFilter({ filter, validationError, autoFocus, onSelect, onDeselect, onRemoveFilter, onFocus, isDivisionFilterSelected }) {
  return (
    <MultiSelectFilter
      id="team_filter_selected_ids"
      name="team_filter_selected_ids"
      label="Teams"
      placeholder="Search teams..."
      autoFocus={autoFocus}
      url="/training_register_reports/teams"
      message={isDivisionFilterSelected ? "If a team is not in a division that is being used as a filter, it won't be included in the report." : null}
      selectedIds={filter.selectedIds}
      resourceLabel={(resource) => resource.attributes.name}
      resourceSort={sortLabelsAlphabetically}
      fieldError={validationError}
      onSelect={(id) => onSelect("teamFilter", id)}
      onDeselect={(id) => onDeselect("teamFilter", id)}
      onRemove={() => onRemoveFilter("teamFilter")}
      onFocus={() => onFocus("teamFilter")}
    />
  )
}


export default function Filters(props) {
  const {
    filters,
    validationErrors = {},
    onAddFilter,
    onRemoveFilter,
    onSelect,
    onDeselect,
    onChange,
    onFocus
  } = props;

  const isInitialMount = useRef(true);
  const currentActor = useCurrentActor();
  const availableFilters = [
    "personnelTypeFilter",
    "roleFilter",
    "courseFilter",
    "courseRequirementFilter"
  ];

  const selectedSet = new Set(filters.selectedFilters);
  const disabledButton = availableFilters.every(filter => selectedSet.has(filter));
  const autoFocus = !isInitialMount.current;
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const {
    hasTRPersonnelAccess,
    hasLineManagerAccess,
    hasTeamManagerAccess
  } = trainingRegisterResourceManagementContext;
  const hasLineManagerOnlyAccess = hasLineManagerAccess && !(hasTRPersonnelAccess || hasTeamManagerAccess)
  const filterComponents = {
    personnelTypeFilter: (
      <PersonnelTypeFilter
        filter={filters.personnelTypeFilter}
        onChange={onChange}
        onRemoveFilter={onRemoveFilter}
      />
    ),
    roleFilter: (
      <RoleFilter
        filter={filters.roleFilter}
        validationError={validationErrors.roleFilter}
        autoFocus={autoFocus}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onRemoveFilter={onRemoveFilter}
        onFocus={onFocus}
      />
    ),
    courseFilter: (
      <CourseFilter
        filter={filters.courseFilter}
        validationError={validationErrors.courseFilter}
        autoFocus={autoFocus}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onRemoveFilter={onRemoveFilter}
        onFocus={onFocus}
      />
    ),
    courseRequirementFilter: (
      <CourseRequirementFilter
        filter={filters.courseRequirementFilter}
        onChange={onChange}
        onRemoveFilter={onRemoveFilter}
      />
    )
  };

  if (!hasLineManagerOnlyAccess) {
    availableFilters.push("lineManagerFilter");

    filterComponents["lineManagerFilter"] = (
      <LineManagerFilter
        filter={filters.lineManagerFilter}
        validationError={validationErrors.lineManagerFilter}
        division={currentActor.division}
        autoFocus={autoFocus}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onRemoveFilter={onRemoveFilter}
        onFocus={onFocus}
      />
    )
  }

  if (trainingRegisterResourceManagementContext.hasTeamViewableAccess && !hasLineManagerOnlyAccess) {
    availableFilters.push("teamFilter");
    filterComponents["teamFilter"] = (
      <TeamFilter
        filter={filters.teamFilter}
        validationError={validationErrors.teamFilter}
        autoFocus={autoFocus}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onRemoveFilter={onRemoveFilter}
        onFocus={onFocus}
        isDivisionFilterSelected={selectedSet.has('divisionFilter')}
      />
    )
  }

  if (currentActor.division.attributes.primary) {
    availableFilters.unshift("divisionFilter")

    filterComponents["divisionFilter"] = (
      <DivisionFilter
        filter={filters.divisionFilter}
        validationError={validationErrors.divisionFilter}
        autoFocus={autoFocus}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onRemoveFilter={onRemoveFilter}
        onFocus={onFocus}
      />
    )
  }

  useEffect(() => {
    if (isInitialMount.current) isInitialMount.current = false;
  }, [])

  return (
    <>
      <div className="tw-flex tw-items-center">
        <label className="tw-text-l tw-font-semibold tw-tracking-auto tw-m-0 tw-mr-2">Filters</label>
        <Tooltip placement="top" trigger="hover" tooltip={<p className="tw-m-0">Filters are combined e.g. <span className="tw-font-bold">Engineers + First Aid at Work</span> will only include personnel with both that role and that course</p>}>
          <CircleQuestion />
        </Tooltip>
      </div>
      <div className="tw-mt-6">
        {filters.selectedFilters.map((filter) => {
          return (
            <div key={filter} className="tw-mb-6">
              {filterComponents[filter]}
            </div>
          )
        })}
        <Options
          align="left"
          toggleStyle="tw-inline-block"
          listStyle="tw-w-52 [&_li]:tw-border-none"
          buttonDisabled={disabledButton}
          btnToggle={
            (isOpen) => {
              return (
                <div className="tooltip-parent">
                  <OutlinedButton size="sm" color="grey" disabled={disabledButton} onClick={() => { }}>
                    <span>Add filter</span>
                    <ChevronUpIcon className="-tw-mr-[6px] -tw-mb-[1px]" width={24} height={24} style={{ display: isOpen ? "inline-block" : "none" }} />
                    <ChevronDownIcon className="-tw-mr-[6px] -tw-mb-[1px]" width={24} height={24} style={{ display: isOpen ? "none" : "inline-block" }} />
                  </OutlinedButton>
                  {disabledButton && (<Tooltip trigger="hover" placement="top" tooltip="All filters have been added" />)}
                </div>
              )
            }
          }
        >
          {availableFilters.map((filter) => {
            return !selectedSet.has(filter) && (
              <OptionChoice
                key={filter}
                onClick={(setOptionsOpen) => {
                  setOptionsOpen(false)
                  onAddFilter(filter)
                }}
              >
                <a className="tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto">{filterOptionMap[filter]}</a>
              </OptionChoice>
            )
          })}
        </Options>
      </div>
    </>
  )
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  validationErrors: PropTypes.object,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
