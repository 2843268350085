$(function() {
  if ($('.dropdown[data-clickable]').length) {
    $(document).on('click', function(event) {
      if ($(event.target).closest('.dropdown__container').length) { return false; }

      $('.dropdown__content').hide();
    });

    $(document).on('click', '.dropdown[data-clickable] a', function(event) {
      event.stopPropagation();
      const thisDropdownContent = $(this).closest('.dropdown').find('.dropdown__content');

      $('.dropdown__content').not(thisDropdownContent).hide();
      thisDropdownContent.toggle();
    });
  }
});
