import React, { useCallback } from 'react';
import voca from 'voca';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';

export default function TextArea(props) {
  // this should be a helper
  const fieldRef = useCallback(node => { props.autoFocus && node !== null && focusNode(node) }, []);
  const focusNode = (node) => {
    node.focus()
    node.setSelectionRange(props.value.length, props.value.length)
    node.scrollTop = node.scrollHeight;
  }

  const isInvalid = !!props.fieldError?.fieldHighlighted;

  const textareaClassName = (() => {
    switch (true) {
      case (isInvalid):
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <div className='text-field'>
      <div className='form-group'>
        <label className={`field__label tw-font-medium${props.labelVisible ? '' : ' hide'}${props.isRequired || props.isOptional ? ` after:tw-absolute after:tw-text-grey-500 after:tw-right-0 after:tw-font-normal` : ''}${props.isRequired ? " after:tw-content-['Required']" : ''}${props.isOptional ? " after:tw-content-['Optional']" : ''}${isInvalid ? " after:tw-text-red-600" : ''}`} htmlFor={props.name}>
          {props.label}
          {props.tooltip &&
            <Tooltip placement='top' trigger='hover' tooltip={props.tooltip} className={props.tooltipClassName}>
              <CircleQuestion />
            </Tooltip>
          }
        </label>
        <div className='flex flex--vertically-centered'>
          <textarea
            id={voca.snakeCase(props.name)}
            name={props.name}
            className={`field__input field__input--textarea tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${textareaClassName}${props.autoHeight ? ' field__input--textarea-growable' : ''}${props.padded ? ' field__input--textarea-padded' : ''}${props.smallFontSize ? ' field__input--textarea-small-font-size' : ''}${props.resizeVertical ? ' field__input--resize-vertical' : ''}`}
            value={props.value}
            onChange={props.onChange}
            onFocus={props.onFocus}
            ref={fieldRef}
            placeholder={props.placeholderText}
          />
        </div>
      </div>
    </div>
  )
}

TextArea.defaultProps = {
  labelVisible: true,
  autoHeight: false,
  padded: false,
  tooltip: null,
  resizeVertical: false,
  isRequired: false,
  isOptional: false,
  placeholder: null,
  onFocus: () => {},
}
