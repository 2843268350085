import { useState } from 'react';

export default function useToggle(defaultValue) {
  const [value, setValue] = useState(defaultValue);

  const toggle = () => setValue(prevState => !prevState);

  return [
    value,
    toggle
  ]
}
