import HandshqApp from '../handshq-app';

HandshqApp.AutoSubmittingForm = function(options) {
  if (this instanceof HandshqApp.AutoSubmittingForm) {
    this.$form = options['form'];
    this.$triggeringInputs = this.$form.find('input, select');
    this.$formTrigger = this.$form.find('input[type="submit"]');
    this.onSuccess = options['success'];

    this.setup = function() {
      listenForChanges.bind(this)();
      registerSuccessAction.bind(this)();
    };

    this.submit = function(){
      this.$formTrigger.click();
    };

    function listenForChanges (){
      this.$triggeringInputs.on('change', this.submit.bind(this));
    }

    function registerSuccessAction(_onSuccess){
      this.$form.on('ajax:success', this.onSuccess);
    }
  }
};

$(function() {
  $('form.auto-submit').each(function(){
    const autoForm = new HandshqApp.AutoSubmittingForm({ form: $(this) });
    autoForm.setup();
  });
});
