import React, { useReducer } from "react";
import { enableAllPlugins } from "immer";
enableAllPlugins()
import { produce } from "immer";
import moment from "moment";

import validationErrorsReducer, { initialValidationErrorsState, mapValidationErrorsDispatch } from "components/validationErrors/reducers/validationErrorsReducer";
import { expiryDateSchema } from "components/trainingRegisterReports/schemas/dateSchema";
import { validateForm, buildFormValues } from "components/trainingRegisterReports/helpers";

import { useCurrentActor } from "components/contexts/CurrentActor";
import useReportForm, { initialDateState, initialEstimatesState, initialFiltersState } from "components/trainingRegisterReports/hooks/useReportForm";
import useWindowStorage from "components/hooks/useWindowStorage";

import Report from "components/trainingRegisterReports/reports/Report";
import DatePeriod from "components/trainingRegisterReports/components/DatePeriod";

const initialFormValues = { expiryDate: initialDateState, estimates: initialEstimatesState, filters: initialFiltersState };

const additionalValidators = [{ schema: expiryDateSchema, key: "expiryDate" }];

export default function ExpiringTrainingReport() {
  const currentActor = useCurrentActor();

  const [getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem] = useWindowStorage(`expiringTrainingReport|${currentActor.user.id}|${currentActor.division.id}`);
  const {
    formValues,
    handleDateChange,
    handleDateRangeChange,
    handleEstimatesChange,
    handleFilterAddition,
    handleFilterRemoval,
    handleFilterSelection,
    handleFilterDeselection,
    handleFilterChange
  } = useReportForm("expiringTraining", initialFormValues, (initialState) => {
    const computedFormValues = produce(initialState, draftState => {
      draftState.expiryDate.period = "withinTheNext";
      draftState.expiryDate.withinTheNextFrequency = "12";
      draftState.expiryDate.withinTheNextInterval = "weeks";
      draftState.expiryDate.withinTheLastFrequency = "12";
      draftState.expiryDate.withinTheLastInterval = "weeks";
      draftState.expiryDate.before = moment().toDate();
      draftState.expiryDate.after = moment().toDate();
      draftState.expiryDate.onExactDate = moment().toDate();
      draftState.expiryDate.customDateRangeFrom = moment().toDate();
      draftState.expiryDate.customDateRangeTo = moment().add(3, "months").toDate();
    })

    return buildFormValues({
      type: "expiringTraining",
      formValues: computedFormValues,
      additionalValidators,
      getLocalStorageItem,
      removeLocalStorageItem
    })
  });

  const [validationErrors, validationErrorsDispatch] = useReducer(validationErrorsReducer, initialValidationErrorsState);
  const validationErrorsHandlers = mapValidationErrorsDispatch(validationErrorsDispatch);

  const handleValidate = () => {
    return validateForm({ formValues, additionalValidators, safe: true })
  }

  return (
    <Report
      type="expiringTraining"
      title="Expiring training report"
      tooltip="The expiring training report only includes the last recorded training for each course and not any historical training"
      setLocalStorageItem={setLocalStorageItem}
      formValues={formValues}
      validationErrors={validationErrors}
      onValidationErrorsReset={validationErrorsHandlers.handleValidationErrorsReset}
      onValidationErrorsAssignment={validationErrorsHandlers.handleValidationErrorsAssignment}
      onValidationErrorsUnhighlight={validationErrorsHandlers.handleValidationErrorsUnhighlight}
      onEstimatesChange={handleEstimatesChange}
      onFilterAddition={handleFilterAddition}
      onFilterRemoval={handleFilterRemoval}
      onFilterSelection={handleFilterSelection}
      onFilterDeselection={handleFilterDeselection}
      onFilterChange={handleFilterChange}
      onValidate={handleValidate}
    >
      <label className="tw-font-medium tw-mb-1">Expiry date</label>
      <div className="tw-mb-6">
        <DatePeriod
          name="expiry_date"
          datePeriod={formValues.expiryDate}
          fieldError={validationErrors.expiryDate}
          onChange={handleDateChange}
          onDateRangeChange={handleDateRangeChange}
          onFocus={() => { if (validationErrors.expiryDate) validationErrorsHandlers.handleValidationErrorsUnhighlight("expiryDate") }}
        />
      </div>
    </Report>
  )
}
