import HandshqApp from '../handshq-app';

HandshqApp.togglableLabels = {
  setup: function(labels) {
    const _this = this;

    $(labels).each(function(_index, _elements) {
      const label = $(this);
      const toggle = label.find(label.data('toggle'));
      const targets = label.find(label.data('targets'));

      _this.toggleChecked(toggle, targets);
      _this.toggleCheckedOrNot(toggle, targets);
    });
  },

  toggleChecked: function(toggle, targets) {
    if (toggle.find('input').is(':checked')) {
      this.disableTargets(targets, true);
    }
  },

  toggleCheckedOrNot: function(toggle, targets) {
    const _this = this;

    toggle.find('input').on('change', function() {
      if ($(this).is(':checked')) {
        _this.disableTargets(targets, true);
      } else {
        _this.disableTargets(targets, false);
      }
    });
  },

  disableTargets: function(targets, disabled) {
    targets.each(function(_index, _elements) {
      if (disabled && $(this).find('input').is(':checked')) { $(this).click() }

      $(this).toggleClass('disabled display-checked');
      $(this).find('span').toggleClass('disabled');
      $(this).find('input').attr('disabled', disabled);
    });
  }
};

$(function() {
  if ($('.togglable-labels').length) { HandshqApp.togglableLabels.setup('.togglable-labels') }
});
