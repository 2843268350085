import React from 'react';
import PropTypes from 'prop-types';

import RowBar from 'components/application/RowBar';
import Table from 'components/application/Table';
import UserManagementRow from 'components/users/UserManagementRow';
import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';
import DivisionFilter from 'components/application/DivisionFilter';
import SearchField from 'components/application/SearchField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person.svg';

UserManagementTable.propTypes = {
  users: PropTypes.array.isRequired,
  divisionSelectionOptions: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  onAddUserClick: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired
}

export default function UserManagementTable(props) {
  const {
    users,
    divisionSelectionOptions,
    searchOptions,
    isAtUserCapacity,
    onAddUserClick,
    onResendInvitation,
    onPasswordReset,
    onRemoveUser,
    onEditClick
  } = props;

  return (
    <>
      <TableHeader {...{ divisionSelectionOptions, searchOptions, isAtUserCapacity, onAddUserClick}} />
      <Table
        headers={
          <tr >
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>User</th>
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-190'>Status</th>
            { divisionSelectionOptions.isSelectable && (<th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-280'>Division</th>) }
            <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-50'></th>
          </tr>
        }
        rows={ users.map((user) =>
          <UserManagementRow
            key={`UserManagementRow--${user.id}`}
            {...user}
            withDivisionInfo={divisionSelectionOptions.isSelectable}
            onResendInvitation={onResendInvitation}
            onPasswordReset={onPasswordReset}
            onRemoveUser={onRemoveUser}
            onEditClick={onEditClick}
          />
        )}
        blankState={<NoUsersResult search={searchOptions.debouncedValue} />}
      />
    </>
  )
}

function NoUsersResult({ search }) {
  return (
    <div style={{ borderTop: 'none' }} className='section section--notice br-tl-0 br-tr-0 b-t-0 tw-border-grey-100 tw-bg-white'>
      <NoResultsIcon width={64} height={64} className='[&_path]:tw-fill-grey-300'/>
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>
        No results found{search && <span> for '{search}'</span>}
      </h2>
    </div>
  )
}

export function TableHeader({ divisionSelectionOptions, searchOptions, onAddUserClick, isAtUserCapacity}) {
  return(
    <RowBar
      modifiers={['border-top-curved', 'border-bottom-none', 'large']}
      content={
        <div className='flex flex--vertically-centered'>
          {divisionSelectionOptions.isSelectable && (
            <div className='fw-200 m-r-12'>
              <DivisionFilter
                divisions={divisionSelectionOptions.divisions}
                selectedDivisionId={divisionSelectionOptions.selectedDivisionId}
                onChange={divisionSelectionOptions.onDivisionSelection}
              />
            </div>
          )}
          <SearchField
            value={searchOptions.value}
            name={'user_search'}
            placeholder={'Search users…'}
            onReset={searchOptions.onReset}
            onChange={searchOptions.onChange}
          />
        </div>
      }
      actions={
        <OutlinedButton size='sm' color='grey' disabled={isAtUserCapacity} onClick={!isAtUserCapacity && onAddUserClick}>
          <PersonIcon className='m-l--8' width={24} height={24} />
          <span>Add user</span>
        </OutlinedButton>
      }
    />
  )
}
