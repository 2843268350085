import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDebounce from 'components/hooks/useDebounce';
import CollectionSelect from 'components/application/CollectionSelect';

export default function SelectLineManager(props) {
  const defaultSelect = { inputValue: null, callback: null };
  const [select, setSelect] = useState(defaultSelect);
  const [debouncedInputValue, resetDebouncedInputValue] = useDebounce(select.inputValue, 250);
  const [lineManagers, setLineManagers] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const lineManagerOption = personnel => ({ value: personnel.id, label: `${personnel.attributes.firstName} ${personnel.attributes.lastName}` });

  const currentLineManager = [...new Set([...props.lineManagers, ...lineManagers])].find(personnel => personnel.id === props.currentPersonnel.lineManagerId);
  const lineManagerValue = (currentLineManager ? lineManagerOption(currentLineManager) : '');

  const filteredLineManagerLoadOptions = collection => {
    return collection.filter(personnel => personnel.id !== props.currentPersonnel.id).map(personnel => lineManagerOption(personnel))
  }

  const fetchLineManagerLoadOptions = () => {
    if (debouncedInputValue && debouncedInputValue.length > 0) {
      axios
        .get('/personnel', { params: { account_personnel: true, incl_employees: true, search: debouncedInputValue } })
        .then((response) => {
          setLineManagers([...new Set([...lineManagers, ...response.data.data])])
          setMenuIsOpen(true)
          select.callback(filteredLineManagerLoadOptions(response.data.data))
        })
    } else {
      select.callback([])
    }
  };

  const lineManagerOptions = (inputValue, callback) => setSelect({ inputValue: inputValue, callback: callback });

  const handleInputChange = (inputValue) => {
    if (inputValue.length < 1) {
      setMenuIsOpen(false)
    }
  };

  useEffect(() => {
    if (select.callback) { fetchLineManagerLoadOptions() }
  }, [debouncedInputValue])

  return (
    <CollectionSelect
      label='Line manager'
      isDisabled={props.isDisabled}
      name='lineManagerId'
      value={props.isDisabled ? '' : lineManagerValue}
      isAsync={true}
      isClearable={true}
      options={lineManagerOptions}
      tooltip='Line managers can only be specified for employees and not sub-contractors'
      onChange={props.onChange}
      externalProps={{ menuIsOpen: menuIsOpen, onFocus: resetDebouncedInputValue, onInputChange: handleInputChange }}
    />
  )
}

SelectLineManager.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  currentPersonnel: PropTypes.object.isRequired,
  lineManagers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
