import moment from 'moment';

export function uniqueTrainingCollectionByCourse({ training }) {
  const map = new Map();

  return training.reduce((acc, member) => {
    if (!map.has(member.relationships.course.data.id)) {
      map.set(member.relationships.course.data.id, true)
      acc.push(member)
    }
    return acc
  }, [])
}

export function groupTrainingByCourseId({ training }) {
  return training.reduce((acc, member) => {
    if (!acc.hasOwnProperty(member.relationships.course.data.id)) {
      acc[member.relationships.course.data.id] = [];
    }
    acc[member.relationships.course.data.id].push(member)
    return acc
  }, {})
}

export function getTrainingStatus({ training, course }) {
  if (!training) { return 'missing' }

  const expiryDate = training.attributes.expiryDate ? moment.parseZone(training.attributes.expiryDate) : null;
  const expiringPeriodStartDate = moment(expiryDate).subtract(course.attributes.expiringDuration);

  if (course.attributes.expires) {
    switch (true) {
      case moment().isBefore(expiringPeriodStartDate, 'day'):
        return 'valid'
      // moment what are these arguments... [] = inclusive, () = exclusive
      case moment().isBetween(expiringPeriodStartDate, expiryDate, 'day', '[)'):
        return 'expiring'
      case moment().isSameOrAfter(expiryDate, 'day'):
        return 'expired'
      case expiryDate === null:
        return 'expiryDateMissing'
      default:
        return 'invalid'
    }
  } else {
    return 'valid'
  }
}

export const courseTrainingStatusOrdering = ({ training, course, isRequired }) => {
  const status = getTrainingStatus({training, course});
  switch (status) {
    case 'missing':
      return isRequired ? 0 : 4
    case 'expiring':
      return isRequired ? 2 : 3
    case 'expired':
    case 'expiryDateMissing':
      return isRequired ? 1 : 4
    case 'valid':
      return 3
    case 'invalid':
      return 4
    default:
      return 4
  }
}

export function getTrainingExpiryDateLabel({ training, course }) {
  if (!training) { return 'Missing' }

  const expiryDate = moment.parseZone(training.attributes.expiryDate);

  return course.attributes.expires ? (training.attributes.expiryDate ? expiryDate.format('DD MMM YYYY') : 'Date missing') : 'Does not expire';
}
