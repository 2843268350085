import React from 'react';
import PropTypes from 'prop-types';
import TextButton from 'components/application/buttons/TextButton';

export default function ActionModalContent(props) {
  const {
    headerText,
    bodyIcon,
    bodyTitle,
    bodyText,
    closeable,
    closeModal,
    modifiers,
    iconStyling,
    withHeader,
    closeButtonText
  } = props

  return (
    <React.Fragment>
      <div className={`action-modal__box tw-bg-white${modifiers ? modifiers.map(modifier => ` action-modal__box--${modifier}`).join('') : ''}`}>
        {withHeader && (
          <section className='action-modal__header truncated-text-container tw-text-grey-900 tw-text-l tw-font-semibold tw-border-grey-100'>
            {headerText}
          </section>
        )}
        <section className='action-modal__body'>
          <span className={`action-modal__icon${iconStyling ? ` ${iconStyling}` : ''}`}>
            {bodyIcon}
          </span>
          <div className='action-modal__body--title tw-text-grey-900 tw-text-l tw-font-semibold'>
            {bodyTitle}
          </div>
          <div className='action-modal__body--text'>
            {bodyText}
          </div>
        </section>
        {closeable && (
          <section className='action-modal__footer tw-border-grey-100'>
            <TextButton size='md' color='blue' onClick={closeModal}>
              {closeButtonText}
            </TextButton>
          </section>
        )}
      </div>
    </React.Fragment>
  )
}

ActionModalContent.defaultProps = {
  closeable: true,
  withHeader: true,
  closeButtonText: 'Close'
}

ActionModalContent.propTypes = {
  headerText: PropTypes.string,
  bodyIcon: PropTypes.object,
  bodyTitle: PropTypes.string,
  bodyText: PropTypes.string,
  closeable: PropTypes.bool,
  closeModal: PropTypes.func,
  modifiers: PropTypes.array,
  iconStyling: PropTypes.string
}
