$(function() {
  // Project form actions
  const projectForm = $( '.project-form' ).length === 0 ? $( '.edit_company' ) : $( '.project-form' );
  const projectSubmitBtn = projectForm.find( '#submit' );
  const projectNextBtn = $( '#next' );
  const saveAndExitBtn = $( '#save-and-exit' );

  if ( projectNextBtn.length > 0 || saveAndExitBtn.length > 0 ) {
    if ( projectNextBtn.length > 0 ) {
      projectNextBtn.on('click', function() {
        const urlParams = new URLSearchParams(window.location.search)

        if ( urlParams.has('review') ) {
          const reviewInput = $('<input>')
          .attr('type', 'hidden')
          .attr('name', 'review').val('true');

          projectForm.append(reviewInput);
        }

        submitForm();
      });
    }

    if ( saveAndExitBtn.length > 0 ) {
      const urlParams = new URLSearchParams(window.location.search)

      saveAndExitBtn.on('click', function() {
        const input = $('<input>')
                      .attr('type', 'hidden')
                      .attr('name', 'save').val('true')

        projectForm.append(input);

        if ( urlParams.has('review') ) {
          const reviewInput = $('<input>')
          .attr('type', 'hidden')
          .attr('name', 'review').val('true');

          projectForm.append(reviewInput);
        }

        submitForm();
      });
    }

    function submitForm() {
      setTimeout(function() {
        // Some forms use HTML5 validations and therefore we need to click a button
        projectSubmitBtn.length === 1 ? projectSubmitBtn.click() : projectForm.submit();
      }, 300);
    }
  }
});
