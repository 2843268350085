import React, { useRef } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import moment from "moment";

import { permittedPeriods, permittedIntervals } from "components/trainingRegisterReports/schemas/dateSchema";

import { formatOption } from "components/trainingRegisterReports/helpers";

import { DropdownIndicator, Option } from "components/application/CollectionSelect";
import { DatePicker } from "components/application/DatePickerField";

const permittedPeriodsSet = new Set(permittedPeriods);

const integersRegex = /^\d+(\.\d{0,2})?/;

const SingleValue = ({ ...props }) => (
  <components.SingleValue { ...props }>
    <div className="tw-relative -tw-top-[1px]">{props.children}</div>
  </components.SingleValue>
)

export default function DatePeriod(props) {
  const {
    name,
    datePeriod,
    periodOptions,
    fieldError,
    beforeProps = {},
    afterProps = {},
    onExactDateProps = {},
    customDateRangeFromProps = {},
    customDateRangeToProps = {},
    onChange,
    onDateRangeChange,
    onFocus
  } = props;

  // refs are required in order to stop first option from being focused when select is focused, a bit of a hack!!!
  const selectPeriodRef = useRef();
  const selectIntervalRef = useRef();

  const isFrequencyIntervalPickerVisible = ["withinTheNext", "withinTheLast"].includes(datePeriod.period);

  const isDatePickerVisible = ["before", "after", "onExactDate"].includes(datePeriod.period);

  let dateProps;
  if (datePeriod.period === "before") {
    dateProps = beforeProps;
  } else if (datePeriod.period === "after") {
    dateProps = afterProps;
  } else if (datePeriod.period === "onExactDate") {
    dateProps = onExactDateProps;
  }

  const isDatePickerFromToVisible = datePeriod.period === "customDateRange";

  const selectablePeriodOptions = periodOptions ? periodOptions.filter((periodOption) => permittedPeriodsSet.has(periodOption)) : permittedPeriods;

  const isInvalid = !!fieldError?.fieldHighlighted;

  const inputClassName = isInvalid ? (
    "tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600"
  ) : (
    "tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300"
  );

  const handlePeriodChange = (selected) => {
    onChange("period", selected ? selected.value : "")
  }

  const handleFrequencyChange = (event) => {
    const matchedValue = event.target.value.match(integersRegex);

    onChange(`${datePeriod.period}Frequency`, matchedValue ? matchedValue[0] : "")
  }

  const handleIntervalChange = (selected) => {
    onChange(`${datePeriod.period}Interval`, selected ? selected.value : "")
  }

  const handleDateRangeFromChange = (_, date) => {
    const toDate = (moment(date).isAfter(datePeriod.customDateRangeTo, "day")) ? null : undefined;

    onDateRangeChange(date, toDate)
  }

  const handleDateRangeToChange = (_, date) => {
    const fromDate = (moment(date).isBefore(datePeriod.customDateRangeFrom, "day")) ? null : undefined;

    onDateRangeChange(fromDate, date)
  }

  return (
    <div onFocus={onFocus}>
      <div className="tw-mb-2">
        <Select
          ref={selectPeriodRef}
          className="collection-select__select-container"
          classNamePrefix="collection-select"
          id={`${name}_period`}
          name={`${name}_period`}
          value={formatOption(datePeriod.period)}
          controlShouldRenderValue={true}
          isSearchable={false}
          autoFocus={false}
          components={{ SingleValue, DropdownIndicator, Option }}
          styles={{ menu: base => ({ ...base, maxHeight: 288 }), menuList: base => ({ ...base, maxHeight: 286 }) }}
          options={selectablePeriodOptions.map(periodOption => formatOption(periodOption))}
          onChange={handlePeriodChange}
          onFocus={() => selectPeriodRef.current.select.getNextFocusedOption = () => null}
        />
      </div>
      {isFrequencyIntervalPickerVisible && (
        <div className="tw-flex">
          <div className="tw-w-20 tw-mr-2">
            <input
              className={`field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${inputClassName}`}
              type="text"
              id={`${name}_frequency`}
              name={`${name}_frequency`}
              value={datePeriod[`${datePeriod.period}Frequency`]}
              onChange={handleFrequencyChange}
            />
          </div>
          <div className="tw-w-full">
            <Select
              ref={selectIntervalRef}
              className="collection-select__select-container"
              classNamePrefix="collection-select"
              id={`${name}_interval`}
              name={`${name}_interval`}
              value={formatOption(datePeriod[`${datePeriod.period}Interval`])}
              controlShouldRenderValue={true}
              isSearchable={false}
              autoFocus={false}
              components={{ SingleValue, DropdownIndicator, Option }}
              options={permittedIntervals.map(intervalOption => formatOption(intervalOption))}
              onChange={handleIntervalChange}
              onFocus={() => selectIntervalRef.current.select.getNextFocusedOption = () => null}
            />
          </div>
        </div>
      )}
      {isDatePickerVisible && (
        <div className="tw-w-36">
          <DatePicker
            id={`${name}_date`}
            name={`${name}_date`}
            value={datePeriod[datePeriod.period]}
            latestDate={dateProps.latestDate}
            fieldError={fieldError}
            onChange={(_, date) => onChange(datePeriod.period, date)}
          />
        </div>
      )}
      {isDatePickerFromToVisible && (
        <div className="tw-flex">
          <DatePicker
            id={`${name}_custom_date_range_from`}
            name={`${name}_custom_date_range_from`}
            value={datePeriod.customDateRangeFrom}
            latestDate={customDateRangeFromProps.latestDate}
            fieldError={fieldError}
            onChange={handleDateRangeFromChange}
          />
          <div className="tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-font-medium">
            to
          </div>
          <DatePicker
            id={`${name}_custom_date_range_to`}
            name={`${name}_custom_date_range_to`}
            value={datePeriod.customDateRangeTo}
            latestDate={customDateRangeToProps.latestDate}
            fieldError={fieldError}
            onChange={handleDateRangeToChange}
          />
        </div>
      )}
    </div>
  )
}

DatePeriod.propTypes = {
  name: PropTypes.string.isRequired,
  datePeriod: PropTypes.object.isRequired,
  periodOptions: PropTypes.array,
  fieldError: PropTypes.object,
  beforeProps: PropTypes.shape({ latestDate: PropTypes.instanceOf(Date) }),
  afterProps: PropTypes.shape({ latestDate: PropTypes.instanceOf(Date) }),
  onExactDateProps: PropTypes.shape({ latestDate: PropTypes.instanceOf(Date) }),
  customDateRangeFromProps: PropTypes.shape({ latestDate: PropTypes.instanceOf(Date) }),
  customDateRangeToProps: PropTypes.shape({ latestDate: PropTypes.instanceOf(Date) }),
  onChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired
};
