import { useState, useEffect } from 'react';

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const resetDebouncedValue = () => {
    setDebouncedValue({ ...debouncedValue, value: null })
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay);

    return () => { clearTimeout(handler) }
  }, [value, delay])

  return [
    debouncedValue,
    resetDebouncedValue
  ]
}
