import HandshqApp from '../handshq-app';

HandshqApp.recurlyService = {
  setup: function() {
    if (typeof(recurly) !== 'undefined') { HandshqApp.configureRecurly() }
    if ($('.payment-form').length) { HandshqApp.configurePayment() }
    if ($('.security-check-form').length === 1) { HandshqApp.authenticatePayment() }
  }
}

$(function() { HandshqApp.recurlyService.setup() });
