import React from 'react';
import PropTypes from 'prop-types';
import TrainingStatusChart from 'components/personnel/TrainingStatusChart';
import TrainingStatusFilter from 'components/personnel/TrainingStatusFilter';
import PersonnelTypeFilters from 'components/personnel/PersonnelTypeFilters';
import PersonnelTeamSelect from 'components/personnel/PersonnelTeamSelect';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

export default function PersonnelFilter({
  scopedCount,
  totalCount,
  metaData,
  trainingStatusFilters,
  personnelTypeFilters,
  teamFilter,
  isDisabled,
}) {
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  const personnelCountBox = currentActor.isAllowedFeature('training_register') ? <TrainingStatusChart statusCounts={metaData.trainingStatusCounts} scopedCount={scopedCount} totalCount={totalCount} /> :
    (<div className='filter-container__block tw-bg-grey-050'>
      <h3 className='filter-container__info tw-text-grey-900 tw-text-3xl tw-font-semibold tw-tracking-tighter'>{scopedCount}</h3>
      <p className='filter-container__sub-info'>of {totalCount} personnel</p>
    </div>)

  const containerStyle = trainingRegisterResourceManagementContext.hasTeamViewableAccess ? '--lg' : ''
  const containerTeamFilterStyle = trainingRegisterResourceManagementContext.hasTeamViewableAccess ? 'tw-mb-8' : ''

  return (
    <div className='col-sm-3 p-l-0'>
      <div className='filter-container tw-border-grey-100 tw-bg-white tw-pb-3'>
        <div className={`filter-container__header${containerStyle}`}>
          {trainingRegisterResourceManagementContext.hasTeamViewableAccess && (
            <div className={`${containerTeamFilterStyle}`}>
              <PersonnelTeamSelect filter={teamFilter.filter} onOptionChange={teamFilter.onChange} />
            </div>
          )}
          {personnelCountBox}
        </div>
        {currentActor.isAllowedFeature('training_register') && (
          <div className='filter-container__content'>
            <TrainingStatusFilter
              filters={trainingStatusFilters.filters}
              onChange={trainingStatusFilters.onChange}
              statusCounts={metaData.trainingStatusCounts}
              isDisabled={isDisabled}
            />
          </div>
        )}
        {trainingRegisterResourceManagementContext.hasSubcontractorPersonnelViewableAccess && (
          <div>
            {currentActor.isAllowedFeature('training_register') && <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0'></hr>}
            <div className='filter-container__content'>
              <PersonnelTypeFilters
                filters={personnelTypeFilters.filters}
                onChange={personnelTypeFilters.onChange}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

PersonnelFilter.propTypes = {
  scopedCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaData: PropTypes.object.isRequired,
  personnelTypeFilters: PropTypes.object.isRequired,
  trainingStatusFilters: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  teamFilter: PropTypes.object.isRequired
};

PersonnelFilter.defaultProps = {
  isDisabled: false
};
