import { useState, useEffect } from 'react';
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function useTrackedPersonnel() {
  const [usage, setUsage] = useState({ loaded: false, value: null });
  const currentActor = useCurrentActor();
  const allowance = currentActor.subscription.attributes.trackedPersonnelAllowance;

  useEffect(() => {
    if (allowance) {
      fetchUsage()
    } else {
      setUsage({ loaded: true, value: null })
    }
  }, [allowance])

  function fetchUsage() {
    axios
      .get(`/personnel?tracked=true&meta_only=true&account_personnel=true`)
      .then(response => {
        setUsage({
          value: response.data.meta.scopedCount,
          loaded: true
        })
      })
      .catch(console.error)
  }

  const hasReachedTrackedPersonnelUsageThreshold = (!!allowance) && (usage.loaded) && ((usage.value / allowance) >= 0.8)

  const hasReachedLimit = (!!allowance) && (usage.loaded) && ((usage.value >= allowance))

  return [
    usage,
    fetchUsage,
    hasReachedTrackedPersonnelUsageThreshold,
    hasReachedLimit
  ]
}
