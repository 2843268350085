import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useForm from 'components/hooks/useForm';
import useRequestError from 'components/hooks/useRequestError';
import { pick } from 'components/helpers/objects';
import { scrollToTop } from 'components/helpers/scrolling';

import EnableFormCard from 'components/identityProviders/EnableFormCard';
import EnforceFormCard from 'components/identityProviders/EnforceFormCard';
import Portal from 'components/application/Portal';
import Alert from 'components/application/Alert';
import RequestErrorMessage from 'components/application/RequestErrorMessage';

const initialIdentityProvider = {
  enabled: false,
  enforced: false,
  loaded: false
};

const initialFormValues = {
  enabled: false,
  name: '',
  targetUrl: '',
  certificate: '',
  enforced: false
};

export default function SingleSignOnPage(props) {
  const {
    isSamlSsoEnforceable,
    domainNames,
    serviceProviderMetadataUrl,
    assertionConsumerServiceUrl,
    identityProviderId,
    identityProviderNames
  } = props;

  const bodyRef = useRef(document.body);

  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [identityProvider, setIdentityProvider] = useState(initialIdentityProvider);
  const [formValues, setFormValues, handleInputChange] = useForm(initialFormValues);
  const [requestError, , removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();

  const resetAlerts = () => {
    resetRequestError()
    setIsRequestSuccess(false)
  }

  const onNameChange = (selected) => {
    const formValuesSource = selected.value == identityProvider.name ? identityProvider : initialFormValues;
    const newFormValues = pick(formValuesSource, 'targetUrl', 'certificate');

    setFormValues(prevState => ({ ...prevState, ...newFormValues, name: selected.value }))
  }

  const onSuccess = () => {
    setIsRequestSuccess(true)
    scrollToTop(bodyRef)
  }

  const onError = (error) => {
    handleRequestError(error)
    scrollToTop(bodyRef)
  }

  const refreshIdentityProvider = (attributes) => {
    setIdentityProvider({ ...attributes, loaded: true })
  }

  const refreshForm = (attributes, ...keys) => {
    const newFormValues = pick(attributes, ...keys);

    setFormValues(prevState => ({ ...prevState, ...newFormValues }))
  }

  const onEnableFormSubmit = () => {
    resetAlerts()

    const params = { identity_provider: { enabled: formValues.enabled, name: formValues.name, target_url: formValues.targetUrl, certificate: formValues.certificate } };

    axios
      .patch(`/identity_providers/${identityProviderId}`, params)
      .then(response => {
        const attributes = response.data.data.attributes;

        refreshForm(attributes, 'enabled', 'name', 'targetUrl', 'certificate')
        refreshIdentityProvider(attributes)
        onSuccess()
      })
      .catch(onError)
  }

  const onEnforceFormSubmit = () => {
    resetAlerts()

    const params = { identity_provider: { enforced: formValues.enforced } };

    axios
      .patch(`/identity_providers/${identityProviderId}`, params)
      .then(response => {
        const attributes = response.data.data.attributes;

        refreshForm(attributes, 'enforced')
        refreshIdentityProvider(attributes)
        onSuccess()
      })
      .catch(onError)
  }

  const fetchIdentityProvider = () => {
    axios
      .get(`/identity_providers/${identityProviderId}`)
      .then(response => {
        const attributes = response.data.data.attributes;

        refreshForm(attributes, 'enabled', 'name', 'targetUrl', 'certificate', 'enforced')
        refreshIdentityProvider(attributes)
      })
      .catch(onError)
  }

  useEffect(() => {
    fetchIdentityProvider()
  }, [])

  return (
    <>
      {identityProvider.loaded ? (
        <>
          <EnableFormCard
            domainNames={domainNames}
            serviceProviderMetadataUrl={serviceProviderMetadataUrl}
            assertionConsumerServiceUrl={assertionConsumerServiceUrl}
            identityProviderNames={identityProviderNames}
            formValues={formValues}
            isIdentityProviderEnforced={identityProvider.enforced}
            requestError={requestError}
            removeErrorStyling={removeErrorStyling}
            onInputChange={handleInputChange}
            onNameChange={onNameChange}
            onSubmit={onEnableFormSubmit}
          />
          <div className='m-b-24'></div>
          <EnforceFormCard
            formValues={formValues}
            isUpgradeRequired={!isSamlSsoEnforceable}
            isIdentityProviderEnabled={identityProvider.enabled}
            onInputChange={handleInputChange}
            onSubmit={onEnforceFormSubmit}
          />
        </>
      ) : null}
      <Portal containerSelector='#single-sign-on-page-alert'>
        <>
          {isRequestSuccess && (
            <div className='m-b-24'>
              <Alert type='success'>Single sign-on settings have been updated.</Alert>
            </div>
          )}
          {(requestError.isFallback || Object.keys(requestError.validationErrors).length > 0) && (
            <div className='m-b-24'>
              <Alert type='danger'>
                <RequestErrorMessage
                  isFallback={requestError.isFallback}
                  validationErrors={requestError.validationErrors}
                />
              </Alert>
            </div>
          )}
        </>
      </Portal>
    </>
  )
}

SingleSignOnPage.propTypes = {
  isSamlSsoEnforceable: PropTypes.bool.isRequired,
  domainNames: PropTypes.string.isRequired,
  serviceProviderMetadataUrl: PropTypes.string.isRequired,
  assertionConsumerServiceUrl: PropTypes.string.isRequired,
  identityProviderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  identityProviderNames: PropTypes.arrayOf(PropTypes.string).isRequired
};
