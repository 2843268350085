import React from 'react';
import StatusIndicator from 'components/application/indicators/StatusIndicator';
import Tooltip from 'components/application/Tooltip';

export default function TrainingStatus(props) {
  const {
    currentTrainingStatus,
    isRequired,
    requiredSource
  } = props;

  const trainingStatusOptions = {
    'valid': {
      status: 'low',
      tooltipText: `Training is up to date${isRequired ? '' : ' but not required'}`
    },
    'expiryDateMissing': {
      status: props.isRequired ? 'high' : 'grey',
      tooltipText: `Expiry date is missing${props.isRequired ? '' : ' but not required'}`
    },
    'expiring': {
      status: isRequired ? 'medium' : 'low',
      tooltipText: `Training is expiring soon${isRequired ? '' : ' but not required'}${requiredSource == 'UserCourse' ? ' and required by this individual' : ''}`
    },
    'expired': {
      status: isRequired ? 'high' : 'grey',
      tooltipText: `Training is expired${isRequired ? '' : ' but not required'}${requiredSource == 'UserCourse' ? ' and required by this individual' : ''}`
    },
    'invalid': {
      status: isRequired ? 'high' : 'grey',
      tooltipText: 'Expiry date not set'
    },
    'missing': {
      status: isRequired ? 'high' : 'grey',
      tooltipText: `Training is missing${isRequired ? '' : ' but not required'}${requiredSource == 'UserCourse' ? ' and required by this individual' : ''}`
    },
    'archived': {
      status: 'hollow',
      tooltipText: 'Personnel is archived'
    }
  };

  return (
    <div className='tooltip-parent flex'>
      <StatusIndicator status={trainingStatusOptions[currentTrainingStatus]['status']} />
      <Tooltip placement='top' trigger='hover' tooltip={trainingStatusOptions[currentTrainingStatus]['tooltipText']} />
    </div>
  )
}
