import React from "react";
import PropTypes from "prop-types";

const statusClassName = {
  high: 'tw-bg-red-500',
  medium: 'tw-bg-amber-400',
  low: 'tw-bg-green-400',
  grey: 'tw-bg-grey-300',
  hollow: 'tw-bg-white tw-border-2 tw-border-solid tw-border-grey-300'
};

export default function StatusIndicator({ status }) {
  return (
    <div
      className={`tw-inline-flex tw-h-2.5 tw-w-2.5 tw-rounded-full ${statusClassName[status] || ''}`}
    />
  )
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired
}
