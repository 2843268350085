import React from 'react';
import { asDate } from 'components/helpers/dates'
import Tooltip from 'components/application/Tooltip';
import OpenedIcon from '-!svg-react-loader?name=OpenedIcon!icons/ic-email-opened.svg';
import ReceivedIcon from '-!svg-react-loader?name=OpenedIcon!icons/ic-email-received.svg';
import SentIcon from '-!svg-react-loader?name=OpenedIcon!icons/ic-email-sent.svg';
import ErrorIcon from '-!svg-react-loader?name=OpenedIcon!icons/ic-email-error.svg';

export default function DispatchRow({dispatch}) {

  const {
    status,
    statusUpdatedAt,
    email
  } = dispatch.attributes

  const date = statusUpdatedAt ? asDate(statusUpdatedAt) : ''

  const dispatchStatus = {
    "opened": {icon: OpenedIcon, tooltip: 'Opened', description: `opened the email ${date}`},
    "delivered": {icon: ReceivedIcon, tooltip: 'Received', description: `received the email ${date}`},
    "created": {icon: SentIcon, tooltip: 'Processing', description: `hasn't received the email (processing email)`},
    "processed": {icon: SentIcon, tooltip: 'Processing', description: `hasn't received the email (processing email)`},
    "deferred": {icon: SentIcon, tooltip: 'Trying to resend', description: `hasn't received the email (email failed, trying to resend)`},
    "bounced": {icon: ErrorIcon, tooltip: 'Not sent', description: `didn't receive the email (email could not be sent)`},
    "dropped": {icon: ErrorIcon, tooltip: 'Not sent', description: `didn't receive the email (email could not be sent)`},
    "spamreport": {icon: ErrorIcon, tooltip: 'Reported as spam', description: `marked this email as spam ${date}`},
    "failed": {icon: ErrorIcon, tooltip: 'Failed to send', description: 'was not sent the email (email failed to send)'}
  }[status]

  const DispatchIcon = dispatchStatus.icon

  return (
    <div className='handshq__cell-content--near-spaced m-t-8'>
      <Tooltip placement='top' trigger='hover' triggerProps={{ className: 'tooltip-trigger tw-flex' }} tooltip={dispatchStatus.tooltip}>
        <DispatchIcon height={16} width={24} />
      </Tooltip>
      <div className='m-l-8'>
        <span className='tw-font-medium'>{`${email}`}</span>
        {` ${dispatchStatus.description}`}
      </div>
    </div>
  )
}
