import voca from "voca";

const listFormatter = new Intl.ListFormat("en-GB", { style: "long", type: "conjunction" });

export function filtersListFormatter({ filters, ramsPluralName }) {
  const list = Object.keys(filters).reduce((acc, filter) => {
    if (filters[filter]) filter.split("And").forEach((subFilter) => acc.push(voca.lowerCase(subFilter)))

    return acc
  }, [])

  return `${voca.capitalize(listFormatter.format(list))} ${ramsPluralName}`;
}

export function filtersFormatter({ filters, filterCounts, filterDots }) {
  return Object.keys(filters).map((filter) => {
    return {
      count: filterCounts[filter],
      dot: filterDots[filter],
      label: voca.capitalize(voca.kebabCase(filter).replace(/-/g, " ")),
      name: filter,
      value: filters[filter]
    }
  })
}
