import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RoleTrainingTable from 'components/training/RoleTrainingTable';
import AdditionalTrainingBar from 'components/training/AdditionalTrainingBar';
import AdditionalTrainingTable from 'components/training/AdditionalTrainingTable';
import RoleTrainingBar from 'components/training/RoleTrainingBar';

export default function RoleTrainingTab(props) {
  const personnelRole = props.role || { id: 'blankRole', relationships: { courses: { data: [] } } };

  const tabCompanyRoleCourseIds = personnelRole.relationships.courses ? personnelRole.relationships.courses.data.map(companyRoleCourse => companyRoleCourse.id) : [];
  const allCompanyRoleCourseIds = props.roles.reduce((acc, role) => {
    const ids = role.relationships.courses.data.map(roleCourse => roleCourse.id);
    return [...acc, ...ids]
  }, []);
  const allUserCourseCourseIds = props.userCourses.map(userCourse => userCourse.relationships.course.data.id);

  const nonAssociatedTraining = props.training.filter(training => ![...allUserCourseCourseIds, ...allCompanyRoleCourseIds].includes(training.relationships.course.data.id));
  const nonAssociatedTrainingCourseIds = nonAssociatedTraining.map(training => training.relationships.course.data.id);

  const bookingCourseIds = props.currentBookings.map((booking) => booking.relationships.course.data.id);
  const tabCompanyRoleRequiredCourses = tabCompanyRoleCourseIds.map(id => props.courses[id]);
  const additionalCourses = [...new Set([...allUserCourseCourseIds, ...allCompanyRoleCourseIds, ...nonAssociatedTrainingCourseIds, ...bookingCourseIds])].filter((id) => !tabCompanyRoleCourseIds.includes(id)).map(id => props.courses[id]);
  const roleHasRequiredCourses = tabCompanyRoleRequiredCourses.length > 0;

  const courseCompanyRolesForRole = props.courseCompanyRoles.filter(ccr => ccr.relationships.companyRole.data.id == props.role.id);
  const filteredCourseCompanyRoles = courseCompanyRolesForRole.reduce((acc, ccr) => {
    acc[ccr.relationships.course.data.id] = ccr
    return acc
  }, {});

  return (
    <Fragment>
      <RoleTrainingBar
        roleHasRequiredCourses={roleHasRequiredCourses}
        name={props.currentPersonnel.firstName}
        role={props.role}
        isArchived={props.isArchived}
        courseTrainingStatus={props.courseTrainingStatus}
        onAddRole={props.onAddRole}
        personnelId={props.currentPersonnel.id}
        training={props.training}
        trainingEvidenceProcessed={props.trainingEvidenceProcessed}
        onTrainingEvidenceProcessed={props.onTrainingEvidenceProcessed}
      />
      {roleHasRequiredCourses && (
        <RoleTrainingTable
          hasTrainingEditableAccess={props.hasTrainingEditableAccess}
          currentPersonnel={props.currentPersonnel}
          courses={tabCompanyRoleRequiredCourses}
          autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
          autoEnrolmentExclusions={props.autoEnrolmentExclusions}
          eLearningCourses={props.eLearningCourses}
          eLearningAllowance={props.eLearningAllowance}
          userCourses={props.userCourses}
          courseCompanyRoles={filteredCourseCompanyRoles}
          training={props.training}
          bookers={props.bookers}
          currentBookings={props.currentBookings}
          currentRegistrations={props.currentRegistrations}
          attachments={props.attachments}
          creditInfoUnavailable={props.creditInfoUnavailable}
          onNewTrainingClick={props.onNewTrainingClick}
          onEditTrainingClick={props.onEditTrainingClick}
          onDeleteTrainingClick={props.onDeleteTrainingClick}
          onAddBooking={props.onAddBooking}
          onAddELearningBooking={props.onAddELearningBooking}
          onRemoveBooking={props.onRemoveBooking}
          onBookingReminder={props.onBookingReminder}
          setModalBoxData={props.setModalBoxData}
          personnelId={props.personnelId}
          onShowBooking={props.onShowBooking}
          createAutoEnrolmentExclusion={props.createAutoEnrolmentExclusion}
          deleteAutoEnrolmentExclusion={props.deleteAutoEnrolmentExclusion}
        />
      )}
      {((props.isArchived && additionalCourses.length > 0) || !props.isArchived) && (
        <div className='m-t-16'>
          <AdditionalTrainingBar
            roleHasRequiredCourses={roleHasRequiredCourses}
            additionalCoursesCount={additionalCourses.length}
            actionButtonsVisible={props.hasTrainingEditableAccess && !props.isArchived}
            roles={props.roles}
            training={props.training}
            courses={props.courses}
            userCourses={props.userCourses}
            coursesMeta={props.coursesMeta}
            onCourseSelectChange={props.onCourseSelectChange}
            onCourseSelectError={props.onCourseSelectError}
            personnelId={props.currentPersonnel.id}
          />
          {additionalCourses.length > 0 && (
            <AdditionalTrainingTable
              hasTrainingEditableAccess={props.hasTrainingEditableAccess}
              currentPersonnel={props.currentPersonnel}
              courses={additionalCourses}
              autoEnrolmentConfigurations={props.autoEnrolmentConfigurations}
              autoEnrolmentExclusions={props.autoEnrolmentExclusions}
              eLearningCourses={props.eLearningCourses}
              eLearningAllowance={props.eLearningAllowance}
              roles={props.roles}
              userCourses={props.userCourses}
              training={props.training}
              bookers={props.bookers}
              attachments={props.attachments}
              currentBookings={props.currentBookings}
              currentRegistrations={props.currentRegistrations}
              updateUserCourse={props.updateUserCourse}
              createUserCourse={props.createUserCourse}
              creditInfoUnavailable={props.creditInfoUnavailable}
              onNewTrainingClick={props.onNewTrainingClick}
              onEditTrainingClick={props.onEditTrainingClick}
              onAddBooking={props.onAddBooking}
              onAddELearningBooking={props.onAddELearningBooking}
              onRemoveBooking={props.onRemoveBooking}
              onBookingReminder={props.onBookingReminder}
              onDeleteTrainingClick={props.onDeleteTrainingClick}
              onDeleteCourseClick={props.onDeleteCourseClick}
              setModalBoxData={props.setModalBoxData}
              personnelId={props.personnelId}
              onShowBooking={props.onShowBooking}
              createAutoEnrolmentExclusion={props.createAutoEnrolmentExclusion}
              deleteAutoEnrolmentExclusion={props.deleteAutoEnrolmentExclusion}
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

RoleTrainingTab.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  currentBookings: PropTypes.array,
  currentRegistrations: PropTypes.array,
  training: PropTypes.array.isRequired,
  bookers: PropTypes.object,
  roles: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  role: PropTypes.object,
  courses: PropTypes.object,
  autoEnrolmentConfigurations: PropTypes.object,
  autoEnrolmentExclusions: PropTypes.object,
  eLearningCourses: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  courseCompanyRoles: PropTypes.array,
  userCourses: PropTypes.array,
  hasTrainingEditableAccess: PropTypes.bool,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminder: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  createAutoEnrolmentExclusion: PropTypes.func,
  deleteAutoEnrolmentExclusion: PropTypes.func
}

RoleTrainingTab.defaultProps = {
  userCourses: []
}
