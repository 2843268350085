import React, { useState } from 'react';

export default function ActionLink(props) {
  const [disabled, setDisabled] = useState(false)

  const {
    styleClasses,
    style,
    url,
    dataMethod,
    linkText,
    rel,
    performCheck,
    target,
    openUnsavedChangesModal,
    disableAfterClick
  } = props

  const disableMultipleClicks = (event) => {
    if (disabled) {
      event.preventDefault()
    } else if (!disabled) {
      setDisabled(true)
    }
  }

  const handleClick = (event) => {
    if (performCheck) {
      event.preventDefault()
      openUnsavedChangesModal(linkText)
    } else if (disableAfterClick) {
      disableMultipleClicks(event)
    }
  }

  return(
    <React.Fragment>
      <a
        className={styleClasses}
        style={style}
        href={url}
        rel={disabled || !rel ? null : rel}
        data-method={performCheck || disabled || !dataMethod ? null : dataMethod}
        data-disabled={disabled}
        onClick={handleClick}
        target={target}
      >
        { linkText }
      </a>
    </React.Fragment>
  )
}

ActionLink.defaultProps = {
  performCheck: null,
  rel: null,
  dataMethod: null,
  target: null,
  disableAfterClick: false
}
