// NB: not used due to issue with Options being in charge of onClick when being used as a fully fledged component

import React from 'react';
import Tooltip from 'components/application/Tooltip';
import OptionChoice from 'components/application/OptionChoice';

export default function OptionChoiceSignLatestVersion(props) {
  const reviewsRequestedForLatestVersion = (props.latestVersion && props.latestVersion.attributes.displayNumber) === (props.requestedVersion && props.requestedVersion.attributes.displayNumber);

  return reviewsRequestedForLatestVersion ? (
    <OptionChoice disabled={true}>
      <Tooltip placement='top' trigger='hover' tooltip='The latest version is currently being reviewed'>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Ask everyone to sign latest version</a>
      </Tooltip>
    </OptionChoice>
  ) : (
    <OptionChoice
      onClick={(setOptionsOpen) => {
        props.requestSignatures()
        setOptionsOpen(false)
      }}
    >
      <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto'>Ask everyone to sign latest version</a>
    </OptionChoice>
  )
}
