import React from 'react';
import PropTypes from 'prop-types';
import BlockList from 'components/application/BlockList';

export default function PersonnelSidePanelTeamsTab(props) {
  const {
    teams
  } = props;

  return (
    teams.length > 0 ? (
      <BlockList
        collection={teams}
        onItemDisplay={(team) => {
          return(
            <div className='block-list__item--flex-container'>
              <span className='truncated-text-container truncated-text-container--width-auto'>
                {`${team.attributes.name}`}
              </span>
            </div>
          )
        }}
      />) : (
        <div className='tw-py-6 tw-px-5 tw-text-grey-400'>
          Not a member of any teams
        </div>
      )
  )
}

PersonnelSidePanelTeamsTab.propTypes = {
  teams: PropTypes.array.isRequired
};
