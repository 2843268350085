import React from "react";
import PropTypes from "prop-types";

import CheckboxFields from "components/application/CheckboxFields";

export default function TrainingStatusFilter(props) {
  const {
    trainingStatusFilter,
    onChange
  } = props;

  return (
    <CheckboxFields
      checkboxes={[
        { name: "training_status_up_to_date", label: "Up to date", value: trainingStatusFilter.valid, onChange: (event) => onChange("valid", event.target.checked) },
        { name: "training_status_expiring_soon", label: "Expiring soon", value: trainingStatusFilter.expiring, onChange: (event) => onChange("expiring", event.target.checked) },
        { name: "training_status_expired", label: "Expired", value: trainingStatusFilter.expired, onChange: (event) => onChange("expired", event.target.checked) },
        { name: "training_status_missing", label: "Missing", value: trainingStatusFilter.missing, onChange: (event) => onChange("missing", event.target.checked) }
      ]}
    />
  )
}

TrainingStatusFilter.propTypes = {
  trainingStatusFilter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
