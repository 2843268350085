import HandshqApp from './handshq-app';
import MobileDetect from 'mobile-detect';
import SignaturePad from 'signature_pad/dist/signature_pad.min.js';
import EXIF from 'exif-js';
import moment from 'moment';

HandshqApp.DigitalSignature = function(options) {
  if (this instanceof HandshqApp.DigitalSignature) {
    const _this = this;
    const form = options['form'];
    const signatureCanvas = form.find('[data-select="signature-canvas"]');
    const pad = new SignaturePad(signatureCanvas[0]);
    const md = new MobileDetect(window.navigator.userAgent);
    const splash = options['splash'];
    const signInPerson = form.data('in-person');
    const signatureBody = form.find('[data-select="signature-body"]');
    const signatureImageBody = form.find('[data-select="signature-image-body"]');
    const signatureImageButton = form.find('[data-select="signature-image-button"]');
    const errorTryAgainButton = splash.find('a');
    let videoStream;
    let photoBase64;
    let isRecentPhoto;

    this.photoButton = options['photoButton'];
    this.submitButton = options['submitButton'];
    this.clearSignatureButton = options['clearSignatureButton'];
    this.signaturePlaceholder = options['signaturePlaceholder'];

    this.setup = function() {
      _this.windowChange();
      _this.resizeCanvas();
      _this.startSign();
      _this.finishSign();
      _this.clearSignature();
      _this.imageButtonSetup();
      _this.tryAgainButtonClick();
      _this.photoButtonClick();
      _this.inputFieldChange();
      _this.startVideoStream();
    };

    this.windowChange = function() {
      window.addEventListener('orientationchange', _this.resizeCanvas);
      window.addEventListener('resize', _this.resizeCanvas);
    };

    this.toggleVisibility = function(show, hide) {
      show.show();
      hide.hide();
    };

    let previousWidth;
    this.resizeCanvas = function() {
      const lines = pad.toData(); // Save the current signature

      signatureCanvas[0].height = signatureBody.height();
      signatureCanvas[0].width = signatureBody.width();

      const currentWidth = signatureBody.width();
      const scale = currentWidth / previousWidth;
      previousWidth = currentWidth;  // Prepare for next time
      // Scale the contents along with the width
      _this.rescaleSignature(lines, scale);
      // Redraw the signature
      pad.fromData(lines);
    };

    // Keeps the signature from warping when the canvas is resized
    this.rescaleSignature = (lines, scale) => {
      lines.forEach(line => {
        line.points.forEach(point => {
          point.x *= scale;
          point.y *= scale;
        });
      });
    };

    this.startSign = function(_event) {
      signatureCanvas.on('mousedown touchstart', function() {
        _this.enableSubmit((signInPerson && photoBase64) || !signInPerson);
        _this.clearSignatureButton.show();
        _this.clearSignatureButton.removeClass('tw-hidden');
        _this.signaturePlaceholder.addClass('tw-hidden');
      });
    };

    this.enableSubmit = function(condition) {
      if (condition) {
        _this.submitButton.removeAttr('disabled');
      }
    };

    this.finishSign = function() {
      _this.submitButton.on('click', function(event) {
        event.preventDefault();

        if (!pad.isEmpty()) {
          signatureCanvas.addClass('disabled');
          _this.clearSignatureButton.addClass('disabled');
          pad.off();
          _this.clearSignatureButton.off();
          _this.submitForm();
        }
      });
    };

    this.submitForm = function() {
      if (!signInPerson || isRecentPhoto) {
        _this.appendHiddenField({
          name: '[review][signature_attributes]attached_data_uri',
          value: pad.toDataURL()
        });

        if (photoBase64) {
          _this.appendHiddenField({
            name: '[review][signature_attributes][photo_attributes]attached_data_uri',
            value: photoBase64
          });
        }

        if (signInPerson) {
          _this.appendHiddenField({
            name: '[review]in_person',
            value: true
          });
        }

        form.submit();
      } else {
        _this.toggleVisibility(splash, form);
      }
    };

    this.appendHiddenField = function(args) {
      const field = $('<input>').attr({
        type: 'hidden',
        name: args['name'],
        value: args['value']
      });

      form.append(field);
    };

    this.clearSignature = function() {
      _this.clearSignatureButton.on('click', function(_event) {
        pad.clear();
        $(this).hide();

        _this.signaturePlaceholder.removeClass('tw-hidden');
        _this.submitButton.attr('disabled', 'disabled');
      });
    };

    this.imageButtonSetup = function() {
      if (!md.mobile()) {
        signatureImageButton.find('label, input').remove();
        signatureImageButton.find('a').removeClass('tw-hidden');
        signatureImageButton.find('a').addClass('tw-inline-flex');
      } else {
        signatureImageButton.find('a').remove();
        signatureImageButton.find('label').removeClass('tw-hidden');
        signatureImageButton.find('label').addClass('tw-inline-flex');
      }
    };

    this.tryAgainButtonClick = function() {
      errorTryAgainButton.on('click', function(_event) {
        _this.toggleVisibility(form, splash);
        _this.resetPhoto();
        signatureImageButton.find('input')[0].value = '';
      });
    };

    this.photoButtonClick = function() {
      $(_this.photoButton).on('click', () => {
        if ($(_this.photoButton).attr('data-state') === 'retake') {
          _this.retakePhoto();
        } else {
          _this.takePhoto();
        }
      });
    };

    this.inputFieldChange = function() {
      if (md.mobile()) {
        signatureImageButton.find('input').on('change', function(event) {
          const file = event.target.files[0];

          if (file) {
            const reader = new FileReader();
            const image = new Image();

            reader.onload = function(file) {
              image.onload = function() {
                _this.isRecentPhoto(image);

                _this.processImage({
                  img: this,
                  width: this.width,
                  height: this.height
                });

                _this.enableSubmit(!pad.isEmpty());
              }

              image.src = file.target.result;
            }

            reader.readAsDataURL(file);
          }
        });
      }
    };

    this.isRecentPhoto = function(image) {
      let dateTimeOriginal;
      const fiveMinutesAgo = moment().subtract(5, 'minutes');

      EXIF.getData(image, function() {
        dateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal');
      });

      isRecentPhoto = (dateTimeOriginal ? moment(dateTimeOriginal, 'YYYY:MM:DD hh:mm:ss').isAfter(fiveMinutesAgo) : true)
    };

    this.takePhoto = function() {
      if (!md.mobile()) {
        _this.processImage({
          img: signatureImageBody.find('video')[0],
          width: signatureImageBody.find('video')[0].videoWidth,
          height: signatureImageBody.find('video')[0].videoHeight
        });
        _this.enableSubmit(!pad.isEmpty());
        _this.stopVideoStream();
        isRecentPhoto = true;
      }

      _this.photoButton.attr('data-state', 'retake');
      _this.photoButton.find('span.text').text('Retake photo');
      _this.photoButton.find('span.camera-icon').hide();
      _this.photoButton.find('span.retake-icon').show();
    };

    this.processImage = function(args) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // size for image to be saved
      canvas.width = 260;
      canvas.height = 260;

      ctx.save();

      if (args['height'] > args['width']) { // Portrait
        // center vertically - create a square image with the width size, as it's the smaller edge
        const offsetHeight = (args['height'] - args['width']) / 2;
        // drawImage(img, sx, sy, swidth, sheight, x, y, width, height)
        ctx.drawImage(args['img'], 0, offsetHeight, args['width'], args['width'], 0, 0, canvas.width, canvas.height);
      } else { // Landscape
        // center horizontally - create a square image with the height size, as it's the smaller edge
        const offsetWidth = (args['width'] - args['height']) / 2;
        // drawImage(img, sx, sy, swidth, sheight, x, y, width, height)
        ctx.drawImage(args['img'], offsetWidth, 0, args['height'], args['height'], 0, 0, canvas.width, canvas.height);
      }

      ctx.restore();

      signatureImageBody.html('<img class="tw-w-full" src="' + canvas.toDataURL() + '" alt="Signature image">');
      photoBase64 = canvas.toDataURL();
    };

    this.retakePhoto = function() {
      if (!md.mobile()) {
        _this.resetPhoto();
        _this.startVideoStream();
      }
    };

    this.resetPhoto = function() {
      photoBase64 = null;
      _this.submitButton.attr('disabled', 'disabled');
      _this.photoButton.attr('data-state', 'take')
      _this.photoButton.find('span.text').text('Take photo');
      _this.photoButton.find('span.camera-icon').show();
      _this.photoButton.find('span.retake-icon').hide();
    };

    this.startVideoStream = function() {
      if (!md.mobile() && signInPerson) {
        if (navigator.mediaDevices === undefined) navigator.mediaDevices = {};

        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function(constraints) {
            const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            if (!getUserMedia) return Promise.reject(new Error('getUserMedia is not implemented in this browser'))

            return new Promise(function(resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject)
            })
          }
        }

        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(_this.startVideo).catch(_this.videoError)
      }
    };

    this.startVideo = function(stream) {
      videoStream = stream;
      const video = document.createElement('video');

      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream);
      }

      video.onloadedmetadata = function(_e) {
        video.play();
      };

      video.className = 'tw-h-full';

      signatureImageBody.html(video);

      _this.photoButton.removeAttr('disabled')
    };

    this.videoError = function(e) {
      console.log('Video errors: ' + e);
    };

    this.stopVideoStream = function() {
      videoStream.getVideoTracks()[0].stop();
    };
  } else {
    throw new Error('HandshqApp.DigitalSignature invoked without new');
  }
};

$(function() {
  const signaturePage = $("div[data-select='signature-page']");

  if (signaturePage.length) {
    signaturePage.each(function(_index, _el) {
      new HandshqApp.DigitalSignature({
        splash: $('[data-select="splash"]'), // use classes as they're used for CSS
        form: $("form[data-select='signature-form']"),
        photoButton: $("[data-select='signature-photo-button']"),
        submitButton: $("button[data-select='form-submit-button']"),
        clearSignatureButton: $('a[data-select="signature-clear-button"]'),
        signatureContainer: $('div[data-select="signature-container"]'),
        signaturePlaceholder: $('span[data-select="signature-placeholder"]'),
      }).setup();
    });
  }
});
