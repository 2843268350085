import React, { useState, useEffect } from 'react';
import ModalBox from 'components/application/ModalBox';
import PersonnelDetails from 'components/personnel/PersonnelDetails';
import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';
import { personDisplayName } from 'components/helpers/users';

export default function PersonnelModalBox(props) {
  const [isOpen, setIsOpen] = useState(false);

  const openModalBox = () => { setIsOpen(true) };
  const closeModalBox = () => { setIsOpen(false) };
  const _displayPersonnelFields = props.personnel.loaded && (props.personnel.member.attributes.email || props.personnel.includedLineManager || Object.keys(props.personnel.fieldValues).length > 0)

  const companyDisplayName = function() {
    const company = props.personnel.includedSubcontractor || props.personnel.includedCompany;

    return company ? company.attributes.name : ''
  };

  const displayRoles = function() {
    const roles =  props.personnel.includedRoles.map((role, index) => {
      return <li key={index}><span className='pill tw-bg-grey-050'>{role.attributes.position}</span></li>
    });

    return roles.length > 0 && <ul className='pill-list m-t-24'>{roles}</ul>
  };

  useEffect(() => {
    if (props.personnel.loaded) { openModalBox() }
  }, [props.personnel.loaded])

  return (
    <ModalBox mode='portrait' isOpen={isOpen} onClose={closeModalBox}>
      {
        props.personnel.loaded && (
          <React.Fragment>
            <div className='popup__header popup__header--sm popup__header--secondary tw-bg-mint-500'>
              <div className='popup__porthole popup__porthole--secondary tw-border-white tw-bg-mint-500 [&_svg_path]:tw-fill-white'><PersonIcon width={32} height={32}/></div>
            </div>
            <div className='popup__body'>
              <div className='popup__body-show--short'>
                <h2 className='popup__body-header truncated-text-container tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'>{personDisplayName(props.personnel.member.attributes)}</h2>
                <h3 className='popup__body-header popup__body-header--sub truncated-text-container tw-text-m tw-font-normal tw-tracking-auto'>{companyDisplayName()}</h3>
                <div className='popup__body-content p-t-0'>{displayRoles()}</div>
                <hr className='popup__body-divider'/>
                  {
                    <div>
                      <hr className='popup__body-divider'/>
                      <div className='popup__body-content popup__body-content--scroll'>
                      <PersonnelDetails
                        isCompact={true}
                        isAllVisible={true}
                        defaultDetails={[{label: 'Personnel ID', text: props.personnel.member.attributes.externalId }, {label: 'Email', text: props.personnel.member.attributes.email}, {label: 'Line manager', text: props.personnel.includedLineManager ? personDisplayName(props.personnel.includedLineManager.attributes) : null }]}
                        currentPersonnel={props.personnel}
                        availableFields={props.availableFields}
                        availableFieldOptions={props.availableFieldOptions}
                        availableLineManagers={[!!props.personnel.includedLineManager]}
                      />
                      </div>
                    </div>
                  }
                </div>
              </div>
          </React.Fragment>
        )
      }
    </ModalBox>
  )
}
