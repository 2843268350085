function sanitizeFilename(filename) {
  let extension, sanitizedFilename;
  const filenameExtensionSeparator = filename.lastIndexOf('.');

  if (filenameExtensionSeparator === -1) {
    sanitizedFilename = filename;
  } else {
    extension = filename.substr(filenameExtensionSeparator + 1);
    sanitizedFilename = filename.substr(0, filenameExtensionSeparator);
  }

  sanitizedFilename = sanitizedFilename.replace(/[^a-z0-9]/gi, '_').toLowerCase();

  return filenameExtensionSeparator === -1 ? sanitizedFilename : `${sanitizedFilename}.${extension}`
}

export {
  sanitizeFilename
}
