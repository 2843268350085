import React from 'react';
import PropTypes from 'prop-types';

import Table from 'components/application/Table';
import CourseRow from 'components/courses/CourseRow';

export default function CoursesTable({ courses, originalCourses, onRowClick }) {
  const formattedRows = courses.map((course) => {
    const originalCourseData = course.relationships.originalCourse.data;
    const originalCourse = originalCourseData ? originalCourses[originalCourseData.id] : null;

    return { course, originalCourse }
  });

  return (
    <Table
      headers={
        <tr>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide fw-66'>Status</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-45'>Course</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-10'>Method</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-10'>Provider</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-15'>Renewal frequency</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-10 tw-text-center'>Roles</th>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide w-10 tw-text-center'>Personnel</th>
        </tr>
      }
      rows={formattedRows.map(formattedRow =>
        <CourseRow
          key={formattedRow.course.id}
          course={formattedRow.course}
          originalCourse={formattedRow.originalCourse}
          onRowClick={onRowClick}
        />
      )}
    />
  )
}

CoursesTable.propTypes = {
  courses: PropTypes.array.isRequired,
  originalCourses: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired
}
