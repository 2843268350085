import React from 'react';
import PropTypes from 'prop-types';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import { formatOpeningHours, formatServiceAge } from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function MedicalLocationSelectItem({ assignableMedicalLocation, onSelect }) {
  const openingHours = formatOpeningHours({ hours: assignableMedicalLocation.attributes.openingHours });

  return(
    <div className='tw-flex tw-w-full tw-max-w-xl tw-bg-white tw-border-solid tw-border-grey-200 tw-border-1 tw-rounded-lg tw-p-4 tw-mt-2'>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-justify-between tw-w-2/4 tw-mr-5">
        <div>
          <p className="tw-font-bold tw-m-0 tw-text-grey-900 tw-text-l">{assignableMedicalLocation.attributes.name}</p>
          <p className="tw-m-0 tw-text-grey-700 tw-text-m">
            {!!assignableMedicalLocation.attributes.distance && `${parseFloat(assignableMedicalLocation.attributes.distance).toFixed(1)} miles`}
            {!!assignableMedicalLocation.attributes.distance && !!openingHours && "・"}
            {openingHours}
          </p>
        </div>
        {assignableMedicalLocation.attributes.source === 'nhs' && (
          <p className="tw-m-0 tw-text-grey-700 tw-text-s">
            This service is for: {formatServiceAge({ maxAge: assignableMedicalLocation.attributes.maxAge, minAge: assignableMedicalLocation.attributes.minAge })}
          </p>
        )}
      </div>

      <div className="tw-ml-2 tw-w-1/4">
        <div className="tw-text-grey-700 tw-text-s">
          {!!assignableMedicalLocation.attributes.address1 && <p className='tw-m-0'>{assignableMedicalLocation.attributes.address1},</p>}
          {!!assignableMedicalLocation.attributes.address2 && <p className='tw-m-0'>{assignableMedicalLocation.attributes.address2},</p>}
          <p className='tw-m-0'>
            {!!assignableMedicalLocation.attributes.city && `${assignableMedicalLocation.attributes.city}, `}{assignableMedicalLocation.attributes.postcode}
          </p>
        </div>
        {!!assignableMedicalLocation.attributes.phoneNumber && <div className="tw-mt-2 tw-text-s">{assignableMedicalLocation.attributes.phoneNumber}</div>}
      </div>

      <div className="tw-flex tw-justify-end tw-w-1/4">
        <OutlinedButton className="tw-justify-center tw-ml-2 tw-w-20" size="sm" color="grey" onClick={() => onSelect(assignableMedicalLocation.id)}>
          Select
        </OutlinedButton>
      </div>
    </div>
  );
}

MedicalLocationSelectItem.propTypes = {
  assignableMedicalLocation: resourceShape('assignableMedicalLocation').isRequired,
  onSelect: PropTypes.func.isRequired
};
