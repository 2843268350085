import { z } from "zod";

const resourceIdSchema = z.object({
  eq: z.string().optional(),
  in: z.array(z.string()).optional()
});

export const filterQueryStringSchema = z.object({
  divisionId: resourceIdSchema.optional(),
  roleId: resourceIdSchema.optional(),
  courseId: resourceIdSchema.optional(),
  lineManagerId: resourceIdSchema.optional(),
  teamId: resourceIdSchema.optional(),
});
