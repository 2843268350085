import { useState } from 'react';

export default function useModal(defaultOpenValue, defaultContext) {
  const [isModalOpen, setIsModalOpen] = useState(defaultOpenValue);
  const [modalContext, setModalContext] = useState(defaultContext);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const resetModalContext = () => setModalContext(defaultContext);

  return(
    [
      isModalOpen,
      setIsModalOpen,
      openModal,
      closeModal,
      resetModalContext,
      modalContext,
      setModalContext
    ]
  )
}
