// external
import React, { useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// shared
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import ModalBox from 'components/application/ModalBox';
import PendingBars from 'components/application/PendingBars';
import SelectionRow from 'components/application/SelectionRow';
import setupPolling from 'utilities/setupPolling';
import { useBreadBoard } from 'components/contexts/Toaster';
import { findRelationalHasManyRelations, resourcesAsDomainData } from 'components/helpers/storeHelpers';
// contextual
export const initialResourceState = resourcesAsDomainData(['company', 'project', 'folder'])
import locationSelectorReducer from 'reducers/domain/simpleCollectionReducer';
import { destinationShape } from '../../Shapes';
import * as ConfirmationModal from './Confirmation';
import * as procoreAPI from '../../requests'
import * as SelectionModal from './Selection';

const folderAncestorIds = ({ folders, startFolderID, folderID, ancestorCollection = [] }) => {
  const folder = folders.find((folderIteration) => folderIteration.id === folderID)
  const parent = folder.relationships.parent.data;
  if (parent) {
    if (folder.id !== startFolderID) { ancestorCollection.unshift(folder.id) }
    folderAncestorIds({ startFolderID, folderID: parent.id, folders, ancestorCollection })
  }
  return ancestorCollection
}

const blockerTooltip = {
  'pending': 'Loading...',
  'noProject': 'No Project has been selected',
  'noProjectPermission': 'You do not have the required permissions on Procore to upload files'
}

const defaultInteractions = {
  selectedCompanyID: null,
  selectedProjectID: null,
  selectedFolderID: null,
  traversedProjectID: null,
  traversedFolderIDs: []
}

const pollingInterval = 1000
const pollingMaxTime = 600000

export default function Modal({ isSelecting, onCancel, onSelection, seededLocation }) {
  const breadBoard = useBreadBoard();

  const bouncerPolling = useRef(null);
  const companiesPolling = useRef(null);
  const foldersPolling = useRef(null);
  const projectsPolling = useRef(null);

  const [interactions, setInteractions] = useState(defaultInteractions);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false)
  const [locationSelectorStore, dispatch] = useReducer(locationSelectorReducer, { domain: initialResourceState })
  const [projectPermissions, setProjectPermissions] = useState({})

  // Computed values
  const {
    selectedCompanyID,
    selectedProjectID,
    selectedFolderID,
    traversedProjectID,
    traversedFolderIDs
  } = interactions;

  //-- company resources
  const selectedCompany = selectedCompanyID && locationSelectorStore.domain.companyCollection.data[selectedCompanyID];

  //-- project resources
  const selectedProject = selectedProjectID && locationSelectorStore.domain.projectCollection.data[selectedProjectID];
  const traversedProject = traversedProjectID && locationSelectorStore.domain.projectCollection.data[traversedProjectID];

  //-- folder resources
  const selectedFolder = locationSelectorStore.domain.folderCollection.data[selectedFolderID] || (selectedProject && getSelectedProjectRootFolder())
  const traversedFolders = traversedFolderIDs.map((folderID) => locationSelectorStore.domain.folderCollection.data[folderID]);
  const latestTraversedFolder = traversedFolders.length > 0 && traversedFolders[traversedFolders.length - 1];

  //-- computed
  // TODO figure out safe navigation type behaviour
  const isCompanyProjectCollectionLoaded = !!(selectedCompany && selectedCompany.relationships && selectedCompany.relationships.projects && selectedCompany.relationships.projects.data);
  const isPending = isFetching || !isInitialised;
  const isSelectedProjectFolderCollectionLoaded = !!(selectedProject && selectedProject.relationships && selectedProject.relationships.folders && selectedProject.relationships.folders.data);

  const destinationBlocker = (() => {
    switch (true) {
      case isPending: return 'pending'
      case !selectedProject: return 'noProject'
      case !(projectPermissions[selectedProjectID]): return 'noProjectPermission'
    }
  })();

  const companyCount = locationSelectorStore.domain.companyCollection.allDataIds.length;

  const displayableCompanies = () => {
    return locationSelectorStore.domain.companyCollection.allDataIds.map(id => locationSelectorStore.domain.companyCollection.data[id]);
  }

  // SELECTORS
  const displayableProjects = () => {
    return isCompanyProjectCollectionLoaded ? findRelationalHasManyRelations(locationSelectorStore, selectedCompany, 'project') : []
  }

  const displayableFolders = () => {
    if (!isSelectedProjectFolderCollectionLoaded) { return [] }
    const parentFolder = latestTraversedFolder || findRelationalHasManyRelations(locationSelectorStore, selectedProject, 'folder').find((folder) => !folder.relationships.parent.data)
    return findRelationalHasManyRelations(locationSelectorStore, parentFolder, 'folder');
  };

  function getSelectedProjectRootFolder() {
    if (!selectedProject.relationships) return null;
    const rootRelationship = selectedProject.relationships.folders.data.find((folder) =>
      !locationSelectorStore.domain.folderCollection.data[folder.id].relationships.parent.data
    )
    return locationSelectorStore.domain.folderCollection.data[rootRelationship.id];
  }

  function fetchDestination() {
    return Promise.all([
      startCompaniesPolling(),
      startProjectsPolling({ companyID: seededLocation.companyID }),
      startFoldersPolling({ companyID: seededLocation.companyID, projectID: seededLocation.projectID })
    ])
  }

  const handlePollingResponseCheck = (response) => {
    return ['completed', 'failed'].includes(response.data.meta.request_status)
  }

  const startPolling = ({ polling, requestFn }) => {
    polling.current = setupPolling({
      requestFn,
      testFn: handlePollingResponseCheck,
      intervalTime: pollingInterval,
      maxTime: pollingMaxTime
    })

    return polling.current.startPolling()
  }

  const startBouncerPolling = () => {
    return startPolling({
      polling: bouncerPolling,
      requestFn: () => procoreAPI.fetchProcorePermissions({ companyID: selectedCompanyID, projectID: selectedProjectID })
    })
  }

  const startCompaniesPolling = () => {
    return startPolling({
      polling: companiesPolling,
      requestFn: () => procoreAPI.fetchProcoreCompanies()
    })
  }

  const startFoldersPolling = ({ companyID, projectID }) => {
    return startPolling({
      polling: foldersPolling,
      requestFn: () => procoreAPI.fetchProcoreDocuments({ companyID, projectID })
    })
  }

  const startProjectsPolling = ({ companyID }) => {
    return startPolling({
      polling: projectsPolling,
      requestFn: () => procoreAPI.fetchProcoreCompanyProjects({ companyID })
    })
  }

  // EVENT HANDLERS
  const handleCompanySelection = (companyID) => {
    setInteractions({
      ...interactions,
      selectedCompanyID: companyID, selectedProjectID: null, selectedFolderID: null,
      traversedProjectID: null, traversedFolderIDs: []
    })
  }

  const handleProjectSelection = (projectID) => setInteractions({ ...interactions, selectedProjectID: projectID })
  const handleFolderSelection = (folderID) => setInteractions({ ...interactions, selectedFolderID: folderID })
  const handleTraversalReset = () => handleCompanySelection(null)
  const handleProjectTraversal = (projectID) => setInteractions({ ...interactions, selectedProjectID: projectID, traversedProjectID: projectID, traversedFolderID: null, traversedFolderIDs: [] })
  const handleFolderTraversed = (folderID) => setInteractions({ ...interactions, traversedFolderIDs: [...interactions.traversedFolderIDs, folderID] })
  const handleFolderTraversalTruncation = (index) => setInteractions({ ...interactions, traversedFolderIDs: interactions.traversedFolderIDs.slice(0, index + 1) })
  const handleDestinationSelection = () => setIsConfirming(true)
  const handleConfirmationCancel = () => setIsConfirming(false)

  const handleDestinationConfirmation = () => {
    setIsConfirming(false)
    onSelection({ company: selectedCompany, project: selectedProject, folder: selectedFolder })
  }

  const handleDestinationFetched = (responses) => {
    const [companiesResponse, projectsResponse, foldersResponse] = responses;

    dispatchResourcesFetched([
      { resources: companiesResponse.data.data },
      { resourceType: 'projects', resources: projectsResponse.data.data, relationship: { name: 'projects', resource: { id: seededLocation.companyID, type: 'company' } } },
      { resourceType: 'folders', resources: foldersResponse.data.data, relationship: { name: 'folders', resource: { id: seededLocation.projectID, type: 'project' } } }
    ])

    setInteractions({
      ...interactions,
      selectedCompanyID: seededLocation.companyID,
      selectedFolderID: seededLocation.folderID,
      selectedProjectID: seededLocation.projectID,
      traversedFolderIDs: folderAncestorIds({ folderID: seededLocation.folderID, folders: foldersResponse.data.data, startFolderID: seededLocation.folderID }),
      traversedProjectID: seededLocation.projectID
    })

    // Note that this assumes that permissions check has been done as part of seed lookup
    setProjectPermissions({ [seededLocation.projectID]: true })
  }

  const dispatchResourcesFetched = (payload) => {
    dispatch({ type: 'RESOURCES_FETCHED', payload })
  }

  const endPolling = (polling) => {
    if (polling.current) {
      polling.current.endPolling()
      polling.current = null;
    }
  }

  const isResponseCompleted = (response) => {
    return response.data.meta.request_status === 'completed'
  }

  useEffect(() => {
    let fetchPromise;

    if (isSelecting && !isInitialised) {
      if (seededLocation) {
        fetchPromise = () => fetchDestination().then(handleDestinationFetched)
      } else {
        fetchPromise = () => (
          startCompaniesPolling()
            .then((response) => {
              if (isResponseCompleted(response)) {
                dispatchResourcesFetched({ resources: response.data.data })
              }
            })
        )
      }

      fetchPromise()
        .catch(breadBoard.addInedibleToast)
        .finally(() => setIsInitialised(true))
    }

  }, [isSelecting])

  // selected company if only one
  useEffect(() => {
    if (companyCount === 1 && !selectedCompanyID && isInitialised) {
      handleCompanySelection(locationSelectorStore.domain.companyCollection.allDataIds[0])
    }
  }, [companyCount, selectedCompanyID, isInitialised])

  // load projects if not loaded
  useEffect(() => {
    if (selectedCompanyID && !locationSelectorStore.domain.companyCollection.data[selectedCompanyID].relationships) {
      setIsFetching(true)

      startProjectsPolling({ companyID: selectedCompanyID })
        .then((response) => {
          if (isResponseCompleted(response)) {
            dispatchResourcesFetched({
              resourceType: 'projects', resources: response.data.data,
              relationship: { name: 'projects', resource: { id: selectedCompanyID, type: 'company' } }
            })
          }
        })
        .finally(() => setIsFetching(false))
    }
  }, [selectedCompanyID])

  // TODO verify if we want this behaviour - will force lookup with current approach of useEffect instead move to event?
  // useEffect(() => {
  //   // select first project
  //   if (!selectedProjectID && displayableProjects().length > 0) {
  //     setInteractions({...interactions, selectedProjectID: displayableProjects()[0].id })
  //   }
  // })

  // load folders if not loaded
  useEffect(() => {
    if (selectedProjectID && !locationSelectorStore.domain.projectCollection.data[selectedProjectID].relationships) {
      setIsFetching(true)

      Promise
        .all([
          startBouncerPolling(),
          startFoldersPolling({ companyID: selectedCompanyID, projectID: selectedProjectID })
        ])
        .then((responses) => {
          const [bouncerResponse, foldersResponse] = responses;

          if (isResponseCompleted(bouncerResponse)) {
            setProjectPermissions({ ...projectPermissions, [selectedProjectID]: bouncerResponse.data.data.attributes.canManageFolders })
          }

          if (isResponseCompleted(foldersResponse)) {
            dispatchResourcesFetched({
              resourceType: 'folders', resources: foldersResponse.data.data,
              relationship: { name: 'folders', resource: { id: selectedProjectID , type: 'project' } }
            })
          }
        })
        .finally(() => setIsFetching(false))
    }
  }, [selectedProjectID])

  useEffect(() => {
    return () => {
      endPolling(bouncerPolling)
      endPolling(companiesPolling)
      endPolling(foldersPolling)
      endPolling(projectsPolling)
    }
  }, [])

  function breadCrumbOptions() {
    const breadCrumbs = [
      {
        children: selectedCompany ? selectedCompany.attributes.name : !isPending ? 'Select company' : 'Loading...',
        onClick: selectedCompany ? () => { handleCompanySelection(selectedCompanyID) } : undefined,
      }
    ]
    if (traversedProject) {
      breadCrumbs.push({
        children: traversedProject.attributes.name,
        onClick: () => handleFolderTraversalTruncation(-1)
      })
    }
    traversedFolders.forEach((traversedFolder, i) => {
      breadCrumbs.push({
        children: traversedFolder.attributes.name,
        onClick: () => handleFolderTraversalTruncation(i)
      })
    })
    return breadCrumbs
  }

  //-- render options

  if (!isSelecting) return null;

  const selectionOptions = () => {
    switch (true) {
      case isPending:
        return <PendingBars />
      case !selectedCompany:
        return displayableCompanies().map((company) => {
          return (
            <SelectionRow
              key={`companySelectionOption--${company.id}`}
              content={company.attributes.name}
              showTraverse={true}
              isSelected={selectedCompanyID === company.id}
              onSelection={() => handleCompanySelection(company.id)}
            />
          )
        })
      case !traversedProject:
        return displayableProjects().map((project) => {
          return (
            <SelectionRow
              key={`projectSelectionOption--${project.id}`}
              content={project.attributes.name}
              showTraverse={true}
              isSelected={selectedProjectID === project.id}
              onSelection={() => handleProjectSelection(project.id)}
              onTraversal={() => handleProjectTraversal(project.id)}
            />
          )
        })
      default:
        return displayableFolders().map((folder) => {
          return (
            <SelectionRow
              key={`folderSelectionOption--${folder.id}`}
              content={folder.attributes.name}
              showTraverse={folder.relationships.folders.data.length > 0}
              isSelected={selectedFolderID === folder.id}
              onSelection={() => handleFolderSelection(folder.id)}
              onTraversal={() => handleFolderTraversed(folder.id)}
            />
          )
        })
      }
    }

  const modalContent = <div>
    {isConfirming ? (
      <ConfirmationModal.Content
        folderName={selectedFolder.attributes.name}
      />
    ) : (
      <SelectionModal.Content
        breadCrumbOptions={breadCrumbOptions()}
        selectionOptions={selectionOptions()}
        onTraversalReset={handleTraversalReset}
      />
    )}
  </div>

  const footer = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton
        size='md'
        color='blue'
        className='m-r-12'
        onClick={isConfirming ? handleConfirmationCancel : onCancel}
      >
        {isConfirming ? 'Back' : 'Cancel'}
      </TextButton>
      <FilledButton
        className={destinationBlocker ? 'tooltip-parent--disallowed' : ''}
        color='mint'
        disabled={!!destinationBlocker}
        onClick={destinationBlocker ? () => {} : (isConfirming ? handleDestinationConfirmation : handleDestinationSelection)}
      >
        {isConfirming ? 'Confirm' : (
          destinationBlocker ? (
            <Tooltip trigger='hover' placement='top' tooltip={blockerTooltip[destinationBlocker]}>Set as destination</Tooltip>
          ) : (
            "Set as destination"
          )
        )}
      </FilledButton>
    </div>
  )

  return (
    <ModalBox
      mode='flexible'
      isOpen={isSelecting}
      onClose={() => { }}
      customFooter={footer}
    >
      {modalContent}
    </ModalBox>
  )
}

Modal.propTypes = {
  isSelecting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  seededLocation: destinationShape
}
