import React from "react";
import PropTypes from "prop-types";

import NoDataIcon from "-!svg-react-loader?name=NoDataIcon!icons/nodata.svg";

export default function NoDataMessage({ message }) {
  return (
    <div className="tw-flex tw-flex-col tw-justify-end tw-items-center tw-absolute tw-right-0 tw-bottom-[86px] tw-left-0">
      <NoDataIcon width={64} height={41} className='[&_path]:tw-fill-grey-300 tw-mb-4' />
      <div className="tw-text-grey-700 tw-font-medium tw-mb-1">No data to display</div>
      <div className="tw-text-grey-500">{message}</div>
    </div>
  )
}

NoDataMessage.propTypes = {
  message: PropTypes.node.isRequired
};
