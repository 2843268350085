import React from 'react';
import PropTypes from 'prop-types';

const maxPageCount = 5;

export default function Paginator({
  currentPage,
  totalPages,
  onClick,
}) {
  function initialPage() {
    if (currentPage <= (Math.ceil(maxPageCount / 2)) + 1 ) {
      return 1;
    } else if (currentPage >= (totalPages - (maxPageCount / 2) )) {
      return Math.floor((totalPages - maxPageCount) + 1);
    } else {
      return (currentPage - Math.floor(maxPageCount / 2));
    }
  }

  function renderPages() {
    const initialPageNum = initialPage();
    const pageCount = Math.min(((initialPageNum + maxPageCount) - 1), totalPages);
    const pages = [];

    for (let pageNum = initialPageNum; pageNum <= pageCount; pageNum++) {
      const isCurrentPage = pageNum === currentPage;

      pages.push(isCurrentPage ? (
        <span key={pageNum} className='tw-my-0 tw-mx-[6px] tw-text-grey-025'>{pageNum}</span>
      ) : (
        <a key={pageNum} href='#0' className='tw-my-0 tw-mx-[6px] tw-text-blue-200 hover:tw-text-blue-100 focus:tw-text-blue-200' data-page={pageNum} onClick={onClick}>{pageNum}</a>
      ))
    }
    return pages
  }

  function renderStep(type) {
    const stepClassName = type === 'prev' ? 'tw-mr-6 after:tw-right-[-12px]' : 'tw-ml-6 after:tw-left-[-12px]';

    return (
      <a href='#0' className={`tw-relative tw-inline-flex tw-items-center tw-h-8 tw-p-[6px] tw-rounded-sm tw-text-blue-200 hover:tw-text-blue-100 focus:tw-text-blue-200 hover:tw-bg-grey-700 after:tw-absolute after:tw-content-[''] after:tw-bg-grey-500 after:tw-w-px after:tw-h-5 ${stepClassName}`} data-page={currentPage + (type === 'prev' ? -1 : +1)} onClick={onClick}>
        {type === 'prev' ? <React.Fragment>&lt;&nbsp;&nbsp;Previous</React.Fragment> : <React.Fragment>Next&nbsp;&nbsp;&gt;</React.Fragment>}
      </a>
    )
  }


  return (
    <nav className='tw-font-medium tw-bg-grey-900 tw-inline-flex tw-items-center tw-h-10 tw-p-1 tw-rounded-md'>
      {currentPage > 1 && renderStep('prev')}
      {renderPages()}
      {currentPage < totalPages && renderStep('next')}
    </nav>
  )
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}
