const addError = function(errorKey, payload) {
  return {
    type: 'ADD_ERROR',
    errorKey: errorKey,
    payload: payload
  }
}

const removeError = function(errorKey) {
  return {
    type: 'REMOVE_ERROR',
    errorKey: errorKey
  }
};

export {
  addError,
  removeError
}
