import ProjectTab from './project-tab';
import TabManagement from '../../shared/tab-management';

const ProjectsIndex = (function() {
  const tabs = [];

  function init() {
    addTabs();
    TabManagement.switchTabFromUrl();
    traversalEvents();
    exportProjects();
  }

  function addTabs() {
    $('#projects-index section.tab-pane').toArray().forEach(function(tab) {
      tabs.push(new ProjectTab(tab));
    });
  }

  function getTab(tabName) {
    return tabs.find(tab => tab.name === tabName);
  }

  function traversalEvents() {
    $('.project-tabs > li > a').on('click', function(tabNav) {
      const tab = getTab($(tabNav.currentTarget).data('tabName'));
      tab.notifyTraversal();
    });
  }

  function exportProjects() {
    const export_url = $("#projects-index").data('export-url');

    if (export_url) { window.location.href = export_url }
  }

  function projectRestored(projectId) {
    tabs.forEach(function(tab) {
      const row = tab.getRow(projectId);
      if (row) {
        row.el.find('.regular-actions .other-options').removeClass('blink');
        $('.dropdown__content').hide();
        row.restored();
      }
    });
  }

  function disableDuplication() {
    $( '.duplicate' ).on('click', function(e) {
      e.stopPropagation();
      e.preventDefault();
    });
  }

  function pollForDuplication(jobId) {
    new HandshqApp.Polling({
      url: "/jobs/status/" + jobId,
      responseCheck: function(response) { return response.status == "completed" },
      successCallback: function(response) {
        window.location.href = '/projects/' + response.duplicated_project_id + '/edit';
      },
      failureCallback: function(_response) {  }
    }).start();
  }

  function visualiseDuplication(pendingHTML) {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $('.projects table').prepend(pendingHTML);
  }

  function projectDuplicated(opts) {
    disableDuplication();
    pollForDuplication(opts.jobId);
    visualiseDuplication(opts.pendingHTML);
  }

  return {
    init: init,
    getTab: getTab,
    projectRestored: projectRestored,
    tabs: tabs,
    projectDuplicated: projectDuplicated
  }
}());

$(function() {
  if ($("#projects-index").length) {
    ProjectsIndex.init();
  };
});

window.ProjectsIndex = ProjectsIndex;
