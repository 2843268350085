import React from 'react';
import PropTypes from 'prop-types';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function FormFooter(props) {

  return (
    <div className='popup__footer-actions--flex transition-enabled tw-border-t-grey-200 tw-justify-end'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={props.onCancel}>
        Cancel
      </TextButton>
      <FilledButton color='mint' disabled={props.submitButtonDisabled} onClick={props.onSubmit}>
        {props.text}
      </FilledButton>
    </div>
  )
}

FormFooter.propTypes = {
  text: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}
