const modalTransition = (function() {
  function hideCurrentModal() {
    return currentActiveModal().modal('hide');
  }

  function isModalActive() {
    return currentActiveModal().length > 0
  }

  function currentActiveModal() {
    return $('.modal:visible');
  }

  function hideAndPerform(followingAction) {
    if (!isModalActive()) {
      followingAction();
    } else {
      hideCurrentModal();
      // ensures modal backdrop is cleaned
      setTimeout(function() {
        $('.modal-backdrop').remove();
        followingAction();
      }, 250 );
    }
  }

  return {
    hideAndPerform: hideAndPerform
  }
}());

window.modalTransition = modalTransition;
