import { initOptions } from 'helpers/tinymce';

import 'style-loader!tinymce/skins/handshq/skin.min.css';

function setupCustomFieldRichTextEditor({ selector, useSpellChecker }) {
  setupRichTextEditor({ selector, toolbar: 'bold italic | bullist | forecolor | table', useSpellChecker })
};

function setupMethodStepRichTextEditor({ selector, useSpellChecker }) {
  setupRichTextEditor({
    additionalContentStyleString: '.mce-content-body p:has(+ ol), .mce-content-body p:has(+ ul), .mce-content-body p + ol, .mce-content-body p + ul { margin: 0; }',
    selector,
    toolbar: 'bold italic underline | bullist numlist | colorpreset', useSpellChecker
  })
};

function setupMethodTextRichTextEditor({ selector, useSpellChecker }) {
  setupRichTextEditor({ selector, toolbar: 'bold italic underline | bullist numlist | colorpreset | table', useSpellChecker })
};

function setupRichTextEditor({
  additionalContentStyleString,
  autoFocus,
  height,
  inline,
  minHeight,
  onInit,
  onSetup,
  placeholder,
  selector,
  toolbar,
  useSpellChecker
}) {
  tinyMCE.init(
    initOptions({
      additionalContentStyleString,
      autoFocus,
      height,
      inline,
      minHeight,
      onInit,
      onSetup,
      placeholder,
      selector,
      toolbar,
      useSpellChecker
    })
  )
};

export {
  setupCustomFieldRichTextEditor,
  setupMethodStepRichTextEditor,
  setupMethodTextRichTextEditor,
  setupRichTextEditor
}
