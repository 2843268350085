import voca from 'voca'
import { paramsForResourcesDeletions } from 'components/helpers/resources/shared'

export const paramsForCreate = ({currentId, frontendCourseCompanyRoles, currentType}) => {
  const associateKey = currentType == 'company_role' ? 'course_id' : 'company_role_id';

  return frontendCourseCompanyRoles.map((courseCompanyRole) => {
    const formattedParam = { required: courseCompanyRole.required };
    formattedParam[associateKey] = courseCompanyRole[voca.camelCase(associateKey)];
    formattedParam[currentType + '_id'] = currentId;
    return formattedParam
  })
}

export const paramsForUpdate = ({currentId, frontendCourseCompanyRoles, serverCourseCompanyRoles, currentType}) => {
  const serverReadyParams = []
  const frontendCcrIds = []
  const associateType  = currentType == 'company_role' ? 'course' : 'companyRole';
  const associateTypeIdString = voca.camelCase(associateType + '_id');

  // iterate through courseCompanyRoles and update required if needed
  frontendCourseCompanyRoles.map((courseCompanyRole) => {
    const serverCcr = serverCourseCompanyRoles.find(ccr =>
      ccr.relationships[associateType].data.id == courseCompanyRole[associateTypeIdString]  && ccr.relationships[associateType].data.type == associateType
    );
    if(serverCcr) {
      // update CCR
      frontendCcrIds.push(serverCcr.id) //get the CCR id from the server data

      // update required if different
      if(courseCompanyRole.required != serverCcr.attributes.required){
        serverReadyParams.push({ id: serverCcr.id, required: courseCompanyRole.required })
      }
    }
    else {
      // new CCR
      const formattedParam = { required: courseCompanyRole.required }
      formattedParam[voca.snakeCase(associateType) + '_id'] = courseCompanyRole[associateTypeIdString];
      formattedParam[currentType + '_id'] = currentId;
      serverReadyParams.push(formattedParam)
    }
  })

  const serverCcrIds = serverCourseCompanyRoles ? serverCourseCompanyRoles.map(ele => ele.id) : [];
  const deleteParams = paramsForResourcesDeletions({serverSideIds: serverCcrIds, frontendSideIds: frontendCcrIds})
  return [...serverReadyParams, ...deleteParams]
}
