import tailwindConfig from "stylesheets/tailwind.config.js";

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import qs from "qs";

import { BarChart, XAxis, YAxis, Bar } from "recharts";

import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useRamsAnalyticsDashboardContext } from "components/contexts/RamsAnalyticsDashboardContext";
import { useBreadBoard } from "components/contexts/Toaster";
import useFilters from "components/ramsAnalytics/hooks/useFilters";
import useKeyHighlight from "components/ramsAnalytics/hooks/useKeyHighlight";
import useProjectsModal from "components/ramsAnalytics/hooks/useProjectsModal";

import { formatPaginatedProjects } from "components/ramsAnalytics/helpers/projectsModal";
import { getNextPageParam } from "components/ramsAnalytics/helpers/reactQuery";

import Card from "components/ramsAnalytics/components/Card";
import ReportHeader from "components/ramsAnalytics/components/ReportHeader";
import CountDisplay from "components/ramsAnalytics/components/CountDisplay";
import ProjectsModal from "components/ramsAnalytics/components/ProjectsModal";
import NoDataMessage from "components/ramsAnalytics/components/NoDataMessage";

import HashedPatternGreen from "-!svg-react-loader?name=HashedPatternGreen!components/ramsAnalytics/patterns/hashed-pattern-green.svg";
import HashedPatternAmber from "-!svg-react-loader?name=HashedPatternAmber!components/ramsAnalytics/patterns/hashed-pattern-amber.svg";

const colours = tailwindConfig.theme.colors;

const initialBarFills = {
  live: colours.green[400],
  endingInNextSevenDays: "url(#hashed-pattern-green)",
  future: colours.amber[200],
  startingInNextSevenDays: "url(#hashed-pattern-amber)",
  endedInLastSevenDays: colours.grey[500],
  noDates: colours.grey[300]
};

const highlightedBarFills = {
  live: { live: colours.green[400], endingInNextSevenDays: colours.green[400] },
  endingInNextSevenDays: { endingInNextSevenDays: colours.green[500] },
  future: { future: colours.amber[200], startingInNextSevenDays: colours.amber[200] },
  startingInNextSevenDays: { startingInNextSevenDays: colours.amber[400] },
  endedInLastSevenDays: { endedInLastSevenDays: colours.grey[500] },
  noDates: { noDates: colours.grey[300] }
};

const barChartWidth = 514;

export const leftBarRadii = [4, 0, 0, 4];
export const middleBarRadii = [0, 0, 0, 0];
export const rightBarRadii = [0, 4, 4, 0];
export const onlyBarRadii = [4, 4, 4, 4];

const projectsModalAppliedFilters = {
  "Live": { live: true },
  "Ending in next 7 days": { live_and_ending_in_next_seven_days: true },
  "Ended in last 7 days": { ended_and_ended_in_last_seven_days: true },
  "Future": { future: true },
  "Starting in next 7 days": { future_and_starting_in_next_seven_days: true },
  "No dates": { no_dates: true }
};

export default function CurrentProjectsCard() {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const breadBoard = useBreadBoard();
  const { filters, handleFilterChange } = useFilters({ includeNoDates: true })
  const { projectsModal, openProjectsModal, closeProjectsModal } = useProjectsModal();

  const fetchCurrentProjectsReport = () => axios.post("/rams_analytics/current_projects_report");

  const {
    data: currentProjectsReport
  } = useQuery({
    queryKey: ["currentProjectsReport"],
    queryFn: fetchCurrentProjectsReport,
    onError: breadBoard.addInedibleToast
  });

  const counts = currentProjectsReport ? currentProjectsReport.data.data.attributes : {};

  const {
    liveWithoutSubsetCount,
    liveAndEndingInNextSevenDaysCount,
    futureWithoutSubsetCount,
    futureAndStartingInNextSevenDaysCount,
    endedAndEndedInLastSevenDaysCount,
    noStatusCount
  } = counts ? counts : {};

  const totalCount = Object.values(counts).reduce((a, b) => a + b, 0) - (filters.includeNoDates ? 0 : noStatusCount);

  const barGapTwoPixelEquivalent = totalCount / (barChartWidth / 2);

  const barGapCount = ({ previousBarCounts, remainingBarCounts }) => {
    return previousBarCounts.some(c => c > 0) && remainingBarCounts.some(c => c > 0) ? barGapTwoPixelEquivalent : 0
  }

  const noStatusBarCount = filters.includeNoDates ? noStatusCount : 0;

  const chartData = {
    live: liveWithoutSubsetCount,
    endingInNextSevenDays: liveAndEndingInNextSevenDaysCount,
    firstBarGap: barGapCount({
      previousBarCounts: [liveWithoutSubsetCount, liveAndEndingInNextSevenDaysCount],
      remainingBarCounts: [futureWithoutSubsetCount, futureAndStartingInNextSevenDaysCount, endedAndEndedInLastSevenDaysCount, noStatusBarCount]
    }),
    future: futureWithoutSubsetCount,
    startingInNextSevenDays: futureAndStartingInNextSevenDaysCount,
    secondBarGap: barGapCount({
      previousBarCounts: [futureWithoutSubsetCount, futureAndStartingInNextSevenDaysCount],
      remainingBarCounts: [endedAndEndedInLastSevenDaysCount, noStatusBarCount]
    }),
    endedInLastSevenDays: endedAndEndedInLastSevenDaysCount,
    thirdBarGap: barGapCount({
      previousBarCounts: [endedAndEndedInLastSevenDaysCount],
      remainingBarCounts: [noStatusBarCount]
    }),
    noDates: noStatusBarCount
  };

  const fetchProjects = (page) => {
    const queryString = qs.stringify({ filter: { ...projectsModal.filters }, page });

    return axios.get(`/rams_analytics/current_projects_report/projects?${queryString}`)
  }

  const {
    data: projects,
    fetchNextPage: handleFetchNextPageOfProjects,
    hasNextPage: hasNextPageOfProjects
  } = useInfiniteQuery({
    queryKey: ["projects", "currentProjectsReport", projectsModal.filters],
    queryFn: async ({ pageParam = 1 }) => {
      const projectsResponse = await fetchProjects(pageParam);
      return projectsResponse.data
    },
    select: formatPaginatedProjects,
    getNextPageParam: getNextPageParam,
    enabled: projectsModal.isOpen,
    onError: breadBoard.addInedibleToast
  });

  const handleTotalCountClick = () => {
    openProjectsModal({
      title: `Current ${ramsAnalyticsDashboardContext.ramsPluralName}: All`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: totalCount }),
      appliedFilters: { live: true, live_and_ending_in_next_seven_days: true, ended_and_ended_in_last_seven_days: true, future: true, future_and_starting_in_next_seven_days: true, no_dates: filters.includeNoDates }
    })

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Current projects",
      segment: "All"
    })
  }

  const handleProjectClick = ({ project }) => {
    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: project click", {
      chart: "Current projects",
      project: project.name
    })
  }

  return (
    <Card>
      <ReportHeader
        title={`Current ${ramsAnalyticsDashboardContext.ramsPluralName}`}
        totalCount={totalCount > 0 ? totalCount : null}
        onTotalCountClick={totalCount > 0 ? handleTotalCountClick : null}
        filters={[{
          name: "includeNoDates",
          label: "Include RAMS with no dates",
          value: filters.includeNoDates,
          count: noStatusCount
        }]}
        onFilterChange={handleFilterChange}
      />
      {currentProjectsReport && (
        totalCount > 0 ? (
          <ReportBody chartData={chartData} openProjectsModal={openProjectsModal} filters={filters} />
        ) : (
          <NoDataMessage message={`There are no current ${ramsAnalyticsDashboardContext.ramsPluralName}`} />
        )
      )}
      <ProjectsModal
        isOpen={projectsModal.isOpen}
        title={projectsModal.title}
        subTitle={projectsModal.subTitle}
        projects={projects}
        hasNextPage={hasNextPageOfProjects}
        closeModal={closeProjectsModal}
        onFetchNextPage={handleFetchNextPageOfProjects}
        onProjectClick={handleProjectClick}
      />
    </Card>
  )
}

const staticEntryMap = {
  live: { name: "Live" },
  endingInNextSevenDays: { name: "Ending in next 7 days" },
  endedInLastSevenDays: { name: "Ended in last 7 days" },
  future: { name: "Future" },
  startingInNextSevenDays: { name: "Starting in next 7 days" },
  noDates: { name: "No dates" }
};

function ReportBody({ chartData, openProjectsModal, filters }) {
  const ramsAnalyticsDashboardContext = useRamsAnalyticsDashboardContext();
  const { highlightedKey, handleKeyHighlight, handleKeyUnhighlight } = useKeyHighlight(null);

  const { startCountKey, endCountKey } = (() => {
    const nonZeroCountKeys = Object.keys(chartData).filter((countKey) => chartData[countKey] && !countKey.endsWith("Gap"));
    const startCountKey = nonZeroCountKeys[0];
    const endCountKey = nonZeroCountKeys[nonZeroCountKeys.length - 1];

    return { startCountKey, endCountKey }
  })();

  const barFill = (countKey) => {
    if (highlightedKey) {
      return highlightedBarFills[highlightedKey][countKey] || colours.grey[100]
    } else {
      return initialBarFills[countKey]
    }
  };

  const barRadius = (countKey) => {
    if (countKey === startCountKey && countKey === endCountKey) {
      return onlyBarRadii
    } else if (countKey === startCountKey) {
      return leftBarRadii
    } else if (countKey === endCountKey) {
      return rightBarRadii
    } else {
      return middleBarRadii
    }
  };

  const keyHighlightProps = {
    highlightedKey,
    onKeyHighlight: handleKeyHighlight,
    onKeyUnhighlight: handleKeyUnhighlight
  };

  const dynamicEntryMap = {
    live: { count: chartData.live + chartData.endingInNextSevenDays },
    endingInNextSevenDays: { count: chartData.endingInNextSevenDays },
    endedInLastSevenDays: { count: chartData.endedInLastSevenDays },
    future: { count: chartData.future + chartData.startingInNextSevenDays },
    startingInNextSevenDays: { count: chartData.startingInNextSevenDays },
    noDates: { count: chartData.noDates }
  };

  const entryMap = _.merge(staticEntryMap, dynamicEntryMap);

  const handleSegmentClick = ({ entry }) => {
    openProjectsModal({
      title: `Current ${ramsAnalyticsDashboardContext.ramsPluralName}: ${entry.name}`,
      subTitle: ramsAnalyticsDashboardContext.pluralizeRamsCount({ count: entry.count }),
      appliedFilters: projectsModalAppliedFilters[entry.name]
    })

    ramsAnalyticsDashboardContext.sendAnalytics("RAMS analytics: segment click", {
      chart: "Current projects",
      segment: entry.name
    })
  }

  return (
    <div className="tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-pr-6 tw-pb-6 tw-pl-6">
      <HashedPatternGreen />
      <HashedPatternAmber />
      <div className="tw-mb-4">
        <BarChart
          data={[chartData]}
          layout="vertical"
          width={barChartWidth}
          height={80}
          barSize={72}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          onMouseLeave={handleKeyUnhighlight}
        >
          <XAxis type="number" domain={[0, "dataMax"]} hide />
          <YAxis type="category" domain={[0, "dataMax"]} dataKey="none" hide />
          {Object.keys(chartData).map((countKey) => {
            if (countKey.endsWith("Gap")) {
              return <Bar key={countKey} dataKey={countKey} stackId="a" fill="transparent" />
            } else {
              return <Bar key={countKey} dataKey={countKey} stackId="a" cursor="pointer" fill={barFill(countKey)} radius={barRadius(countKey)} onMouseEnter={() => handleKeyHighlight(countKey)} onClick={() => handleSegmentClick({ entry: entryMap[countKey] })} />
            }
          })}
        </BarChart>
      </div>
      <div className="tw-flex tw-justify-between" onMouseLeave={handleKeyUnhighlight}>
        <div className="tw-w-[254px]">
          <CountDisplay countKey="live" dotClassName="tw-bg-green-400" {...entryMap["live"]} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["live"] })} />
          <CountDisplay countKey="endingInNextSevenDays" dotClassName="tw-bg-green-500" {...entryMap["endingInNextSevenDays"]} isSub={true} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["endingInNextSevenDays"] })} />
          <CountDisplay countKey="endedInLastSevenDays" dotClassName="tw-bg-grey-500" {...entryMap["endedInLastSevenDays"]} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["endedInLastSevenDays"] })} />
        </div>
        <div className="tw-w-[254px]">
          <CountDisplay countKey="future" dotClassName="tw-bg-amber-200" {...entryMap["future"]} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["future"] })} />
          <CountDisplay countKey="startingInNextSevenDays" dotClassName="tw-bg-amber-400" {...entryMap["startingInNextSevenDays"]} isSub={true} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["startingInNextSevenDays"] })} />
          {filters.includeNoDates && (
            <CountDisplay countKey="noDates" dotClassName="tw-bg-transparent tw-border-2 tw-border-solid tw-border-grey-300" {...entryMap["noDates"]} {...keyHighlightProps} onClick={() => handleSegmentClick({ entry: entryMap["noDates"] })} />
          )}
        </div>
      </div>
    </div>
  )
}

ReportBody.propTypes = {
  chartData: PropTypes.object.isRequired,
  openProjectsModal: PropTypes.func.isRequired
};
