import React from "react";
import PropTypes from "prop-types";

import CheckboxFields from "components/application/CheckboxFields";

export default function CheckboxFilter({ label, checkboxes, onRemove }) {
  return (
    <div className="tw-border-1 tw-border-solid tw-rounded-sm tw-border-grey-100 tw-bg-white tw-p-5">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <label className="tw-font-medium tw-m-0">{label}</label>
        <div className="tw-flex tw-items-center tw-border-0 tw-rounded-lg tw-cursor-pointer tw-h-5 tw-px-2 tw-text-grey-500 tw-bg-white hover:tw-text-red-500 hover:tw-bg-red-025" onClick={onRemove}>
          <span className="tw-text-s tw-font-medium tw-tracking-wide">Remove filter</span>
        </div>
      </div>
      <CheckboxFields checkboxes={checkboxes} />
    </div>
  )
}

CheckboxFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checkboxes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  })),
  onRemove: PropTypes.func.isRequired
};
