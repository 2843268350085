import React, { Fragment, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { SizeMe } from 'react-sizeme';

import { useStepper } from 'components/hooks/useStepper';

import Collection from 'components/attachments/Collection';
import Previewer from 'components/attachments/Previewer';

const gutterWidth = 6;
const colWidth = { 'default': 194, 'compact': 128 }

const isPreviewable = { 'default': true, 'compact': false };

export default function Attachments(props) {
  const {
    mode,
    attachments,
    createAttachment,
    updateAttachment,
    deleteAttachment,
    setInvisible,
    onError,
    onUpload,
    onChange,
    onSortEnd,
    onPollingSuccess,
    onPollingFailure,
    maxUploads,
  } = props;

  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [step, stepDown, stepUp, , setStep] = useStepper(attachments.length);

  const openPreviewer = (index) => {
    setStep(index + 1)
    setIsPreviewerOpen(true)
  };
  const closePreviewer = () => setIsPreviewerOpen(false);

  const gridWidthNumCols = useMemo(() => {
    return Array(5).fill().reduce((obj, item, index) => {
      const totalCols = colWidth[mode] * (index + 1);
      const totalGutters = gutterWidth * index;
      obj[index + 1] = totalCols + totalGutters;
      return obj
    }, {})
  }, [mode]);


  const attachmentsClass = (width) => {
    switch (true) {
      case (width > gridWidthNumCols[5]): return 'attachments--cols-5'
      case (width > gridWidthNumCols[4]): return 'attachments--cols-4'
      case (width > gridWidthNumCols[3]): return 'attachments--cols-3'
      case (width > gridWidthNumCols[2]): return 'attachments--cols-2'
      default: return 'attachments--cols-1'
    }
  };

  const previewerContents = <Previewer
    step={step}
    stepDown={stepDown}
    stepUp={stepUp}
    isOpen={isPreviewerOpen}
    attachments={attachments}
    onClose={closePreviewer}
  />

  return (
    <Fragment>
      <SizeMe>
        {({ size }) => (
          <div className={`attachments ${attachmentsClass(size.width)}`}>
            {size.width ? (
              <Collection
                mode={mode}
                attachments={attachments}
                uploaderProps={{
                  mode: mode,
                  uploadsCount: attachments.length,
                  onError: onError,
                  onCancel: setInvisible,
                  onUpload: onUpload,
                  maxUploads: maxUploads,
                }}
                viewerProps={{
                  mode: mode,
                  createAttachment: createAttachment,
                  updateAttachment: updateAttachment,
                  deleteAttachment: deleteAttachment,
                  openPreviewer: openPreviewer,
                  onError: onError,
                  onChange: onChange,
                  onPollingSuccess: onPollingSuccess,
                  onPollingFailure: onPollingFailure,
                }}
                onSortEnd={onSortEnd}
              />
            ) : (null)}
          </div>
        )}
      </SizeMe>
      {isPreviewable[mode] && (
        props.previewerLocation ? (<PortaledPreviewer modalLocation={props.previewerLocation}>{previewerContents}</PortaledPreviewer>) : (previewerContents)
      )}
    </Fragment>
  )
}

const PortaledPreviewer = (props) => {
  const modalLocationElement = document.getElementById(props.modalLocation)
  return (
    ReactDOM.createPortal(props.children, modalLocationElement)
  )
};

Attachments.propTypes = {
  mode: PropTypes.string,
  attachments: PropTypes.array.isRequired,
  createAttachment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  setVisible: PropTypes.func,
  setInvisible: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func,
  onPollingSuccess: PropTypes.func.isRequired,
  onPollingFailure: PropTypes.func
}

Attachments.defaultProps = {
  mode: 'default',
  setVisible: () => {},
  setInvisible: () => {}
}
