var React = require('react');

function RestrictedIcon (props) {
    return React.createElement("svg",props,[React.createElement("path",{"fillRule":"evenodd","clipRule":"evenodd","d":"M2.4032 1.6098C2.61296 1.60798 2.81504 1.68861 2.96592 1.83434L4.39072 3.25914C5.50952 2.69262 6.74627 2.39824 8.00032 2.39994C11.5827 2.39994 14.6147 4.75434 15.6339 7.99994C15.155 9.51988 14.2335 10.8624 12.9875 11.8559L14.1659 13.0343C14.3117 13.1852 14.3923 13.3873 14.3905 13.5971C14.3886 13.8068 14.3045 14.0075 14.1562 14.1558C14.0079 14.3041 13.8072 14.3883 13.5974 14.3901C13.3877 14.3919 13.1856 14.3113 13.0347 14.1655L1.83472 2.96554C1.689 2.81466 1.60836 2.61258 1.61018 2.40282C1.61201 2.19306 1.69614 1.99241 1.84447 1.84409C1.99279 1.69576 2.19344 1.61162 2.4032 1.6098ZM6.37472 5.24234C6.37489 5.24224 6.37456 5.24244 6.37472 5.24234ZM6.37472 5.24234L7.58592 6.45434C7.85719 6.3823 8.14263 6.38278 8.41366 6.45573C8.68469 6.52867 8.9318 6.67153 9.13027 6.86999C9.32874 7.06846 9.47159 7.31558 9.54454 7.58661C9.61749 7.85763 9.61796 8.14307 9.54592 8.41434L10.7571 9.62554C11.1179 9.01436 11.2653 8.30058 11.176 7.59648C11.0868 6.89239 10.766 6.23794 10.2642 5.73608C9.76233 5.23423 9.10788 4.91346 8.40378 4.82422C7.69988 4.73501 6.98579 4.88177 6.37472 5.24234Z","fill":"#B94A48","key":0}),React.createElement("path",{"d":"M7.80001 11.1937L9.96321 13.3577C9.33521 13.5161 8.67761 13.6001 8.00001 13.6001C4.41841 13.6001 1.38561 11.2457 0.366409 8.0001C0.681889 6.99763 1.19218 6.06731 1.86801 5.2625L4.80641 8.2009C4.85535 8.97873 5.18648 9.71197 5.73765 10.263C6.28883 10.814 7.02216 11.145 7.80001 11.1937Z","fill":"#B94A48","key":1})]);
}

RestrictedIcon.defaultProps = {"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none"};

module.exports = RestrictedIcon;

RestrictedIcon.default = RestrictedIcon;
