import React from 'react';

import Options from 'components/application/Options';
import OptionChoice from 'components/application/OptionChoice';

export default function MinimalTrainingOptions(props) {
  const {
    onEditTrainingClick,
    onDeleteTrainingClick
  } = props;

  return (
    <Options>
      <OptionChoice>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto' onClick={onEditTrainingClick}>Edit training</a>
      </OptionChoice>
      <OptionChoice warning={true}>
        <a className='tw-text-m tw-text-grey-700 hover:tw-text-grey-700 tw-font-medium tw-tracking-auto' onClick={onDeleteTrainingClick}>Delete training</a>
      </OptionChoice>
    </Options>
  )
}
