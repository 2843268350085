import React, { useState } from 'react';
import DocumentGenerator from 'components/application/DocumentGenerator';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import DownloadIcon from '-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg';
import TrainingMatrixOptionsModal from 'components/training/trainingMatrixDownload/TrainingMatrixOptionsModal';

const defaultDownloadDetails = {
  isPendingGeneration: false,
  downloadOptions: {
    includeEmployees: true,
    employeeScope: 'all',
    includeSubcontractors: true
  }
};

const baseURL = `/training_matrix_downloads`;
const queryParamMap = {
  includeEmployees: 'incl_employees',
  includeSubcontractors: 'incl_subcontractors',
  employeeScope: 'employee_scope'
}

export default function TrainingMatrixDownloader() {
  const [trainingMatrixDownloadDetails, setTrainingMatrixDownloadDetails] = useState(defaultDownloadDetails);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleDocumentGeneratingToggle = (isPendingGeneration) => {
    setTrainingMatrixDownloadDetails({...trainingMatrixDownloadDetails, isPendingGeneration})
  }

  const handleOptionsSubmission = (options) => {
    setIsOptionsOpen(false)
    setTrainingMatrixDownloadDetails({...trainingMatrixDownloadDetails, downloadOptions: options, isPendingGeneration: true })
  }

  const trainingMatrixDownloadURL = (function buildURL() {
    const queryableParams = Object.entries(trainingMatrixDownloadDetails.downloadOptions)
      .map((mappedEntry) => [queryParamMap[mappedEntry[0]], mappedEntry[1]])
      .map((queryableParams) => `${queryableParams[0]}=${queryableParams[1]}`)

    return `${baseURL}${queryableParams.length > 0 ? `?${queryableParams.join('&')}` : ''}`;
  }());

  return (
    <div className='tooltip-parent m-r-12'>
      <OutlinedButton size='sm' color='grey' onClick={() => setIsOptionsOpen(true) }>
        <DownloadIcon className='m-l--8' width={24} height={24} />
        <span>Download training matrix</span>
      </OutlinedButton>

      {isOptionsOpen &&
        <TrainingMatrixOptionsModal
          onCancel={() => setIsOptionsOpen(false)}
          onSubmit={handleOptionsSubmission}
        />
      }
      <DocumentGenerator
        isGenerating={trainingMatrixDownloadDetails.isPendingGeneration}
        setIsGenerating={handleDocumentGeneratingToggle}
        generateUrl={trainingMatrixDownloadURL}
        modalHeader='Download training matrix'
        modalSuccessMessage='Training matrix ready to download'
        modalFailureMessage='There was a problem creating the training matrix. Please try again'
        isInline={true}
      />
    </div>
  )
}
