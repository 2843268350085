import React from 'react';
import PropTypes from 'prop-types';
import useEscapeKey from 'components/hooks/useEscapeKey';
import useBodyOverflowToggle from 'components/hooks/useBodyOverflowToggle';

export default function ActionModal(props) {
  const {
    isOpen,
    closeModal,
    closeable,
    children
  } = props

  useBodyOverflowToggle(isOpen)

  useEscapeKey(closeModal, [isOpen, closeable])

  const handleWrapperClick = (event) => {
    if (closeable) {
      if (event.target.classList.contains('modalbox__box-wrapper')) { closeModal() }
    }
  }

  return (
    <div className={`modalbox modalbox--${isOpen ? 'open' : 'closed'}`}>
      <div className='modalbox__scroller'>
        <div className='popup__backdrop tw-bg-grey-900 tw-opacity-75'></div>
        <div className='modalbox__box-wrapper' onClick={handleWrapperClick}>
          {children}
        </div>
      </div>
    </div>
  )
}

ActionModal.defaultProps = {
  closeable: true
}

ActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeable: PropTypes.bool
}
