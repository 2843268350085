import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sendAnalytics } from 'components/helpers/analytics';
import axios from 'axios';
import FilledButton from 'components/application/buttons/FilledButton';

export default function ELearningLauncher({ bookingHashid, booking, registrationUuid, onRegistrationUpdate, onError }) {
  const [launchLink, setLaunchLink] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const personnelId = booking.data.relationships.personnel.data.id;
  const courseId = booking.data.relationships.course.data.id;

  const createRegistrationThenLaunchELearning = () => {
    axios
      .post('/e_learning/registrations', { registration: { booking_id: bookingHashid } })
      .then((response) => {
        const newRegistrationUuid = response.data.data.attributes.uuid;
        onRegistrationUpdate({ uuid: newRegistrationUuid })
        launchELearning(newRegistrationUuid)
      })
      .catch(onError)
  };

  const launchELearning = (launchRegistrationUuid) => {
    axios
      .post(`/e_learning/registrations/${launchRegistrationUuid}/launch_links`)
      .then((response) => {
        sendAnalytics('eLearning course started', { personnelId, courseId, registrationUuid: launchRegistrationUuid })
        setLaunchLink(response.data.data.attributes.launchLink)
      })
      .catch(onError)
  };

  const syncRegistration = () => {
    axios
      .get(`/e_learning/registrations/${registrationUuid}?sync=true`)
      .then((response) => {
        if (response.data.meta.isSynced) {
          const { completionStatus, successStatus, score, completedAndPassed, completedAndPassedWithTest } = response.data.data.attributes;

          sendAnalytics('eLearning course synced', { personnelId, courseId, registrationUuid, completionStatus, successStatus, score })
          onRegistrationUpdate({ completionStatus, successStatus, score, completedAndPassed, completedAndPassedWithTest })
        }

        setIsButtonDisabled(false)
      })
      .catch(onError)
  };

  const handleButtonClick = () => {
    setIsButtonDisabled(true)
    registrationUuid ? launchELearning(registrationUuid) : createRegistrationThenLaunchELearning()
  };

  const handleIFrameLoad = (event) => {
    try { if (event.target.contentWindow.location.pathname == '/200.html') { syncRegistration() } } catch { }
  };

  return (
    <>
      <FilledButton color="mint" disabled={isButtonDisabled} onClick={handleButtonClick}>
        {`${registrationUuid ? 'Continue' : 'Take'} course`}
      </FilledButton>
      <iframe className='e-learning__iframe' src={launchLink} onLoad={handleIFrameLoad}></iframe>
    </>
  )
}

ELearningLauncher.propTypes = {
  bookingHashid: PropTypes.string.isRequired,
  booking: PropTypes.shape({
    data: PropTypes.shape({
      relationships: PropTypes.shape({
        personnel: PropTypes.object.isRequired,
        course: PropTypes.object.isRequired
      })
    })
  }),
  registrationUuid: PropTypes.string,
  onRegistrationUpdate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

ELearningLauncher.defaultProps = {
  registrationUuid: null
};
