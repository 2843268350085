import React from 'react';
import RowBar from 'components/application/RowBar';
import Glimmer from 'components/application/Glimmer';

const widths = ['148', '168', '180', '144'];

export default function PendingBars() {
  return (
    widths.map((width) =>
      <RowBar
        key={`pendingRow--${width}`}
        content={
          <span className='mw-360 trunc-standard'>
            <div className={`fw-${width}`}><Glimmer /></div>
          </span>
        }
      />
    )
  )
}
