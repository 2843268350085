import React, { useMemo } from 'react';
import DownloadModalWrapper from 'components/application/DownloadModalWrapper';
import useDownloadPolling from 'components/hooks/useDownloadPolling';
import Polling from 'handshq-app/utilities/polling';
import ActionButton from './buttons/ActionButton';

export default function VersionDownloadButton(props) {

  const statusPoller = useMemo(() => {
    return new Polling({
      interval: 1000,
      url: `/versions/${props.versionId}`,
      responseCheck: function(response) {
        return response.data.relationships.version_pdf.data !== null;
      },
      successCallback: function(response) {
        setDownloadState({...downloadState, downloadFailed: false, downloadInProgress: false, downloadSucceeded: true, downloadLink: response.included[0].links.content });
      },
      failureCallback: function(_response) {
        setDownloadState({...downloadState, downloadInProgress: false, downloadFailed: true})
      }
    });
  }, []);

  const [onClick, startDownload, resetAction, closeModal, modalIsOpen, downloadState, setDownloadState] = useDownloadPolling(statusPoller);

  const downloadVersion = () => {
    window.open(downloadState.downloadLink, '_blank');
  };

  const analyticProps = {
    projectId: props.projectId,
    versionId: props.versionId,
    currentUser: props.currentUser
  };

  return (
    <React.Fragment>
      <ActionButton
        buttonText="Download"
        onClick={onClick}
        styleClasses='app-button__group-member m-l--1 tw-apply-outlined-button tw-apply-outlined-button--md tw-apply-outlined-button--grey tw-apply-outlined-button--on-light hover:tw-z-10 active:tw-z-10'
        disabledClasses='tw-text-grey-300 tw-bg-grey-050 tw-border-grey-100'
      />
      {modalIsOpen &&
        <DownloadModalWrapper
          analyticTrackingId={'Project downloaded'}
          analyticProps={analyticProps}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          headerText={"Download document"}
          downloadingBodyText={"Preparing to download PDF"}
          successBodyText={"PDF ready to download"}
          errorBodyText={"There was a problem creating the PDF. Please try again"}
          downloadStatus={downloadState}
          retryAction={startDownload}
          downloadAction={downloadVersion}
          resetAction={resetAction}
        />
      }
    </React.Fragment>
  )
}
