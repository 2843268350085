import HandshqApp from '../handshq-app/handshq-app';

$(function() {
  $('html').on('hidden.bs.collapse', '.togglable-collapse', function () {
    $(this).parents('tr').hide();
  });

  $('html').on('hide.bs.collapse', '.togglable-collapse', function () {
    const targetElement = $('#' + $(this).data('target')).find('.accordion-chevron-toggle img');
    targetElement.attr('src', HandshqApp.rails.chevronUpIconSvgPath);
  });

  $('html').on('show.bs.collapse', '.togglable-collapse', function () {
    $(this).parents('tr').show();
    const targetElement = $('#' + $(this).data('target')).find('.accordion-chevron-toggle img');
    targetElement.attr('src', HandshqApp.rails.chevronDownIconSvgPath);
  });
});