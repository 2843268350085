import React from 'react';
import PropTypes from 'prop-types';
import BlockList from 'components/application/BlockList';

export default function CourseSidePanelRolesTab({ courseCompanyRoles }) {
  // order courseCompanyRoles alphabetically
  const orderedCourseCompanyRoles = [...courseCompanyRoles].sort((a, b) => a.rolePosition.localeCompare(b.rolePosition, 'en', { sensitivity: 'base' }));

  return (
    <div key='rolesTab' label='roles'>
      <BlockList
        collection={orderedCourseCompanyRoles}
        onItemDisplay={(courseCompanyRole) => {
          return <div className='tw-flex tw-flex-col'>
            <span className='tw-text-m tw-text-grey-700'>{courseCompanyRole.rolePosition}</span>
            <span className='tw-text-s tw-text-grey-500' >{courseCompanyRole.required ? 'Required' : 'Optional'}</span>
          </div>
        }}
      />
    </div>
  )
}

CourseSidePanelRolesTab.propTypes = {
  courseCompanyRoles: PropTypes.array.isRequired
}
