import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import LoadingIcon from '-!svg-react-loader?name=LoadingIcon!icons/loading.svg';

export default function DuplicationProcessingModal(props) {
  const {
    originalProjectName,
    ramsSingularName,
    closeModal,
    modifiers
  } = props

  return (
    <React.Fragment>
      <ActionModal
        isOpen={true}
        closeModal={closeModal}
        closeable={false}
      >
        <ActionModalContent
          headerText={`Duplicate ${originalProjectName}`}
          bodyIcon={<LoadingIcon width={48} height={48} />}
          bodyTitle={`Creating ${ramsSingularName}`}
          bodyText={`This may take a few minutes. When it is finished, you will be redirected to the new ${ramsSingularName}.`}
          closeable={false}
          closeModal={closeModal}
          modifiers={modifiers}
          iconStyling='tw-apply-loading-spinner--blue-light'
        />
      </ActionModal>
    </React.Fragment>
  )
}

DuplicationProcessingModal.defaultProps = {
  ramsSingularName: 'project'
};

DuplicationProcessingModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  ramsSingularName: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  modifiers: PropTypes.array
};
