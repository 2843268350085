import { produce } from 'immer';
import { baseStoreState } from 'components/helpers/storeHelpers';

const colourFields = ['primaryBgColour', 'buttonBgColour', 'buttonTextColour', 'textLinkColour'];
const defaultEditableLogo = { data: null, src: null, markedForDeletion: false, invalid: false }
const defaultBranding = {
  primaryBgColour: '#0A182E',
  buttonBgColour: '#65DCA4',
  buttonTextColour: '#0A182E',
  textLinkColour: '#1D70F2'
}

function loadFromPayload({draftState, branding, logo}) {
  draftState.domain.branding = branding;
  draftState.domain.logo = logo;
  draftState.ui.editableBranding = brandingAsEditable(branding);
  const logoUrl = draftState.domain.logo && draftState.domain.logo.attributes.attachedUrl
  draftState.ui.logo = { data: null, src: logoUrl, markedForDeletion: false, invalid: false };
  return draftState
}

function editableBrandingAttributes({ labelToggle, colourValue }) {
  return {
    handshqLabel: labelToggle(),
    ...editableColourFields(colourValue)
  }
}

function editableColourFields (colourValue) {
  return colourFields.reduce((memo, attributeName) => {
    memo[attributeName] = { toggled: false, value: colourValue(attributeName) };
    return memo
  }, {})
}

function brandingAsEditable(branding) {
  return editableBrandingAttributes({
    labelToggle: () => branding.attributes.handshqLabel,
    colourValue: (colourAttribute) => branding.attributes[colourAttribute]
  })
}

const defaultEditableBrandingValues = editableBrandingAttributes({
  labelToggle: () => false,
  colourValue: (_attributeName) => null
})

export const initialState = {
  ...baseStoreState,
  domain: { branding: null },
  application: {
    pending: {
      brandingFetch: true
    },
    isCustomBranding: false
  },
  ui: {
    editableBranding: defaultEditableBrandingValues,
    logo: defaultEditableLogo
  }
}

export default function reducer(state, action) {
  return (
    produce(state, draftState => {
      switch (action.type) {
        case 'FETCHED_BRANDING':
          loadFromPayload({ draftState: draftState, branding: action.payload.branding, logo: action.payload.logo })
          draftState.application.pending.brandingFetch = false;
          draftState.application.isCustomBranding = action.payload.branding.attributes.isCustom;
          break;
        case 'CHANGED_COLOUR':
          draftState.ui.editableBranding[action.payload.attribute].value = action.payload.value;
          draftState.application.isCustomBranding = true
          break;
        case 'TOGGLED_COLOUR':
          draftState.ui.editableBranding[action.payload.attribute].toggled = !draftState.ui.editableBranding[action.payload.attribute].toggled;
          break;
        case 'TOGGLED_LABEL':
          draftState.ui.editableBranding.handshqLabel = !draftState.ui.editableBranding.handshqLabel;
          break;
        case 'REQUESTED_BRANDING_PERSISTANCE':
          draftState.application.pending.brandingPersisting = true;
          break;
        case 'PERSISTED_BRANDING':
          loadFromPayload({ draftState: draftState, branding: action.payload.branding, logo: action.payload.logo })
          draftState.application.pending.brandingPersisting = false;
          break;
        case 'SELECTED_LOGO':
          draftState.ui.logo = { data: action.payload.fileData, src: action.payload.src, markedForDeletion: false };
          draftState.ui.logo.invalid = false;
          break;
        case 'LOGO_MARKED_FOR_DELETION':
          draftState.ui.logo = { ...defaultEditableLogo, markedForDeletion: !!(draftState.domain.logo) }
          break;
        case 'RESET_DEFAULTS':
          Object.keys(defaultBranding).map((attr) => {
            draftState.ui.editableBranding[attr].value = defaultBranding[attr]
          })
          draftState.application.isCustomBranding = false;
          draftState.ui.editableBranding.handshqLabel = true;
          draftState.ui.logo = { ...defaultEditableLogo, markedForDeletion: !!(draftState.domain.logo) }
          break;
        case 'INVALID_LOGO':
          draftState.ui.logo.invalid = true;
          break;
      }
    })
  )
}
