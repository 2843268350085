import React from 'react';
import Table from 'components/application/Table'
import TableCell from 'components/application/TableCell';

export default function SubcontractorsTable(props) {
  const subcontractorRow = (subcon) => {
    return (
      <tr key={subcon.id} className='handshq__tr--clickable fh-49 hover:tw-bg-grey-025' onClick={(event) => {props.onRowClick(event, subcon)}} >
        <TableCell>
          <span className='truncated-text-container tw-text-m tw-text-grey-900 tw-font-medium'>{subcon.attributes.name}</span>
        </TableCell>
      </tr>
    )
  }

  return (
    <Table
      headers={
        <tr>
          <th className='tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Company</th>
        </tr>
      }
      rows={
        props.subcontractors.map((subcon) =>
          subcontractorRow(subcon)
        )
      }
    />
  )
}
