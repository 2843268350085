import { connect } from 'react-redux';

import { addError } from 'action-creators/ErrorActionCreators';

import SequenceRow from '../components/sequences/SequenceRow.jsx';

const mapStateToProps = (state, ownProps) => {
  const existingSequence = state.domain.methodSequenceCollection.data[ownProps.sequenceId];
  const sequenceNameKey = existingSequence && `editingSequenceName--${existingSequence.id}`;
  const newStepNameKey = existingSequence && `sequenceNewStep--${existingSequence.id}`;
  const masterSequence = state.domain.masterMethodSequenceCollection.data[ownProps.masterSequenceId]
  const stepIds = existingSequence && state.ui.ordering[`methodSequenceSteps--${existingSequence.id}`].list;
  const tradeOrderingInProgress = state.ui.ordering.projectTrades;
  const stepsOrderingInProgress = existingSequence && state.ui.ordering[`methodSequenceSteps--${existingSequence.id}`].inProgress;
  const sequenceOrderingInProgress = state.ui.ordering[`projectTradeSequences--${ownProps.projectTradeId}`].inProgress;
  const isStepsOpen = existingSequence && !sequenceOrderingInProgress;
  const sequenceTemplate = existingSequence || masterSequence;
  const sequenceFieldText = state.application.editingContent.hasOwnProperty(sequenceNameKey) ? state.application.editingContent[sequenceNameKey] : sequenceTemplate.attributes.name;
  const projectId = state.application.projectId;
  const newStepText = existingSequence && state.application.editingContent[`sequenceNewStep--${existingSequence.id}`];
  const isEditingSequenceToggle = existingSequence && !sequenceOrderingInProgress && state.ui.toggling[sequenceNameKey];
  const isAddingStepName = existingSequence && state.ui.toggling[newStepNameKey];
  const isFocused = existingSequence && state.ui.focused['sequenceRow'] === sequenceNameKey

  return {
    existingSequence,
    isAddingStepName,
    isEditingSequenceToggle,
    isFocused,
    isStepsOpen,
    masterSequence,
    newStepNameKey,
    newStepText,
    projectId,
    sequenceFieldText,
    sequenceNameKey,
    sequenceOrderingInProgress,
    sequenceTemplate,
    stepIds,
    stepsOrderingInProgress,
    tradeOrderingInProgress
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload))
  }
}

const AvailableSequence = connect(mapStateToProps, mapDispatchToProps)(SequenceRow)

export default AvailableSequence
