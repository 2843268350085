import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import ChevronRightIcon from '-!svg-react-loader?name=ChevronRightIcon!icons/chevron-right.svg';

const colorClassName = {
  green: 'tw-text-green-800 tw-bg-green-025 [&_svg_path]:tw-fill-green-800 [&_svg_polygon]:tw-fill-green-800',
  cyan: 'tw-text-cyan-800 tw-bg-cyan-025 [&_svg_path]:tw-fill-cyan-800 [&_svg_polygon]:tw-fill-cyan-800',
  red: 'tw-text-red-800 tw-bg-red-025 [&_svg_path]:tw-fill-red-800 [&_svg_polygon]:tw-fill-red-800',
  grey: 'tw-text-grey-600 tw-bg-grey-050 [&_svg_path]:tw-fill-grey-600 [&_svg_polygon]:tw-fill-grey-600',
  amber: 'tw-text-amber-800 tw-bg-amber-025 [&_svg_path]:tw-fill-amber-800 [&_svg_polygon]:tw-fill-amber-800'
};

const interactableColorClassName = {
  green: 'hover:tw-bg-green-050',
  cyan: 'hover:tw-bg-cyan-050',
  red: 'hover:tw-bg-red-050',
  grey: 'hover:tw-bg-grey-100',
  amber: 'hover:tw-bg-amber-050'
};

export default function ActionsPill(props) {
  const {
    color,
    isDisabled,
    displayText,
    tooltipText,
    icon,
    iconStyling,
    onClick
  } = props;

  const interactableClassName = onClick ? ` tw-cursor-pointer ${interactableColorClassName[color]}` : '';
  const disabledClassName = isDisabled ? ' tw-opacity-30 tw-pointer-events-none' : '';

  return (
    <div className='actions-pill__tooltip-wrapper'>
      <Tooltip placement='top' trigger={tooltipText ? 'hover' : 'none'} tooltip={tooltipText}>
        <div className={`actions-pill__container ${colorClassName[color]}${interactableClassName}${disabledClassName}`} onClick={() => !isDisabled && onClick ? onClick() : void(0)}>
          <div className={`actions-pill__icon-wrapper${iconStyling ? ` ${iconStyling}` : ''}`}>
            {icon}
          </div>
          <div className={`actions-pill__text tw-text-s tw-font-medium tw-tracking-wide${onClick ? ' m-r-0' : ''}`}>
            {displayText}
          </div>
          {onClick && (
            <div className='actions-pill__action-icon-wrapper'>
              <ChevronRightIcon width={24} height={24} />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

ActionsPill.propTypes = {
  color: PropTypes.oneOf(['green', 'cyan', 'red', 'grey', 'amber']).isRequired,
  isDisabled: PropTypes.bool,
  displayText: PropTypes.string,
  icon: PropTypes.object,
  iconStyling: PropTypes.string,
  onClick: PropTypes.func
}

ActionsPill.defaultProps = {
  isDisabled: false,
  displayText: '',
  tooltipText: ''
}
