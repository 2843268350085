import React from 'react';
import PropTypes from 'prop-types';
import { assignedDetailsShape } from '../../Shapes';

InputFields.propTypes = {
  procoreIdentityID: PropTypes.string.isRequired,
  existingIntegration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["update", "delete"]).isRequired,
  }),
  assignedDetails: assignedDetailsShape,
}

export default function InputFields ({ existingIntegration, assignedDetails, procoreIdentityID }) {
  const isDeleting = existingIntegration && existingIntegration.mode === 'delete';
  const isPersisted = existingIntegration && existingIntegration.id;
  // TODO - use assignedDetailsShape to determine if shape is met
  const isSubmittable = assignedDetails.company && assignedDetails.project && assignedDetails.folder && assignedDetails.assignerID;

  return (
    <div>
      {isPersisted && <input name='project[procore_project_integration_attributes][id]' type='hidden' value={existingIntegration.id} />}
      {isDeleting && <input name='project[procore_project_integration_attributes][_destroy]' type='hidden' value={1} />}
      {
        isSubmittable && <>
          <input name='project[procore_project_integration_attributes][external_identity_id]' type='hidden' value={assignedDetails.assignerID ? assignedDetails.assignerID : procoreIdentityID} />
          <input name='project[procore_project_integration_attributes][procore_company_id]' type='hidden' value={assignedDetails.company ? assignedDetails.company.id : ''} />
          <input name='project[procore_project_integration_attributes][procore_project_id]' type='hidden' value={assignedDetails.project ? assignedDetails.project.id : ''} />
          <input name='project[procore_project_integration_attributes][procore_folder_id]' type='hidden' value={assignedDetails.folder ? assignedDetails.folder.id : ''} />
        </>
      }
    </div>
  )
}
