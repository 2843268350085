import React, { Fragment, useReducer, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import arrayMove from 'array-move';

import useAttachmentsVisibility from 'components/hooks/useAttachmentsVisibility';
import useToasts from 'components/hooks/useToasts';

import { reducer, initialState } from 'reducers/collectionReducer';
import { initialMember, getMember, getCollection } from 'components/helpers/resources/attachments';

import AttachmentsButton from 'components/attachments/AttachmentsButton';
import Attachments from 'components/attachments/Attachments';
import ToastRack from 'components/application/ToastRack';

export default function AttachmentsWrapper(props) {
  const { buttonLocation, attachableId, attachableType, attachablePlural, seedAttachments } = props;

  const [toasts, , , , addInedibleToast] = useToasts();
  const [attachments, dispatch] = useReducer(reducer, initialState);
  const [areAttachmentsVisible, setAttachmentsVisible, setAttachmentsInvisible] = useAttachmentsVisibility(attachments.collection);

  const getAttachments = () => {
    axios
      .get(`/${attachablePlural}/${attachableId}/attachments`)
      .then(response => dispatch({ type: 'LOAD', payload: getCollection(response.data) }))
      .catch(_error => addInedibleToast())
  };

  const bulkUpdateAttachments = ({ params }) => {
    axios
      .post(`/${attachablePlural}/${attachableId}/attachment_bulk_updates`, {
        attachments: params
      })
      .then(response => dispatch({ type: 'RELOAD', attr: 'id', payload: getCollection(response.data) }))
      .catch(_error => addInedibleToast())
  };

  const createAttachment = ({ attachment, fileType, uploadKey }) => {
    axios
      .post('/attachments', {
        additional_processing: fileType === 'pdf',
        attachment: {
          attachable_type: attachableType,
          attachable_id: attachableId,
          attached_key: uploadKey
        },
        type: fileType
      })
      .then(response => {
        const member = getMember({
          attachment: response.data.data,
          coverImages: response.data.included.filter(obj => obj.type === 'coverImage'),
          attachments: response.data.included.filter(obj => obj.type === 'attachment')
        });

        dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
      })
      .catch(_error => addInedibleToast())
  };

  const updateAttachment = ({ attachment, params }) => {
    axios
      .patch(`/attachments/${attachment.hashid}`, { type: attachment.recordType, attachment: params })
      .then(response => {
        const member = getMember({
          attachment: response.data.data,
          coverImages: response.data.included.filter(obj => obj.type === 'coverImage'),
          attachments: response.data.included.filter(obj => obj.type === 'attachment')
        });

        dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
      })
      .catch(_error => addInedibleToast())
  };

  const deleteAttachment = ({ attachment }) => {
    axios
      .delete(`/attachments/${attachment.hashid}`, { params: { type: attachment.recordType } })
      .then(_response => dispatch({ type: 'REMOVE', key: attachment.key }))
      .catch(_error => addInedibleToast())
  };

  const handleUpload = file => dispatch({ type: 'ADD', payload: {...initialMember, file: file } });

  const handleChange = (event, { attachment }) => dispatch({ type: 'CHANGE', event: event, key: attachment.key });

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    let newCollection = [...attachments.collection];
    newCollection = arrayMove(newCollection, oldIndex, newIndex);

    const attachmentAttributes = newCollection.reduce((obj, item, index) => { return { ...obj, [item.id]: { position: index } } }, {});

    dispatch({ type: 'RELOAD', attr: 'id', payload: newCollection })
    bulkUpdateAttachments({ params: { attachment_attributes: attachmentAttributes } })
  };

  const handlePollingSuccess = ({ response, attachment }) => {
    const member = getMember({
      attachment: response.data,
      coverImages: response.included.filter(obj => obj.type === 'coverImage'),
      attachments: response.included.filter(obj => obj.type === 'attachment')
    });

    dispatch({ type: 'REPLACE', payload: member, key: attachment.key })
  };

  const buttonContents = <AttachmentsButton onClick={setAttachmentsVisible} />;

  useEffect(() => {
    seedAttachments ? dispatch({ type: 'LOAD', payload: getCollection(seedAttachments) }) : getAttachments()
  }, [])

  return (
    <Fragment>
      {attachments.loaded && (
        areAttachmentsVisible ? (
          <Attachments
            buttonLocation={buttonLocation}
            attachments={attachments.collection}
            createAttachment={createAttachment}
            updateAttachment={updateAttachment}
            deleteAttachment={deleteAttachment}
            setVisible={setAttachmentsVisible}
            setInvisible={setAttachmentsInvisible}
            onError={addInedibleToast}
            onUpload={handleUpload}
            onChange={handleChange}
            onSortEnd={handleSortEnd}
            onPollingSuccess={handlePollingSuccess}
          />
        ) : (
          props.buttonLocation ? (<PortaledButton buttonLocation={buttonLocation}>{buttonContents}</PortaledButton>) : (buttonContents)
        )
      )}
      <ToastRack toasts={toasts} />
    </Fragment>
  )
}

const PortaledButton = (props) => {
  const element = document.getElementById(props.buttonLocation);

  return (
    ReactDOM.createPortal(props.children, element)
  )
};

AttachmentsWrapper.propTypes = {
  attachableId: PropTypes.number.isRequired,
  attachableType: PropTypes.string.isRequired,
  attachablePlural: PropTypes.string.isRequired,
  seedAttachments: PropTypes.object
};
