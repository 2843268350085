import HandshqApp from '../handshq-app';

export default HandshqApp.Polling = function(options) {
  if (this instanceof HandshqApp.Polling) {
    const _this = this;
    let num = 0;

    this.interval = options['interval'] || 2000;
    this.maxTime = options['maxTime'] || 60000;
    this.maxNum = Math.round(_this.maxTime / _this.interval);
    this.successCallback = options['successCallback'];
    this.failureCallback = options['failureCallback'];
    this.responseCheck = options['responseCheck'];
    this.url = options['url'];

    this.start = function() {
      this.intervalId = setInterval(function() {
        $.ajax({
          type: 'GET',
          dataType: 'json',
          url: _this.url,

          error: function(response, _status, _err) {
            _this.failureCallback(response);
            _this.end();
          },

          success: function(response) {
            if (_this.responseCheck(response)) {
              _this.successCallback(response);
              _this.end();
            }
          }
        });

        _this.isLimitReached();
      }, _this.interval);
    };

    this.end = function() {
      clearInterval(_this.intervalId);
    };

    this.isLimitReached = function() {
      if (++num === _this.maxNum) {
        _this.failureCallback();
        _this.end();
      }
    };
  } else {
    throw new Error('HandshqApp.Polling invoked without new');
  }
};
