import React  from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/application/Toast';
import DivisionAccessDisallowed from 'components/toastMessages/messages/DivisionAccessDisallowed';
import ToastUpdateMessage from 'components/toastMessages/messages/ToastUpdateMessage';

const messageComponents = {
  'divisionAccessDisallowed': DivisionAccessDisallowed,
  'toastUpdateMessage' : ToastUpdateMessage
};

export default function GlobalToast({ toastKey, messageType, messageProps, onBurnToast }) {
  const MessageComponent = messageComponents[messageType];

  return(
    <Toast
      toastKey={toastKey}
      modifiers='no-undo'
      burnTimer={messageType === 'divisionAccessDisallowed' ? 30000 : 5000 }
      contents={<MessageComponent {...messageProps} />}
      burn={onBurnToast}
    />
  )
}

GlobalToast.propTypes = {
  messageType: PropTypes.string.isRequired,
  messageProps: PropTypes.object.isRequired,
  onBurnToast: PropTypes.func.isRequired
};
