$( document ).ready( function() {
	$( document ).on( 'change', '.toggle_cover_sheet', toggleCoverSheet );
  let timer;
  function toggleCoverSheet() {
    const _this = this;
    const check_box_state = this.checked;
    const pdf_id = this.getAttribute( 'id' );

    clearTimeout(timer);

    // Build form data
    const data = {
      pdf: {
        cover_sheet: check_box_state
      }
    };

    // Uncheck other boxes
    $( '.toggle_cover_sheet' ).each( function ( x , checkBox ) {
      if ( checkBox !== _this ) {
        checkBox.checked = false;
      };
    });

    timer = setTimeout(function() {
      // Update db
      $.ajax({
        type: 'PUT',
        url: '/pdfs/' + pdf_id + '/cover_sheet',
        data: data
      });

    }, 600);
  }
});
