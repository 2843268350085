import React from 'react';
import ModalBox from 'components/application/ModalBox';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sendAnalytics } from 'components/helpers/analytics';
import RedInfoIcon from '-!svg-react-loader?name=RedInfoIcon!icons/info-red.svg';
import * as IntercomHelperModule from 'components/helpers/intercom';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function UnarchiveBlockedModal(props) {
  const {
    isOpen,
    closeModal,
    usage
  } = props;

  const handleUpgradeClick = () => {
    IntercomHelperModule.launchIntercomWithMessage("Hello, I'm interested in upgrading my Training Register plan.")
    closeModal()
    sendAnalytics('Upgrade clicked from unarchive blocked modal', { user: currentActor.user, division: currentActor.division, account: currentActor.account })
  }

  const currentActor = useCurrentActor();
  const allowance = currentActor.subscription.attributes.trackedPersonnelAllowance;

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={closeModal}>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={handleUpgradeClick}>
        Upgrade plan
      </FilledButton>
    </div>
  )

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={closeModal}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <div className='m-b-16 tw-text-grey-900 tw-font-semibold tw-font-inter tw-text-xl'>
          You can't unarchive this personnel as you have reached your training limit
        </div>
        <div className='m-b-12 flex flex--vertically-centered py-12 px-20 b-standard br-3 tw-bg-white tw-border-grey-100 tw-text-m'>
          <RedInfoIcon height="24px" width="24px" className="[&_path]:tw-fill-red-600" />
          <span className='m-l-12'>
            {usage} personnel with recorded or booked training / {allowance} in your plan
          </span>
        </div>
        <div>
          As this personnel has recorded training, you can't unarchive them as you would exceed your limit. Try archiving another personnel with training first, or upgrade your plan.
        </div>
      </div>

    </ModalBox>
  )
}
