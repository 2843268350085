import HandshqApp from './handshq-app';

HandshqApp.keyCodes = {
  ENTER_KEY: 13,
  ESC_KEY: 27,
  TAB_KEY: 9,
  LEFT_CLICK_BTN: 1,
  UP_ARROW_KEY: 38,
  DOWN_ARROW_KEY: 40
};
