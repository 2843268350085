import React from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';
import ActionModal from 'components/application/ActionModal';
import ActionModalContent from 'components/application/ActionModalContent';
import ErrorIcon from '-!svg-react-loader?name=ErrorIcon!icons/ic-error-large.svg';

export default function DuplicationFailedModal(props) {
  const {
    originalProjectName,
    ramsSingularName,
    isOpen,
    closeModal
  } = props

  return (
    <React.Fragment>
      <ActionModal
        isOpen={isOpen}
        closeModal={closeModal}
        closeable={true}
      >
        <ActionModalContent
          headerText={`Duplicate ${originalProjectName}`}
          bodyIcon={<ErrorIcon width={48} height={48} className='[&_path]:tw-fill-red-600' />}
          bodyTitle={voca.capitalize(`${ramsSingularName} not created`)}
          bodyText='Please try again or contact support for help.'
          closeable={true}
          closeModal={closeModal}
        />
      </ActionModal>
    </React.Fragment>
  )
}

DuplicationFailedModal.defaultProps = {
  ramsSingularName: 'project'
};

DuplicationFailedModal.propTypes = {
  originalProjectName: PropTypes.string.isRequired,
  ramsSingularName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
