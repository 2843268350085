import { connect } from 'react-redux';

import { addError } from 'action-creators/ErrorActionCreators';

import StepList from 'components/sequences/StepList';

const mapStateToProps = (state, ownProps) => {
  const stepIds = state.ui.ordering[`methodSequenceSteps--${ownProps.methodSequenceId}`].list;
  const isSorting = state.ui.ordering[`methodSequenceSteps--${ownProps.methodSequenceId}`].inProgress;
  const isStepsOpen = !state.ui.ordering[`projectTradeSequences--${ownProps.projectTradeId}`].inProgress;
  const methodSequenceId = ownProps.methodSequenceId
  return {
    stepIds, isStepsOpen, isSorting, methodSequenceId
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload))
  }
}

const AvailableStepList = connect(mapStateToProps, mapDispatchToProps)(StepList);
export default AvailableStepList
