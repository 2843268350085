import React from 'react';
import PropTypes from 'prop-types';
import CardWrapper from './CardWrapper';

export default function InactiveCard ({ reason }) {
  return (
    <CardWrapper isInactive={true}>
      <div className='tw-text-grey-900'>Procore integration set up</div>
      <div className='tw-text-s tw-font-normal tw-tracking-wide'>{reason}</div>
    </CardWrapper>
  )
}

InactiveCard.propTypes = {
  reason: PropTypes.node
}
