import HandshqApp from '../handshq-app';
import voca from 'voca';

HandshqApp.MasterActivity = (function() {
  function MasterActivity(masterActivity) {
    if (this instanceof HandshqApp.MasterActivity) {
      this.element = $(masterActivity);
      this.form = this.element.find('form');
      this.checkbox = this.form.find(':checkbox');
      this.remoteId = $(this.checkbox).val();
      this.labelName = this.element.find('.master-activity__name')
      this.editButton = this.element.find('.activity__edit');
      this.versionInfoElement = this.element.find('.master-activity__version');
      this.formData = {};
      this.editButtonSpinnerIsActive = false;
      this.interactionTimer;
      this.removeUnavailableModal = $('.remove-master-activity');

      this.init();
    } else {
      throw new Error('HandshqApp.MasterActivity invoked without new');
    }
  }

  // private functions can be provided here if needed

  MasterActivity.prototype = {
    init: function() {
      this.generateFormData();
      this.respondToCheckboxChange();
      this.updateEditButtonStatus();
      this.triggerCheckboxFromName();
    },

    // applies to all master activities (including self) with same remote ID
    forAllSimiliarMasterActivities: function(applyCallback) {
      const _this = this;
      $(HandshqApp.masterActivities).each(function(i, masterActivity) {
        if (masterActivity.remoteId === _this.remoteId) {
          applyCallback(masterActivity);
        }
      })
    },

    showSpinnerWhileCreating: function() {
      const _this = this;
      if (!_this.hasActivityFormData()) {
        _this.editButton.on('click', function(event) {
          event.preventDefault();
          _this.setEditButtonSpinnerActiveState(true);
        });
      }
    },

    toggleAllSimiliarMasterActivities: function() {
      const _this = this;

      this.forAllSimiliarMasterActivities(function(masterActivity) {
        if (_this != masterActivity) { masterActivity.toggleChecked() }
        masterActivity.updateEditButtonStatus()
        masterActivity.updateVersionStatus()
      })
    },

    respondToCheckboxChange: function() {
      const _this = this;

      this.checkbox.change(function() {
        clearTimeout(_this.interactionTimer)
        _this.showSpinnerWhileCreating()
        _this.toggleAllSimiliarMasterActivities()
        if (_this.activityAvailable()) {
          _this.interactionTimer = setTimeout(_this.createOrDestroyRemoteObject.bind(_this), 1000);
        } else {
          _this.handleUnavailableActivity()
        }
      });
    },

    handleUnavailableActivity: function() {
      if (!this.checkboxIsChecked() && this.hasActivityFormData()) {
        const _this = this;
        const cancelButton = this.removeUnavailableModal.find('.remove-master-activity__cancel');
        const confirmButton = this.removeUnavailableModal.find('.remove-master-activity__confirm');

        cancelButton[0].onclick = function() {
          _this.toggleChecked()
          _this.updateEditButtonStatus()
          _this.toggleAllSimiliarMasterActivities()
        }
        confirmButton[0].onclick = function() { _this.removeUnavailableActivity() }

        this.removeUnavailableModal.modal('show');
      }
    },

    removeUnavailableActivity: function() {
      const _this = this;

      this.interactionTimer = setTimeout(this.destroyRemoteActivity(), 1000);
      this.removeUnavailableModal.modal('hide')
      this.element.remove()

      this.forAllSimiliarMasterActivities(function(masterActivity) {
        if (_this != masterActivity) { masterActivity.element.remove() }
      })
    },

    createOrDestroyRemoteObject: function() {
      if (this.checkboxIsChecked() && !this.hasActivityFormData()) {
        this.createRemoteActivity();
      } else if (!this.checkboxIsChecked() && this.hasActivityFormData()) {
        this.destroyRemoteActivity();
      }
    },

    createRemoteActivity: function() {
      const _this = this;
      $.ajax({
        type: 'POST',
        url: '/projects/' + _this.formData.project + '/master_activities/' +  _this.formData.master_activity + '/activities',
        dataType: 'json',
        success: function(response) { _this.activityGenerated(response.id) }
      });
    },

    destroyRemoteActivity: function() {
      const _this = this;
      $.ajax({
        url: '/projects/' + _this.formData.project + '/activities/' + _this.formData.activity,
        dataType: 'json',
        method: 'delete',
        success: _this.activityDestroyed.bind(_this)
      });
    },

    activityGenerated: function(activityId) {
      const _this = this;
      this.forAllSimiliarMasterActivities(function(masterActivity) {
        masterActivity.updateActivityFormData(activityId);
        masterActivity.updateEditButtonUrl();
        masterActivity.editButton.off();

        if (masterActivity === _this && _this.editButtonSpinnerIsActive) {
          _this.setEditButtonSpinnerActiveState(false);
          // redirect if already clicked and waiting to proceed to edit.
          window.location = _this.editButton.attr('href');
        }
      });
    },

    activityDestroyed: function() {
      this.forAllSimiliarMasterActivities(function(masterActivity) {
        masterActivity.resetActivityFormData();
        masterActivity.editButton.attr('href', '#');
        masterActivity.editButton.find('.activity__edited-icon').remove();
        masterActivity.editButton.find('.activity__edited-text').text('Edit');
        masterActivity.editButton.attr('data-updated', false);
      });
    },

    toggleChecked: function() {
      if (!this.checkboxIsChecked()) {
        this.checkbox.prop('checked', true);
      } else {
        this.checkbox.prop('checked', false);
      }
    },

    updateEditButtonStatus: function() {
      if (this.checkboxIsChecked()) {
        this.editButton.attr('data-disabled', false);
      } else {
        this.editButton.attr('data-disabled', true);
      }
    },

    setEditButtonSpinnerActiveState: function(shouldBeActive) {
      const spinner = this.editButton.find('.spinner');

      shouldBeActive ? spinner.removeClass('tw-hidden') : spinner.addClass('tw-hidden')

      this.editButtonSpinnerIsActive = shouldBeActive;
    },

    updateEditButtonUrl: function() {
      let url;
      if (this.hasActivityFormData()) {
        url = '/projects/' + this.formData.project + '/activities/' + this.formData.activity + '/edit?trade=' + this.formData.trade;
      } else {
        url = '#';
      }

      this.editButton.attr('href', url);
    },

    updateActivityFormData: function(activityId) {
      this.form.find('#activity').val(activityId);
      this.formData.activity = activityId;
    },

    resetActivityFormData: function() {
      this.form.find('#activity').removeAttr('value');
      this.formData.activity = '';
    },

    triggerCheckboxFromName: function() {
      this.labelName.on('click', function() {
        $(this).parent().find('label').click();
      });
    },

    updateVersionStatus: function() {
      if (this.checkboxIsChecked()) {
        this.setVersionVisualisation('latest-version');
      } else {
        this.setVersionVisualisation('');
      }
    },

    setVersionVisualisation: function(state) {
      if (state) {
        this.versionInfoElement.find('.circle').addClass(state);
        this.versionInfoElement.find('.version-text').addClass(state).text(voca.capitalize(state).replace(/-/g, ' '));
      } else {
        this.versionInfoElement.find('.circle').attr('class', 'circle');
        this.versionInfoElement.find('.version-text').attr('class', 'version-text tw-text-xs tw-font-semibold tw-tracking-wide').text('');
      }
    },

    generateFormData: function() {
      const _this = this;
      this.form.find('input').each(function(i, el) {
        _this.formData[el.name] = $(el).val();
      });
    },

    hasActivityFormData: function() {
      return this.formData.activity !== '';
    },

    checkboxIsChecked: function() {
      return this.checkbox[0].checked;
    },

    activityAvailable: function() {
      return this.checkbox.attr('data-available') === 'true';
    }
  };

  return MasterActivity;
})();
