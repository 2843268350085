var React = require('react');

function HashedPatternGreen (props) {
    return React.createElement("svg",props,React.createElement("defs",null,React.createElement("pattern",{"id":"hashed-pattern-green","x":"0","y":"0","width":"8","height":"8","patternUnits":"userSpaceOnUse"},[React.createElement("g",{"clipPath":"url(#clip0_1543_647)","key":0},[React.createElement("rect",{"width":"8","height":"8","fill":"#2FBC7A","key":0}),React.createElement("path",{"d":"M4 0H0V4L4 0Z","fill":"#26A16C","key":1}),React.createElement("path",{"d":"M0 8L8 0V4L4 8H0Z","fill":"#26A16C","key":2})]),React.createElement("defs",{"key":1},React.createElement("clipPath",{"id":"clip0_1543_647"},React.createElement("rect",{"width":"8","height":"8","fill":"white"})))])));
}

HashedPatternGreen.defaultProps = {"width":"0","height":"0"};

module.exports = HashedPatternGreen;

HashedPatternGreen.default = HashedPatternGreen;
