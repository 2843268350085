import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useToasts from 'components/hooks/useToasts';

import ToastRack from 'components/application/ToastRack';
import Portal from 'components/application/Portal';
import MasterCoshhTable from 'components/masterCoshh/MasterCoshhTable';
import MasterCoshhBackButton from 'components/masterCoshh/MasterCoshhBackButton';

export default function MasterCoshhContainer(props) {
  const {
    projectId,
    workerExposureTimeOptions,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    personsAtRiskOptions,
    projectDisplayName,
    secondaryFieldLabel,
    secondaryFieldDefaultValue
  } = props;

  const [coshhDocumentsChangeMade, setCoshhDocumentsChangeMade] = useState(false);
  const [toasts, _setToasts, _addToast, _handleBurnToast, addInedibleToast] = useToasts();

  const handleCoshDocumentChange = () => {
    setCoshhDocumentsChangeMade(true)
  };

  return (
    <>
      <MasterCoshhTable
        projectId={projectId}
        workerExposureTimeOptions={workerExposureTimeOptions}
        exposureFrequencyOptions={exposureFrequencyOptions}
        locationOptions={locationOptions}
        methodOfUseOptions={methodOfUseOptions}
        personsAtRiskOptions={personsAtRiskOptions}
        addInedibleToast={addInedibleToast}
        onCoshDocumentChange={handleCoshDocumentChange}
        secondaryFieldLabel={secondaryFieldLabel}
        secondaryFieldDefaultValue={secondaryFieldDefaultValue}
      />
      <Portal containerSelector='.coshh-back-button'>
        <MasterCoshhBackButton
          projectId={projectId}
          projectDisplayName={projectDisplayName}
          coshhDocumentsChangeMade={coshhDocumentsChangeMade}
          addInedibleToast={addInedibleToast}
        />
      </Portal>
      <ToastRack
        toasts={toasts}
      />
    </>
  )
}

MasterCoshhContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  personsAtRiskOptions: PropTypes.array.isRequired,
  projectDisplayName: PropTypes.string.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired
}
