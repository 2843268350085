import React from 'react';
import { asDate } from 'components/helpers/dates'
import ClockIcon from '-!svg-react-loader?name=ClockIcon!icons/clock.svg';
import { escapeDoubleQuotes } from 'components/helpers/strings.js';

export default function ActivityTableData(props) {
  const {
    history,
    showVersionNumberColumn,
    downloadButtonVisible
  } = props

  return (
    <React.Fragment>
      {showVersionNumberColumn &&
        <td className='tw-font-medium tw-text-grey-900 tw-border-grey-100'>
          <div className='fh-32 flex flex--vertically-centered flex--justify-content__center'>
            {history.displayNumber}
          </div>
        </td>
      }

      <td className='tw-border-grey-100'>
        <div className='fh-32 handshq__cell-content--near-spaced'>
          <ClockIcon className='[&_path]:tw-fill-grey-500 m-r-4' height={20} width={20}/>
          <span>{asDate(history.createdAt)}</span>
        </div>
      </td>

      <td className='tw-border-grey-100' colSpan={downloadButtonVisible ? 1 : 2}>
        <div>{history.nadminUser ? `${history.nadminUser} on behalf of ${escapeDoubleQuotes(history.reason)}` : escapeDoubleQuotes(history.reason)}</div>
      </td>
  </React.Fragment>

  )
}
