import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from 'components/application/CheckboxField';

export default function CheckboxCollection(props) {
  const checkedBoxes = props.checkBoxes.filter((checkbox) => checkbox.checked)
  const lastCheckedBox = checkedBoxes.length === 1 && checkedBoxes[0];

  return(
    <ul className={'checkbox-collection list-unstyled' + (props.nested ? ' checkbox-collection--nested' : '')}>
      {
        props.checkBoxes.map((checkbox, index) =>
          <li className='checkbox-collection__item' key={index}>
            <CheckboxField
              label={checkbox.label}
              checked={checkbox.checked}
              name={checkbox.name}
              value={checkbox.value}
              tooltip={checkbox.tooltip}
              tooltipAlignment={checkbox.tooltipAlignment}
              additionalContent={checkbox.additionalContent}
              renderNestedContent={checkbox.renderNestedContent}
              onChange={checkbox.onChange || props.onChange}
              disabled={props.isDisabled || (!!checkbox.disabled) || (props.mustSelect && checkbox === lastCheckedBox)}
              labelTextProps={props.labelTextProps}
            />
          </li>
        )
      }
    </ul>
  )
}

CheckboxCollection.propTypes = {
  checkBoxes: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  labelTextProps: PropTypes.object,
  nested: PropTypes.bool,
  isDisabled: PropTypes.bool
};

CheckboxCollection.defaultProps = {
  isDisabled: false,
  mustSelect: false
};
