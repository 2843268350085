import { connect } from 'react-redux';

import { findBelongsToRelation } from 'components/helpers/storeHelpers';
import { addError } from 'action-creators/ErrorActionCreators';

import ProjectTrade from '../components/sequences/ProjectTrade.jsx';

const mapStateToProps = (state, ownProps) => {
  const sequenceListIsOrdering = state.ui.ordering[`projectTradeSequences--${ownProps.projectTradeId}`].inProgress;
  const projectTrade = state.domain.projectTradeCollection.data[ownProps.projectTradeId];
  const trade = findBelongsToRelation(state, projectTrade, 'trade');
  const tradeName = trade.attributes.name;
  const isOrdering = state.ui.ordering.projectTrades;
  const newSequenceText = state.application.editingContent[`projectTradeNewSequence--${ownProps.projectTradeId}`];
  const newSequenceNameKey = `projectTradeNewSequence--${ownProps.projectTradeId}`;
  const isAddingSequence = !sequenceListIsOrdering && state.ui.toggling[`projectTradeNewSequence--${ownProps.projectTradeId}`];
  const isFocused = state.ui.focused.projectTrade === ownProps.projectTradeId

  return {
    isAddingSequence,
    isFocused,
    isOrdering,
    newSequenceNameKey,
    newSequenceText,
    projectTrade,
    tradeName
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    dispatch: dispatch,
    addError: (errorKey, payload) => dispatch(addError(errorKey, payload))
  }
}

const AvailableProjectTrade = connect(mapStateToProps, mapDispatchToProps)(ProjectTrade)

export default AvailableProjectTrade
