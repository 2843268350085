import React from 'react';
import PropTypes from 'prop-types';

export default function RangeField(props) {
  const {
    name,
    min = 0,
    max = 2,
    value,
    disabled = false,
    onChange
  } = props;

  return (
    <div className='tw-relative tw-inline-flex tw-w-full tw-h-[10px]'>
      <div className={`tw-absolute tw-top-[1px] tw-left-1/2 tw--ml-[1px] tw-h-2 tw-w-[2px] tw-cursor-pointer ${disabled ? 'tw-bg-grey-100' : 'tw-bg-grey-200'}`} />
      <input
        className='tw-apply-range'
        name={name}
        type='range'
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

RangeField.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
