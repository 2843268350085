import PropTypes from 'prop-types';
import React from 'react';
import { mapConfigurationShape } from './helpers';
import { resourceShape } from 'components/helpers/serialisableResources';

export default function MedicalLocationInputFields({
  domainMedicalLocationId,
  mapConfiguration,
  medicalLocation,
  showMap
}) {
  const medicalLocationActionRequired = !!domainMedicalLocationId
    ? !!medicalLocation
      ? 'update'
      : 'destroy'
    : !!medicalLocation
      ? 'create'
      : null;

  const mapConfigurationActionRequired = ['create', 'update'].includes(medicalLocationActionRequired)
    ? !!mapConfiguration.id
      ? showMap
        ? 'update'
        : 'destroy'
      : showMap
        ? 'create'
        : null
    : null;

  switch (medicalLocationActionRequired) {
    case 'create':
      return (
        <>
          <MedicalLocationAttributeInputFields medicalLocation={medicalLocation} />
          {mapConfigurationActionRequired === 'create' && <MedicalLocationMapConfigurationAttributeInputFields mapConfiguration={mapConfiguration} />}
        </>
      )
    case 'destroy':
      return (
        <>
          <MedicalLocationIdInputField domainMedicalLocationId={domainMedicalLocationId} />
          <input name='project[medical_location_attributes][_destroy]' type='hidden' value={1} />
        </>
      );
    case 'update':
      return (
        <>
          <MedicalLocationIdInputField domainMedicalLocationId={domainMedicalLocationId} />
          <MedicalLocationAttributeInputFields medicalLocation={medicalLocation} />
          {(mapConfigurationActionRequired === 'create' && <MedicalLocationMapConfigurationAttributeInputFields mapConfiguration={mapConfiguration} />) ||
            (mapConfigurationActionRequired === 'update' && (
              <>
                <MedicalLocationMapConfigurationIdInputField mapConfigurationId={mapConfiguration.id} />
                <MedicalLocationMapConfigurationAttributeInputFields mapConfiguration={mapConfiguration} />
              </>
            )) ||
            (mapConfigurationActionRequired === 'destroy' && (
              <>
                <MedicalLocationMapConfigurationIdInputField mapConfigurationId={mapConfiguration.id} />
                <input name='project[medical_location_attributes][medical_location_map_configuration_attributes][_destroy]' type='hidden' value={1} />
              </>
            ))}
        </>
      );
  }
}

MedicalLocationInputFields.propTypes = {
  domainMedicalLocationId: PropTypes.string,
  mapConfiguration: PropTypes.shape(mapConfigurationShape).isRequired,
  medicalLocation: PropTypes.oneOfType([
    resourceShape('assignableMedicalLocation'),
    resourceShape('medicalLocation')
  ]),
  showMap: PropTypes.bool.isRequired
};

function MedicalLocationIdInputField({ domainMedicalLocationId }) {
  return (
    <input name='project[medical_location_attributes][id]' type='hidden' value={domainMedicalLocationId} />
  )
}

function MedicalLocationAttributeInputFields({ medicalLocation }) {
  return (
    <>
      <input name='project[medical_location_attributes][address1]' type='hidden' value={medicalLocation.attributes.address1} />
      <input name='project[medical_location_attributes][address2]' type='hidden' value={medicalLocation.attributes.address2} />
      <input name='project[medical_location_attributes][city]' type='hidden' value={medicalLocation.attributes.city} />
      <input name='project[medical_location_attributes][external_id]' type='hidden' value={medicalLocation.attributes.externalId} />
      <input name='project[medical_location_attributes][latitude]' type='hidden' value={medicalLocation.attributes.latitude} />
      <input name='project[medical_location_attributes][longitude]' type='hidden' value={medicalLocation.attributes.longitude} />
      <input name='project[medical_location_attributes][max_age]' type='hidden' value={medicalLocation.attributes.maxAge} />
      <input name='project[medical_location_attributes][min_age]' type='hidden' value={medicalLocation.attributes.minAge} />
      <input name='project[medical_location_attributes][name]' type='hidden' value={medicalLocation.attributes.name} />
      <input name='project[medical_location_attributes][opening_hours]' type='hidden' value={JSON.stringify(medicalLocation.attributes.openingHours)} />
      <input name='project[medical_location_attributes][phone_number]' type='hidden' value={medicalLocation.attributes.phoneNumber} />
      <input name='project[medical_location_attributes][postcode]' type='hidden' value={medicalLocation.attributes.postcode} />
      <input name='project[medical_location_attributes][source]' type='hidden' value={medicalLocation.attributes.source} />
    </>
  )
};

function MedicalLocationMapConfigurationIdInputField({ mapConfigurationId }) {
  return (
    <input name='project[medical_location_attributes][medical_location_map_configuration_attributes][id]' type='hidden' value={mapConfigurationId} />
  )
};

function MedicalLocationMapConfigurationAttributeInputFields({ mapConfiguration }) {
  return (
    <>
      <input name='project[medical_location_attributes][medical_location_map_configuration_attributes][center_latitude]' type='hidden' value={mapConfiguration.centerLatitude} />
      <input name='project[medical_location_attributes][medical_location_map_configuration_attributes][center_longitude]' type='hidden' value={mapConfiguration.centerLongitude} />
      <input name='project[medical_location_attributes][medical_location_map_configuration_attributes][zoom_level]' type='hidden' value={mapConfiguration.zoomLevel} />
    </>
  )
};
