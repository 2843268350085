$(function() {
  if($('.risk-assessment-share')) {
    const shareSettings = {
      allCheckboxes: [].slice.call($("input[name='master_activity[trade_ids][]']")),

      setup: function() {
        this.checkboxEvents();
        this.disableCheckbox();
        this.formSubmitEvent();
      },

      checkboxEvents: function() {
        const _this = this;

        this.allCheckboxes.forEach(function(checkbox) {
          $(checkbox).change(function(_event) {
            _this.disableCheckbox();
            _this.updateFormAction();
          });
        });
      },

      disableCheckbox: function() {
        const checkedCheckboxes = $("input[name='master_activity[trade_ids][]']:checked");

        if (checkedCheckboxes.length === 1) {
          $(checkedCheckboxes[0]).prop('disabled', true);
        } else {
          [].slice.call(checkedCheckboxes).forEach(function(checkbox) {
            $(checkbox).prop('disabled', false);
          });
        }
      },

      updateFormAction: function() {
        const firstCheckedCheckbox = $("input[name='master_activity[trade_ids][]']:checked")[0];

        $('form').attr('action', $(firstCheckedCheckbox).data('url'));
      },

      formSubmitEvent: function() {
        $('form').submit(function(_event) {
          $('input').prop('disabled', false);
        });
      }
    };

    shareSettings.setup();
  }
});
