import { getUrlParameter, disableAddFileButton } from '../../attachments';
import { sanitizeFilename } from 'helpers/files';

$( document ).ready( function() {
  "use strict";
  $( '#pdf_file' ).on( 'change', submitForm );
    // Checking to see if a new pdf has been added to a project and jump to it
  if ( getUrlParameter( "pdf_id" ) ) {
    location.hash = getUrlParameter( "pdf_id" );
  }

  function submitForm ( event ) {
    const allowedTypes = [ 'pdf' ];
    const _formButton = event.currentTarget;
    const displayButton = $('.upload_button');

    const key = $('#upload_pdf input[name="key"]').val()
    const pdfFilename = sanitizeFilename($(event.currentTarget).val().split('/').pop().split('\\').pop());

    const inputFile = $( event.currentTarget );
    const inputFileType = event.currentTarget.files[0].type.split('/').pop().toLowerCase();
    const inputFileSize = event.currentTarget.files[0].size;

    if ( $.inArray( inputFileType, allowedTypes) >= 0 && inputFileSize <= 10000000 ) {
      disableAddFileButton( displayButton );
      $('#upload_pdf input[name="key"]').val(key.replace('${filename}', pdfFilename))
      $('#upload_pdf').submit();
      fetchPendingPdf();
    } else if ( $.inArray( inputFileType, allowedTypes) < 0 ) {
      $(".pdf-warning").modal();
      inputFile.replaceWith(inputFile.val( '' ).clone( true ) );
    } else {
      $(".file-warning").modal();
      inputFile.replaceWith(inputFile.val( '' ).clone( true ) );
    }

    // fetches pending pdf row
    function fetchPendingPdf() {
      const projectId = typeof project_id !== 'undefined' ? project_id : undefined;
      const companyId = typeof company_id !== 'undefined' ? company_id : undefined;

      const data = {
        pdf_filename: pdfFilename
      }

      if ( projectId ) {
        $.ajax({
          url: '/projects/'+ projectId.value + '/pdfs/new.js',
          type: 'GET',
          data: data
        });
      } else if ( companyId ) {
        $.ajax({
          url: '/companies/'+ companyId.value + '/pdfs/new.js',
          type: 'GET',
          data: data
        });
      }
    }
  }
});
