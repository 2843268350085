import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { titleize } from 'components/helpers/strings';
import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import ReadOnlyField from 'components/application/ReadOnlyField';
import CopyableField from 'components/application/CopyableField';
import FilledButton from 'components/application/buttons/FilledButton';
import { DropdownIndicator, Option } from 'components/application/CollectionSelect';

function formatValue(value) {
  return { value: value, label: titleize(value) }
}

export default function EnableFormCard(props) {
  const {
    domainNames,
    serviceProviderMetadataUrl,
    assertionConsumerServiceUrl,
    identityProviderNames,
    formValues,
    isIdentityProviderEnforced,
    requestError,
    removeErrorStyling,
    onInputChange,
    onNameChange,
    onSubmit
  } = props;

  const isFieldInvalid = (field) => {
    const validationError = requestError.validationErrors[field];

    return !!validationError && validationError.fieldHighlighted;
  }

  return (
    <div className='section p-40 tw-bg-white tw-border-grey-100'>
      <h3 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-tracking-auto m-t-0 m-b-8'>Single sign-on</h3>
      <p className='m-0 m-b-32'>
        <span>For help setting up single sign-on, read our </span>
        <a className='app-link va-baseline tw-font-medium tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='https://intercom.help/hands-hq/en/articles/6887426-guide-to-single-sign-on-saml-sso' target='_blank'>help guide</a>
        <span>.</span>
      </p>
      <div className='m-0 form-group'>
        <label className='hhq-checkbox__label tooltip-parent' htmlFor='enabled'>
          <input
            className='hhq-checkbox__input'
            id='enabled'
            name='enabled'
            type='checkbox'
            value='enabled'
            checked={formValues.enabled}
            disabled={isIdentityProviderEnforced}
            onChange={onInputChange}
          />
          <span className={`hhq-checkbox__toggle tw-cursor-pointer tw-text-white tw-font-bold tw-border-2 ${formValues.enabled ? 'tw-bg-blue-400 tw-border-blue-400 hover:tw-bg-blue-500 hover:tw-border-blue-500' : 'tw-bg-white tw-border-grey-300 hover:tw-bg-grey-025 hover:tw-border-blue-300'}`}></span>
          {isIdentityProviderEnforced && (
            <div className='pos-abs pos-t-0 pos-l-0 fh-22 fw-22'>
              <Tooltip placement='top' trigger='hover' tooltip='Single sign-on must not be enforced' />
            </div>
          )}
          <span className='tw-font-normal tw-tracking-auto m-l-12'>
            Enable single sign-on
          </span>
        </label>
      </div>
      {formValues.enabled && (
        <>
          <div className='m-t-32 m-b-32 form-group'>
            <label className='field__label tw-font-medium' htmlFor='domain_names'>
              <span>Domain names</span>
              <Tooltip placement='top' trigger='hover' tooltip='These need to match the domain names in your identify provider. To change these, speak to your account manager.'>
                <CircleQuestion />
              </Tooltip>
            </label>
            <div className='flex'>
              <ReadOnlyField
                name='domain_names'
                value={domainNames}
              />
              <div className='fw-40 fh-40'></div>
            </div>
          </div>
          <div className='m-b-32 form-group'>
            <CopyableUrl
              labelText='Service provider metadata URL'
              name='service_provider_metadata_url'
              value={serviceProviderMetadataUrl}
              tooltipText='The intended audience of the SAML Assertion'
            />
          </div>
          <div className='m-b-32 form-group'>
            <CopyableUrl
              labelText='Service provider single sign-on URL'
              name='assertion_consumer_service_url'
              value={assertionConsumerServiceUrl}
              tooltipText='The destination where the SAML Assertion is sent'
            />
          </div>
          <hr className='tw-h-px tw-bg-grey-100 tw-m-0 tw-border-0 tw-my-8' />
          <div className='w-50 m-b-32 form-group'>
            <label className={`field__label tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal`} htmlFor='name'>
              <span>Identity provider</span>
            </label>
            <Select
              className='collection-select__select-container'
              classNamePrefix='collection-select'
              id='name'
              name='name'
              components={{ DropdownIndicator, Option }}
              options={identityProviderNames.map(name => formatValue(name))}
              value={formatValue(formValues.name)}
              onChange={onNameChange}
            />
          </div>
          <div className={`m-b-32 form-group`}>
            <label className={`field__label tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal${isFieldInvalid('targetUrl') ? " after:tw-text-red-600" : ''}`} htmlFor='targetUrl'>
              <span>Identity provider single sign-on URL</span>
              <Tooltip placement='top' trigger='hover' tooltip='The destination of your identity provider sign-in'>
                <CircleQuestion />
              </Tooltip>
            </label>
            <input
              className={`field__input tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${isFieldInvalid('targetUrl') ? "tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600" : "tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300"}`}
              id='targetUrl'
              name='targetUrl'
              type='text'
              value={formValues.targetUrl}
              onChange={onInputChange}
              onFocus={removeErrorStyling}
            />
          </div>
          <div className={`form-group`}>
            <label className={`field__label tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal${isFieldInvalid('certificate') ? " after:tw-text-red-600" : ''}`} htmlFor='certificate'>
              <span>Identity provider certificate</span>
              <Tooltip placement='top' trigger='hover' tooltip='The SAML signing certificate'>
                <CircleQuestion />
              </Tooltip>
            </label>
            <textarea
              className={`field__input field__input--textarea fh-320 tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${isFieldInvalid('certificate') ? "tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600" : "tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300"}`}
              id='certificate'
              name='certificate'
              rows='12'
              spellCheck='false'
              value={formValues.certificate}
              onChange={onInputChange}
              onFocus={removeErrorStyling}
            />
          </div>
        </>
      )}
      <FilledButton color='blue' className='m-t-40' onClick={onSubmit}>
        Save changes
      </FilledButton>
    </div>
  )
}

const CopyableUrl = ({ labelText, name, value, tooltipText }) => {
  return (
    <>
      <label className='field__label tw-font-medium' htmlFor={name}>
        <span>{labelText}</span>
        <Tooltip placement='top' trigger='hover' tooltip={tooltipText}>
          <CircleQuestion />
        </Tooltip>
      </label>
      <CopyableField
        name={name}
        value={value}
      />
    </>
  )
}

EnableFormCard.propTypes = {
  domainNames: PropTypes.string.isRequired,
  serviceProviderMetadataUrl: PropTypes.string.isRequired,
  assertionConsumerServiceUrl: PropTypes.string.isRequired,
  identityProviderNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  formValues: PropTypes.object.isRequired,
  isIdentityProviderEnforced: PropTypes.bool.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
