import { useState } from 'react';

export default function useSidePanel(defaultOpenValue, defaultContext) {
  const [sidePanelIsOpen, setSidePanelIsOpen] = useState(defaultOpenValue);
  const [sidePanelContext, setSidePanelContext] = useState(defaultContext)
  const openSidePanel = () => { setSidePanelIsOpen(true) }
  const closeSidePanel = () => { setSidePanelIsOpen(false) }
  const resetSidePanelContext = () => { setSidePanelContext(defaultContext) }

  return(
    [
      sidePanelIsOpen,
      setSidePanelIsOpen,
      openSidePanel,
      closeSidePanel,
      resetSidePanelContext,
      sidePanelContext,
      setSidePanelContext
    ]
  )
}
