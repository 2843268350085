import React from 'react';
import PropTypes from 'prop-types';

const justifyContentClassNameMap = {
  'start': 'flex--justify-content__flex-start',
  'center': 'flex--justify-content__center',
  'end': 'flex--justify-content__flex-end'
};

export default function TableCell(props) {
  const {
    style,
    justifyContent,
    onClick,
    children
  } = props;

  return (
    <td className='tw-border-grey-100' style={style} onClick={onClick}>
      <div className={`flex flex--vertically-centered ${justifyContentClassNameMap[justifyContent]} fh-32`}>
        {children}
      </div>
    </td>
  )
}

TableCell.protoTypes = {
  style: PropTypes.object,
  justifyContent: PropTypes.string,
  onClick: PropTypes.func
};

TableCell.defaultProps = {
  justifyContent: 'start'
};
