import moment from "moment";

export function formatPaginatedProjects(data) {
  return data.pages.map(projects => formatProjects(projects)).flat()
}

export function formatProjects(projects) {
  const inclusions = projects.included.reduce((acc, inclusion) => {
    acc[inclusion.type][inclusion.id] = inclusion;

    return acc
  }, { clientField: {}, companyField: {}, company: {}, user: {}, scheduledContentReviewRequest: {} });

  const findInclusion = ({ project, resourceType }) => {
    const relationship = project.relationships[resourceType];

    return relationship ? inclusions[resourceType][(relationship.data || {}).id] : null
  }

  const riskAssessmentsStatus = ({ project, hasOutOfDateActivities }) => {
    switch (project.attributes.lifecycleStatus) {
      case ("notSet"): return "noStatus"
      case ("ended"): return "notApplicable"
      case ("live"): return hasOutOfDateActivities ? "outOfDate" : "upToDate"
      case ("future"): return hasOutOfDateActivities ? "outOfDate" : "upToDate"
    }
  }

  const reviewDueDate = ({ dueDate }) => moment.parseZone(dueDate).format("DD MMM YY")

  return projects.data.map((project) => {
    const clientField = findInclusion({ project, resourceType: "clientField" });
    const companyField = findInclusion({ project, resourceType: "companyField" });
    const company = findInclusion({ project, resourceType: "company" });
    const user = findInclusion({ project, resourceType: "user" });
    const hasOutOfDateActivities = !!project.relationships.outOfDateActivities && project.relationships.outOfDateActivities.data.length > 0;
    const scheduledContentReviewRequest = findInclusion({ project, resourceType: "scheduledContentReviewRequest" });

    return {
      id: project.id,
      lifecycleStatus: project.attributes.lifecycleStatus,
      name: project.attributes.name,
      client: (clientField && clientField.attributes.value) || (companyField && companyField.attributes.value),
      divisionName: (company && company.attributes.name),
      creator: (user && `${user.attributes.firstName}${user.attributes.lastName ? ` ${user.attributes.lastName}` : ''}`),
      riskAssessmentsStatus: riskAssessmentsStatus({ project, hasOutOfDateActivities }),
      reviewStatus: (scheduledContentReviewRequest && scheduledContentReviewRequest.attributes.reviewRequestStatus),
      reviewDueDate: (scheduledContentReviewRequest && reviewDueDate({ dueDate: scheduledContentReviewRequest.attributes.dueDate })),
      approvalStatus: project.attributes.state,
      archivedAt: project.attributes.archivedAt,
      companyId: (company && company.id)
    }
  })
}
