import React from 'react';
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function BookingReminderModal({
  isOpen,
  onClose,
  booking,
  personnel,
  course,
  onSendReminderConfirmation
}) {

  const onSendReminder = () => {
    onClose()
    onSendReminderConfirmation({ booking, course })
  }

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton color='mint' onClick={onSendReminder}>
        Send reminder
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <div className='p-32'>
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0'>Are you sure you want to send a reminder to {personnel && personnel.firstName} to take {course && course.attributes.name}?</h2>
      </div>
    </ModalBox>
  )
}
