import HandshqApp from './handshq-app';

HandshqApp.datepicker = {
  setup: function() {
    const _this = this;

    this.projectStartDate = $('input#project_start_date');
    this.projectEndDate = $('input#project_end_date');
    this.projectForm = $('.project-form');

    this.initDatePicker(_this.projectStartDate, { todayHighlight: true, container: _this.projectForm });
    this.initDatePicker(_this.projectEndDate, { container: _this.projectForm });
    this.initDatePicker($('.nadmin-content .field .date_field'));
    this.initDatePicker($('.project-form .field .date_field'), { container: _this.projectForm });
    this.initDatePicker($('.pickable-date-field'));

    if (_this.projectStartDate.length && _this.projectEndDate.length) { this.projectStartDateOnChange() }
  },

  initDatePicker: function(elements, options) {
    const defaultOptions = {
      format: 'dd/mm/yyyy',
      weekStart: 1,
      autoclose: true,
      clearBtn: true
    };

    $.extend(defaultOptions, options);
    elements.datepicker(defaultOptions);
  },

  projectStartDateOnChange: function() {
    const _this = this;

    _this.projectStartDate.datepicker().on('changeDate', function() {
      const startDate = _this.projectStartDate.datepicker('getDate');

      _this.projectEndDate.datepicker('setStartDate', startDate);
    });
  }
};

$(function() { HandshqApp.datepicker.setup() });
