function ProjectExporter() {
  if (this instanceof ProjectExporter) {
    this.modal = $('.export-request__modal');

    this.modalContentRequest = this.modal.find('.export-request__content-request');
    this.modalContentEmailSent = this.modal.find('.export-request__content-email-sent');
    this.modalContentFailure = this.modal.find('.export-request__content-failure');

    this.modalRequestButton = this.modal.find('.export-request__request');
    this.modalTryAgainButton = this.modal.find('.export-request__try-again');

    this.handleExportRequest();
    this.handleExportTryAgain();
  } else {
    throw new Error('ProjectExporter invoked without new');
  }
}

ProjectExporter.prototype.handleExportRequest = function() {
  const _this = this;

  this.modalRequestButton.on('click', function() { _this.requestExport() });
};

ProjectExporter.prototype.handleExportTryAgain = function() {
  const _this = this;

  this.modalTryAgainButton.on('click', function() { _this.modalAsRequest() });
};

ProjectExporter.prototype.toggleModalContent = function(elements) {
  $.each(elements['hide'], function(key, element) {
    element.hide();
  });

  elements['show'].show();
};

ProjectExporter.prototype.requestExport = function() {
  const _this = this;
  const exportPath = this.modalRequestButton.data('export-path');
  const searchForm = $('#project_search');
  const searchParams = searchForm.serialize();

  $.ajax({
    type: 'GET',
    url: exportPath,
    data: searchParams,
    cache: false,
    success: function(_data) { _this.modalAsEmailSent() },
    error: function(_data) { _this.modalAsFailure() }
  });
};

ProjectExporter.prototype.modalAsRequest = function() {
  this.toggleModalContent({
    show: this.modalContentRequest,
    hide: [this.modalContentEmailSent, this.modalContentFailure]
  });
};

ProjectExporter.prototype.modalAsEmailSent = function() {
  this.toggleModalContent({
    show: this.modalContentEmailSent,
    hide: [this.modalContentRequest, this.modalContentFailure]
  });
};

ProjectExporter.prototype.modalAsFailure = function() {
  this.toggleModalContent({
    show: this.modalContentFailure,
    hide: [this.modalContentRequest, this.modalContentEmailSent]
  });
};

export default ProjectExporter;
