import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { toggleGrabbing } from 'components/helpers/document';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';
import FieldRow from 'components/settings/personnelInformation/FieldRow';

const SortableItem = SortableElement(({ formattedRow, onIncludedToggle, onRestrictedToggle, onEdit, onDestroyFieldAttribute, onDestroyFieldValues }) => (
  <FieldRow
    key={formattedRow.fieldSetting.id}
    fieldSetting={formattedRow.fieldSetting}
    fieldAttribute={formattedRow.fieldAttribute}
    onIncludedToggle={onIncludedToggle}
    onRestrictedToggle={onRestrictedToggle}
    onEdit={onEdit}
    onDestroyFieldAttribute={onDestroyFieldAttribute}
    onDestroyFieldValues={onDestroyFieldValues}
  />
));

const SortableList = SortableContainer(({ formattedRows, isSorting, onIncludedToggle, onRestrictedToggle, onEdit, onDestroyFieldAttribute, onDestroyFieldValues }) => {
  return (
    <div className='no-user-select pos-rel zi-1'>
      <div className='header-bar header-bar--border p-l-20 p-r-20 tw-border-grey-100 tw-bg-grey-050'>
        <div className='fw-52 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Include</div>
        <div className='flex-item flex-item--grow-but-truncate tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Name</div>
        <div className='fw-148 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Field type</div>
        <div className='fw-124 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
          <div className='flex flex--vertically-centered'>
            <span className='m-r-4'>Visibility</span>
            <Tooltip placement='top' trigger='hover' tooltip='Restricted information can only be seen by personnel editors'>
              <CircleQuestion size='sm' />
            </Tooltip>
          </div>
        </div>
        <div className='fw-80 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>
          <div className='flex flex--vertically-centered'>
            <span className='m-r-4'>Records</span>
            <Tooltip placement='top' trigger='hover' tooltip='Number of personnel with this information stored'>
              <CircleQuestion size='sm' />
            </Tooltip>
          </div>
        </div>
        <div className='fw-40 tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide'>Actions</div>
      </div>
      <ul className='block-list block-list--border-bottom-none pos-rel zi-1'>
        {formattedRows.map((formattedRow, index) => (
          <Fragment key={`sortable-item-${formattedRow.fieldSetting.id}`}>
            {isSorting && <div className='row-bar--ghost tw-border-grey-100'></div>}
            <SortableItem
              index={index}
              formattedRow={formattedRow}
              onIncludedToggle={onIncludedToggle}
              onRestrictedToggle={onRestrictedToggle}
              onEdit={onEdit}
              onDestroyFieldAttribute={onDestroyFieldAttribute}
              onDestroyFieldValues={onDestroyFieldValues}
            />
          </Fragment>
        ))}
      </ul>
    </div>
  )
});

export default function FieldTable({
  formattedRows,
  onIncludedToggle,
  onRestrictedToggle,
  onEdit,
  onDestroyFieldAttribute,
  onDestroyFieldValues,
  onSortEnd: propsOnSortEnd,
}) {
  const [isSorting, setIsSorting] = useState(false);

  function handleSortStart() {
    setIsSorting(true)
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    propsOnSortEnd({ oldIndex, newIndex })
    setIsSorting(false)
  }

  useEffect(() => {
    toggleGrabbing(isSorting);
  }, [isSorting]);

  return (
    <SortableList
      lockAxis='y'
      axis='y'
      useDragHandle={true}
      useWindowAsScrollContainer={true}
      helperClass='sortable-item--active'
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
      formattedRows={formattedRows}
      isSorting={isSorting}
      onIncludedToggle={onIncludedToggle}
      onRestrictedToggle={onRestrictedToggle}
      onEdit={onEdit}
      onDestroyFieldAttribute={onDestroyFieldAttribute}
      onDestroyFieldValues={onDestroyFieldValues}
    />
  )
}

FieldTable.propTypes = {
  formattedRows: PropTypes.arrayOf(PropTypes.shape({
    fieldAttribute: PropTypes.object.isRequired,
    fieldSetting: PropTypes.object.isRequired,
  })).isRequired,
  onIncludedToggle: PropTypes.func.isRequired,
  onRestrictedToggle: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDestroyFieldAttribute: PropTypes.func.isRequired,
  onDestroyFieldValues: PropTypes.func.isRequired
};
