import { useState } from "react";

export default function useProjectsModal() {
  const initialState = { isOpen: false, title: "", subTitle: "", filters: {} };
  const [projectsModal, setProjectsModal] = useState(initialState);

  const openProjectsModal = ({ title, subTitle, appliedFilters }) => {
    setProjectsModal({ isOpen: true, title, subTitle, filters: appliedFilters })
  }

  const closeProjectsModal = () => setProjectsModal(initialState)

  return {
    projectsModal,
    openProjectsModal,
    closeProjectsModal
  }
}
